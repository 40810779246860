import React from "react";
import { Link } from "react-router-dom";

class DisplayMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: this.props.count,
      failureCount: this.props.zeroImagesToAction ? 0 : this.props.failureCount,
      zeroImagesToAction: this.props.zeroImagesToAction,
    };
  }
  lastClickHandler = () => {
    this.props.closeModel();
  };

  render() {
    let boxView;
    let windowText;
    let failureText;

    if (this.state.zeroImagesToAction) {
      windowText = "No Images available to take action on ";
    } else {
      windowText = "Unapproved Image Count = " + this.state.number;
    }

    if (this.props.last) {
      boxView = (
        <button
          className="btn fw-normal btn-blue txt-white pl-3 pr-3 mr-2 pop-btn "
          onClick={this.lastClickHandler.bind(this)}
        >
          Ok
        </button>
      );
      if (this.props.isApprove === 2) {
        windowText = "Approved Image Count = " + this.state.number;
        failureText = "Actioned by someone else = ";
      }
    } else if (this.props.isApprove === 2 && !this.props.last) {
      boxView = (
        <div>
          <Link
            to={"/qcuser/dailyqcdashboard"}
            className="btn fw-normal btn-white txt-blue pl-3 pr-3 mr-3 pop-btn text-center"
          >
            Daily QC dashboard
          </Link>
          <button
            type="button"
            className="btn fw-normal btn-blue txt-white pl-3 pr-3 pop-btn"
            onClick={this.props.closeModel}
          >
            Next Image
          </button>
        </div>
      );
      windowText = "Successfully Approved Image Count = " + this.state.number;
      failureText = "Actioned by someone else = ";
    } else if (this.props.isApprove === 5) {
      boxView = (
        <button
          type="button"
          className="btn fw-normal btn-blue txt-white pl-3 pr-3 mr-2 pop-btn "
          onClick={this.props.closeModel}
        >
          Ok
        </button>
      );
      windowText = "Successfully Approved Image Count = " + this.state.number;
      failureText = "Actioned by someone else = ";
    } else if (this.props.isApprove === 6) {
      boxView = (
        <button
          type="button"
          className="btn fw-normal btn-blue txt-white pl-3 pr-3 mr-2 pop-btn "
          onClick={this.props.closeModel}
        >
          Ok
        </button>
      );
      windowText = "Successfully Rejected Image Count = " + this.state.number;
      failureText = "Actioned by someone else = ";
    } else {
      boxView = (
        <button
          type="button"
          className="btn fw-normal btn-blue txt-white pl-3 pr-3 mr-2 pop-btn "
          onClick={this.props.closeModel}
        >
          Ok
        </button>
      );
    }

    return (
      <div>
        <div>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={this.props.closeModel}
          >
            &times;
          </button>
        </div>
        <div className="modal-content brnone bbnone blnone ">
          <div className="modal-body pt-3 text-center">
            {windowText} <br />
            {this.state.failureCount > 0
              ? failureText + this.state.failureCount
              : null}
          </div>
          <div className="modal-footer approve-footer text-center">
            {boxView}
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayMessage;
