import React, { Component } from "react";
import Logo from "../../assets/images/Gracenote-logo-mini-nielsen-white-2017.png";
import Tick from "../../assets/images/check-mark.png";
import { Link } from "react-router-dom";
import axios from "axios";
import Navigator from "./Navigator";
import config from "../../containers/config";
import { userService } from "../../services/userService";
import { authHeader } from "../../services/authHeader";
import { RedirectedIfError } from "../../hoc/RedirectedIfError";
// import { Redirect } from 'react-router-dom';
import ReactJoyride, { STATUS } from "react-joyride";
import { SharedService } from "../SharedService/SharedService";
let $ = require("jquery");

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapRoles: [],
      mapMenu: [],
      role: "",
      roleId: 0,
      userRoleID: 0,
      loading: true,
      run: false,
      dailyqcsteps: [
        {
          content: (
            <div className="background-both">
              Start the demo tour for<br></br>
              <span>Daily QC Worklist page.</span>
            </div>
          ),
          placement: "center",
          target: ".start",
        },
        {
          content: (
            <div className="background-right">
              <span>Collapsible sidebar menu</span>
            </div>
          ),
          placement: "bottom",
          target: ".app-sidebar__toggle",
        },
        {
          content: (
            <div className="background-left">
              <span>Change user roles</span>
            </div>
          ),
          placement: "bottom",
          target: ".roleChange",
        },
        {
          content: (
            <div className="background-left">
              <span>Logout from the Tool</span>
            </div>
          ),
          placement: "bottom",
          target: ".logout",
        },
        {
          content: (
            <div className="background-both">
              <span>Focus on the tabs</span> using <span>Shift+T key.</span>
            </div>
          ),
          placement: "top",
          target: ".react-tabs__tab-list",
        },
        {
          content: (
            <div className="background-left">
              <span>Apply the filter</span> using <span>Enter key.</span>
            </div>
          ),
          placement: "top",
          target: ".filter",
        },
        {
          content: (
            <div className="background-right">
              <span>Reset the filter</span> using <span>R key</span>
            </div>
          ),
          placement: "top",
          target: ".reset",
        },
        {
          content: (
            <div className="background-left">
              <span>Approve the images in bulk</span>
            </div>
          ),
          placement: "top",
          target: ".bulkApprove",
        },
        {
          content: (
            <div className="background-right">
              <span>Reject the images in bulk</span>
            </div>
          ),
          placement: "top",
          target: ".bulkReject",
        },
        {
          content: (
            <div className="background-both">
              <span>Click on image</span> to see <span>image details.</span>
            </div>
          ),
          placement: "top",
          target: ".gallery-img",
        },
        {
          content: (
            <div className="background-left">
              <span>Select Number of images</span> on page
            </div>
          ),
          placement: "top",
          target: ".imgCount",
        },
        {
          content: (
            <div className="background-right">
              <span>Navigate to the next set of images/page</span>
            </div>
          ),
          placement: "top",
          target: ".next",
        },
        {
          content: (
            <div className="background-left">
              <span>Select Number of images</span> on page
            </div>
          ),
          placement: "top",
          target: ".-pageSizeOptions",
        },
        {
          content: (
            <div className="background-right">
              <span>Navigate to the next set of images/page</span>
            </div>
          ),
          placement: "top",
          target: ".-next .-btn",
        },
        {
          content: (
            <div className="background-both">
              <div>
                <img alt="checkmark" className="check-mark" src={Tick}></img>
              </div>
              <div>
                <span className="fs18 mb10">Tour Complete</span>
              </div>
              <div className="complete-text">
                Perfect! You are now familiar with the basics of QC Tool.
                <br></br>As you discover more of the application we'll assist
                you with helpful tours along the way. Have a nice day!
              </div>
            </div>
          ),
          placement: "center",
          target: ".complete",
          styles: {
            options: {
              width: 450,
            },
          },
          hideBackButton: true,
          locale: { last: "Ok" },
        },
      ],
      singleImageSteps: [
        {
          content: (
            <div className="background-both">
              Start the demo tour for <br></br>
              <span>Single image view page</span>
            </div>
          ),
          placement: "center",
          target: ".start",
        },
        {
          content: (
            <div className="background-left">
              <span>System generated Image properties</span>
            </div>
          ),
          placement: "bottom",
          target: ".system_properties",
        },
        {
          content: (
            <div className="background-right">
              <span>Close the Single Image View</span>
            </div>
          ),
          placement: "bottom",
          target: ".close_icon",
        },
        {
          content: (
            <div className="background-left">
              <span>Navigate to CMS "Image Details" page</span>
            </div>
          ),
          placement: "left",
          target: ".edit_icon",
        },
        {
          content: (
            <div className="background-left">
              <span>Collapse top panel</span>
            </div>
          ),
          placement: "top",
          target: ".top-toggle-image",
        },
        {
          content: (
            <div className="background-both">
              <span>Zoom the Image</span> by <br></br>
              <span>clicking on the image.</span>
            </div>
          ),
          placement: "top",
          target: ".img-fluid",
        },
        {
          content: (
            <div className="background-right">
              <span>Navigate to next image</span>
            </div>
          ),
          placement: "top",
          target: ".arrow",
        },
        {
          content: (
            <div className="background-left">
              <span>Editor populated Image properties</span>
            </div>
          ),
          placement: "left",
          target: ".editor_properties",
        },
        {
          content: (
            <div className="background-left">
              <span>Collapse right panel</span>
            </div>
          ),
          placement: "left",
          target: ".collapse-arrow",
        },
        {
          content: (
            <div className="background-right">
              <span>Select the error type</span>
            </div>
          ),
          placement: "top",
          target: ".error_type",
        },
        {
          content: (
            <div className="background-left">
              <span>Submit the comments</span> using <span>Enter key</span>
            </div>
          ),
          placement: "top",
          target: ".comments",
        },
        {
          content: (
            <div className="background-right">
              <span>Revert to Editor </span>the image using <br></br>
              <span>Shift+D key</span>
            </div>
          ),
          placement: "top",
          target: ".revert-editor",
        },
        {
          content: (
            <div className="background-right">
              <span>Revert to QC </span>the image using <span>Shift+Q</span>
            </div>
          ),
          placement: "top",
          target: ".revert",
        },
        {
          content: (
            <div className="background-left">
              <span>Acknowledge</span> the image using <span>Shift+K</span>
            </div>
          ),
          placement: "top",
          target: ".acknowledge",
        },
        {
          content: (
            <div className="background-right">
              <span>Approve with Edits</span> the image using{" "}
              <span>Shift+E key</span>
            </div>
          ),
          placement: "top",
          target: ".btn-edit",
        },
        {
          content: (
            <div className="background-left">
              <span>Reject the image</span> using <span>Shift+R key</span>
            </div>
          ),
          placement: "top",
          target: ".btn-reject",
        },
        {
          content: (
            <div className="background-right">
              <span>Approve the image</span> using <span>Shift+A key</span>
            </div>
          ),
          placement: "top",
          target: ".btn-approve",
        },
        {
          content: (
            <div className="background-left">
              <span>Improvise the image</span> using <span>Shift+I key</span>
            </div>
          ),
          placement: "top",
          target: ".btn-improvisation",
        },
        {
          content: (
            <div className="background-both">
              <div>
                <img alt="checkmark" className="check-mark" src={Tick}></img>
              </div>
              <div>
                <span className="fs18 mb10">Tour Complete</span>
              </div>
              <div className="complete-text">
                Perfect! You are now familiar with the basics of QC Tool.
                <br></br>As you discover more of the application we'll assist
                you with helpful tours along the way. Have a nice day!
              </div>
            </div>
          ),
          placement: "center",
          target: ".complete",
          styles: {
            options: {
              width: 450,
            },
          },
          hideBackButton: true,
          locale: { last: "Ok" },
        },
      ],
      editorDailyQCStep: [
        {
          content: (
            <div className="background-both">
              Start the demo tour for<br></br>
              <span>Daily QC Worklist page.</span>
            </div>
          ),
          placement: "center",
          target: ".start",
        },
        {
          content: (
            <div className="background-right">
              <span>Collapsible sidebar menu</span>
            </div>
          ),
          placement: "bottom",
          target: ".app-sidebar__toggle",
        },
        {
          content: (
            <div className="background-left">
              <span>Change user roles</span>
            </div>
          ),
          placement: "bottom",
          target: ".roleChange",
        },
        {
          content: (
            <div className="background-left">
              <span>Logout from the Tool</span>
            </div>
          ),
          placement: "bottom",
          target: ".logout",
        },
        {
          content: (
            <div className="background-both">
              <span>Focus on the tabs</span> using <span>Shift+T key.</span>
            </div>
          ),
          placement: "top",
          target: ".react-tabs__tab-list",
        },
        {
          content: (
            <div className="background-left">
              <span>Apply the filter</span> using <span>Enter key.</span>
            </div>
          ),
          placement: "top",
          target: ".search",
        },
        {
          content: (
            <div className="background-right">
              <span>Reset the filter</span> using <span>R key</span>.
            </div>
          ),
          placement: "top",
          target: ".reset",
        },
        {
          content: (
            <div className="background-left">
              <span>Select Number of rows</span> on page
            </div>
          ),
          placement: "top",
          target: ".-pageSizeOptions",
        },
        {
          content: (
            <div className="background-right">
              <span>Navigate to the next set of results</span>
            </div>
          ),
          placement: "top",
          target: ".-next .-btn",
        },
        {
          content: (
            <div className="background-both">
              <div>
                <img alt="checkmark" className="check-mark" src={Tick}></img>
              </div>
              <div>
                <span className="fs18 mb10">Tour Complete</span>
              </div>
              <div className="complete-text">
                Perfect! You are now familiar with the basics of QC Tool.
                <br></br>As you discover more of the application we'll assist
                you with helpful tours along the way. Have a nice day!
              </div>
            </div>
          ),
          placement: "center",
          target: ".complete",
          styles: {
            options: {
              width: 450,
            },
          },
          hideBackButton: true,
          locale: { last: "Ok" },
        },
      ],
      editorSingleImageStep: [
        {
          content: (
            <div className="background-both">
              Start the demo tour for <br></br>
              <span>Single image view page</span>
            </div>
          ),
          placement: "center",
          target: ".start",
        },
        {
          content: (
            <div className="background-left">
              <span>System generated Image properties</span>
            </div>
          ),
          placement: "bottom",
          target: ".system_properties",
        },
        {
          content: (
            <div className="background-right">
              <span>Close the Single Image View</span>
            </div>
          ),
          placement: "bottom",
          target: ".close_icon",
        },
        {
          content: (
            <div className="background-left">
              <span>Navigate to CMS "Image Details" page</span>
            </div>
          ),
          placement: "left",
          target: ".edit_icon",
        },
        {
          content: (
            <div className="background-left">
              <span>Collapse top panel</span>
            </div>
          ),
          placement: "top",
          target: ".top-toggle-image",
        },
        {
          content: (
            <div className="background-both">
              <span>Zoom the Image</span> by <span>clicking on the image.</span>
            </div>
          ),
          placement: "top",
          target: ".img-fluid",
        },
        {
          content: (
            <div className="background-right">
              <span>Navigate to next image</span>
            </div>
          ),
          placement: "top",
          target: ".arrow",
        },
        {
          content: (
            <div className="background-left">
              <span>Editor populated Image properties</span>
            </div>
          ),
          placement: "left",
          target: ".editor_properties",
        },
        {
          content: (
            <div className="background-left">
              <span>Collapse right panel</span>
            </div>
          ),
          placement: "left",
          target: ".collapse-arrow",
        },
        {
          content: (
            <div className="background-left">
              <span>Submit the comments</span> using <span>Enter key</span>
            </div>
          ),
          placement: "top",
          target: ".comments",
        },
        {
          content: (
            <div className="background-right">
              <span>Revert to QC </span>the image using <span>Shift+Q</span>
            </div>
          ),
          placement: "top",
          target: ".revert",
        },
        {
          content: (
            <div className="background-left">
              <span>Acknowledge</span> the image using <span>Shift+K</span>
            </div>
          ),
          placement: "top",
          target: ".acknowledge",
        },
        {
          content: (
            <div className="background-both">
              <div>
                <img alt="checkmark" className="check-mark" src={Tick}></img>
              </div>
              <div>
                <span className="fs18 mb10">Tour Complete</span>
              </div>
              <div className="complete-text">
                Perfect! You are now familiar with the basics of QC Tool.
                <br></br>As you discover more of the application we'll assist
                you with helpful tours along the way. Have a nice day!
              </div>
            </div>
          ),
          placement: "center",
          target: ".complete",
          styles: {
            options: {
              width: 450,
            },
          },
          hideBackButton: true,
          locale: { last: "Ok" },
        },
      ],
      scheduleqcdailysteps: [
        {
          content: (
            <div className="background-both">
              Start the demo tour for<br></br>
              <span>Scheduled QC Worklist page.</span>
            </div>
          ),
          placement: "center",
          target: ".start",
        },
        {
          content: (
            <div className="background-right">
              <span>Collapsible sidebar menu</span>
            </div>
          ),
          placement: "bottom",
          target: ".app-sidebar__toggle",
        },
        {
          content: (
            <div className="background-left">
              <span>Change user roles</span>
            </div>
          ),
          placement: "bottom",
          target: ".roleChange",
        },
        {
          content: (
            <div className="background-left">
              <span>Logout from the Tool</span>
            </div>
          ),
          placement: "bottom",
          target: ".logout",
        },
        {
          content: (
            <div className="background-both">
              <span>Focus on the tabs</span> using <span>Shift+T key.</span>
            </div>
          ),
          placement: "top",
          target: ".react-tabs__tab-list",
        },
        {
          content: (
            <div className="background-left">
              <span>Apply the filter</span> using <span>Enter key.</span>
            </div>
          ),
          placement: "top",
          target: ".filter",
        },
        {
          content: (
            <div className="background-right">
              <span>Reset the filter</span> using <span>R key</span>
            </div>
          ),
          placement: "top",
          target: ".reset",
        },
        {
          content: (
            <div className="background-right">
              <span>Collapsible Filter Panel</span>
            </div>
          ),
          placement: "top",
          target: ".filter-block",
        },
        {
          content: (
            <div className="background-left">
              <span>Approve the images in bulk</span>
            </div>
          ),
          placement: "top",
          target: ".bulkApprove",
        },
        {
          content: (
            <div className="background-right">
              <span>Reject the images in bulk</span>
            </div>
          ),
          placement: "top",
          target: ".bulkReject",
        },
        {
          content: (
            <div className="background-left">
              <span>Image Count with categories</span>
            </div>
          ),
          placement: "top",
          target: ".imgCategory",
        },
        {
          content: (
            <div className="background-left">
              <span>Export to excel</span>
            </div>
          ),
          placement: "top",
          target: ".missing_count",
        },
        {
          content: (
            <div className="background-both">
              <span>Click on image</span> to see <span>image details.</span>
            </div>
          ),
          placement: "top",
          target: ".gallery-img",
        },
        {
          content: (
            <div className="background-left">
              <span>Select Number of images</span> on page
            </div>
          ),
          placement: "top",
          target: ".imgCount",
        },
        {
          content: (
            <div className="background-right">
              <span>Navigate to the next set of images/page</span>
            </div>
          ),
          placement: "top",
          target: ".next",
        },
        {
          content: (
            <div className="background-both">
              <div>
                <img alt="checkmark" className="check-mark" src={Tick}></img>
              </div>
              <div>
                <span className="fs18 mb10">Tour Complete</span>
              </div>
              <div className="complete-text">
                Perfect! You are now familiar with the basics of QC Tool.
                <br></br>As you discover more of the application we'll assist
                you with helpful tours along the way. Have a nice day!
              </div>
            </div>
          ),
          placement: "center",
          target: ".complete",
          styles: {
            options: {
              width: 450,
            },
          },
          hideBackButton: true,
          locale: { last: "Ok" },
        },
      ],
      Allocator: [
        {
          content: (
            <div className="background-both">
              Start the demo tour for <br></br>
              <span>Editor Allocation page</span>
            </div>
          ),
          placement: "center",
          target: ".user-name",
        },

        {
          content: (
            <div className="background-right">
              <span>Collapsible sidebar menu</span>
            </div>
          ),
          placement: "bottom",
          target: ".app-sidebar__toggle",
        },
        {
          content: (
            <div className="background-left">
              <span>Change user roles</span>
            </div>
          ),
          placement: "bottom",
          target: ".roleChange",
        },
        {
          content: (
            <div className="background-left">
              <span>Logout from the Tool</span>
            </div>
          ),
          placement: "bottom",
          target: ".logout",
        },
        {
          content: (
            <div className="background-right">
              <span>List of Editors</span>
            </div>
          ),
          placement: "bottom",
          target: ".panel-slider",
        },
        {
          content: (
            <div className="background-left">
              <span>Select location</span>
            </div>
          ),
          placement: "bottom",
          target: ".location-dropdown",
        },
        {
          content: (
            <div className="background-right">
              <span>Search Editors</span>
            </div>
          ),
          placement: "bottom",
          target: ".search-block",
        },
        {
          content: (
            <div className="background-left">
              <span>Collapsible left panel</span>
            </div>
          ),
          placement: "left",
          target: ".collapse-arrow",
        },
        {
          content: (
            <div className="background-left">
              <span>Permanent Allocation panel</span>
            </div>
          ),
          placement: "top",
          target: ".per",
        },
        {
          content: (
            <div className="background-right">
              <span>Allocated Editor</span>
            </div>
          ),
          placement: "top",
          target: ".per-allocated",
        },
        {
          content: (
            <div className="background-left">
              Click to <span>Reallocate editor</span>
            </div>
          ),
          placement: "top",
          target: ".per-popup",
        },
        {
          content: (
            <div className="background-right">
              Click to <span>Set date</span>
            </div>
          ),
          placement: "top",
          target: ".qc-user",
        },
        {
          content: (
            <div className="background-left">
              <span>Temporary Allocation panel</span>
            </div>
          ),
          placement: "top",
          target: ".temp",
        },
        {
          content: (
            <div className="background-right">
              <span>Temporary Allocated Editor</span>
            </div>
          ),
          placement: "top",
          target: ".temp-allocated",
        },
        {
          content: (
            <div className="background-left">
              Click to <span>Reallocate editor</span>
            </div>
          ),
          placement: "top",
          target: ".temp-popup",
        },
        {
          content: (
            <div className="background-both">
              <div>
                <img alt="checkmark" className="check-mark" src={Tick}></img>
              </div>
              <div>
                <span className="fs18 mb10">Tour Complete</span>
              </div>
              <div className="complete-text">
                Perfect! You are now familiar with the basics of QC Tool.
                <br></br>As you discover more of the application we'll assist
                you with helpful tours along the way. Have a nice day!
              </div>
            </div>
          ),
          placement: "center",
          target: ".complete",
          styles: {
            options: {
              width: 450,
            },
          },
          hideBackButton: true,
          locale: { last: "Ok" },
        },
      ],
      isSingleImageView: false,
      isHelpScreenActive: false,
      isEditorActive: false,
    };
  }

  componentDidMount() {
    const url = this.props.computedMatch.path.split("/");
    const temp = localStorage.getItem("userID");

    const userrolesapi = `Menu/GetUserRoles/${temp}`;
    axios({
      url: `${config.apiUrl + config.qcApi + userrolesapi}`,
      method: "get",
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 200 && res.data && res.data.objRespMessage) {
          const roleData = res.data.objRespMessage;
          const value = res.data.objRespMessage[0].strRoleName
            .toString()
            .toLowerCase()
            .split(" ")
            .join("");
          let intCtr = 0;

          if (value !== url[1].toString().toLowerCase()) {
            intCtr = 1;
          }
          this.setState({
            mapRoles: roleData,
            userRoleID: res.data.objRespMessage[intCtr].intUserRoleID,
            role: res.data.objRespMessage[intCtr].strRoleName,
            roleId: res.data.objRespMessage[intCtr].intRoleID,
            showError: false,
            loading: false,
          });

          if (res.data.objRespMessage[intCtr].intRoleID === 1) {
            this.setState({ isAllocatorActive: true });
          }

          if (res.data.objRespMessage[intCtr].intRoleID === 3) {
            this.setState({ isEditorActive: true });
          }

          this.handleChangeMenu(
            res.data.objRespMessage[intCtr].strRoleName,
            res.data.objRespMessage[intCtr].intRoleID,
            res.data.objRespMessage[intCtr].intUserRoleID
          );
        } else {
          this.setState({ loading: false, showError: true });
        }
      })
      .catch((error) => {
        if (!error.response) {
          error = "Not able to communicate with the backend service !";
          console.log(error);
        } else {
          switch (error.response.status) {
            case 404:
              error =
                "API Error - 404 received from the API\n\n API URL -" +
                error.request.responseURL;
              console.log(error);
              break;

            case 504:
              error =
                "API Error - 504 received from the API\n\n API URL -" +
                error.request.responseURL;
              console.log(error);
              break;

            default:
              error =
                "Unknown Error received from the API - Status " +
                error.response.status;
              console.log(error);
              break;
          }
        }
        this.setState({ loading: false, showError: true });
      });

    SharedService.getSingleImageView().subscribe((data) => {
      this.setState({ isSingleImageView: data.isSingleImageView });
    });

    SharedService.getIsHelpScreenActive().subscribe((data) => {
      this.setState({ isHelpScreenActive: data.isHelpScreenActive });
    });
  }
  componentDidUpdate() {
    if (
      this.props.location.pathname !== "/qcuser/SingleImageView" &&
      this.state.isSingleImageView
    ) {
      if (sessionStorage.getItem("isRefreshPage") === "1") {
        sessionStorage.setItem("isRefreshPage", "0");
      }
      this.setState({ isSingleImageView: false });
    }
  }
  handleChangeMenu(name, roleId, userroleId) {
    const menulistapi = "Menu/GetMenuList/";
    axios({
      url: `${config.apiUrl + config.qcApi + menulistapi + roleId}`,
      method: "get",
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 200 && res.data && res.data.objRespMessage) {
          const menu = res.data.objRespMessage;
          this.setState({
            mapMenu: menu,
            role: name,
            roleId: roleId,
            loading: false,
            showError: false,
          });
        } else {
          this.setState({ loading: false, showError: true });
        }
        localStorage.setItem("userRoleID", userroleId);
        localStorage.setItem("roleID", roleId);
      })
      .catch((error) => {
        if (!error.response) {
          error = "Not able to communicate with the backend service !";
          console.log(error);
        } else {
          switch (error.response.status) {
            case 404:
              error =
                "API Error - 404 received from the API\n\n API URL -" +
                error.request.responseURL;
              console.log(error);
              break;
            case 504:
              error =
                "API Error - 504 received from the API\n\n API URL -" +
                error.request.responseURL;
              console.log(error);
              break;
            default:
              error =
                "Unknown Error received from the API - Status " +
                error.response.status;
              console.log(error);
              break;
          }
        }
        this.setState({ loading: false, showError: true });
      });
  }

  toggleSidebar(event) {
    $(".app").toggleClass("sidenav-toggled");
    event.preventDefault();
    $(".app-sidebar__toggle").blur();
  }

  logoutUser() {
    userService.logout();
  }

  handleClickStart() {
    this.setState({ run: true });
    SharedService.setHelpScreenActive({ isHelpScreenActive: true });
    $(document).keypress(function (event) {
      if (event.which === "13") {
        event.preventDefault();
      }
    });
  }

  handleJoyrideCallback = (data) => {
    // const { action, index, status, type } = data;
    const status = data.status;
    // this.setState({dailyqcsteps:obj});
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      this.setState({ run: false });
      SharedService.setHelpScreenActive({ isHelpScreenActive: false });
    }
  };

  render() {
    const { mapRoles, role, loading } = this.state;
    let isSingleImageView;
    SharedService.getSingleImageView().subscribe((data) => {
      isSingleImageView = data.isSingleImageView;
    });

    let tmpSteps = [];
    let url = this.props.computedMatch.path.split("/");
    if (this.state.roleId === 1) {
      tmpSteps = this.state.Allocator;
    } else if (this.state.roleId === 3) {
      this.state.isSingleImageView
        ? (tmpSteps = this.state.editorSingleImageStep)
        : (tmpSteps = this.state.editorDailyQCStep);
    } else if (this.state.roleId === 2) {
      if (url[2].toString().toLowerCase() === "dailyqcworklist") {
        this.state.isSingleImageView
          ? (tmpSteps = this.state.singleImageSteps)
          : (tmpSteps = this.state.dailyqcsteps);
      } else {
        this.state.isSingleImageView
          ? (tmpSteps = this.state.singleImageSteps)
          : (tmpSteps = this.state.scheduleqcdailysteps);
      }
    }
    let content;
    loading
      ? (content = (
          <div id="loader-wrapper">
            <div id="loader"></div>
          </div>
        ))
      : (content = <Navigator mapmenu={this.state.mapMenu} />);
    let pathname = "";

    return (
      <RedirectedIfError showError={this.state.showError}>
        <ReactJoyride
          callback={this.handleJoyrideCallback}
          continuous
          disableCloseOnEsc
          disableOverlayClose
          run={this.state.run}
          scrollToFirstStep
          showSkipButton
          steps={tmpSteps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />

        <div>
          <header className="app-header">
            <Link className="app-header__logo" to="#">
              <img src={Logo} alt="Site Logo" width="150" />
            </Link>
            <Link
              className="app-sidebar__toggle header-bottom"
              to="#"
              data-toggle="sidebar"
              aria-label="Hide Sidebar"
              onClick={this.toggleSidebar.bind(this)}
            ></Link>
            <ul className="app-nav header-bottom">
              <li className="roleChange dropdown">
                <Link
                  className="app-nav__item"
                  to="#"
                  data-toggle="dropdown"
                  aria-label="Open Profile Menu"
                >
                  <b style={{ fontWeight: "600" }}>Welcome </b>{" "}
                  {localStorage.getItem("userName")} <br />
                  <span style={{ color: "#3e484e", fontSize: "12px" }}>
                    {role}
                  </span>
                </Link>
                <ul className="dropdown-menu settings-menu">
                  {mapRoles.map((item, index) => {
                    pathname =
                      item.intRoleID === 1
                        ? "/allocator/dailyqcworklist"
                        : item.intRoleID === 3
                        ? "/editor/dailyqcworklist"
                        : item.intRoleID === 2
                        ? "/qcuser/dailyqcworklist"
                        : null;
                    return item.strRoleName === role ? null : (
                      <Link key={index} to={pathname}>
                        <li
                          key={index}
                          className="dropdown-item"
                          onClick={this.handleChangeMenu.bind(
                            this,
                            item.strRoleName,
                            item.intRoleID,
                            item.intUserRoleID
                          )}
                        >
                          {item.strRoleName}
                        </li>
                      </Link>
                    );
                  })}
                </ul>
              </li>
              <li>
                <i
                  onClick={(e) => this.handleClickStart(e)}
                  className="fa fa-question-circle user-name help_icon"
                  aria-hidden="true"
                ></i>
              </li>
              <li className="logout p-3">
                {" "}
                <Link
                  to="/login"
                  data-toggle="control-sidebar"
                  title="Logout"
                  onClick={this.logoutUser.bind(this)}
                >
                  <i className="fa fa-power-off fa-lg pt-2"></i>
                </Link>{" "}
              </li>
            </ul>
          </header>
          {content}
        </div>
      </RedirectedIfError>
    );
  }
}
export default Header;
