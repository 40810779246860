import React, { Component } from "react";
import config from "../containers/config";

class Login extends Component {
  render() {
    return (
      <div>
        <div className="logout-block">
          <div>
            <h5 className="logout-sucess">Logout Successful</h5>
            <p className="logout-content">
              Please{" "}
              <span>
                <a className="logout-link" href={`${config.cmsLoginPage}`}>
                  click here
                </a>
              </span>{" "}
              to continue
            </p>
          </div>
        </div>
        <p className="main-footer text-right logout-footer">
          � 2000-present. Gracenote, A Nielsen Company. All rights reserved.
        </p>
      </div>
    );
  }
}

export default Login;
