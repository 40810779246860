import React from "react";

class Buttons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btnID: this.props.id,
      status: this.props.btnStatus,
    };
  }

  onButtonClick() {
    this.props.onClick();
  }

  render() {
    return (
      <button
        type="button"
        id={this.props.id}
        className={this.props.class}
        onClick={this.onButtonClick.bind(this)}
        disabled={this.props.btnStatus}
      >
        {this.props.btnValue}
      </button>
    );
  }
}
export default Buttons;
