import React from "react";
import ToggleImage from "../../assets/images/img-pro-img.jpg";
import TopToggle from "../../assets/images/top-toggle.jpg";
import axios from "axios";
import config from "../../containers/config";
import Picky from "react-picky";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Modal from "react-modal";
import ZoomView from "./ZoomView";
import ConfirmApprovePopup from "./ConfirmApprovePopup";
import DisplayMessage from "./DisplayMessage";
import { Link } from "react-router-dom";
import { SharedService } from "../SharedService/SharedService";
import Buttons from "./Buttons";
import CommentBox from "./CommentBox";
import SingleImageSideProperties from "./SingleImageSideProperties";
import Alert from "react-s-alert";

let $ = require("jquery");

const modalstyle = {
  content: { width: "320px", height: "135px" },
};

const modalstyle2 = {
  content: { width: "320px", height: "150px" },
};

class SingleImageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgProperties: [],
      strImageLink: "",
      gridImageDataSet: this.props.location.data,
      listerrors: [],
      selectederrorarr: [],
      presentImgID: [this.props.location.imageid],
      lstImgComments: [],
      newlstImgComments: [],
      cmntarr: [],
      cmtTxt: "",
      cmntBtnStatus: false,
      modalConfirmIsOpen: false,
      modalDispMsgIsOpen: false,
      isApprove: 1,
      minIndex: 0,
      items: 0,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 4 / 3,
      zoomOpen: false,
      loaderStatus: true,
      modalIsOpen: false,
      propertyslider: true,
      topSlider: true,
      stpLeftArrow: false,
      isLastImageOfSet: false,
      currentPage: this.props.location.currentPage,
      actionPopupStatus: "",
      cssVar: "",
      scrollClicked: false,
      cmntboxfocus: false,
      isChildWindow: false,
      childWindowTxt: null,
      isSingleImageView: true,
      isHelpScreenActive: false,
      isEditIconClicked: false,
      serialNum: this.props.location.imgindex + 1,
      availableimages: [],
      traversalPageSize: this.props.location.pageSize,
      actionableImages: [],
      failureCount: 0,
      actionedImage: false,
      firstImageOfNextPagePopup: false,
      actionedImageReset: false,
      zeroImagesToAction: false,
      showError: false,
    };

    let tempindex =
      this.state.serialNum +
      this.state.traversalPageSize * (this.state.currentPage - 1) -
      1;

    SharedService.setSingleImageView({ isSingleImageView: true });
    this.getImgProperties(this.state.presentImgID, tempindex, false, false);

    if (sessionStorage.getItem("isRefreshPage") === "1") {
      this.refreshClick();
    } else if (sessionStorage.getItem("isRefreshPage") !== "1") {
      sessionStorage.setItem("isRefreshPage", "1");
    }
  }

  componentWillMount() {
    var result = [];
    //Get list of all available images in another array. The main array contains missing images' placeholders as well.
    //The second array will help us in traversing from one image to other, which we need to display.
    if (this.state.gridImageDataSet) {
      this.state.gridImageDataSet.forEach((object, i) => {
        if (object.lngImageID) {
          result.push(object);
        }
      });
    }

    let tmpFirstImageOfPage = true;
    let tmpPageOffsetCounter =
      (this.state.currentPage - 1) * this.state.traversalPageSize;
    let tmpCurrImgMainIndex = tmpPageOffsetCounter + this.state.serialNum - 1;

    //If there's atleast one image at the left of current image on that page, then we do not hide the left arrow key.
    for (
      let counter = tmpPageOffsetCounter;
      counter < tmpCurrImgMainIndex;
      counter++
    ) {
      if (this.state.gridImageDataSet[counter].lngImageID) {
        tmpFirstImageOfPage = false;
        break;
      }
    }

    axios
      .get(`${config.apiUrl + config.qcApi}Common/GetErrors`)
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.objRespMessage
        ) {
          this.setState({
            availableimages: result,
            listerrors: response.data.objRespMessage,
            minIndex: tmpCurrImgMainIndex,
            stpLeftArrow: tmpFirstImageOfPage,
            showError: false,
          });
        } else {
          this.setState({ showError: true });
        }
      })
      .catch((error) => {
        error = "Not able to communicate with the backend service !";
        console.log(error);
        this.setState({ showError: true });
      });

    SharedService.getIsHelpScreenActive().subscribe((data) => {
      this.setState({ isHelpScreenActive: data.isHelpScreenActive });
    });
  }

  getImgProperties(lngImageID, index, denyPopup, isAction) {
    let tempArray = this.state.gridImageDataSet;

    axios
      .get(`${config.apiUrl + config.qcApi}Image/GetImageData/${lngImageID}`)
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.objRespMessage
        ) {
          let cssvar = "";
          if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "17:25"
          ) {
            cssvar = "image-ratio-17-25";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "9:16"
          ) {
            cssvar = "image-ratio-9-16";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "16:9"
          ) {
            cssvar = "image-ratio-16-9";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "4:3"
          ) {
            cssvar = "image-ratio-4-3";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "3:4"
          ) {
            cssvar = "image-ratio-3-4";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "3:2"
          ) {
            cssvar = "image-ratio-3-2";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "2:3"
          ) {
            cssvar = "image-ratio-2-3";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "2:1"
          ) {
            cssvar = "image-ratio-unknownLandscape";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "1:1"
          ) {
            cssvar = "image-ratio-1-1";
          } else if (
            response.data.objRespMessage.lstImageData[0].chrImageOrientation ===
            "L"
          ) {
            if (
              (response.data.objRespMessage.lstImageData[0].strWidth >= 1200) &
              (response.data.objRespMessage.lstImageData[0].strAspectRatio ===
                "Unknown")
            ) {
              cssvar = "image-ratio-unknownLandscape";
            }
          } else if (
            response.data.objRespMessage.lstImageData[0].chrImageOrientation ===
            "P"
          ) {
            if (
              (response.data.objRespMessage.lstImageData[0].strHeight > 1000) &
              (response.data.objRespMessage.lstImageData[0].strAspectRatio ===
                "Unknown")
            ) {
              cssvar = "image-ratio-unknownPortrait";
            }
          }
          let tempstatus = ["0", "1", ""].includes(
            response.data.objRespMessage.lstImageData[0].strActionStatus.toString()
          )
            ? false
            : true;
          let tempoldstatus = ["0", "1", ""].includes(
            tempArray[index].intActionStatusCode.toString()
          )
            ? false
            : true;

          if (
            denyPopup ||
            tempstatus === tempoldstatus ||
            response.data.objRespMessage.lstImageData[0].strActionStatus.toString() ===
            tempArray[index].intActionStatusCode.toString()
          ) {
            denyPopup =
              denyPopup &&
              !(
                tempstatus === tempoldstatus ||
                response.data.objRespMessage.lstImageData[0].strActionStatus.toString() ===
                tempArray[index].intActionStatusCode.toString()
              );
          } else {
            let flyerText = "No action, QC for this image is already done";
            if (!tempstatus) {
              flyerText = "This image was Reset to Default!";
            }
            if (isAction) {
              setTimeout(() => {
                this.flyerMsg(flyerText, 5);
              }, 1000);
            } else {
              this.flyerMsg(flyerText, 5);
            }
          }

          this.setState({
            imgProperties: response.data.objRespMessage.lstImageData[0],
            lstImgComments: response.data.objRespMessage.lstImageComment,
            loaderStatus: false,
            cssVar: cssvar,
            showError: false,
            actionedImage: tempstatus,
            firstImageOfNextPagePopup: denyPopup,
            actionedImageReset: !tempstatus,
          });
        } else {
          this.setState({ showError: true, loaderStatus: false });
        }
      })
      .catch((error) => {
        error = "Not able to communicate with the backend service !";
        console.log(error);
        this.setState({ showError: true, loaderStatus: false });
      });
  }

  closeModal3(e, values) {
    this.setState({
      modalConfirmIsOpen: false,
      modalDispMsgIsOpen: true,
      minIndex: this.getMatchingIndex(
        this.state.presentImgID[0],
        this.state.gridImageDataSet
      ),
    });
  }

  closeModal2(e, values) {
    this.setState({ modalDispMsgIsOpen: false, isApprove: 1 });
    if (this.state.isLastImageOfSet) {
      this.moveToDailyQC();
    } else {
      if (this.state.firstImageOfNextPagePopup) {
        let flyerText = "No action, QC for this image is already done";
        if (this.state.actionedImageReset) {
          flyerText = "This image was Reset to Default!";
        }

        this.flyerMsg(flyerText, 5);
      }
    }
  }

  flyerMsg = (msgText, msgFlag) => {
    switch (msgFlag) {
      case 2:
        Alert.info(
          '<div><i class="fa fa-check notification" aria-hidden="true"></i>' +
          msgText +
          "</div>",
          {
            position: "bottom-right",
            effect: "bouncyflip",
            html: true,
            timeout: 2000,
          }
        );
        break;
      case 1:
      case 3:
        Alert.success(
          '<div><i class="fa fa-check notification" aria-hidden="true"></i>' +
          msgText +
          "</div>",
          {
            position: "bottom-right",
            effect: "bouncyflip",
            html: true,
            timeout: 2000,
          }
        );
        break;

      case 4:
        Alert.error(
          '<div><i class="fa fa-check notification" aria-hidden="true"></i>' +
          msgText +
          "</div>",
          {
            position: "bottom-right",
            effect: "bouncyflip",
            html: true,
            timeout: 2000,
          }
        );
        break;

      case 5:
        Alert.warning(
          '<div><i class="fa fa-check notification" aria-hidden="true"></i>' +
          msgText +
          "</div>",
          {
            position: "bottom-right",
            effect: "bouncyflip",
            html: true,
            timeout: 2000,
          }
        );
        break;

      default:
        break;
    }
  };

  onApproveClick() {
    this.actionButtonClick(2, "Image has been approved", true, "");
  }

  onApproveWthEditsClick() {
    this.actionButtonClick(
      3,
      "Image has been approved with edits",
      this.state.isEditIconClicked,
      "Please edit in CMS before Approve with Edits"
    );
  }

  onRejectClick() {
    this.actionButtonClick(4, "Image has been rejected", true, "");
  }
  onResetClick() {
    this.actionButtonClick(1, "Image has been reset", true, "");
  }

  onImprovisationClick() {
    this.actionButtonClick(5, "Image sent to Improvisation", true, "");
  }

  actionButtonClick(
    actionId,
    popupText,
    actionValidation,
    validationFailureText
  ) {
    var imgid = this.state.presentImgID,
      tempSelectedErrorLst = this.state.selectederrorarr;

    let isReject = actionId === 4;
    let rejectionArray = [];
    if (isReject) {
      let arr = this.state.selectederrorarr;
      arr.forEach((a) => {
        rejectionArray.push(a.id + 1);
      });
    }

    if (
      ((isReject && tempSelectedErrorLst.length > 0) ||
        (!isReject && tempSelectedErrorLst.length === 0)) &&
      !this.state.zoomOpen &&
      !this.state.cmntboxfocus &&
      !this.state.isHelpScreenActive &&
      !this.state.actionedImage &&
      !this.state.modalConfirmIsOpen &&
      !this.state.modalDispMsgIsOpen &&
      actionValidation
    ) {
      this.setState({ loaderStatus: true });

      let cmntarr = [];
      let tmpIsLastImageofSet = this.state.isLastImageOfSet;
      this.state.newlstImgComments.forEach((element) => {
        cmntarr.push(element.strComment);
      });

      const ids = [];
      var obj = {};
      obj.imageid = imgid.toString();
      obj.status = "0";
      ids.push(obj);

      axios
        .post(`${config.apiUrl + config.qcApi}Action/PerformImageQC`, {
          lstImageData: ids, //this.state.presentImgID,
          intWLTypeID: parseInt(this.props.location.WorkListType),
          lstReasonID: rejectionArray,
          intActionStatusCode: actionId,
          lstComment: cmntarr,
          intUserRoleID: localStorage.getItem("userRoleID"),
        })
        .then((response) => {
          if (response.status === 200) {
            this.loadNxtImage(
              tmpIsLastImageofSet,
              response.data.objRespMessage[1][0].currentActionCount,
              response.data.objRespMessage[0][0].actionStatusID,
              popupText
            );
          } else if (response.status === 204) {
            this.setState({ loaderStatus: false });
          } else {
            this.setState({ showError: true, loaderStatus: false });
          }
        })
        .catch(function (error) {
          console.log(error);
          this.setState({ showError: true, loaderStatus: false });
        });
    } else {
      if (
        !(
          (isReject && tempSelectedErrorLst.length > 0) ||
          (!isReject && tempSelectedErrorLst.length === 0)
        ) &&
        !this.state.zoomOpen &&
        !this.state.cmntboxfocus &&
        !this.state.actionedImage
      ) {
        if (actionId === 4)
          this.flyerMsg("Please select atleast one error ", 4);
        else this.flyerMsg("Please remove selected error ", 4);
      }

      if (
        actionId === 3 &&
        !actionValidation &&
        !this.state.approvePopupFlag &&
        !this.state.zoomOpen &&
        !this.state.cmntboxfocus &&
        !this.state.isHelpScreenActive &&
        !this.state.actionedImage
      ) {
        ////////////////////////////////////////////
        this.flyerMsg(validationFailureText, 4);
      }
      if (actionId === 1) {
        let tmpIsLastImageofSet = this.state.isLastImageOfSet;
        const dailyimgapivalue = "Action/ResetDailyImage/";
        axios
          .post(`${config.apiUrl + config.qcApi + dailyimgapivalue}`, {
            lstImageID: this.state.presentImgID, //imageIdArray,
            intUserRoleID: localStorage.getItem("userRoleID"),
            intMode: 3
          })
          .then((response) => {
            if (response.status === 200) {
              this.loadNxtImage(
                tmpIsLastImageofSet,
                1,
                1,
                popupText
              );
            } else if (response.status === 204) {
              this.setState({ loaderStatus: false });
            } else {
              this.setState({ showError: true, loaderStatus: false });
            }

          })
          .catch((error) => {
            console.log("Error", error)
          })
      }

      if (
        this.state.actionedImage &&
        !this.state.zoomOpen &&
        !this.state.cmntboxfocus &&
        actionId !== 1
      ) {
        this.flyerMsg(
          "Action disabled since QC for this image is already done",
          5
        );
      }
    }
  }

  moveToDailyQC() {
    sessionStorage.removeItem("isRefreshPage");
    this.lastClickHandler();
    //this.props.history.push('dailyqcworklist');

    if (this.props.location.WorkListType === "2") {
      this.props.history.push("/qcuser/dailyqcworklist");
    } else {
      this.props.history.push("/qcuser/scheduleqcworklist");
    }
  }

  selectMultipleOption(name) {
    const errIndex = [];
    name.map((i) => errIndex.push(i.id));
    this.setState({ selectederrorarr: name, errIndex });
  }

  onTextInput(event) {
    let tempcmntbtnStatus = false;

    if (event.target.value.length > 0) {
      tempcmntbtnStatus = true;
    }
    this.setState({
      cmtTxt: event.target.value,
      cmntBtnStatus: tempcmntbtnStatus,
    });
  }

  cmntBtnClicked() {
    var cmntboxtxtvalue = this.state.cmtTxt;
    let tempcmntarr = [];
    if (cmntboxtxtvalue !== "") {
      tempcmntarr = this.state.newlstImgComments;
      var month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var d = new Date();
      var months = month[d.getMonth()];
      var dates = new Date().getDate();
      var years = new Date().getFullYear();
      var h = new Date().getHours();
      var m = new Date().getMinutes();
      var amorpm = "AM";

      let scrolled = false;
      window.setTimeout(function updateScroll() {
        if (!scrolled) {
          var element = document.querySelector(".comment-wrapper");
          element.scrollTop = element.scrollHeight;
        }
      }, 10);

      $(".comment-wrapper").on("scroll", function () {
        scrolled = true;
      });

      if (h > 12) {
        h = h - 12;
        amorpm = "PM";
      }
      let objcmnt = {
        strComment: this.state.cmtTxt,
        strDateTime:
          months + " " + dates + " " + years + " " + h + ":" + m + " " + amorpm,
        intID: 1,
        strUserName: localStorage.getItem("userName"),
        strReason: "",
      };
      tempcmntarr.push(objcmnt);
      this.setState({
        newlstImgComments: tempcmntarr,
        cmtTxt: "",
        cmntBtnStatus: false,
      });
    } else {
      this.flyerMsg("Please enter comment", 5);
    }
  }

  loadNxtImage(tmpIsLastImageofSet, actionTaken, actionstatusid, popupText) {
    let tmpProceed = false;
    if (actionTaken === 0) {
      this.flyerMsg("No action, QC for this image is already done", 5);
    } else {
      this.flyerMsg(popupText, actionstatusid);
      tmpProceed = true;
    }

    let qcactionIndex = [];
    if (this.props.location.actionfilter)
      this.props.location.actionfilter.map((i) => qcactionIndex.push(i.id));

    var prvIndex = 0;
    let previousImgID = this.state.presentImgID[0];
    let tmpGridSet = this.state.gridImageDataSet;
    let tmpAvail = this.state.availableimages;
    let tmpSerial = this.state.serialNum;
    let imgindex =
      tmpSerial +
      (this.state.currentPage - 1) * this.state.traversalPageSize -
      1;
    let tmpImgMain = tmpGridSet[imgindex];
    tmpGridSet[imgindex].intActionStatusCode = actionstatusid;

    prvIndex = this.getMatchingIndex(previousImgID, tmpAvail);
    if (
      (qcactionIndex.length > 0 &&
        !qcactionIndex.includes(tmpImgMain.intActionStatusCode)) ||
      this.props.location.WorkListType === "2"
    ) {
      tmpGridSet.splice(imgindex, 1);
      tmpAvail.splice(prvIndex, 1);
      tmpProceed = true;
    } else {
      prvIndex += 1;
    }

    if (tmpProceed) {
      if (prvIndex >= tmpAvail.length) {
        tmpIsLastImageofSet = true;
      } else {
        tmpIsLastImageofSet = false;
      }
      let arr = [];
      if (!tmpIsLastImageofSet) {
        var nxtImgID = tmpAvail[prvIndex].lngImageID;
        arr.push(nxtImgID);
        let tmpImgMainIndex = this.getMatchingIndex(nxtImgID, tmpGridSet);
        let tmpCurrentPage =
          Math.floor(tmpImgMainIndex / this.state.traversalPageSize) + 1;
        let tmpPageOffsetCounter =
          (tmpCurrentPage - 1) * this.state.traversalPageSize;
        let serialno = tmpImgMainIndex - tmpPageOffsetCounter + 1;

        let tmpFirstImageOfPage = true;

        for (
          let counter = tmpPageOffsetCounter;
          counter < tmpImgMainIndex;
          counter++
        ) {
          if (tmpGridSet[counter].lngImageID) {
            tmpFirstImageOfPage = false;
            break;
          }
        }

        this.setState({
          presentImgID: arr,
          gridImageDataSet: tmpGridSet,
          selectederrorarr: [],
          newlstImgComments: [],
          isEditIconClicked: false,
          serialNum: serialno,
          currentPage: tmpCurrentPage,
          showError: false,
          cmtTxt: "",
          stpLeftArrow: tmpFirstImageOfPage,
        });
        this.getImgProperties(nxtImgID, tmpImgMainIndex, false, true);
      } else {
        this.moveToDailyQC(tmpIsLastImageofSet);
      }
    } else {
      this.setState({
        loaderStatus: false,
        actionedImage: true,
        gridImageDataSet: tmpGridSet,
      });
    }
  }

  showZoomImage() {
    if (this.state.imgProperties.strAspectRatio !== "Unknown") {
      this.setState({ zoomOpen: true });
    }
  }

  getMatchingIndex(value, arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].lngImageID === value) {
        return i;
      }
    }
    return -1;
  }

  onImgSliderClick(btnClicked) {
    //this variable checks whether I am moving forward or backward
    var isLeft = btnClicked === "prev" || btnClicked === "left";
    let tmpImagePageIndex = this.state.serialNum - 1;
    let tmpCurrentPage = this.state.currentPage;

    //If my modal popups are open, I don't have to move anywhere.
    //If I am at the first image of any page, I don't have to move left.
    //If I am at the last image of any page, I have to show the confirmation popup & move to next page.
    //If I am at the last image of the set, I have to show the confirmation popup & move back to gallery view.
    if (
      !this.state.modalConfirmIsOpen &&
      !this.state.modalDispMsgIsOpen &&
      !this.state.zoomOpen &&
      !this.state.isHelpScreenActive &&
      ((!this.state.stpLeftArrow && isLeft) || !isLeft)
    ) {
      let tmpPrevMainImageIndex = this.getMatchingIndex(
        this.state.presentImgID[0],
        this.state.gridImageDataSet
      );
      let tmpCurrImgMainIndex = 0,
        tmpCurrImgIndex = 0;
      let tmpImgSetSize = this.state.availableimages.length;
      let tmpIsLastImageOfSet = false;
      let tmpFirstImageOfPage = false;
      var tmpMinIndex = this.state.minIndex;

      tmpCurrImgIndex = this.getMatchingIndex(
        this.state.presentImgID[0],
        this.state.availableimages
      );

      let imgID = [];
      if (isLeft) {
        //Check whether the current image is the first image of any page. So to stop left arrow key
        //If it's first image of the set, it's still the first image of the first page.
        tmpImagePageIndex = tmpImagePageIndex - 1;
        tmpCurrImgIndex = tmpCurrImgIndex - 1;

        if (tmpImagePageIndex <= 0) {
          tmpCurrImgMainIndex = this.getMatchingIndex(
            this.state.availableimages[tmpCurrImgIndex].lngImageID,
            this.state.gridImageDataSet
          );
          tmpFirstImageOfPage = true;
          tmpImagePageIndex = 0;
          tmpMinIndex = Math.min(tmpCurrImgMainIndex, this.state.minIndex);
        } else {
          //This is not the first index of the page. Checking whether it's the first available image of set.
          tmpCurrImgMainIndex = this.getMatchingIndex(
            this.state.availableimages[tmpCurrImgIndex].lngImageID,
            this.state.gridImageDataSet
          );
          //This is not the first image of the set. Need to check whether it's the first image of the page.

          let tmpPageOffsetCounter =
            (this.state.currentPage - 1) * this.state.traversalPageSize;
          if (tmpPageOffsetCounter === tmpCurrImgMainIndex) {
            tmpFirstImageOfPage = true;
          } else {
            //This image is not the physical first image of that page.
            //But we need to check whether this image is the first available image of that page.
            //So let's assume this is the first available image.
            //If we find any image in between, from offset first index of page to here, then it's not the first.
            tmpFirstImageOfPage = true;
            for (
              let counter = tmpPageOffsetCounter;
              counter < tmpCurrImgMainIndex;
              counter++
            ) {
              if (this.state.gridImageDataSet[counter].lngImageID) {
                tmpFirstImageOfPage = false;
                break;
              }
            }
          }
          tmpMinIndex = Math.min(tmpCurrImgMainIndex, this.state.minIndex);
        }
        //VAIBHAV CHECK: Variables used here, while setState

        //load new image data
        imgID.push(this.state.availableimages[tmpCurrImgIndex].lngImageID);
      } else {
        tmpCurrImgIndex = tmpCurrImgIndex + 1;

        //If the image on which right was clicked, was the last image of available images array.
        if (tmpCurrImgIndex === tmpImgSetSize) {
          tmpIsLastImageOfSet = true;
          tmpCurrImgMainIndex = tmpCurrImgMainIndex + 1;
          tmpCurrentPage = tmpCurrentPage + 1;
        } else {
          //This image is not the last image of available image array.
          //Need to check whether it is the last image of (any) page; and hence, next (available) image will be first image of new page.
          tmpCurrImgMainIndex = this.getMatchingIndex(
            this.state.availableimages[tmpCurrImgIndex].lngImageID,
            this.state.gridImageDataSet
          );
          let tmpNextPageFirstIndex =
            this.state.currentPage * this.state.traversalPageSize;
          //The current image sits at the last index of (any) page. Page will be changed after the right key is pressed.
          if (tmpCurrImgMainIndex >= tmpNextPageFirstIndex) {
            tmpFirstImageOfPage = true;
            tmpCurrentPage =
              Math.floor(tmpCurrImgMainIndex / this.state.traversalPageSize) +
              1;
            //VAIBHAV CHECK: if confirmation window handles this, then remove currentpage logic.
          }

          imgID.push(this.state.availableimages[tmpCurrImgIndex].lngImageID);
        }
      }
      let tmpLoaderStatus = true;
      if (tmpIsLastImageOfSet) {
        tmpLoaderStatus = false;
        //Getting the serial number of image on that page
      } else {
        //Getting the serial number of image on that page
        tmpImagePageIndex =
          tmpCurrImgMainIndex -
          (tmpCurrentPage - 1) * this.state.traversalPageSize;
      }

      sessionStorage.setItem("QCCurrentPage", tmpCurrentPage);
      //set values for next image to be loaded
      this.setState({
        currentPage: tmpCurrentPage,
        presentImgID: imgID,
        minIndex: tmpMinIndex,
        loaderStatus: tmpLoaderStatus,
        newlstImgComments: [],
        cmtTxt: "",
        selectederrorarr: [],
        stpLeftArrow: tmpFirstImageOfPage,
        isLastImageOfSet: tmpIsLastImageOfSet,
        isEditIconClicked: false,
        serialNum: tmpImagePageIndex + 1,
      });
      //let tmpCurrentIndex = 1;

      let confirmWindow =
        (!isLeft && tmpFirstImageOfPage) || tmpIsLastImageOfSet;
      //show confirmation popup only if my page has turned
      if (confirmWindow) {
        this.showConfirmWindow(
          tmpCurrImgMainIndex,
          tmpImagePageIndex,
          tmpCurrentPage - 1,
          tmpIsLastImageOfSet,
          tmpPrevMainImageIndex
        );
      }
      if (!tmpIsLastImageOfSet) {
        this.getImgProperties(imgID, tmpCurrImgMainIndex, confirmWindow, false);
      }
    }
  }

  showConfirmWindow(
    tmpCurrImgMainIndex,
    tmpImagePageIndex,
    tmpActionablePage,
    tmpIsLastImageOfSet,
    tmpPrevMainImageIndex
  ) {
    let tmpGrid = this.state.gridImageDataSet;
    // let tmpMaxValue = tmpIsLastImageOfSet ? this.state.gridImageDataSet.length : (Math.min(tmpCurrImgMainIndex, this.state.traversalPageSize * tmpActionablePage));

    let tmpMinValue = this.state.minIndex;
    let currentPageOfMinIndex = Math.ceil(
      this.state.minIndex / this.state.traversalPageSize
    );
    let currentPageOfPrevImgIndex = Math.ceil(
      tmpPrevMainImageIndex / this.state.traversalPageSize
    );
    if (!(currentPageOfMinIndex === currentPageOfPrevImgIndex)) {
      tmpMinValue =
        (currentPageOfPrevImgIndex - 1) * this.state.traversalPageSize;
    }

    let tmpActionableImages = [];

    //let tmpMinValue = ((tmpCurrImgMainIndex - this.state.minIndex) > this.state.traversalPageSize) ? tmpCurrImgMainIndex - this.state.traversalPageSize : this.state.minIndex;
    for (
      var tmpCounter = tmpMinValue;
      tmpCounter <= tmpPrevMainImageIndex;
      tmpCounter++
    ) {
      if (
        tmpGrid[tmpCounter].lngImageID &&
        (tmpGrid[tmpCounter].intActionStatusCode === 1 ||
          tmpGrid[tmpCounter].intActionStatusCode === 0)
      ) {
        let testArray = {};
        testArray.lngImageID = tmpGrid[tmpCounter].lngImageID;
        testArray.Index = tmpCounter;
        tmpActionableImages.push(testArray);
      }
    }

    let tmpItemCount = tmpActionableImages.length;
    if (tmpItemCount === 0) {
      this.setState({
        actionableImages: tmpActionableImages,
        items: tmpItemCount,
        minIndex: tmpMinValue,
        modalDispMsgIsOpen: true,
        serialNum: tmpImagePageIndex + 1,
        zeroImagesToAction: true,
      });
    } else {
      this.setState({
        actionableImages: tmpActionableImages,
        items: tmpItemCount,
        minIndex: tmpMinValue,
        modalConfirmIsOpen: true,
        serialNum: tmpImagePageIndex + 1,
      });
    }
  }

  onEscClick() {
    if (!this.state.modalConfirmIsOpen && !this.state.modalDispMsgIsOpen) {
      this.setState({ zoomOpen: false });
    }
  }

  confirmBoxCancel() {
    this.setState({ isApprove: 3 });
  }

  confirmBoxApprove() {
    this.setState({ loaderStatus: true });
    let tmpArray = this.state.actionableImages;
    let tmpGridSet = this.state.gridImageDataSet;
    let currentpage = this.state.currentPage;

    tmpArray.sort((a, b) => (a.Index > b.Index ? -1 : 1));

    const imgIds = [];
    this.state.actionableImages.forEach((e) => {
      var obj = {};
      obj.imageid = e.lngImageID;
      obj.status = "0";
      imgIds.push(obj);
    });

    axios
      .post(`${config.apiUrl + config.qcApi}Action/PerformImageQC/`, {
        lstImageData: imgIds,
        lstReasonID: [],
        intWLTypeID: parseInt(this.props.location.WorkListType),
        intActionStatusCode: 2,
        intUserRoleID: localStorage.getItem("userRoleID"),
        lstComment: [],
      })
      .then((response) => {
        if (response.status === 200) {
          let qcactionIndex = [];
          if (this.props.location.actionfilter)
            this.props.location.actionfilter.map((i) =>
              qcactionIndex.push(i.id)
            );

          let tmpSerial = this.state.serialNum;
          let tmpAvail = this.state.availableimages;

          for (let tmpCounter = 0; tmpCounter < tmpArray.length; tmpCounter++) {
            let tmpImg = response.data.objRespMessage[0].filter(
              (img) => img.imageID === tmpArray[tmpCounter].lngImageID
            );
            tmpGridSet[tmpArray[tmpCounter].Index].intActionStatusCode =
              tmpImg[0].actionStatusID;

            if (this.props.location.WorkListType === "1") {
              if (
                qcactionIndex.length > 0 &&
                !qcactionIndex.includes(tmpImg[0].actionStatusID)
              ) {
                tmpGridSet.splice(tmpArray[tmpCounter].Index, 1);

                for (
                  let tmpcount = 0;
                  tmpcount < this.state.availableimages.length;
                  tmpcount++
                ) {
                  if (
                    tmpImg[0].imageID.toString() ===
                    this.state.availableimages[tmpcount].lngImageID.toString()
                  ) {
                    tmpAvail.splice(tmpcount, 1);
                  }
                }
                tmpSerial -= 1;
              }
            } else {
              tmpGridSet.splice(tmpArray[tmpCounter].Index, 1);
              tmpAvail.splice(tmpArray[tmpCounter].Index, 1);
              tmpSerial -= 1;
            }
          }

          if (tmpSerial <= 0) {
            currentpage -= 1;
            tmpSerial += this.state.traversalPageSize;
          }

          let tmpFirstImageOfPage = true;
          let tmpPageOffsetCounter =
            (currentpage - 1) * this.state.traversalPageSize;
          let tmpCurrImgMainIndex = tmpPageOffsetCounter + tmpSerial - 1;

          if (!this.state.isLastImageOfSet) {
            //If there's atleast one image at the left of current image on that page, then we do not hide the left arrow key.
            for (
              let counter = tmpPageOffsetCounter;
              counter < tmpCurrImgMainIndex;
              counter++
            ) {
              if (tmpGridSet[counter].lngImageID) {
                tmpFirstImageOfPage = false;
                break;
              }
            }
          }
          let tmpfailureCount =
            this.state.items -
            response.data.objRespMessage[1][0].currentActionCount;

          this.setState({
            isApprove: 2,
            modalConfirmIsOpen: false,
            modalDispMsgIsOpen: true,
            showError: false,
            loaderStatus: false,
            serialNum: tmpSerial,
            stpLeftArrow: tmpFirstImageOfPage,
            gridImageDataSet: tmpGridSet,
            availableimages: tmpAvail,
            currentPage: currentpage,
            minIndex: tmpCurrImgMainIndex,
            failureCount: tmpfailureCount,
            items: response.data.objRespMessage[1][0].currentActionCount,
            zeroImagesToAction: false,
          });
        } else {
          this.setState({ showError: true, loaderStatus: false });
        }
      })
      .catch(function (error) {
        console.log(error);
        this.setState({ showError: true, loaderStatus: false });
      });
  }

  componentDidMount() {
    this.setState({ loaderStatus: false });
  }
  componentDidUpdate() {
    let comment_panel = document.querySelector(".comment-wrapper");
    if (comment_panel !== null) {
      comment_panel.scrollTop = comment_panel.scrollHeight;
    }
  }

  onSliderClick() {
    this.setState({ propertyslider: this.state.propertyslider ? false : true });
  }

  onTopToggleClick = () => {
    this.setState({ topSlider: this.state.topSlider ? false : true });
  };

  lastClickHandler = () => {
    let tempcount = [];
    tempcount.intUnActionedCount = 0;
    tempcount.intMissingCount = 0;
    tempcount.intActionedCount = 0;
    this.state.gridImageDataSet.forEach((arrVal) => {
      if (
        arrVal.lngImageID &&
        (arrVal.intActionStatusCode === 1 || arrVal.intActionStatusCode === 0)
      )
        tempcount.intUnActionedCount += 1;
      else if (arrVal.lngImageID) tempcount.intActionedCount += 1;
      else tempcount.intMissingCount += 1;
    });
    SharedService.setFilterData({
      pageSize: this.props.location.pageSize,
      currentPage: 1,
      action: "LastImage",
      worklisttype: this.props.location.WorkListType,
      filteredData: this.state.gridImageDataSet,
      AllImagesCount: tempcount,
    });
  };

  redirectToCms() {
    let userID = localStorage.getItem("userID");
    let buffData = new Buffer(userID);
    let base64data = buffData.toString("base64");
    let cmsUrl =
      `${config.cmsImageLink}` +
      this.state.imgProperties.strProgrammeID +
      "&ImageID=" +
      this.state.imgProperties.lngImageID +
      "&opt=QCImageDetails&UserID=" +
      base64data;
    window.open(cmsUrl, "_blank");
    this.setState({ isEditIconClicked: true });
  }

  closeClickHandler = () => {
    let tempcount = {};
    tempcount.intUnActionedCount = 0;
    tempcount.intMissingCount = 0;
    tempcount.intActionedCount = 0;
    this.state.gridImageDataSet.forEach((arrVal) => {
      if (
        arrVal.lngImageID &&
        (arrVal.intActionStatusCode === 1 || arrVal.intActionStatusCode === 0)
      )
        tempcount.intUnActionedCount += 1;
      else if (arrVal.lngImageID) tempcount.intActionedCount += 1;
      else tempcount.intMissingCount += 1;
    });
    SharedService.setImageIndex({ id: this.state.serialNum - 1 ,mode:this.props.location.WorkListType}); //Added for ImageFocus
    sessionStorage.removeItem("isRefreshPage");
    SharedService.setSingleImageView({ isSingleImageView: false });
    SharedService.setFilterData({
      pageSize: this.props.location.pageSize,
      currentPage: this.state.currentPage,
      action: "imageTopView",
      status: this.props.location.actionstatus,
      filteredData: this.state.gridImageDataSet,
      AllImagesCount: tempcount,
      worklisttype: this.props.location.WorkListType,
    });
  };

  refreshClick() {
    sessionStorage.removeItem("isRefreshPage");
    SharedService.setFilterData({
      pageSize: this.props.location.pageSize,
      currentPage: parseInt(sessionStorage.getItem("QCCurrentPage")),
      action: "imageTopView",
      status: sessionStorage.getItem("QCActionStatus"),
      worklisttype: this.props.location.WorkListType,
    });
    if (this.props.location.WorkListType === "2") {
      this.props.history.push("/qcuser/dailyqcworklist");
    } else if (this.props.location.WorkListType === "1") {
      this.props.history.push("/qcuser/scheduleqcworklist");
    } else {
      this.props.history.push("/login");
    }
  }

  render() {
    let listSource = [];
    this.state.listerrors.map((source, index) =>
      listSource.push({ id: index, name: source.strErrorName })
    );

    let pathtoclose =
      this.props.location.WorkListType === "2"
        ? "/qcuser/dailyqcworklist"
        : "/qcuser/scheduleqcworklist";

    const cmntbox =
      this.state.lstImgComments.length > 0
        ? this.state.lstImgComments.map((element) => (
          <CommentBox cmntContent={element} />
        ))
        : null;

    let prpsliderclass = "";
    let fullpageviewtoggle = "";
    if (this.state.propertyslider) {
      prpsliderclass =
        "panel-slider panel-slider-right float-right img-pro-arrow-bg";
      fullpageviewtoggle = "mr-3 container-panel container-panel-right";
    } else {
      prpsliderclass =
        "panel-slider panel-slider-right float-right img-pro-arrow-bg active";
      fullpageviewtoggle = "mr-3 container-panel container-panel-right active";
    }

    let topSliderClass = "";
    let closeToggleClass = "";
    if (this.state.topSlider) {
      topSliderClass = "row bg-grey pt-3 pb-3 ml-0 system_properties";
      closeToggleClass = "view-close-icon-toggle d-none";
    } else if (!this.state.topSlider && !this.state.propertyslider) {
      topSliderClass = "row bg-grey pt-3 pb-3 ml-0 system_properties d-none";
      closeToggleClass = "fullView-close-icon-toggle";
    } else {
      topSliderClass = "row bg-grey pt-3 pb-3 ml-0 system_properties d-none";
      closeToggleClass = "view-close-icon-toggle";
    }

    let singleImgClass = "";
    if (this.state.imgProperties.chrImageOrientation === "P") {
      if (this.state.imgProperties.strHeight >= 880) {
        singleImgClass = "singleImageViewPortrait img-fluid";
      } else {
        singleImgClass = "img-fluid";
      }
    } else {
      if (this.state.imgProperties.strWidth >= 880) {
        singleImgClass = "singleImageViewLandscape img-fluid";
      } else {
        singleImgClass = "img-fluid";
      }
    }

    const pageView = (
      // <DivWithErrorHandling showError={this.state.showError}>
      <div className="programs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 pl-0 pr-0">
              <div className={prpsliderclass}>
                <div className="img-pro-content editor_properties">
                  <div className="pull-left pt-3 pl-2">
                    <h4 className="txt-blue fs14">IMAGE PROPERTIES</h4>
                  </div>

                  <div
                    className="pull-right mt-3 pr-2"
                    onClick={this.redirectToCms.bind(this)}
                  >
                    <i
                      className="fa fa-pencil txt-grey edit_icon"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                  <div className="clearfix"></div>
                  <ul className="pl-2 pr-2 mt-2">
                    <SingleImageSideProperties
                      imgprop={this.state.imgProperties}
                      showPropNo={4}
                    />
                  </ul>
                  <div className="col-md-12 w-100 pr-2 mt-3 pl-2">
                    <div className="form-group dropdown-select error_type">
                      <label
                        htmlFor="pro-name"
                        className="control-label fs14 txt-blue fw-bold"
                      >
                        ERROR TYPE
                      </label>
                      <Picky
                        value={this.state.selectederrorarr}
                        options={listSource}
                        onChange={this.selectMultipleOption.bind(this)}
                        valueKey="id"
                        labelKey="name"
                        multiple={true}
                        includeFilter={true}
                        dropdownHeight={150}
                        open={false}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 comment-panel pl-2 pr-2 mb-4">
                    <h5 className="fs14 txt-blue mb-0 lh25">COMMENTS</h5>
                    <div className="comment-content pt-2">
                      <div className="comment-wrapper">
                        {cmntbox}
                        {this.state.newlstImgComments.map((element, key) => (
                          <CommentBox cmntContent={element} />
                        ))}
                      </div>
                      <div>
                        <KeyboardEventHandler
                          handleKeys={["Enter"]}
                          handleFocusableElements={true}
                          onKeyEvent={(key, e) => this.cmntBtnClicked()}
                        >
                          <input
                            type="text"
                            value={this.state.cmtTxt}
                            className="form-control search-input border comments"
                            placeholder="Your Message..."
                            onChange={this.onTextInput.bind(this)}
                            onFocus={() => {
                              this.setState({ cmntboxfocus: true });
                            }}
                            onBlur={() =>
                              this.setState({ cmntboxfocus: false })
                            }
                          />
                        </KeyboardEventHandler>
                      </div>
                      {this.state.cmntBtnStatus ? (
                        <div
                          className="msg-icon"
                          onClick={this.cmntBtnClicked.bind(this)}
                        >
                          <i
                            className="fa fa-paper-plane txt-blue"
                            aria-hidden="true"
                          ></i>
                        </div>
                      ) : (
                        <div className="msg-icon icon-deactive">
                          <i
                            className="fa fa-paper-plane txt-blue"
                            aria-hidden="true"
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 pl-2 pr-2 mb-4">
                    {
                      this.state.actionedImage ? (
                        <Buttons
                          type="button"
                          id={"ResetImg"}
                          class={
                            "btn fw-normal btn-img-reset  btn-img-pro pl-2 pr-2 "
                          }
                          btnValue={"Reset Image"}
                          // onClick={this.onImprovisationClick.bind(this)}
                          onClick={this.onResetClick.bind(this)}
                          btnStatus={false}
                        />
                      ) : null
                    }
                    {this.state.selectederrorarr.length > 0 ||
                      !this.state.isEditIconClicked ||
                      this.state.actionedImage ? (
                      <Buttons
                        id={"ApproveWithEdits"}
                        class={
                          "btn fw-normal btn-edit btn-img-pro pl-2 pr-2"
                        }
                        btnValue={"Approve With Edits"}
                        btnStatus={true}
                      />
                    ) : (
                      <Buttons
                        id={"ApproveWithEdits"}
                        class={
                          "btn fw-normal btn-edit btn-img-pro pl-2 pr-2 edit"
                        }
                        btnValue={"Approve With Edits"}
                        onClick={this.onApproveWthEditsClick.bind(this)}
                        btnStatus={false}
                      />
                    )}
                    {this.state.selectederrorarr.length <= 0 ||
                      this.state.actionedImage ? (
                      <Buttons
                        id={"Reject"}
                        class={
                          "btn fw-normal btn-reject btn-img-pro pl-2 pr-2 reject"
                        }
                        btnValue={"Reject"}
                        onClick={this.onRejectClick.bind(this)}
                        btnStatus={true}
                      />
                    ) : (
                      <Buttons
                        id={"Reject"}
                        class={
                          "btn fw-normal btn-reject btn-img-pro pl-2 pr-2"
                        }
                        btnValue={"Reject"}
                        onClick={this.onRejectClick.bind(this)}
                        btnStatus={false}
                      />
                    )}
                    {this.state.selectederrorarr.length > 0 ||
                      this.state.actionedImage ? (
                      <Buttons
                        id={"Approve"}
                        class="btn fw-normal btn-approve btn-img-pro pl-2  pr-2"
                        btnValue={"Approve"}
                        btnStatus={true}
                      />
                    ) : (
                      <Buttons
                        id={"Approve"}
                        class={
                          "btn fw-normal btn-approve btn-img-pro pl-2  pr-2 approve"
                        }
                        btnValue={"Approve"}
                        onClick={this.onApproveClick.bind(this)}
                        btnStatus={false}
                      />
                    )}
                    {this.state.selectederrorarr.length > 0 ||
                      this.state.actionedImage ? (
                      <Buttons
                        id={"Improvization"}
                        class={
                          "btn fw-normal btn-improvisation btn-img-pro pl-2 pr-2"
                        }
                        btnValue={"Improvisation"}
                        btnStatus={true}
                      />
                    ) : (
                      <Buttons
                        id={"Improvization"}
                        class={
                          "btn fw-normal btn-improvisation btn-img-pro pl-2 pr-2 improve"
                        }
                        btnValue={"Improvisation"}
                        onClick={this.onImprovisationClick.bind(this)}
                        btnStatus={false}
                      />
                    )}
                  </div>
                </div>
                <div
                  className="collapse-arrow arrow-right"
                  id="sidebarCollapse"
                  onClick={this.onSliderClick.bind(this)}
                >
                  <img
                    src={ToggleImage}
                    width="8"
                    height="40"
                    alt="toggle-icon"
                  />
                </div>
              </div>
              <div className={fullpageviewtoggle}>
                <div className={topSliderClass}>
                  <div className="col-md-4 pl-4">
                    <ul className="border-white">
                      <SingleImageSideProperties
                        imgprop={this.state.imgProperties}
                        showPropNo={1}
                      />
                    </ul>
                  </div>
                  <div className="col-md-4 pl-4">
                    <ul className="border-white">
                      <SingleImageSideProperties
                        imgprop={this.state.imgProperties}
                        showPropNo={2}
                      />
                    </ul>
                  </div>
                  <div className="col-md-4 pl-4">
                    <ul>
                      <SingleImageSideProperties
                        imgprop={this.state.imgProperties}
                        showPropNo={3}
                      />
                    </ul>
                  </div>
                </div>
                <div className="top-hide-panel">
                  <img
                    className="top-toggle-image"
                    onClick={this.onTopToggleClick}
                    alt="toggle-icon-top"
                    src={TopToggle}
                  />
                </div>
                <div className="serial_number_header fs14 txt-orange">
                  <div className="serial_number" >
                    Image {this.state.serialNum} of{" "}
                    {parseInt(localStorage.getItem("pagesize"), 10)}
                  </div>
                  <div
                    className="view-close-icon"
                    onClick={this.closeClickHandler}
                  >
                    <Link
                      to={{
                        pathname: pathtoclose, // '/qcuser/dailyqcworklist'
                      }}
                    >
                      <i
                        className="fa fa-times txt-blue fs18 close_icon"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <div className="row pl-5 pr-3">
                  <div
                    id="img-gallery"
                    className="carousel slide col-md-12"
                    data-ride="carousel"
                    data-interval="false"
                  >
                    <div
                      className={closeToggleClass}
                      onClick={this.closeClickHandler}
                    >
                      <Link
                        to={{
                          pathname: pathtoclose, // '/qcuser/dailyqcworklist'
                        }}
                      >
                        <i
                          className="fa fa-times txt-blue fs18 close_icon"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                    <div className="carousel-inner img-gallery-carousel-inner text-center">
                      <div className="carousel-item active">
                        <span
                          data-toggle="modal"
                          style={{ cursor: "pointer" }}
                          data-target="#model-img"
                        >
                          <img
                            alt="zoom"
                            src={this.state.imgProperties.strImageLink}
                            className={singleImgClass}
                            onClick={this.showZoomImage.bind(this)}
                          />
                        </span>
                      </div>
                      {this.state.stpLeftArrow ? null : (
                        <a
                          className="carousel-control-prev img-gallery-arrow-left"
                          href="#img-gallery"
                          onClick={() => this.onImgSliderClick("prev")}
                          data-slide="prev"
                        >
                          {" "}
                          <i
                            className="fa fa-chevron-left fs30"
                            aria-hidden="true"
                          ></i>{" "}
                        </a>
                      )}
                      <a
                        className="carousel-control-next img-gallery-arrow-right arrow"
                        href="#img-gallery"
                        data-slide="next"
                        onClick={() => this.onImgSliderClick("next")}
                      >
                        <i
                          className="fa fa-chevron-right fs30"
                          aria-hidden="true"
                        ></i>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    // </DivWithErrorHandling>

    let zoomviewvar = "";

    this.state.imgProperties.strAspectRatio === "Unknown"
      ? (zoomviewvar = null)
      : (zoomviewvar = (
        <Modal
          isOpen={this.state.zoomOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
          className={this.state.cssVar}
        >
          <ZoomView
            loader={this.state.loaderStatus}
            imgLink={this.state.imgProperties.strImageLink}
            closeModel={this.onEscClick.bind(this)}
            classVar={this.state.cssVar}
          />
        </Modal>
      ));

    return (
      <div className="start complete">
        <KeyboardEventHandler
          handleKeys={["left", "right"]}
          handleFocusableElements={false}
          onKeyEvent={(key, e) => this.onImgSliderClick(key)}
        />
        <KeyboardEventHandler
          handleKeys={["esc"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onEscClick()}
        />
        <KeyboardEventHandler
          handleKeys={["esc"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.closeApprovePopup()}
        />
        <KeyboardEventHandler
          handleKeys={["shift+e"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onApproveWthEditsClick()}
        />
        <KeyboardEventHandler
          handleKeys={["shift+r"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onRejectClick()}
        />
        <KeyboardEventHandler
          handleKeys={["shift+a"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onApproveClick()}
        />
        <KeyboardEventHandler
          handleKeys={["shift+i"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onImprovisationClick()}
        />

        <KeyboardEventHandler
          handleKeys={["F5"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.refreshClick()}
        />

        {this.state.loaderStatus ? (
          <div id="loader-wrapper">
            <div id="loader"></div>
          </div>
        ) : (
          pageView
        )}

        {zoomviewvar}

        <Modal
          isOpen={this.state.modalConfirmIsOpen}
          onRequestClose={this.closeModal3.bind(this)}
          contentLabel="Confirmation popup"
          style={modalstyle}
        >
          <ConfirmApprovePopup
            pagesize={this.state.items}
            closeModel={this.closeModal3.bind(this)}
            confirmBoxApprove={this.confirmBoxApprove.bind(this)}
            confirmBoxCancel={this.confirmBoxCancel.bind(this)}
          />
        </Modal>
        <Modal
          isOpen={this.state.modalDispMsgIsOpen}
          onRequestClose={this.closeModal2.bind(this)}
          contentLabel="Message Display"
          style={this.state.failureCount > 0 ? modalstyle2 : modalstyle}
        >
          <DisplayMessage
            closeModel={this.closeModal2.bind(this)}
            isApprove={this.state.isApprove}
            count={this.state.items}
            zeroImagesToAction={this.state.zeroImagesToAction}
            failureCount={this.state.failureCount}
            last={this.state.isLastImageOfSet}
            currentPage={this.state.currentPage}
            setFilterData={this.props.data}
          />
        </Modal>
      </div>
    );
  }
}
export default SingleImageView;
