import React from "react";
class NotFound extends React.Component {
  render() {
    return (
      <div className="heading-bottom-line ml-3 pt-4">
        <h1 className="txt-violet fw-normal fs18">Not Found</h1>
        <br />
        <br />
        <br />
        <h2 align="center"> Page under construction.. </h2>
      </div>
    );
  }
}

export default NotFound;
