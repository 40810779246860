import React, { Component } from "react";
import ScheduleQCMainDropdown from "./ScheduleQCMainDropdown";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { SharedService } from "../SharedService/SharedService";
import KeyboardEventHandler from "react-keyboard-event-handler";

let $ = require("jquery");

class ScheduleQcWorklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleqctabs: this.props.scheduleqctabs,
      isHelpScreenActive: false,
    };
  }

  componentWillMount() {
    SharedService.getDataForScheduleQC().subscribe((response) => {
      this.setState({
        scheduleqctabs: response,
      });
    });

    SharedService.getIsHelpScreenActive().subscribe((data) => {
      this.setState({ isHelpScreenActive: data.isHelpScreenActive });
    });
  }

  componentWillUnmount() {}

  componentDidMount() {
    this.focusOnRefresh();
  }

  focusOnRefresh = () => {
    if (sessionStorage.getItem("isActiveTab") === "1") {
      $("#0").parent().click();
      sessionStorage.setItem("isEditorReverted", "0");
    } else if (sessionStorage.getItem("isActiveTab") === "2") {
      $("#1").parent().click();
      sessionStorage.setItem("isEditorReverted", "0");
    } else if (sessionStorage.getItem("isActiveTab") === "3") {
      $("#2").parent().click();

      sessionStorage.setItem("isEditorReverted", "0");
    } else if (sessionStorage.getItem("isActiveTab") === "4") {
      $("#3").parent().click();

      sessionStorage.setItem("isEditorReverted", "0");
    } else if (sessionStorage.getItem("isActiveTab") === "8") {
      $("#4").parent().click();
    } else if (sessionStorage.getItem("isActiveTab") === "5") {
      $("#5").parent().click();

      sessionStorage.setItem("isEditorReverted", "0");
    }
  };

  focusTab = () => {
    if (!this.state.isHelpScreenActive) {
      $(".react-tabs__tab--selected").focus();
      $(".react-tabs__tab").on("keydown", function (e) {
        if (e.keyCode === 38) {
          return false;
        } else if (e.keyCode === 40) {
          return false;
        }
      });
    }
  };

  render() {
    if (sessionStorage.getItem("firstTab") === "0") {
      this.focusOnRefresh();
    }

    return (
      <div>
        <KeyboardEventHandler
          handleKeys={["shift+t"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.focusTab()}
        />
        <div>
          <div className="heading-bottom-line ml-3 pt-4 start complete">
            <h1 className="txt-violet fw-normal fs18">Scheduled QC Worklist</h1>
          </div>
          <div className="programs mb20">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 pl-0 pr-0">
                  <Tabs>
                    <TabList>
                      {this.state.scheduleqctabs.map((tab, i) => (
                        <Tab key={i}>
                          <div id={i}>{tab.tabName}</div>
                        </Tab>
                      ))}
                    </TabList>
                    {this.state.scheduleqctabs.map((tab, i) => (
                      <TabPanel key={i}>
                        <ScheduleQCMainDropdown
                          data={tab}
                          applyFilter={this.applyFilterHandler}
                          applyReset={this.applyResetHandler}
                          populateEditor={this.populateEditorHandler}
                          populateSubGenre={this.populateSubGenreHandler}
                          selectMultipleOptionTest={this.EditorHandler}
                          airingreset={this.airingDataReset}
                          EditorRevertedFunc={this.EditorRevertedHandler}
                          populateImgSize={this.populateImgSizeHandler}
                          populateChannelLangChannelGenre={this.populateChannelLangChannelGenreHandler}
                          populateChannelWRTChannelGenre={this.populateChannelWRTChannelGenreHandler}
                          applyResetOnMoreFilterButton={this.MoreFilterReset}
                          resetFilters={this.resetFilters}
                        />
                      </TabPanel>
                    ))}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  applyFilterHandler = (data) => {
    data.action = "ScheduleQCApplyFilter";
    SharedService.setFilterData(data);
  };

  EditorHandler = (value, name) => {
    name.action = "Editor";
    name.worklisttype = "1";
    name.status = value.status;
    SharedService.setFilterData(name);
  };

  applyResetHandler = (data) => {
    data.action = "ScheduleQCApplyReset";
    SharedService.setFilterData(data);
  };

  airingDataReset = (data) => {
    data.action = "ScheduleQCAiringDateReset";
    SharedService.setFilterData(data);
  };

  populateEditorHandler = (data) => {
    data.action = "populateEditor";
    data.worklisttype = "1";
    SharedService.setFilterData(data);
  };

  populateSubGenreHandler = (data) => {
    data.action = "populateSubGenre";
    data.worklisttype = "1";
    SharedService.setFilterData(data);
  };

  populateImgSizeHandler = (data) => {
    data.action = "populateImgSize";
    data.worklisttype = "1";
    SharedService.setFilterData(data);
  };

  populateChannelLangChannelGenreHandler = (data) => {
    data.action = "populateChannel&Language&ChannelGenre";
    data.worklisttype = "1";
    SharedService.setFilterData(data);
  };

  populateChannelWRTChannelGenreHandler= (data)=>
  {
    data.action="populateChannelWRTGroup&ChannelGenre";
    data.worklisttype="1";
    SharedService.setFilterData(data);
  }


  MoreFilterReset = (data) => {
    data.action = "MoreFilterResetSQ";
    SharedService.setFilterData(data);
  };

  resetFilters = (data) => {
    data.action = "FilterReset";
    SharedService.setFilterData(data);
  };
}
export default ScheduleQcWorklist;
