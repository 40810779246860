import React from "react";
import axios from "axios";
import "react-dates/initialize";
import "react-picky/dist/picky.css";
import config from "../../containers/config";
import Dropdown from "../QCUser/Dropdown";
import EditorTables from "./EditorTables";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import DateRangePickerNew from "react-bootstrap-daterangepicker";
// import { DivWithErrorHandling } from '../../hoc/DivWithErrorHandling';
import KeyboardEventHandler from "react-keyboard-event-handler";
import { SharedService } from "../SharedService/SharedService";
// import { type } from 'jquery';

class EditorMainDropdown extends React.Component {
  constructor(props) {
    super(props);
    let subtractTwoDaysDate = moment().subtract(2, "days").format("MM/DD/YYYY");
    let subtractOneMonthDate = moment()
      .subtract(1, "months")
      .format("MM/DD/YYYY");
    this.state = {
      arrayValSource: props.data.editorTabContent.bindSource.selectedValue,
      arrayValChannel: props.data.editorTabContent.bindChannel.selectedValue,
      filteredData: props.data.editorTabContent.filteredData,
      displayDate:
        props.data.status === "2"
          ? props.data.editorTabContent.approvedDate
          : props.data.editorTabContent.displayDate,
      loading: props.data.editorTabContent.loader,
      pageSize: props.data.editorTabContent.pageSize,
      currentPage: props.data.editorTabContent.currentPage,
      dateRequired: false,
      tabName: props.data.tabName,
      startDate: props.data.editorTabContent.startDate
        ? moment(props.data.editorTabContent.startDate).format("MM/DD/YYYY")
        : props.data.status === "2"
        ? subtractTwoDaysDate
        : subtractOneMonthDate,
      endDate: props.data.editorTabContent.endDate
        ? moment(props.data.editorTabContent.endDate).format("MM/DD/YYYY")
        : moment().format("MM/DD/YYYY"),
      isConfirmActive: false,
      startAiringDate: moment().subtract(2, "days"),
      endAiringDate: moment(),
      displayAiringDate: props.data.editorTabContent.displayAiringDate,
      isHelpScreenActive: false,
      first: props.data.first,
      isSourceDropdown: false,
      isChannelDropdown: false,
      arrayValWorklist: props.data.editorTabContent.bindWorklist.selectedValue,
    };
    this.selectMultipleOption = this.selectMultipleOption.bind(this);
  }

  componentDidMount() {
    sessionStorage.setItem("EditorActionStatus", this.props.data.status);
    if (
      this.props.data.isFirstTime &&
      sessionStorage.getItem("isActiveTabEditor") === this.props.data.status
    ) {
      // && sessionStorage.getItem("isActiveTabEditor") === this.props.data.status
      sessionStorage.setItem("firstTabEditor", "1");
      this.applyFilters();
    }

    this.subscription = SharedService.getIsHelpScreenActive().subscribe(
      (data) => {
        this.setState({ isHelpScreenActive: data.isHelpScreenActive });
      }
    );
  }

  isDropOpen(val, drpDwnName) {
    if (drpDwnName === "Source") {
      this.setState({ isSourceDropdown: val });
    } else if (drpDwnName === "Channel") {
      this.setState({ isChannelDropdown: val });
    }
  }

  componentDidUpdate(propsData) {
    if (propsData.data.callApplyFilter !== this.props.data.callApplyFilter) {
      if (this.props.data.callApplyFilter) {
        this.applyFilters();
      }
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    window.removeEventListener("scroll",this.scrollfunction);
  }

  selectMultipleOption(value, name) {
    switch (value) {
      case "Source":
        this.setState({ arrayValSource: name });
        break;
      case "Channel":
        this.setState({ arrayValChannel: name });
        break;
      case "Worklist":
        this.setState({ arrayValWorklist: name });
        break;
      default:
        break;
    }
  }

  onApplyDateAiring = (e, picker) => {
    let displayAiringDate = `${moment(picker.startDate).format(
      "DD/MM/YYYY"
    )} - ${moment(picker.endDate).format("DD/MM/YYYY")}`;
    this.setState({
      displayAiringDate: displayAiringDate,
      startAiringDate: moment(picker.startDate).format("MM/DD/YYYY"),
      endAiringDate: moment(picker.endDate).format("MM/DD/YYYY"),
    });
  };

  onApplyDate = (e, picker) => {
    let displayDate = `${moment(picker.startDate).format(
      "DD/MM/YYYY"
    )} - ${moment(picker.endDate).format("DD/MM/YYYY")}`;
    if (this.props.data.status === "2") {
      this.props.data.editorTabContent.approvedDate = displayDate;
    } else {
      this.props.data.editorTabContent.displayDate = displayDate;
    }
    this.setState({
      displayDate: displayDate,
      dateRequired: false,
      startDate: moment(picker.startDate).format("MM/DD/YYYY"),
      endDate: moment(picker.endDate).format("MM/DD/YYYY"),
    });
    this.props.data.editorTabContent.startDate = this.state.startDate;
    this.props.data.editorTabContent.endDate = this.state.endDate;
    this.props.data.editorTabContent.dateRequired = this.state.dateRequired;
  };

  applyFilters = (e) => {
    if (
      !this.state.isConfirmActive &&
      !this.state.isHelpScreenActive &&
      !this.isDropDownActive()
    ) {
      let arrayValSource, arrayValChannel, arrayValWorklist;
      if (this.props.data.first < 2) {
        arrayValSource = this.state.arrayValSource;
        arrayValChannel = this.state.arrayValChannel;
        arrayValWorklist = this.state.arrayValWorklist;
      } else {
        arrayValSource =
          this.props.data.editorTabContent.bindSource.selectedValue.length > 0
            ? this.props.data.editorTabContent.bindSource.selectedValue
            : this.state.arrayValSource;
        arrayValChannel =
          this.props.data.editorTabContent.bindChannel.selectedValue.length > 0
            ? this.props.data.editorTabContent.bindChannel.selectedValue
            : this.state.arrayValChannel;
        arrayValWorklist =
          this.props.data.editorTabContent.bindWorklist.selectedValue.length > 0
            ? this.props.data.editorTabContent.bindWorklist.selectedValue
            : this.state.arrayValWorklist;
      }

      let sourceIndex = [];
      arrayValSource.map((i) => sourceIndex.push(i.id));
      let channelIndex = [];
      arrayValChannel.map((i) => channelIndex.push(i.id));
      let worklistIndex = [];
      arrayValWorklist.map((i) => worklistIndex.push(i.id));
      if (
        (!this.props.data.isFirstTime && this.props.data.first < 2) ||
        this.props.data.first === 2
      ) {
        if (this.state.displayDate.length <= 0) {
          this.setState({ dateRequired: true });
          return;
        } else if (this.state.displayDate.length > 0) {
          this.setState({ dateRequired: false });
        }
      }

      let pageSizeTemp1;
      let currentPageTemp1;

      pageSizeTemp1 =
        this.props.data.first < 2
          ? 25
          : this.props.data.editorTabContent.pageSize;
      currentPageTemp1 =
        this.props.data.first < 2
          ? 0
          : this.props.data.editorTabContent.currentPage;

      this.setState({
        loading: "true",
        arrayValSource: arrayValSource,
        arrayValChannel: arrayValChannel,
        isHelpScreenActive: false,
        isConfirmActive: false,
        pageSize: pageSizeTemp1,
        currentPage: currentPageTemp1,
        first: 0,
        arrayValWorklist: arrayValWorklist,
      });

      if (this.state.displayDate.length > 0) {
        axios
          .post(
            `${config.apiUrl + config.qcApi}Image/GetEditorTabularImages/`,
            {
              objDailyQCFilter: {
                dtmFromDate: moment(this.state.startDate).format("YYYY-MM-DD"),
                dtmToDate: moment(this.state.endDate).format("YYYY-MM-DD"),
                lstSourceID: sourceIndex,
                lstChannelID: channelIndex,
                dtmairingFromDate:
                  this.state.displayAiringDate !== ""
                    ? moment(this.state.startAiringDate).format("YYYY-MM-DD")
                    : null,
                dtmairingToDate:
                  this.state.displayAiringDate !== ""
                    ? moment(this.state.endAiringDate).format("YYYY-MM-DD")
                    : null,
                lstWorklistType: worklistIndex,
              },
              intActionStatusCode: this.props.data.status,
              intUserRoleID: localStorage.getItem("userRoleID"),
            }
          )
          .then((response) => {
            if (
              response.status === 200 &&
              response.data &&
              response.data.objRespMessage
            ) {
              this.setState({
                filteredData: response.data.objRespMessage,
                loading: "false",
                pageSize: pageSizeTemp1,
                currentPage: currentPageTemp1,
                showError: false,
              });
              this.props.applyFilter({
                propsData: this.props.data,
                stateData: this.state,
              });
              SharedService.setEditorFilterDataForTabularView(
                this.state.filteredData
              );
            } else if (response.status === 204) {
              this.setState({
                filteredData: [],
                loading: "false",
                showError: false,
              });
              this.props.applyFilter({
                propsData: this.props.data,
                stateData: this.state,
              });
              SharedService.setEditorFilterDataForTabularView(
                response.data.objRespMessage
              );
            }
          })
          .catch((error) => {
            if (!error.response) {
              error = "Not able to communicate with the backend service !";
              console.log(error);
            } else {
              switch (error.response.status) {
                case 404:
                  error =
                    "API Error - 404 received from the API\n\n API URL -" +
                    error.request.responseURL;
                  console.log(error);
                  break;

                case 504:
                  error =
                    "API Error - 504 received from the API\n\n API URL -" +
                    error.request.responseURL;
                  console.log(error);
                  break;

                default:
                  error =
                    "Unknown Error received from the API - Status " +
                    error.response.status;
                  console.log(error);
                  break;
              }
            }
            this.setState({
              filteredData: [],
              loading: "false",
              showError: true,
            });
          });
      }
    }
  };

  isDropDownActive = () => {
    let retVal = false;
    if (this.state.isSourceDropdown || this.state.isChannelDropdown) {
      retVal = true;
    }
    return retVal;
  };

  scrollfunction = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      this.setState({ isShowTopBtn: true })
    }
    else {
      this.setState({ isShowTopBtn: false })
    }
  }

  topFunction=()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  handleClick() {
    this.applyFilters();
  }

  applyReset() {
    if (!this.state.isConfirmActive && !this.state.isHelpScreenActive) {
      this.setState({
        arrayValSource: [],
        arrayValChannel: [],
        displayDate: "",
        filteredData: [],
        dateRequired: false,
        endDate: moment(),
        startDate: moment(),
        displayAiringDate: "",
        startAiringDate: moment(),
        endAiringDate: moment(),
        arrayValWorklist: [],
      });
      this.props.applyReset({ stateData: this.state });
    }
  }

  stopShortCut = (tmpIsConfirmActive) => {
    this.setState({ isConfirmActive: tmpIsConfirmActive });
  };

  onCancelAiring() {
    this.setState({
      displayAiringDate: "",
      startAiringDate: moment(),
      endAiringDate: moment(),
    });
    this.props.airingreset(this.state);
  }
  render() {
    if (
      sessionStorage.getItem("isEditorRole") === "0" &&
      sessionStorage.getItem("firstTabEditor") === "1"
    ) {
      sessionStorage.setItem("isActiveTabEditor", this.props.data.status);
    }

    const { bindSource, bindChannel, bindWorklist } =
      this.props.data.editorTabContent;

    let listSource = [];
    bindSource.options.map((source) =>
      listSource.push({ id: source.intSourceID, name: source.strSource })
    );

    let listChannel = [];
    bindChannel.options.map((channel) =>
      listChannel.push({
        id: channel.strChannelID,
        name: channel.strChannelName,
      })
    );

    let listWorklist = [];
    bindWorklist.options.map((worklist) =>
      listWorklist.push({
        id: worklist.intWorklistID,
        name: worklist.strWorklist,
      })
    );

    let gridSection;

    if (this.state.loading === "false") {
      if (this.state.filteredData.length > 0) {
        gridSection = (
          <EditorTables
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            filter={this.props.data.editorTabContent.filteredData}
            status={this.props.data.status}
            stopShortCuts={this.stopShortCut}
          ></EditorTables>
        );
      } else if (this.state.filteredData.length === 0) {
        gridSection = (
          <div>
            <div
              className="tab-pane fade show active"
              id="qc-worklist"
              role="tabpanel"
              aria-labelledby="qc-tab"
            >
              <div className="row ml-0 mr-0">
                <div className="col-md-12">
                  <div
                    className="border pt-3 pb-3 pr-3 pl-3"
                    style={{ overflow: "hidden" }}
                  >
                    <div className="pull-left pt-3">
                      <h3 className="txt-orange fw-normal fs18">
                        0 Images Found{" "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else if (this.state.loading === "true") {
      gridSection = (
        <div id="loader-wrapper">
          <div id="loader"></div>
        </div>
      );
    }
    window.addEventListener("scroll",this.scrollfunction)

    return (
      <div>
        <KeyboardEventHandler
          handleKeys={["Enter"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.applyFilters()}
        />
        <KeyboardEventHandler
          handleKeys={["r"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.applyReset()}
        />
        <div className="tab-content mt-3" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="qc-worklist"
            role="tabpanel"
            aria-labelledby="qc-tab"
          >
            <div className="row ml-0 mr-0">
              <div className="col-md-3">
                <div className="form-group dropdown-select">
                  <label
                    htmlFor="field-1"
                    className="control-label fs11 pl-2 txt-blue"
                  >
                    QCed From & To Date <span className="txt-red">*</span>
                  </label>
                  <DateRangePickerNew
                    autoApply
                    onApply={this.onApplyDate}
                    minDate={moment().add(-6, "months")}
                    maxDate={moment()}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  >
                    <input
                      readOnly
                      type="text"
                      className={
                        this.state.dateRequired
                          ? "form__daterange form-control daterange hasDatepicker required"
                          : "form__daterange form-control daterange hasDatepicker"
                      }
                      id="datePicker"
                      value={this.state.displayDate}
                    />
                    <div className="cal-icon">
                      <i className="fa fa-calendar"></i>
                    </div>
                  </DateRangePickerNew>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group dropdown-select">
                  <label
                    htmlFor="field-1"
                    className="control-label fs11 pl-2 txt-blue"
                  >
                    Airing From & To Date{" "}
                  </label>
                  <DateRangePickerNew
                    autoApply
                    onApply={this.onApplyDateAiring}
                    minDate={moment().subtract(1, "Minutes")}
                    maxDate={moment().add(15, "Days")}
                    startDate={this.state.startAiringDate}
                    endDate={this.state.endAiringDate}
                  >
                    <input
                      readOnly
                      type="text"
                      className="form__daterange form-control daterange hasDatepicker"
                      id="datePicker"
                      value={this.state.displayAiringDate}
                    />
                    <div className="cal-icon">
                      <i className="fa fa-calendar mr5"></i>
                    </div>
                  </DateRangePickerNew>
                  <i
                    className="fa fa-undo user-name icon-deactive airing-reset"
                    aria-hidden="true"
                    onClick={() => {
                      this.onCancelAiring();
                    }}
                  ></i>
                </div>
              </div>
              <Dropdown
                label={"Source"}
                selectedvalue={this.state.arrayValSource}
                option={listSource}
                change={this.selectMultipleOption.bind(this, "Source")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Source")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Source")}
              ></Dropdown>
              <Dropdown
                label={"Channel"}
                selectedvalue={this.state.arrayValChannel}
                option={listChannel}
                change={this.selectMultipleOption.bind(this, "Channel")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Channel")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Channel")}
              ></Dropdown>
            </div>
            <div className="row ml-0 mr-0">
              {this.state.isShowTopBtn && <button type="button" id="myBtn" onClick={this.topFunction}>
                <i className="material-icons">keyboard_arrow_up</i>
              </button>}
              <Dropdown
                label={"Worklist"}
                selectedvalue={this.state.arrayValWorklist}
                option={listWorklist}
                change={this.selectMultipleOption.bind(this, "Worklist")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Worklist")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Worklist")}
              ></Dropdown>
              <div className="col-md-12 mb-15 text-right">
                <button
                  type="button"
                  className="search btn fw-normal btn-orange txt-white pl-3 pr-3"
                  onClick={this.handleClick.bind(this)}
                >
                  Search
                </button>
                {this.state.arrayValSource.length > 0 ||
                this.state.arrayValChannel.length > 0 ||
                this.state.displayDate.length > 0 ||
                this.props.data.editorTabContent.filteredData.length > 0 ? (
                  <button
                    type="button"
                    className="reset btn btn-reset"
                    onClick={this.applyReset.bind(this)}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          {/* <DivWithErrorHandling showError={this.state.showError}> */}
          {gridSection}
          {/* </DivWithErrorHandling> */}
        </div>
      </div>
    );
  }
}
export default EditorMainDropdown;
