import React, { Component } from "react";
import EditorMainDropdown from "./EditorMainDropdown";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { SharedService } from "../SharedService/SharedService";
import KeyboardEventHandler from "react-keyboard-event-handler";

let $ = require("jquery");

class EditorDailyQcWorklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editortabs: this.props.editortabs,
    };
  }
  componentWillMount() {
    SharedService.getEditorDataForDQ().subscribe((response) => {
      this.setState({
        editortabs: response,
      });
    });
  }

  componentWillUnmount() {}

  componentDidMount() {
    this.focusOnRefresh();
  }

  focusOnRefresh = () => {
    //let tabCopy = JSON.parse(JSON.stringify(this.state.editortabs));
    // tabCopy.forEach(state => {
    //     if (state.status === "2" && state.editorTabContent.activeTab) {
    //         $('#0').parent().click();
    //     }
    //     else if (state.status === "3" && state.editorTabContent.activeTab) {
    //         $('#1').parent().click();
    //     }
    //     else if (state.status === "4" && state.editorTabContent.activeTab) {
    //         $('#2').parent().click();
    //     }
    //     else if (state.status === "6" && state.editorTabContent.activeTab) {
    //         $('#3').parent().click();
    //     }
    //     else if (state.status === "5" && state.editorTabContent.activeTab) {
    //         $('#4').parent().click();
    //     }
    // })
    if (sessionStorage.getItem("isActiveTabEditor") === "2") {
      $("#0").parent().click();
    } else if (sessionStorage.getItem("isActiveTabEditor") === "3") {
      $("#1").parent().click();
    } else if (sessionStorage.getItem("isActiveTabEditor") === "4") {
      $("#2").parent().click();
    } else if (sessionStorage.getItem("isActiveTabEditor") === "6") {
      $("#3").parent().click();
    } else if (sessionStorage.getItem("isActiveTabEditor") === "5") {
      $("#4").parent().click();
    }
    sessionStorage.setItem("isEditorRole", "0");
  };

  focusTab = () => {
    $(".react-tabs__tab--selected").focus();
    $(".react-tabs__tab").on("keydown", function (e) {
      if (e.keyCode === 38) {
        return false;
      } else if (e.keyCode === 40) {
        return false;
      }
    });
  };

  render() {
    if (sessionStorage.getItem("firstTabEditor") === "0") {
      this.focusOnRefresh();
    }

    return (
      <div>
        <KeyboardEventHandler
          handleKeys={["shift+t"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.focusTab()}
        />
        <div className="start complete">
          <div className="heading-bottom-line ml-3 pt-4">
            <h1 className="txt-violet fw-normal fs18">Daily QC Worklist</h1>
          </div>
          <div className="programs pb-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 pl-0 pr-0">
                  <Tabs>
                    <TabList>
                      {this.state.editortabs.map((tab, i) => (
                        <Tab key={i}>
                          <div id={i}>{tab.tabName}</div>
                        </Tab>
                      ))}
                    </TabList>
                    {this.state.editortabs.map((tab, i) => (
                      <TabPanel key={i}>
                        <EditorMainDropdown
                          data={tab}
                          applyFilter={this.applyFilterHandler}
                          applyReset={this.applyResetHandler}
                          airingreset={this.airingDataReset}
                        />
                      </TabPanel>
                    ))}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  applyFilterHandler = (data) => {
    data.action = "EditorApplyFilter";
    SharedService.setFilterData(data);
  };

  applyResetHandler = (data) => {
    data.action = "EditorApplyReset";
    SharedService.setFilterData(data);
  };

  airingDataReset = (data) => {
    data.action = "EditorAiringDateReset";
    SharedService.setFilterData(data);
  };
}
export default EditorDailyQcWorklist;
