const extend = require("object-assign");

const baseConfig = {
  apiUrl: window.location.protocol + "//" + window.location.hostname, //http://192.168.1.30/
  qcApi: "/qc/api/",
  cmsImageLink:
    "http://cms.whatsonindia.com/DasUploader/MainPages/Program.aspx?ProgramId=",
  cmsLoginPage:
    "http://cms.whatsonindia.com/DasUploader/MainPages/DasLogin.aspx",
};

const devConfig = {
  apiUrl: "http://localhost",
  qcApi: "/qc/api/",
  cmsImageLink:
    "http://cms.whatsonindia.com/DasUploader/MainPages/Program.aspx?ProgramId=",
  cmsLoginPage:
    "http://cms.whatsonindia.com/DasUploader/MainPages/DasLogin.aspx",
};

const config = extend({}, baseConfig);

if (process.env.NODE_ENV === "development") {
  // console.log(process.env)
  module.exports = extend({}, config, devConfig);
} else {
  module.exports = config;
}
