import React from "react";
import { Route, Redirect } from "react-router-dom";
import { userService } from "../services/userService";

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isAuthenticated: false,
      roleId: 0,
    };
  }

  componentWillMount() {
    const url = this.props.computedMatch.path.split("/");
    let valroleid = 0;
    if (url[1].toString().toLowerCase() === "allocator") valroleid = 1;
    else if (url[1].toString().toLowerCase() === "qcuser") valroleid = 2;
    else if (url[1].toString().toLowerCase() === "editor") valroleid = 3;
    else valroleid = 0;

    let tokenid = "";
    if (this.props.computedMatch.params.encryptedtoken !== undefined) {
      userService.logout();
      tokenid = this.props.computedMatch.params.encryptedtoken;
      localStorage.setItem("CmsToken", tokenid);
    } else {
      tokenid = localStorage.getItem("CmsToken");
    }

    if (
      tokenid !== null &&
      tokenid !== "" &&
      (valroleid === 0 ||
        valroleid === parseInt(localStorage.getItem("roleID")))
    ) {
      userService.login(tokenid).subscribe(
        (res) => {
          this.setState({
            isAuthenticated: true,
            loading: false,
            roleId: res.roleId,
          });
        },
        (err) => {
          console.log(err, " Error in login");
        }
      );
    } else {
      this.setState({ Authenticated: false, loading: false });
    }

    if (
      this.props.computedMatch.path === "/allocator/dailyqcworklist" &&
      (this.state.roleId === 2 || this.state.roleId === 3)
    ) {
      this.setState({ loading: false });
    }
  }

  render() {
    const { component: Component, layout: Layout, data, ...rest } = this.props;
    let pathname =
      this.state.roleId === 2
        ? "/qcuser/dailyqcworklist"
        : this.state.roleId === 3
        ? "/editor/dailyqcworklist"
        : this.state.roleId === 1
        ? "/allocator/dailyqcworklist"
        : "/login";
    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("loginresp") !== null &&
          this.state.isAuthenticated &&
          this.props.computedMatch.params.encryptedtoken !== undefined ? (
            <Redirect to={pathname}></Redirect>
          ) : localStorage.getItem("loginresp") !== null &&
            this.state.isAuthenticated ? (
            <Layout component={Component} {...this.props} />
          ) : this.state.loading ? (
            <div id="loader-wrapper">
              <div id="loader"></div>
            </div>
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
}

export default PrivateRoute;
