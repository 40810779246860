import React, { Component } from "react";
import Picky from "react-picky";
import axios from "axios";
import "react-dates/initialize";
import "react-picky/dist/picky.css";
import config from "../../containers/config";
import ToggleImage from "../../assets/images/img-pro-img.jpg";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import AllocationDropdownPopup from "../Allocator/AllocationDropdownPopup";
import SaveQCDate from "./SaveQCDate";
import Alert from "react-s-alert";
import Modal from "react-modal";

let $ = require("jquery");

const modalstyle2 = {
  content: {
    width: "400px",
    height: "150px",
  },
};

class WorkAssignment extends Component {
  constructor(props) {
    super();
    this.state = {
      propertyslider: true,
      listLoc: [],
      arrayValLoc: [],
      qcUsersList: [],
      allocatedEditorsList: [],
      carousalContainer: [],
      editorList: [],
      showModalDisableIcon: false,
      resetFlag: false,
      Type: "",
      showError: false,
      loader: true,
    };
  }

  componentDidMount() {
    this.getLocation();
    let loc = [];
    this.populateEditor(loc)
      .then(() => {
        this.bindEditorsFunction();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  bindEditorsFunction() {
    this.getAllocations()
      .then(() => {
        this.populateQcUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getLocation = () => {
    axios
      .get(`${config.apiUrl + config.qcApi}Common/GetCountryLocation`)
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.objRespMessage
        ) {
          this.setState({ listLoc: response.data.objRespMessage });
        } else {
          this.setState({ listLoc: [] });
        }
      });
  };

  populateEditor = (index) => {
    let promise = new Promise((resolve, reject) => {
      let locatioIds = index.map((loc) => {
        return loc.id;
      });

      axios
        .get(
          `${
            config.apiUrl + config.qcApi
          }Common/GetEditorByLocation/ ${localStorage.getItem(
            "userRoleID"
          )} ?strLocation= ${String(locatioIds)} &isAllocator=1`
        )
        .then((response) => {
          if (
            response.status === 200 &&
            response.data &&
            response.data.objRespMessage
          ) {
            let a = response.data.objRespMessage;

            let editorList = a.map((editor, i) => {
              return (
                <li
                  key={editor.intEditorId}
                  onDragStart={(e) =>
                    this.onDragStart(e, editor, "fromDefault", null)
                  }
                  draggable
                  className="p-1 pl-3 pr-3 mb-2 all-editor"
                  style={{ marginRight: "7px" }}
                >
                  {editor.strEditorName}
                </li>
              );
            });
            this.setState({ editorList: editorList });
          } else if (response.status === 204) {
            this.setState({ editorList: [] });
          }
          resolve();
        })
        .catch((error) => {
          if (!error.response) {
            error = "Not able to communicate with the backend service !";
            console.log(error);
            this.setState({ showError: true });
          }
          reject(error);
        });
    });
    return promise;
  };

  getAllocations = () => {
    let promise = new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl + config.qcApi}Common/getallocations`)
        .then((response) => {
          if (
            response.status === 200 &&
            response.data &&
            response.data.objRespMessage
          ) {
            this.setState({
              allocatedEditorsList: response.data.objRespMessage,
            });
          } else {
            this.setState({ allocatedEditorsList: [] });
          }
          resolve();
        });
    });
    return promise;
  };

  populateQcUsers = () => {
    axios
      .get(`${config.apiUrl + config.qcApi}Common/GetQCUsers`)
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.objRespMessage
        ) {
          let qcUserList = [];
          let qcObject = {};
          let qcData = {};
          response.data.objRespMessage.forEach((qcUser) => {
            qcData[qcUser.intQCUserRoleId] = 0;
          });
          response.data.objRespMessage.forEach((qcUser) => {
            let intCount = 0;
            qcObject = {};
            let permAllocatedList = [];
            let TempAllocatedList = [];
            let fromdate =
              qcUser.fromDate === null
                ? null
                : moment(qcUser.fromDate).format("DD/MM/YYYY");
            let todate =
              qcUser.toDate === null
                ? null
                : moment(qcUser.toDate).format("DD/MM/YYYY");
            this.state.allocatedEditorsList.forEach((editor) => {
              if (
                editor.lstAllocation.length === 1 &&
                qcUser.intQCUserRoleId ===
                  editor.lstAllocation[0].intQCUserRoleID
              ) {
                if (
                  moment().format("YYYY/MM/DD") >=
                    moment(
                      editor.lstAllocation[0].strFromDate,
                      "DD/MM/YYYY"
                    ).format("YYYY/MM/DD") &&
                  moment().format("YYYY/MM/DD") <=
                    moment(
                      editor.lstAllocation[0].strToDate,
                      "DD/MM/YYYY"
                    ).format("YYYY/MM/DD")
                ) {
                  intCount++;
                }
                permAllocatedList.push({
                  name: editor.strEditorName,
                  editorId: editor.intEditorID,
                  showModal: false,
                  //permDate: fromdate === null ? null : fromdate + '-' + todate,
                  permColor: qcUser.strQCColor,
                });
              } else {
                editor.lstAllocation.forEach((ed, i) => {
                  if (qcUser.intQCUserRoleId === ed.intQCUserRoleID) {
                    if (i === 1) {
                      if (
                        moment().format("YYYY/MM/DD") >=
                          moment(ed.strFromDate, "DD/MM/YYYY").format(
                            "YYYY/MM/DD"
                          ) &&
                        moment().format("YYYY/MM/DD") <=
                          moment(ed.strToDate, "DD/MM/YYYY").format(
                            "YYYY/MM/DD"
                          )
                      ) {
                        intCount++;
                      } else if (
                        moment().format("YYYY/MM/DD") <
                          moment(ed.strFromDate, "DD/MM/YYYY").format(
                            "YYYY/MM/DD"
                          ) ||
                        moment().format("YYYY/MM/DD") >
                          moment(ed.strToDate, "DD/MM/YYYY").format(
                            "YYYY/MM/DD"
                          )
                      ) {
                        if (
                          moment().format("YYYY/MM/DD") >=
                            moment(
                              editor.lstAllocation[0].strFromDate,
                              "DD/MM/YYYY"
                            ).format("YYYY/MM/DD") &&
                          moment().format("YYYY/MM/DD") <=
                            moment(
                              editor.lstAllocation[0].strToDate,
                              "DD/MM/YYYY"
                            ).format("YYYY/MM/DD")
                        ) {
                          qcData[editor.lstAllocation[0].intQCUserRoleID]++;
                        }
                      }
                      TempAllocatedList.push({
                        name: editor.strEditorName,
                        editorId: editor.intEditorID,
                        showModal: false,
                        tempDate: ed.strFromDate + " - " + ed.strToDate,
                        tempColor: ed.qcColor !== null ? ed.qcColor : "#b21dac",
                        //temppermDate : fromdate === null ? null : fromdate + '-' + todate
                      });
                    }
                  }
                });
              }
            });

            qcObject = {
              name: qcUser.strQCName,
              intCount: intCount,
              qcId: qcUser.intQCUserRoleId,
              date: fromdate === null ? null : fromdate + "-" + todate,
              color: qcUser.strQCColor,
              background: qcUser.strQCBackgroundColor,
              category: {
                permanentAllocation: permAllocatedList,
                temporaryAllocation: TempAllocatedList,
              },
              showQcCard: false,
            };
            qcUserList.push(qcObject);
          });
          let carousalContainer = [
            {
              carousalItem: [],
            },
          ];
          qcUserList.forEach((qcUser, index) => {
            qcUser.intCount += qcData[qcUser.qcId];
            qcUser.name = qcUser.name + ` (${qcUser.intCount})`;
            if (index % 4 === 0 && index > 0) {
              let object = {
                carousalItem: [qcUser],
              };
              carousalContainer.push(object);
            } else {
              carousalContainer[carousalContainer.length - 1][
                "carousalItem"
              ].push(qcUser);
            }
          });
          //console.log("carousalContainer", carousalContainer);
          // console.log("qcUserList", qcUserList);
          this.setState({
            qcUsersList: qcUserList,
            carousalContainer: carousalContainer,
            loader: false,
          });
        } else {
          this.setState({ qcUsersList: [] });
        }
      });
  };

  onAllocatorDropdown = (
    corouselIndex,
    index,
    categoryIndex,
    flag,
    type,
    event
  ) => {
    // switch the value of the showModal state
    this.setState({ loader: true });
    $(".drp-selected").text("");
    $("#saveQCDate").val("");

    let carousalContainer = [...this.state.carousalContainer];

    carousalContainer.forEach((corousel, corouselIteratorIndex) => {
      if (corouselIteratorIndex === corouselIndex) {
        corousel.carousalItem.forEach((item, iteratorIndex) => {
          item.showQcCard = false;
          if (iteratorIndex === index) {
            if (flag === "fromPerm") {
              item.category.permanentAllocation.forEach(
                (permUser, permIteratorIndex) => {
                  if (permIteratorIndex === categoryIndex) {
                    permUser.showModal = !permUser.showModal;
                  } else {
                    permUser.showModal = false;
                  }
                }
              );
              /*Logic to close all temporary child popups from permanant */
              item.category.temporaryAllocation.forEach((tempUser) => {
                tempUser.showModal = false;
              });
            } else if (flag === "fromTemp") {
              item.category.temporaryAllocation.forEach(
                (tempUser, tempIteratorIndex) => {
                  if (tempIteratorIndex === categoryIndex) {
                    tempUser.showModal = !tempUser.showModal;

                    if (!tempUser.showModal) {
                      // refresh state when user closes child popup from temporary allocation
                      this.componentDidMount();
                      this.setState({ showModalDisableIcon: false });
                    }
                  } else {
                    tempUser.showModal = false;
                  }
                }
              );
              /*Logic to close all permanant child popups from temporary */
              item.category.permanentAllocation.forEach((permUser) => {
                permUser.showModal = false;
              });
            }
          } else {
            item.category.permanentAllocation.forEach((permUser) => {
              permUser.showModal = false;
            });
            item.category.temporaryAllocation.forEach((tempUser) => {
              tempUser.showModal = false;
            });
          }
        });
      } else {
        corousel.carousalItem.forEach((item, iteratorIndex) => {
          item.showQcCard = false;
          item.category.permanentAllocation.forEach((permUser) => {
            permUser.showModal = false;
          });
        });
      }
    });
    this.setState({
      carousalContainer: carousalContainer,
      Type: type,
      loader: false,
    });
  };

  onQcClick(corouselIndex, index) {
    $(".drp-selected").text("");
    let carousalContainer = [...this.state.carousalContainer];
    /* Logic to false each and every qc tab popup except clicked one*/
    carousalContainer.forEach((carousel, c) => {
      if (corouselIndex !== c) {
        carousel.carousalItem.forEach((carouselItem) => {
          this.closeChildPopups(carouselItem);
        });
      } else {
        carousel.carousalItem.forEach((carouselItem, i) => {
          if (index !== i) {
            this.closeChildPopups(carouselItem);
          }
        });
      }
    });

    let showMOdalExistCounter = 0;
    const iterator = carousalContainer[corouselIndex].carousalItem[index]; // clicked qc tab

    /* Logic to check child popup showModal flag */
    iterator.category.permanentAllocation.forEach((permUser) => {
      if (permUser.showModal) {
        showMOdalExistCounter++;
      }
    });
    iterator.category.temporaryAllocation.forEach((tempUser) => {
      if (tempUser.showModal) {
        showMOdalExistCounter++;
      }
    });

    /* Logic to show clicked qc tab and closing child popups  */

    if (showMOdalExistCounter > 0) {
      iterator.showQcCard = true;
      iterator.category.permanentAllocation.forEach((permUser) => {
        permUser.showModal = false;
      });
      iterator.category.temporaryAllocation.forEach((tempUser) => {
        tempUser.showModal = false;
      });
    } else {
      /* Logic to toggle QcCard popup of qc tab */
      iterator.showQcCard = !iterator.showQcCard;
    }
    this.setState({ carousalContainer: carousalContainer });
  }

  closeChildPopups = (carouselItem) => {
    carouselItem.showQcCard = false;

    carouselItem.category.permanentAllocation.forEach((permUser) => {
      permUser.showModal = false;
    });
    carouselItem.category.temporaryAllocation.forEach((tempUser) => {
      tempUser.showModal = false;
    });
  };

  onDragStart = (ev, editorData, flag, index) => {
    if (flag === "fromDefault") {
      ev.dataTransfer.setData("editorId", editorData.intEditorId);
      ev.dataTransfer.setData("editorName", editorData.strEditorName);
      ev.dataTransfer.setData("editorFlag", flag);
    } else {
      ev.dataTransfer.setData("editorId", editorData.editorId);
      ev.dataTransfer.setData("editorName", editorData.name);
      ev.dataTransfer.setData(
        "editorColor",
        flag === "fromPerm"
          ? editorData.permColor
          : flag === "fromTemp"
          ? editorData.tempColor
          : null
      );
    }
    ev.dataTransfer.setData("editorIndex", index);
    //ev.dataTransfer.setData("editorDate", editorData.permDate);

    //
    let carousalContainer = [...this.state.carousalContainer];
    carousalContainer.forEach((corousel, corouselIteratorIndex) => {
      corousel.carousalItem.forEach((item, iteratorIndex) => {
        item.showQcCard = false;
      });
    });
    this.setState({ carousalContainer: carousalContainer });

    // logic to chech if any show modal property of child temporary popup is open or not.
    if (this.commonShowModalCheck() > 0) {
      this.alertErrorMessage("Please complete the current action.");
      ev.preventDefault();
    }
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDrop = (e, corouselIndex, qcData, index, category) => {
    //debugger;
    // console.log(e.dataTransfer.getData("editorDate"))
    let editorId = e.dataTransfer.getData("editorId");
    let editorName = e.dataTransfer.getData("editorName");
    let editorFlag = e.dataTransfer.getData("editorFlag");
    let editorIndex = +e.dataTransfer.getData("editorIndex");
    // console.log("editorId", editorId);
    // console.log("editorName", editorName);
    // console.log("editorFlag", editorFlag);
    // console.log("category", category);
    // console.log("corouselIndex", corouselIndex);
    // console.log("DragIndex", editorIndex);
    // console.log("DropIndex", index);
    // console.log("editorData Color", e.dataTransfer.getData("editorColor"));

    this.setState({ loader: true });
    let editorList = [...this.state.editorList];
    if (
      editorFlag === "fromDefault" &&
      category !== "T" &&
      qcData &&
      qcData.date &&
      editorName !== qcData.name
    ) {
      editorList = editorList.filter((editor) => {
        return Number(editor.key) !== +editorId;
      });
    }
    let carousalContainer = [...this.state.carousalContainer];

    if (editorIndex !== index) {
      if (
        category === "P" &&
        qcData &&
        qcData.date &&
        editorName !== qcData.name &&
        editorId !== ""
      ) {
        //Date Logic
        let date = qcData.date;
        var dateobj = this.setDateFormat(date);
        let from = dateobj.fromdate;
        let to = dateobj.todate;
        axios
          .post(`${config.apiUrl + config.qcApi}action/Allocate`, {
            intAllocatorID: localStorage.getItem("userRoleID"),
            intEditorID: editorId,
            intQcUserRoleID: qcData.qcId,
            intAllocationTypeID: 1,
            dteFrom: from,
            dteTo: to,
            tmpmode: 1,
          })
          .then((response) => {
            if (
              response.status === 200 &&
              response.data &&
              response.data.intStatusCode === 200
            ) {
              this.alertMessage(response.data.objRespMessage);
              this.commonRemoveCard(
                carousalContainer,
                qcData,
                editorName,
                category,
                editorId
              );
              carousalContainer[corouselIndex].carousalItem[
                index
              ].category.permanentAllocation.push({
                name: editorName,
                editorId: +editorId,
                permColor: qcData.color,
              });
              //this.componentDidMount();
              this.bindEditorsFunction();

              this.setState({
                carousalContainer: carousalContainer,
                editorList: editorList,
                loader: false,
              });
            } else if (
              response.status === 200 &&
              response.data.intStatusCode === 409 &&
              response.data
            ) {
              this.setState({
                loader: false,
              });
              this.alertErrorMessage(response.data.objRespMessage);
            } else {
              this.setState({
                loader: false,
              });
              this.alertErrorMessage("No Action");
            }
          });
      } else if (
        category === "T" &&
        editorFlag !== "fromDefault" &&
        editorName !== qcData.name
      ) {
        this.commonRemoveCard(
          carousalContainer,
          qcData,
          editorName,
          category,
          editorId
        );
        carousalContainer[corouselIndex].carousalItem[
          index
        ].category.temporaryAllocation.push({
          name: editorName,
          editorId: +editorId,
          tempColor: e.dataTransfer.getData("editorColor"),
          //temppermDate: e.dataTransfer.getData("editorDate")
        });

        const nextIndex =
          carousalContainer[corouselIndex].carousalItem[index].category
            .temporaryAllocation.length - 1;
        this.onAllocatorDropdown(
          corouselIndex,
          index,
          nextIndex,
          "fromTemp",
          "Drag"
        );

        // New Code
        const lstIndex = this.state.allocatedEditorsList.findIndex(
          (allocatedEd) => {
            return allocatedEd.intEditorID === +editorId;
          }
        );

        const updatedAllocatedEditorsList = [
          ...this.state.allocatedEditorsList,
        ];
        const updatedLstAllocation = updatedAllocatedEditorsList[
          lstIndex
        ].lstAllocation.filter((lst, i) => {
          return i === 0;
        });
        updatedAllocatedEditorsList[lstIndex].lstAllocation =
          updatedLstAllocation;

        this.setState({
          carousalContainer: carousalContainer,
          editorList: editorList,
          allocatedEditorsList: updatedAllocatedEditorsList,
          loader: false,
        });
      } else if (category === "default") {
        axios
          .post(`${config.apiUrl + config.qcApi}action/Allocate`, {
            intAllocatorID: localStorage.getItem("userRoleID"),
            intEditorID: editorId,
            intQcUserRoleID: -1,
            intAllocationTypeID: 0,
            dteFrom: "2019-08-25",
            dteTo: "2029-10-30",
            tmpmode: 2,
          })
          .then((response) => {
            if (
              response.status === 200 &&
              response.data.intStatusCode === 200
            ) {
              this.bindEditorsFunction();
              this.commonRemoveCard(
                carousalContainer,
                qcData,
                editorName,
                category,
                editorId
              );
              const editor = {
                intEditorId: +editorId,
                strEditorName: editorName,
              };
              editorList.unshift(
                <li
                  key={+editorId}
                  onDragStart={(e) =>
                    this.onDragStart(e, editor, "fromDefault", null)
                  }
                  draggable
                  className="p-1 pl-3 pr-3 mb-2 all-editor"
                  style={{ marginRight: "7px" }}
                >
                  {editorName}
                </li>
              );
              this.alertMessage(
                response.data.objRespMessage === null
                  ? "No Action"
                  : response.data.objRespMessage
              );
              this.setState({
                carousalContainer: carousalContainer,
                editorList: editorList,
                loader: false,
              });
            } else if (
              response.status === 200 &&
              response.data.intStatusCode === 409
            ) {
              this.setState({ loader: false });
              this.alertErrorMessage(
                response.data.objRespMessage === null
                  ? "No Action"
                  : response.data.objRespMessage
              );
            } else {
              this.setState({ loader: false });
              this.alertErrorMessage("No Action");
            }
          });
      } else {
        if (category === "T" && editorFlag === "fromDefault") {
          this.alertErrorMessage(
            "Please complete permanent allocation before temporary allocation."
          );
        } else if (qcData.date === null) {
          this.alertErrorMessage("Please select the date of allocation");
        } else if (editorName === qcData.name) {
          this.alertErrorMessage(
            "QC and Editor cannot be the same for allocation."
          );
        }
        this.setState({ loader: false });
      }
    } else {
      //this.alertErrorMessage('Cannot assign editor to same qc user');
      this.alertErrorMessage(
        "Please select different QC User for the allocation"
      );
      this.setState({
        carousalContainer: carousalContainer,
        editorList: editorList,
        loader: false,
      });
    }

    // logic to chech if any show modal property of child temporary popup is open or not.
    if (this.commonShowModalCheck() > 0) {
      this.setState({ showModalDisableIcon: true });
    }
  };

  commonRemoveCard(carousalContainer, qcData, editorName, category, editorId) {
    // common splicing logic
    carousalContainer.forEach((corousel) => {
      corousel.carousalItem.forEach((qcUser) => {
        if (
          (qcData && qcData.date && editorName !== qcData.name) ||
          category === "default" ||
          category === "T"
        ) {
          qcUser.category.permanentAllocation =
            qcUser.category.permanentAllocation.filter((permAllocation) => {
              return permAllocation.editorId !== +editorId;
            });
        }
        if (
          (qcData && qcData.date && editorName !== qcData.name) ||
          category === "default" ||
          category === "T"
        ) {
          qcUser.category.temporaryAllocation =
            qcUser.category.temporaryAllocation.filter((tempAllocation) => {
              return tempAllocation.editorId !== +editorId;
            });
        }
      });
    });
  }

  commonShowModalCheck() {
    // logic to chech if any show modal property of child temporary popup is open or not.
    let carousalContainerTemp = [...this.state.carousalContainer];
    let showModalCounter = 0;
    carousalContainerTemp.forEach((corousel) => {
      corousel.carousalItem.forEach((item) => {
        item.category.temporaryAllocation.forEach((tempUser) => {
          if (tempUser.showModal) showModalCounter++;
        });
      });
    });
    return showModalCounter;
  }

  setDateFormat = (date) => {
    let dateobj = {};
    let splitDate = date.split("-");
    let splitDateFormat1 = splitDate[0].split("/");
    let splitDateFormat2 = splitDate[1].split("/");
    let tempdate1 = [
      splitDateFormat1[2].trim(),
      splitDateFormat1[1].trim(),
      splitDateFormat1[0].trim(),
    ].join("-");
    let tempdate2 = [
      splitDateFormat2[2].trim(),
      splitDateFormat2[1].trim(),
      splitDateFormat2[0].trim(),
    ].join("-");
    let tempdate3 = [tempdate1, tempdate2].join("-");
    dateobj = {
      tempdate3: tempdate3,
      fromdate: tempdate1,
      todate: tempdate2,
    };
    return dateobj;
  };

  resetClick = (e) => {
    this.setState({ resetFlag: e });
  };

  resetClickConfirm = () => {
    this.setState({ loader: true });
    axios
      .post(`${config.apiUrl + config.qcApi}action/Allocate`, {
        intAllocatorID: localStorage.getItem("userRoleID"),
        intEditorID: -1,
        intQcUserRoleID: -1,
        intAllocationTypeID: 0,
        dteFrom: "2019-08-25",
        dteTo: "2029-08-25",
        tmpmode: 1,
      })
      .then((response) => {
        if (response.status === 200 && response.data.intStatusCode === 200) {
          this.setState({
            resetFlag: false,
            loader: false,
            showModalDisableIcon: false,
          });
          this.componentDidMount();
        } else if (
          response.status === 200 &&
          response.data.intStatusCode === 409
        ) {
          this.setState({ loader: false, showModalDisableIcon: false });
          this.alertErrorMessage(response.data.objRespMessage);
        } else {
          this.setState({ loader: false, showModalDisableIcon: false });
          this.alertErrorMessage("No Action");
        }
      });
  };

  selectMultipleOption(value, name) {
    switch (value) {
      case "Editor Location":
        const locIndex = [];
        name.map((i) =>
          locIndex.push({
            id: i.id,
            name: i.name,
          })
        );
        this.populateEditor(locIndex);
        this.setState({ arrayValLoc: name, locIndex, arrayValEditor: [] });
        break;
      default:
        break;
    }
  }

  onSliderClick() {
    this.setState({ propertyslider: this.state.propertyslider ? false : true });
  }

  onKeyUp = () => {
    var value = $("#myInput").val().toLowerCase();
    $("#myUL li").filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };

  saveHandler = () => {
    this.componentDidMount();
    this.setState({ showModalDisableIcon: false, Type: "" });
  };

  cancelHandler = () => {
    this.componentDidMount();
  };

  alertMessage = (Message) => {
    Alert.success(
      '<div><i class="fa fa-check notification" aria-hidden="true"></i>' +
        Message +
        "</div>",
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 3000,
      }
    );
  };

  alertErrorMessage = (Message) => {
    Alert.error(
      '<div><i class="fa fa-times notification" aria-hidden="true"></i>' +
        Message +
        "</div>",
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 3000,
      }
    );
  };

  render() {
    let listLoc = [];
    this.state.listLoc.map((location, index) =>
      listLoc.push({
        id: location.intOfficeLocID,
        name: location.strOfficeLocation,
      })
    );

    let prpsliderclass = "";
    if (this.state.propertyslider) {
      prpsliderclass = "div1 img-pro-arrow-bg panel-slider float-left";
    } else {
      prpsliderclass = "div1 img-pro-arrow-bg panel-slider float-left active";
    }

    return (
      <div className="complete">
        {this.state.loader ? (
          <div id="loader-wrapper">
            <div id="loader"></div>
          </div>
        ) : null}

        <div className="heading-bottom-line ml-3 pt-4">
          <h1 className="txt-violet fw-normal fs18">Editor Allocation</h1>
          <span className="allocator-reset">
            <button
              type="button"
              className="btn btn-reset"
              onClick={() => this.resetClick(true)}
            >
              <i className="fa fa-undo" aria-hidden="true"></i>Reset
            </button>
          </span>
        </div>

        <div
          className="programs"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div
                  className={prpsliderclass}
                  onDragOver={(e) => this.onDragOver(e)}
                  onDrop={(e) => this.onDrop(e, null, null, null, "default")}
                >
                  <div className="bg-grey mr-2 x" style={{ height: "100%" }}>
                    <div className="form-group dropdown-select mr-2 ml-2 mt-0 mb-0 location-dropdown">
                      <Picky
                        value={this.state.arrayValLoc}
                        options={listLoc}
                        onChange={this.selectMultipleOption.bind(
                          this,
                          "Editor Location"
                        )}
                        valueKey="id"
                        labelKey="name"
                        multiple={true}
                        includeFilter={true}
                        dropdownHeight={150}
                        open={false}
                        placeholder="Editor Location"
                        className={""}
                      />
                    </div>
                    <div className="form-group m-2 x">
                      <input
                        id="myInput"
                        className="form-control search-input search-block"
                        type="text"
                        placeholder="Search Editor"
                        onKeyUp={this.onKeyUp.bind(this)}
                      />
                      <span className="search-icon">
                        <i
                          className="fa fa-search txt-grey"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    <div
                      className="name-lst m-2 x"
                      style={{ overflowY: "auto" }}
                    >
                      <ul className="connected pb-2 ui-sortable" id="myUL">
                        {this.state.editorList}
                      </ul>
                    </div>
                    <div
                      className="collapse-arrow"
                      id="sidebarCollapse"
                      onClick={this.onSliderClick.bind(this)}
                      style={{ right: "0px" }}
                    >
                      <img
                        src={ToggleImage}
                        className="cursor-pointer"
                        width="8"
                        height="40"
                        alt="toggle-icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="container-panel">
                  <div className="ml-3">
                    <div className="row border">
                      <div className="col-md-12 allocator-header txt-orange text-center pt-2 pb-2">
                        Permanent Allocation
                      </div>
                      <div className="col-md-12 allocator-header border txt-orange text-center pt-2 pb-2 temp-label">
                        Temporary Allocation
                      </div>
                      <div
                        id="recipeCarousel"
                        className="carousel slide w-100 perm-panel"
                        data-interval="false"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner w-100" role="listbox">
                          {this.state.carousalContainer.map(
                            (carousel, corouselIndex) => {
                              let carouselclass = "carousel-item";
                              if (corouselIndex === 0) {
                                carouselclass = "carousel-item active";
                              }
                              return (
                                <div className={carouselclass}>
                                  <div className="row no-gutters per">
                                    {carousel.carousalItem.map(
                                      (qcUser, index) => {
                                        return (
                                          <div
                                            className="col-md-3 float-left"
                                            onDragOver={(e) =>
                                              this.onDragOver(e)
                                            }
                                            onDrop={(e) =>
                                              this.onDrop(
                                                e,
                                                corouselIndex,
                                                qcUser,
                                                index,
                                                "P"
                                              )
                                            }
                                          >
                                            <div
                                              style={{
                                                background: qcUser.background,
                                                color: qcUser.color,
                                              }}
                                              onClick={
                                                !this.state.showModalDisableIcon
                                                  ? this.onQcClick.bind(
                                                      this,
                                                      corouselIndex,
                                                      index
                                                    )
                                                  : null
                                              }
                                              className="user-name p-3 text-center qc-user"
                                            >
                                              <h5 className="fs12 fw-bold mb-0">
                                                {qcUser.name}
                                              </h5>
                                            </div>
                                            <div
                                              className={
                                                qcUser.showQcCard
                                                  ? "assign-date slidingDi"
                                                  : "assign-date slidingDiv d-none"
                                              }
                                            >
                                              {qcUser.showQcCard ? (
                                                <SaveQCDate
                                                  parentInfo={qcUser}
                                                  cancelQcDate={() =>
                                                    this.onQcClick(
                                                      corouselIndex,
                                                      index
                                                    )
                                                  }
                                                  saveClick={this.saveHandler.bind(
                                                    this
                                                  )}
                                                />
                                              ) : null}
                                            </div>
                                            <div className="name-list border p-3 btnone blnone">
                                              <ul className="connected pt-2 pb-2">
                                                {qcUser.category.permanentAllocation.map(
                                                  (permList, permIndex) => {
                                                    return (
                                                      <li
                                                        key={permList.editorId}
                                                        onDragStart={(e) =>
                                                          this.onDragStart(
                                                            e,
                                                            permList,
                                                            "fromPerm",
                                                            index
                                                          )
                                                        }
                                                        draggable
                                                        style={{
                                                          color: qcUser.color,
                                                          border: `1px solid ${qcUser.color}`,
                                                        }}
                                                        className="p-1 pl-3 pr-3 mb-2 position-relative per-allocated"
                                                      >
                                                        <i
                                                          className="fa fa-plus add-icon user-name per-popup"
                                                          onClick={
                                                            !this.state
                                                              .showModalDisableIcon
                                                              ? this.onAllocatorDropdown.bind(
                                                                  this,
                                                                  corouselIndex,
                                                                  index,
                                                                  permIndex,
                                                                  "fromPerm",
                                                                  "Click"
                                                                )
                                                              : null
                                                          }
                                                          aria-hidden="true"
                                                        ></i>
                                                        <p className="fw-bold mb-1 ">
                                                          {permList.name}
                                                        </p>
                                                        <p className="mb-0">
                                                          {qcUser.date}
                                                        </p>
                                                        {permList.showModal ? (
                                                          <AllocationDropdownPopup
                                                            loading={
                                                              this.state.loader
                                                            }
                                                            userInfo={permList}
                                                            parentInfo={qcUser}
                                                            categoryType="P"
                                                            flag={
                                                              this.state.Type
                                                            }
                                                            saveClick={this.saveHandler.bind(
                                                              this
                                                            )}
                                                            childCancelClick={(
                                                              e
                                                            ) =>
                                                              this.onAllocatorDropdown(
                                                                corouselIndex,
                                                                index,
                                                                permIndex,
                                                                "fromPerm",
                                                                "Close"
                                                              )
                                                            }
                                                            qcUsersList={
                                                              this.state
                                                                .qcUsersList
                                                            }
                                                          />
                                                        ) : null}
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  <div className="row no-gutters temp">
                                    {carousel.carousalItem.map(
                                      (qcUser, index) => {
                                        return (
                                          <div
                                            className="col-md-3 float-left temp-div"
                                            onDragOver={(e) =>
                                              this.onDragOver(e)
                                            }
                                            onDrop={(e) =>
                                              this.onDrop(
                                                e,
                                                corouselIndex,
                                                qcUser,
                                                index,
                                                "T"
                                              )
                                            }
                                          >
                                            <div className="name-list border p-3 btnone blnone bbnone">
                                              <ul className="connected pt-2 pb-2">
                                                {qcUser.category.temporaryAllocation.map(
                                                  (tempList, tempIndex) => {
                                                    let a;
                                                    let formattedQCUser = [];

                                                    this.state.allocatedEditorsList.forEach(
                                                      (ed) => {
                                                        if (
                                                          ed.intEditorID ===
                                                          tempList.editorId
                                                        ) {
                                                          formattedQCUser =
                                                            ed.lstAllocation.filter(
                                                              (a, i) => {
                                                                return i > 0;
                                                              }
                                                            );

                                                          a =
                                                            formattedQCUser.map(
                                                              (item) => (
                                                                <div className="circle-pnl mb-3 mt-2 ">
                                                                  <ul>
                                                                    <li
                                                                      className="dark-yellow mr-2"
                                                                      style={{
                                                                        background:
                                                                          item.tmpQCColor,
                                                                      }}
                                                                    ></li>
                                                                  </ul>
                                                                </div>
                                                              )
                                                            );
                                                        }
                                                      }
                                                    );

                                                    return (
                                                      <li
                                                        key={tempList.editorId}
                                                        onDragStart={(e) =>
                                                          this.onDragStart(
                                                            e,
                                                            tempList,
                                                            "fromTemp",
                                                            index
                                                          )
                                                        }
                                                        draggable
                                                        style={{
                                                          color:
                                                            tempList.tempColor,
                                                          border: `1px solid${tempList.tempColor}`,
                                                        }}
                                                        className="p-1 pl-3 pr-3 mb-2 position-relative temp-allocated"
                                                      >
                                                        <i
                                                          className="fa fa-plus add-icon user-name temp-popup"
                                                          onClick={
                                                            !this.state
                                                              .showModalDisableIcon
                                                              ? this.onAllocatorDropdown.bind(
                                                                  this,
                                                                  corouselIndex,
                                                                  index,
                                                                  tempIndex,
                                                                  "fromTemp",
                                                                  "Click"
                                                                )
                                                              : null
                                                          }
                                                          aria-hidden="true"
                                                        ></i>
                                                        <p
                                                          style={{
                                                            display:
                                                              "inline-flex",
                                                            marginBottom: 0,
                                                          }}
                                                        >
                                                          {" "}
                                                          {a}
                                                        </p>

                                                        <p className="fw-bold mb-1 ">
                                                          {tempList.name}
                                                        </p>
                                                        <p className="mb-0">
                                                          {tempList.tempDate}
                                                        </p>
                                                        {tempList.showModal ? (
                                                          <AllocationDropdownPopup
                                                            loading={
                                                              this.state.loader
                                                            }
                                                            userInfo={tempList}
                                                            parentInfo={qcUser}
                                                            categoryType="T"
                                                            flag={
                                                              this.state.Type
                                                            }
                                                            saveClick={this.saveHandler.bind(
                                                              this
                                                            )}
                                                            childCancelClick={(
                                                              e
                                                            ) =>
                                                              this.onAllocatorDropdown(
                                                                corouselIndex,
                                                                index,
                                                                tempIndex,
                                                                "fromTemp",
                                                                "Close"
                                                              )
                                                            }
                                                            qcUsersList={
                                                              this.state
                                                                .qcUsersList
                                                            }
                                                          />
                                                        ) : null}
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                        <a
                          className="carousel-control-prev carousel-control"
                          href="#recipeCarousel"
                          role="button"
                          data-slide="prev"
                        >
                          {" "}
                          <i
                            className="fa fa-angle-left fs18"
                            aria-hidden="true"
                          ></i>{" "}
                          <span className="sr-only">Previous</span>{" "}
                        </a>
                        <a
                          className="carousel-control-next carousel-control"
                          href="#recipeCarousel"
                          role="button"
                          data-slide="next"
                        >
                          {" "}
                          <i
                            className="fa fa-angle-right fs18"
                            aria-hidden="true"
                          ></i>{" "}
                          <span className="sr-only">Next</span>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-12 pt-4 mt-1 text-right"> */}
            {/* <button type="button" className="btn fw-normal btn-white txt-blue pl-3 pr-3">Save</button> */}
            {/* <button type="button" className="btn btn-reset" onClick={() => this.resetClick(true)}><i className="fa fa-undo" aria-hidden="true"></i>Reset</button> */}
            {/* </div> */}
          </div>
        </div>
        {this.state.resetFlag ? (
          <Modal
            isOpen={true}
            contentLabel="Message Display"
            style={modalstyle2}
          >
            <div>
              <div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => this.resetClick(false)}
                >
                  &times;
                </button>
              </div>
              <div className="modal-content brnone bbnone blnone ">
                <div className="modal-body pt-3 text-center">
                  Do you want to proceed ?
                </div>
                <div className="text-center mt-20">
                  <button
                    type="button"
                    className="btn fw-normal btn-blue txt-white pl-3 pr-3 pop-btn2 mr-20"
                    onClick={() => this.resetClickConfirm()}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn fw-normal btn-blue txt-white pl-3 pr-3 pop-btn2"
                    onClick={() => this.resetClick(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default WorkAssignment;
