import React from "react";
import { Redirect } from "react-router-dom";
const withErrorHandlingLogin =
  (WrappedComponent) =>
  ({ showError, children }) => {
    return (
      <WrappedComponent>
        {showError ? <Redirect to="/login"></Redirect> : null}
        {children}
      </WrappedComponent>
    );
  };

export const RedirectedIfError = withErrorHandlingLogin(({ children }) => (
  <div>{children}</div>
));
