import { Subject } from "rxjs";
const filterSubject = new Subject();
const DailyQCSubject = new Subject();
const UserDetails = new Subject();
const filterTabularSubject = new Subject();
const EditorDailyQCSubject = new Subject();
const editorfilterTabularSubject = new Subject();
const IsSingleImageView = new Subject();
const isHelpScreenActive = new Subject();
const isEditorActive = new Subject();
const ScheduleQCSubject = new Subject();
const filterScheduleTabularSubject = new Subject();
const ImageIndex=new Subject();

export const SharedService = {
  setUserDetails: (data) => {
    UserDetails.next(data);
  },
  getUserDetails: () => UserDetails.asObservable(),

  // QC User
  setFilterData: (data) => {
    filterSubject.next(data);
  },
  getFilterData: () => filterSubject.asObservable(),

  setDataForDQ: (data) => {
    DailyQCSubject.next(data);
  },
  getDataForDQ: () => DailyQCSubject.asObservable(),

  setFilterDataForTabularView: (data) => {
    filterTabularSubject.next(data);
  },
  getFilterDataForTabularView: () => filterTabularSubject.asObservable(),

  //Editor
  setEditorDataForDQ: (data) => {
    EditorDailyQCSubject.next(data);
  },
  getEditorDataForDQ: () => EditorDailyQCSubject.asObservable(),

  setEditorFilterDataForTabularView: (data) => {
    editorfilterTabularSubject.next(data);
  },
  getEditorFilterDataForTabularView: () =>
    editorfilterTabularSubject.asObservable(),

  //Schedule QC

  setDataForScheduleQC: (data) => {
    ScheduleQCSubject.next(data);
  },
  getDataForScheduleQC: () => ScheduleQCSubject.asObservable(),

  setFilterDataForScheduleTabularView: (data) => {
    filterScheduleTabularSubject.next(data);
  },
  getFilterDataForScheduleTabularView: () =>
    filterScheduleTabularSubject.asObservable(),

  //Help screen
  setSingleImageView: (data) => {
    IsSingleImageView.next(data);
  },
  getSingleImageView: () => IsSingleImageView.asObservable(),

  setHelpScreenActive: (data) => {
    isHelpScreenActive.next(data);
  },
  getIsHelpScreenActive: () => isHelpScreenActive.asObservable(),

  setIsEditorActive: (data) => {
    isEditorActive.next(data);
  },
  getIsEditorActive: () => isEditorActive.asObservable(),

  setImageIndex:(data)=>
  {
    ImageIndex.next(data);
  },
  getImageIndex:()=>ImageIndex.asObservable(),
};
