import React from "react";

class ConfirmApprovePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: this.props.pagesize,
      minIndex: this.props.minIndex,
    };
  }
  approveImgs(minIndex) {
    this.props.confirmBoxApprove();
  }

  cancelImgApprove() {
    this.props.closeModel();
    this.props.confirmBoxCancel();
  }

  render() {
    return (
      <div>
        <div>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={this.props.closeModel}
          >
            &times;
          </button>
        </div>
        <div className="modal-content brnone bbnone blnone">
          <div className="modal-body pt-3 text-center">
            QCed Image Count = {this.state.number}
          </div>
          <div className="modal-footer approve-footer text-center">
            <button
              type="button"
              className="btn fw-normal btn-white txt-blue pl-3 pr-3 mr-2 pop-btn2"
              onClick={() => this.cancelImgApprove()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn fw-normal btn-blue txt-white pl-3 pr-3 pop-btn2"
              onClick={() => this.approveImgs(this.state.minIndex)}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmApprovePopup;
