import React, { Component } from "react";
import SideNavigationBar from "./SideNavigationBar";
import { Link } from "react-router-dom";
let $ = require("jquery");
class Navigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapMenu: [],
      setParentMenuIndex: 0,
    };
  }
  handleClick(index, e) {
    this.setState({
      setParentMenuIndex: index,
    });
  }

  tabswitch = (tabname) => {
    $(".multi-level  li span").removeClass("active-submenu");
    if (tabname === "Approved Images") {
      $(".multi-level li#nav0 span").addClass("active-submenu");
      $("#0").parent().click();
    } else if (tabname === "Approved With Edits Images") {
      $(".multi-level li#nav1 span").addClass("active-submenu");
      $("#1").parent().click();
    } else if (tabname === "Rejected Images") {
      $("#2").parent().click();
      $(".multi-level li#nav2 span").addClass("active-submenu");
    } else if (tabname === "QC Reverted Images") {
      $("#3").parent().click();
      $(".multi-level li#nav3 span").addClass("active-submenu");
    } else if (tabname === "Improvisation Worklist") {
      $("#4").parent().click();
      $(".multi-level li#nav4 span").addClass("active-submenu");
    }
  };

  render() {
    const mapMenu = this.props.mapmenu;

    let classChange = "";
    let icon = "";

    const navPathHtml = mapMenu.map(
      (ParentMenu, parentindex) => (
        parentindex === this.state.setParentMenuIndex
          ? (classChange = "treeview is-expanded")
          : (classChange = "treeview"),
        ParentMenu.menuName === "QC Dashboards"
          ? (icon = "app-menu__icon fa fs16 fa-pie-chart")
          : "",
        ParentMenu.menuName === "QC Inbox"
          ? (icon = "app-menu__icon fa fs16 fa-inbox")
          : "",
        ParentMenu.menuName === "Client Reported Issues Worklist"
          ? (icon = "app-menu__icon fa fs16 fa-user")
          : "",
        ParentMenu.menuName === "RM Editor Worklist"
          ? (icon = "app-menu__icon fa fs16 fa-user")
          : "",
        ParentMenu.menuName === "Dashboard"
          ? (icon = "app-menu__icon fa fs16 fa-pie-chart")
          : "",
        ParentMenu.menuName === "Create Worklist"
          ? (icon = "app-menu__icon fa fs16 fa-list-alt")
          : "",
        (
          <li
            key={parentindex}
            className={classChange}
            onClick={this.handleClick.bind(this, parentindex)}
          >
            {ParentMenu.menuName.includes("Grafana") ? (
              <Link
                className="app-menu__item"
                to="#"
                data-toggle="treeview"
                onClick={() => window.open(ParentMenu.link, "_blank")}
              >
                <i className={icon}></i>
                <span className="app-menu__label pt-min">
                  {ParentMenu.menuName}
                </span>
                <i className="fa fa-angle-left pull-right"></i>
              </Link>
            ) : (
              <Link className="app-menu__item" to="#" data-toggle="treeview">
                <i className={icon}></i>
                <span className="app-menu__label pt-min">
                  {ParentMenu.menuName}
                </span>
                <i className="fa fa-angle-left pull-right"></i>
              </Link>
            )}
            <ul className="treeview-menu">
              {ParentMenu.lstSubMenus.map((childmenu, index) => (
                <li key={index} className="treeview submenu is-expanded">
                  <Link
                    to={childmenu.link}
                    className="treeview-item"
                    data-toggle="treeview-submenu"
                  >
                    {/* <i className="fa fa-angle-right" style={{ color: '#8b959b' }} aria-hidden="true"></i> */}
                    <span className="pl-1">{childmenu.menuName}</span>
                  </Link>
                  {/* Menu Latest Code Start */}
                  {/* {
                                childmenu.lstSubMenus.length > 0 ?
                                    childmenu.menuName === 'Daily QC Worklist' ?
                                        childmenu.lstSubMenus.map((childsubmenu, index) => (
                                            <ul className='multi-level user-name' onClick={() => this.tabswitch(childsubmenu.menuName)}>
                                                <li key={index} id={"nav" + index} className="">
                                                    <span className="treeview-item" data-toggle="treeview-submenu">
                                                        <span>-</span>
                                                        <span className='pl-1'>{childsubmenu.menuName}</span>

                                                    </span>
                                                </li>
                                            </ul>
                                        ))
                                        : null

                                    : null
                            } */}
                  {/* Menu Latest Code end */}
                  {/* <ul className="multi-level">
                                <li><Link className="treeview-item" to="/scheduledqcteam"> - multilevel 1</Link></li>
                                <li><Link className="treeview-item" to="/qcindividual" rel="noopener"> - multilevel 2</Link></li>
                            </ul>   */}
                </li>
              ))}
            </ul>
          </li>
        )
      )
    );
    return <SideNavigationBar>{navPathHtml}</SideNavigationBar>;
  }
}

export default Navigator;
