import React from "react";
import { Route } from "react-router-dom";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";

const DefaultLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div className="DefaultLayout">
          <Header {...rest} />
          <main className="app-content">
            <Component {...matchProps} {...rest} />
          </main>
          <Footer />
        </div>
      )}
    />
  );
};

export default DefaultLayout;
