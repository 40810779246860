import React from "react";
import ToggleImage from "../../assets/images/img-pro-img.jpg";
import TopToggle from "../../assets/images/top-toggle.jpg";
import axios from "axios";
import config from "../../containers/config";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Modal from "react-modal";
import ZoomView from "../QCUser/ZoomView";
import { Link } from "react-router-dom";
import { SharedService } from "../SharedService/SharedService";
import Buttons from "../QCUser/Buttons";
import CommentBox from "../QCUser/CommentBox";
import SingleImageSideProperties from "../QCUser/SingleImageSideProperties";

import Picky from "react-picky";
//import { DivWithErrorHandling } from '../../hoc/DivWithErrorHandling';
import Alert from "react-s-alert";

let $ = require("jquery");

const modalstyle3 = {
  content: { width: "400px", height: "100px" },
};

class EditorSingleImageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgProperties: [],
      strImageLink: "",
      gridImageDataSet: this.props.location.data,
      selectederrorarr: [],
      presentImgID: [this.props.location.imageid],
      lstImgComments: [],
      newlstImgComments: [],
      cmtTxt: "",
      cmntBtnStatus: false,
      zoomOpen: false,
      loaderStatus: true,
      propertyslider: true,
      topSlider: true,
      stpLeftArrow: false,
      isLastImageOfSet: false,
      currentPage: this.props.location.currentPage,
      cssVar: "",
      cmntboxfocus: false,
      approvePopupFlag: false,
      actionStatusID: this.props.location.actionstatus,
      isHelpScreenActive: false,
      role: localStorage.getItem("roleID"), //this.props.location.roleId,
      listerrors: [],
      isEditIconClicked: false,
      showError: false,
    };
    SharedService.setSingleImageView({ isSingleImageView: true });
    this.getImgProperties(this.state.presentImgID[0]);

    if (sessionStorage.getItem("isEditorRefreshPage") !== "1") {
      sessionStorage.setItem("isEditorRefreshPage", "1");
    } else if (sessionStorage.getItem("isEditorRefreshPage") === "1") {
      this.refreshEditorClick();
    }
  }

  componentWillMount() {
    var pagesize = this.props.location.pageSize; // parseInt(localStorage.getItem('pagesize'), 10);
    let tmpFirstImageofSet = false,
      tmpIsLastImageofSet = false,
      tmpCurrentIndex = 0,
      arraySize = 0;
    if (this.state.gridImageDataSet) {
      arraySize = this.state.gridImageDataSet.length;
      tmpCurrentIndex = this.getMatchingIndex(
        this.state.presentImgID[0],
        this.state.gridImageDataSet
      );
    }
    var dividevalue = tmpCurrentIndex / pagesize;
    if (dividevalue === 0) {
      tmpFirstImageofSet = true;
    }

    if (arraySize < tmpCurrentIndex + 2) {
      tmpIsLastImageofSet = true;
    }

    let tmpIsHelpScreenActive = false;
    SharedService.getIsHelpScreenActive().subscribe((data) => {
      tmpIsHelpScreenActive = data.isHelpScreenActive;
    });

    //  FOR EDITOR REVERTED TAB
    //let tempErrors = [];
    if (this.state.role === "2") {
      axios
        .get(`${config.apiUrl + config.qcApi}Common/GetErrors`)
        .then((response) => {
          if (
            response.status === 200 &&
            response.data &&
            response.data.objRespMessage
          ) {
            this.setState({
              listerrors: response.data.objRespMessage,
              showError: false,
            });
            // tempErrors = response.data.objRespMessage;
          } else {
            this.setState({ showError: true });
          }
        })
        .catch((error) => {
          error = "Not able to communicate with the backend service !";
          console.log(error);
          this.setState({ showError: true });
        });
    }
    this.setState({
      isLastImageOfSet: tmpIsLastImageofSet,
      stpLeftArrow: tmpFirstImageofSet,
      showError: false,
      isHelpScreenActive: tmpIsHelpScreenActive,
    });
  }

  getImgProperties(lngImageID) {
    axios
      .get(`${config.apiUrl + config.qcApi}Image/GetImageData/` + lngImageID)
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.objRespMessage
        ) {
          let cssvar = "";
          if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "17:25"
          ) {
            cssvar = "image-ratio-17-25";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "9:16"
          ) {
            cssvar = "image-ratio-9-16";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "16:9"
          ) {
            cssvar = "image-ratio-16-9";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "4:3"
          ) {
            cssvar = "image-ratio-4-3";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "3:4"
          ) {
            cssvar = "image-ratio-3-4";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "3:2"
          ) {
            cssvar = "image-ratio-3-2";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "2:3"
          ) {
            cssvar = "image-ratio-2-3";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "2:1"
          ) {
            cssvar = "image-ratio-unknownLandscape";
          } else if (
            response.data.objRespMessage.lstImageData[0].strAspectRatio ===
            "1:1"
          ) {
            cssvar = "image-ratio-1-1";
          } else if (
            response.data.objRespMessage.lstImageData[0].chrImageOrientation ===
            "L"
          ) {
            if (
              (response.data.objRespMessage.lstImageData[0].strWidth >= 1200) &
              (response.data.objRespMessage.lstImageData[0].strAspectRatio ===
                "Unknown")
            ) {
              cssvar = "image-ratio-unknownLandscape";
            }
          } else if (
            response.data.objRespMessage.lstImageData[0].chrImageOrientation ===
            "P"
          ) {
            if (
              (response.data.objRespMessage.lstImageData[0].strHeight > 1000) &
              (response.data.objRespMessage.lstImageData[0].strAspectRatio ===
                "Unknown")
            ) {
              cssvar = "image-ratio-unknownPortrait";
            }
          }
          this.setState({
            imgProperties: response.data.objRespMessage.lstImageData[0],
            lstImgComments: response.data.objRespMessage.lstImageComment,
            loaderStatus: false,
            cssVar: cssvar,
            showError: false,
          });
        } else {
          this.setState({ showError: true, loaderStatus: false });
        }
      })
      .catch((error) => {
        error = "Not able to communicate with the backend service !";
        console.log(error);
        this.setState({ showError: true, loaderStatus: false });
      });
  }

  alertMessage = () => {
    Alert.warning(
      '<div><i class="fa fa-check notification" aria-hidden="true"></i>' +
        this.state.actionPopupStatus +
        "</div>",
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 2000,
      }
    );
  };

  ackMessage = () => {
    Alert.success(
      '<div><i class="fa fa-check notification" aria-hidden="true"></i>Image has been Acknowledged</div>',
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 2000,
      }
    );
  };

  onAcknowledgeClick() {
    var imgid = this.state.presentImgID;
    var tempSelectedErrorLst = this.state.selectederrorarr;
    if (
      tempSelectedErrorLst.length === 0 &&
      this.state.approvePopupFlag !== true &&
      this.state.zoomOpen !== true &&
      this.state.cmntboxfocus !== true &&
      !this.state.isHelpScreenActive &&
      this.state.role === "3" &&
      this.state.actionStatusID === "3"
    ) {
      this.setState({ loaderStatus: true });

      let cmntarr = [];
      this.state.newlstImgComments.forEach((element) => {
        cmntarr.push(element.strComment);
      });
      const ids = [];
      var obj = {};
      obj.imageid = imgid.toString(); //this.state.presentImgID;
      obj.status = "0";
      ids.push(obj);

      axios
        .post(`${config.apiUrl + config.qcApi}Action/PerformImageQC`, {
          lstImageData: ids, //this.state.presentImgID,
          intWLTypeID: 2,
          lstReasonID: [],
          intActionStatusCode: 7,
          lstComment: cmntarr,
          intUserRoleID: localStorage.getItem("userRoleID"),
        })
        .then((response) => {
          if (response.status === 200) {
            this.loadNxtImage();
            this.setState({ showError: false, cmtTxt: "" });
            this.ackMessage();
          } else {
            this.setState({ showError: true, loaderStatus: false });
          }
        })
        .catch(function (error) {
          console.log(error);
          this.setState({ showError: true, loaderStatus: false });
        });
    } else {
      if (
        tempSelectedErrorLst.length > 0 &&
        this.state.zoomOpen !== true &&
        this.state.cmntboxfocus === false &&
        !this.state.isHelpScreenActive &&
        this.state.role === "3" &&
        this.state.actionStatusID === "3"
      ) {
        this.setState({ actionPopupStatus: "Please remove selected error " });
        this.alertMessage();
      }
    }
  }

  revertMessage = () => {
    Alert.success(
      '<div><i class="fa fa-check notification" aria-hidden="true"></i>Image has been reverted</div>',
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 2000,
      }
    );
  };

  onRevertToQcClick() {
    if (
      this.state.newlstImgComments.length > 0 &&
      !this.state.isHelpScreenActive &&
      this.state.role === "3" &&
      this.state.actionStatusID !== "2" &&
      this.state.cmntboxfocus !== true
    ) {
      this.setState({ loaderStatus: true });

      let cmntarr = [];
      this.state.newlstImgComments.forEach((element) => {
        cmntarr.push(element.strComment);
      });
      var imgid = this.state.presentImgID;
      const ids = [];
      var obj = {};
      obj.imageid = imgid.toString(); //this.state.presentImgID;
      obj.status = "0";
      ids.push(obj);

      axios
        .post(`${config.apiUrl + config.qcApi}Action/PerformImageQC`, {
          lstImageData: ids, //this.state.presentImgID,
          intWLTypeID: 2,
          lstReasonID: [],
          intActionStatusCode: 8,
          lstComment: cmntarr,
          intUserRoleID: localStorage.getItem("userRoleID"),
        })
        .then((response) => {
          if (response.status === 200) {
            this.loadNxtImage();
            this.setState({ showError: false, cmtTxt: "" });
            this.revertMessage();
          } else {
            this.setState({ showError: true, loaderStatus: false });
          }
        })
        .catch(function (error) {
          console.log(error);
          this.setState({ showError: true, loaderStatus: false });
        });
    } else {
      if (
        !this.state.isHelpScreenActive &&
        this.state.role === "3" &&
        this.state.actionStatusID !== "2" &&
        this.state.cmntboxfocus !== true
      ) {
        // this.setState({ actionPopupStatus: "Please enter comments" });
        this.revertCmntMessage();
        //this.alertMessage();
      }
    }
  }

  onRevertToEditorClick() {
    if (
      this.state.newlstImgComments.length > 0 &&
      !this.state.isHelpScreenActive &&
      this.state.role === "2" &&
      this.state.cmntboxfocus !== true
    ) {
      this.setState({ loaderStatus: true });

      let cmntarr = [];
      this.state.newlstImgComments.forEach((element) => {
        cmntarr.push(element.strComment);
      });

      var imgid = this.state.presentImgID;
      const ids = [];
      var obj = {};
      obj.imageid = imgid.toString(); //this.state.presentImgID;
      obj.status = "0";
      ids.push(obj);

      axios
        .post(`${config.apiUrl + config.qcApi}Action/PerformImageQC`, {
          lstImageData: ids, //this.state.presentImgID,
          intWLTypeID: 2,
          lstReasonID: [],
          intActionStatusCode: 6,
          lstComment: cmntarr,
          intUserRoleID: localStorage.getItem("userRoleID"),
        })
        .then((response) => {
          if (response.status === 200) {
            this.loadNxtImage();
            this.setState({ showError: false, cmtTxt: "" });
            this.revertMessage();
          } else {
            this.setState({ showError: true, loaderStatus: false });
          }
        })
        .catch(function (error) {
          console.log(error);
          this.setState({ showError: true, loaderStatus: false });
        });
    } else {
      if (
        !this.state.isHelpScreenActive &&
        this.state.role === "2" &&
        this.state.cmntboxfocus !== true
      ) {
        // this.setState({ actionPopupStatus: "Please enter comments" });
        // this.alertMessage();
        this.revertCmntMessage();
      }
    }
  }

  approveWithEditsMessage = () => {
    Alert.success(
      '<div><i class="fa fa-check notification" aria-hidden="true"></i>Image has been approved with edits</div>',
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 2000,
      }
    );
  };

  onApproveWthEditsClick() {
    let cmntarr = [],
      tempSelectedErrorLst = this.state.selectederrorarr;

    if (
      tempSelectedErrorLst.length === 0 &&
      this.state.approvePopupFlag !== true &&
      this.state.zoomOpen !== true &&
      this.state.cmntboxfocus !== true &&
      !this.state.isHelpScreenActive &&
      this.state.isEditIconClicked &&
      this.state.role === "2"
    ) {
      this.setState({ loaderStatus: true });

      let tmpIsLastImageofSet = this.state.isLastImageOfSet;
      this.state.newlstImgComments.forEach((element) => {
        cmntarr.push(element.strComment);
      });

      var imgid = this.state.presentImgID;
      const ids = [];
      var obj = {};
      obj.imageid = imgid.toString(); //this.state.presentImgID;
      obj.status = "0";
      ids.push(obj);

      axios
        .post(`${config.apiUrl + config.qcApi}Action/PerformImageQC`, {
          lstImageData: ids, //this.state.presentImgID,
          intWLTypeID: 2,
          lstReasonID: [],
          intActionStatusCode: 3,
          lstComment: cmntarr,
          intUserRoleID: localStorage.getItem("userRoleID"),
        })
        .then((response) => {
          if (response.status === 200) {
            this.loadNxtImage(tmpIsLastImageofSet);
            this.setState({ showError: false, cmtTxt: "" });
            this.approveWithEditsMessage();
          } else if (response.status === 204) {
            this.setState({ loaderStatus: false });
          } else {
            this.setState({ showError: true, loaderStatus: false });
          }
        })
        .catch(function (error) {
          console.log(error);
          this.setState({ showError: true });
        });
    } else {
      if (
        tempSelectedErrorLst.length > 0 &&
        this.state.zoomOpen !== true &&
        this.state.cmntboxfocus === false &&
        this.state.role === "2" &&
        !this.state.isHelpScreenActive
      ) {
        this.setState({ actionPopupStatus: "Please remove selected error " });
        this.alertMessage();
      }
      if (
        !this.state.isEditIconClicked &&
        this.state.approvePopupFlag !== true &&
        this.state.zoomOpen !== true &&
        this.state.cmntboxfocus !== true &&
        !this.state.isHelpScreenActive &&
        this.state.role === "2"
      ) {
        this.setState({
          actionPopupStatus: "Please edit in CMS before Approve with Edits",
        });
        this.alertMessage();
      }
    }
  }

  rejectMessage = () => {
    Alert.error(
      '<div><i class="fa fa-check notification" aria-hidden="true"></i>Image has been rejected</div>',
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 2000,
      }
    );
  };

  onRejectClick() {
    let tempSelectedErrorLst = this.state.selectederrorarr;
    if (
      tempSelectedErrorLst.length > 0 &&
      this.state.approvePopupFlag !== true &&
      this.state.zoomOpen !== true &&
      this.state.cmntboxfocus !== true &&
      !this.state.isHelpScreenActive &&
      this.state.role === "2"
    ) {
      this.setState({ loaderStatus: true });

      let cmntarr = [];
      this.state.newlstImgComments.forEach((element) => {
        cmntarr.push(element.strComment);
      });
      let arr = this.state.selectederrorarr;
      let arrtemp = [];
      let tmpIsLastImageofSet = this.state.isLastImageOfSet;
      arr.forEach((a) => {
        arrtemp.push(a.id + 1);
      });

      var imgid = this.state.presentImgID;
      const ids = [];
      var obj = {};
      obj.imageid = imgid.toString(); //this.state.presentImgID;
      obj.status = "0";
      ids.push(obj);

      axios
        .post(`${config.apiUrl + config.qcApi}Action/PerformImageQC/`, {
          lstImageData: ids, //this.state.presentImgID,
          intWLTypeID: 2,
          lstReasonID: arrtemp,
          intActionStatusCode: 4,
          lstComment: cmntarr,
          intUserRoleID: localStorage.getItem("userRoleID"),
        })
        .then((response) => {
          if (response.status === 200) {
            this.loadNxtImage(tmpIsLastImageofSet);
            this.setState({ showError: false, cmtTxt: "" });
            this.rejectMessage();
          } else if (response.status === 204) {
            this.setState({ loaderStatus: false });
          } else {
            this.setState({ showError: true, loaderStatus: false });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showError: true, loaderStatus: false });
        });
    } else {
      if (
        tempSelectedErrorLst.length === 0 &&
        this.state.zoomOpen !== true &&
        this.state.cmntboxfocus === false &&
        !this.state.isHelpScreenActive &&
        this.state.role === "2"
      ) {
        this.setState({
          actionPopupStatus: "Please select atleast one error ",
        });
        this.alertMessage();
      }
    }
  }

  approveMessage = () => {
    Alert.info(
      '<div><i class="fa fa-check notification" aria-hidden="true"></i>Image has been approved</div>',
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 2000,
      }
    );
  };

  onApproveClick() {
    var imgid = this.state.presentImgID,
      tempSelectedErrorLst = this.state.selectederrorarr;
    if (
      tempSelectedErrorLst.length === 0 &&
      this.state.approvePopupFlag !== true &&
      this.state.zoomOpen !== true &&
      this.state.cmntboxfocus !== true &&
      !this.state.isHelpScreenActive &&
      this.state.role === "2"
    ) {
      this.setState({ loaderStatus: true });

      let cmntarr = [];
      let tmpIsLastImageofSet = this.state.isLastImageOfSet;
      this.state.newlstImgComments.forEach((element) => {
        cmntarr.push(element.strComment);
      });

      imgid = this.state.presentImgID;
      const ids = [];
      var obj = {};
      obj.imageid = imgid.toString(); //this.state.presentImgID;
      obj.status = "0";
      ids.push(obj);

      axios
        .post(`${config.apiUrl + config.qcApi}Action/PerformImageQC`, {
          lstImageData: ids, //this.state.presentImgID,
          intWLTypeID: 2,
          lstReasonID: [],
          intActionStatusCode: 2,
          lstComment: cmntarr,
          intUserRoleID: localStorage.getItem("userRoleID"),
        })
        .then((response) => {
          if (response.status === 200) {
            this.loadNxtImage(tmpIsLastImageofSet);
            this.setState({ showError: false, cmtTxt: "" });
            this.approveMessage();
          } else if (response.status === 204) {
            this.setState({ loaderStatus: false });
          } else {
            this.setState({ showError: true, loaderStatus: false });
          }
        })
        .catch(function (error) {
          console.log(error);
          this.setState({ showError: true, loaderStatus: false });
        });
    } else {
      if (
        tempSelectedErrorLst.length > 0 &&
        this.state.zoomOpen !== true &&
        this.state.cmntboxfocus === false &&
        !this.state.isHelpScreenActive &&
        this.state.role === "2"
      ) {
        this.setState({ actionPopupStatus: "Please remove selected error " });
        this.alertMessage();
      }
    }
  }

  improvisationMessage = () => {
    Alert.warning(
      '<div><i class="fa fa-check notification" aria-hidden="true"></i>Image sent to Improvisation</div>',
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 2000,
      }
    );
  };

  onImprovisationClick() {
    let cmntarr = [],
      tempSelectedErrorLst = this.state.selectederrorarr;
    if (
      tempSelectedErrorLst.length === 0 &&
      this.state.approvePopupFlag !== true &&
      this.state.zoomOpen !== true &&
      this.state.cmntboxfocus !== true &&
      !this.state.isHelpScreenActive &&
      this.state.role === "2"
    ) {
      this.setState({ loaderStatus: true });

      let tmpIsLastImageofSet = this.state.isLastImageOfSet;
      this.state.newlstImgComments.forEach((element) => {
        cmntarr.push(element.strComment);
      });

      var imgid = this.state.presentImgID;
      const ids = [];
      var obj = {};
      obj.imageid = imgid.toString(); //this.state.presentImgID;
      obj.status = "0";
      ids.push(obj);

      axios
        .post(`${config.apiUrl + config.qcApi}Action/PerformImageQC`, {
          lstImageData: ids, //this.state.presentImgID,
          intWLTypeID: 2,
          lstReasonID: [],
          intActionStatusCode: 5,
          lstComment: cmntarr,
          intUserRoleID: localStorage.getItem("userRoleID"),
        })
        .then((response) => {
          if (response.status === 200) {
            this.loadNxtImage(tmpIsLastImageofSet);
            this.setState({ showError: false, cmtTxt: "" });
            this.improvisationMessage();
          } else if (response.status === 204) {
            this.setState({ loaderStatus: false });
          } else {
            this.setState({ showError: true, loaderStatus: false });
          }
        })
        .catch(function (error) {
          console.log(error);
          this.setState({ showError: true, loaderStatus: false });
        });
    } else {
      if (
        tempSelectedErrorLst.length > 0 &&
        this.state.zoomOpen !== true &&
        this.state.cmntboxfocus === false &&
        !this.state.isHelpScreenActive &&
        this.state.role === "2"
      ) {
        this.setState({ actionPopupStatus: "Please remove selected error " });
        this.alertMessage();
      }
    }
  }

  onTextInput(event) {
    let tempcmntbtnStatus = false;
    //TODO: need to modify this block. Not all text change should be captured and setstate. To implement logic to read textbox value
    if (event.target.value.length > 0) {
      tempcmntbtnStatus = true;
    }
    this.setState({
      cmtTxt: event.target.value,
      cmntBtnStatus: tempcmntbtnStatus,
    });
  }

  cmntBtnClicked() {
    var cmntboxtxtvalue = this.state.cmtTxt;
    let tempcmntarr = [];
    if (cmntboxtxtvalue !== "") {
      tempcmntarr = this.state.newlstImgComments;

      //TODO: use switch case. To not initialise array. instead assign a single string
      var month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var d = new Date();
      var months = month[d.getMonth()];
      var dates = new Date().getDate();
      var years = new Date().getFullYear();
      var h = new Date().getHours();
      var m = new Date().getMinutes();
      var amorpm = "AM";

      let scrolled = false;
      window.setTimeout(function updateScroll() {
        if (!scrolled) {
          var element = document.querySelector(".comment-wrapper");
          element.scrollTop = element.scrollHeight;
        }
      }, 10);

      $(".comment-wrapper").on("scroll", function () {
        scrolled = true;
      });

      if (h > 12) {
        h = h - 12;
        amorpm = "PM";
      }
      let objcmnt = {
        strComment: this.state.cmtTxt,
        strDateTime:
          months + " " + dates + " " + years + " " + h + ":" + m + " " + amorpm,
        intID: 1,
        strUserName: localStorage.getItem("userName"),
        strReason: "",
      };
      tempcmntarr.push(objcmnt);
      this.setState({
        newlstImgComments: tempcmntarr,
        cmtTxt: "",
        cmntBtnStatus: false,
      });
    } else {
      this.setState({ actionPopupStatus: "Please enter comment" });
      this.alertMessage();
    }
  }

  loadNxtImage() {
    var i = 0;
    let tempArray = [],
      tmpIsLastImageofSet = false,
      lastImageReached = false;
    let previousImgID = this.state.presentImgID[0];
    let arrayLength = this.state.gridImageDataSet.length;
    tempArray = this.state.gridImageDataSet;
    let arr = [];

    i = this.getMatchingIndex(previousImgID, tempArray);

    tempArray.splice(i, 1);

    if (arrayLength - 1 === i) {
      tmpIsLastImageofSet = true;
      this.setState({ loaderStatus: false });
    }

    if (arrayLength - 2 === i) {
      lastImageReached = true;
    }

    if (tmpIsLastImageofSet === false) {
      arr.push(tempArray[i].lngImageID);
      this.setState({
        presentImgID: arr,
        gridImageDataSet: tempArray,
        selectederrorarr: [],
        newlstImgComments: [],
        isLastImageOfSet: lastImageReached,
        isEditIconClicked: false,
      });
      var nxtImgID = tempArray[i].lngImageID;
      this.getImgProperties(nxtImgID);
    } else {
      this.setState({ currentPage: 1 });
      sessionStorage.removeItem("isEditorRefreshPage");
      this.closeEditorClickHandler();
      if (this.state.role === "2" && this.props.location.WorkListType === "2") {
        this.props.history.push("/qcuser/dailyqcworklist");
      } else if (
        this.state.role === "2" &&
        this.props.location.WorkListType === "1"
      ) {
        this.props.history.push("/qcuser/scheduleqcworklist");
      } else {
        this.props.history.push("/editor/dailyqcworklist");
      }
    }
  }

  showZoomImage() {
    if (this.state.imgProperties.strAspectRatio !== "Unknown") {
      this.setState({ zoomOpen: true });
    }
  }

  getMatchingIndex(value, arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].lngImageID === value) {
        return i;
      }
    }
    return -1;
  }

  onImgSliderClick(btnClicked) {
    let popup = this.state.zoomOpen;
    var pagesize = this.props.location.pageSize; // parseInt(localStorage.getItem('pagesize'), 10);
    //this variable checks whether I am moving forward or backward
    var isLeft = btnClicked === "prev" || btnClicked === "left";
    //If my modal popups are open, I don't have to move anywhere.
    //If I am at the first image of any page, I don't have to move left.

    if (
      !popup &&
      !this.state.isHelpScreenActive &&
      this.state.approvePopupFlag !== true &&
      ((!this.state.stpLeftArrow && isLeft) ||
        (!isLeft && !this.state.isLastImageOfSet))
    ) {
      var tmpCurrentIndex = 0;
      var arraySize = this.state.gridImageDataSet.length;

      //Current image index
      tmpCurrentIndex = this.getMatchingIndex(
        this.state.presentImgID[0],
        this.state.gridImageDataSet
      );

      let tmpIsLastImageofSet = false;
      let tmpFirstImageofSet = false;
      var dividevalue;
      let imgID = [];
      if (isLeft) {
        //this will hold new image index (of the left side)
        tmpCurrentIndex = tmpCurrentIndex - 1;
        //load new image data
        imgID.push(this.state.gridImageDataSet[tmpCurrentIndex].lngImageID);
        dividevalue = tmpCurrentIndex / pagesize;
      } else {
        //this will hold the new image index (of the right hand side)
        tmpCurrentIndex = tmpCurrentIndex + 1;
        dividevalue = tmpCurrentIndex / pagesize;
        //i have arrived at the last image of set, show confirmation popup
        if (arraySize === tmpCurrentIndex + 1) {
          tmpIsLastImageofSet = true;
        }
        //more images are pending, so load next one
        imgID.push(this.state.gridImageDataSet[tmpCurrentIndex].lngImageID);
      }
      if (dividevalue === 0) {
        tmpFirstImageofSet = true;
      }

      let currentpage = Math.floor(dividevalue) + 1;
      sessionStorage.setItem("EditorCurrentPage", currentpage - 1);
      let Action =
        this.state.role === "2" ? "imageTopView" : "ImageTopViewEditor";
      SharedService.setFilterData({
        pageSize: this.props.location.pageSize,
        currentPage: currentpage,
        action: Action,
        status: this.props.location.actionstatus,
      });
      //set values for next image to be loaded
      this.setState({
        currentPage: currentpage,
        presentImgID: imgID,
        loaderStatus: true,
        newlstImgComments: [],
        cmtTxt: "",
        selectederrorarr: [],
        stpLeftArrow: tmpFirstImageofSet,
        isLastImageOfSet: tmpIsLastImageofSet,
        isEditIconClicked: false,
      });
      this.getImgProperties(imgID);
    }
  }

  onEscClick() {
    this.setState({ zoomOpen: false });
  }

  componentDidMount() {
    this.setState({ loaderStatus: false });

    SharedService.getIsHelpScreenActive().subscribe((data) => {
      this.setState({ isHelpScreenActive: data.isHelpScreenActive });
    });
  }

  componentDidUpdate() {
    let comment_panel = document.querySelector(".comment-wrapper");
    if (comment_panel !== null) {
      comment_panel.scrollTop = comment_panel.scrollHeight;
    }
  }
  revertCmntMessage = () => {
    Alert.error(
      '<div><i class="fa fa-check notification" aria-hidden="true"></i>Please enter comments</div>',
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 2000,
      }
    );
  };

  onSliderClick() {
    this.setState({ propertyslider: !this.state.propertyslider });
  }
  onTopToggleClick = () => {
    this.setState({ topSlider: this.state.topSlider ? false : true });
  };

  redirectToCms() {
    let userID = localStorage.getItem("userID");
    let buffData = new Buffer(userID);
    let base64data = buffData.toString("base64");
    let cmsUrl =
      `${config.cmsImageLink}` +
      this.state.imgProperties.strProgrammeID +
      "&ImageID=" +
      this.state.imgProperties.lngImageID +
      "&opt=QCImageDetails&UserID=" +
      base64data;
    window.open(cmsUrl, "_blank");
    this.setState({ isEditIconClicked: true });
  }

  closeApprovePopup = (e, values) => {
    this.setState({ approvePopupFlag: false });
  };

  refreshEditorClick() {
    if (this.state.role !== "2") {
      sessionStorage.setItem(
        "isActiveTabEditor",
        sessionStorage.getItem("EditorActionStatus")
      );
    }
    // SharedService.setFilterData({
    //     pageSize: parseInt(sessionStorage.getItem("EditorPageSize")),
    //     currentPage: parseInt(sessionStorage.getItem("EditorCurrentPage")),
    //     action: Action,
    //     status: sessionStorage.getItem("EditorActionStatus")
    // })
    sessionStorage.removeItem("isEditorRefreshPage");
    //this.state.role === "2" ? this.props.history.push('/qcuser/dailyqcworklist') : this.props.history.push('/editor/dailyqcworklist');
    if (this.state.role === "2" && this.props.location.WorkListType === "2") {
      this.props.history.push("/qcuser/dailyqcworklist");
    } else if (
      this.state.role === "2" &&
      this.props.location.WorkListType === "1"
    ) {
      this.props.history.push("/qcuser/scheduleqcworklist");
    } else {
      this.props.history.push("/editor/dailyqcworklist");
    }
  }

  closeEditorClickHandler = () => {
    sessionStorage.removeItem("isEditorRefreshPage");
    SharedService.setSingleImageView({ isSingleImageView: false });

    let Action =
      this.props.location.roleId === "2"
        ? "imageTopView"
        : "ImageTopViewEditor";
    if (this.props.location.roleId !== "2") {
      sessionStorage.setItem(
        "isActiveTabEditor",
        this.props.location.actionstatus
      );
    }
    SharedService.setFilterData({
      pageSize: this.props.location.pageSize,
      currentPage: this.state.currentPage,
      action: Action,
      status: this.props.location.actionstatus,
    });
  };

  selectMultipleOption(name) {
    const errIndex = [];
    name.map((i) => errIndex.push(i.id));
    this.setState({ selectederrorarr: name, errIndex });
  }

  render() {
    sessionStorage.setItem("isEditorReverted", "1");
    sessionStorage.setItem("isEditorRole", "1");

    let listErrorss = [];
    this.state.listerrors.map((error, index) =>
      listErrorss.push({ id: index, name: error.strErrorName })
    );

    let pathName = "";
    if (this.state.role === "2" && this.props.location.WorkListType === "2") {
      pathName = "/qcuser/dailyqcworklist";
    } else if (
      this.state.role === "2" &&
      this.props.location.WorkListType === "1"
    ) {
      pathName = "/qcuser/scheduleqcworklist";
    } else {
      pathName = "/editor/dailyqcworklist";
    }

    const cmntbox =
      this.state.lstImgComments.length > 0
        ? this.state.lstImgComments.map((element) => (
            <CommentBox cmntContent={element} />
          ))
        : null;

    let prpsliderclass = "";
    let fullpageviewtoggle = "";

    if (this.state.propertyslider) {
      prpsliderclass =
        "panel-slider panel-slider-right float-right img-pro-arrow-bg editor_properties";
      fullpageviewtoggle = "mr-3 container-panel container-panel-right";
    } else {
      prpsliderclass =
        "panel-slider panel-slider-right float-right img-pro-arrow-bg active editor_properties";
      fullpageviewtoggle = "mr-3 container-panel container-panel-right active";
    }

    let topSliderClass = "";
    let closeToggleClass = "";
    if (this.state.topSlider) {
      topSliderClass = "row bg-grey pt-3 pb-3 ml-0 system_properties";
      closeToggleClass = "view-close-icon-toggle d-none";
    } else if (
      this.state.topSlider === false &&
      this.state.propertyslider === false
    ) {
      topSliderClass = "row bg-grey pt-3 pb-3 ml-0 system_properties d-none";
      closeToggleClass = "fullView-close-icon-toggle";
    } else {
      topSliderClass = "row bg-grey pt-3 pb-3 ml-0 system_properties d-none";
      closeToggleClass = "view-close-icon-toggle";
    }

    let singleImgClass = "";
    if (this.state.imgProperties.chrImageOrientation === "P") {
      if (this.state.imgProperties.strHeight >= 880) {
        singleImgClass = "singleImageViewPortrait img-fluid";
      } else {
        singleImgClass = "img-fluid";
      }
    } else {
      if (this.state.imgProperties.strWidth >= 880) {
        singleImgClass = "singleImageViewLandscape img-fluid";
      } else {
        singleImgClass = "img-fluid";
      }
    }

    const pageView = (
      // <DivWithErrorHandling showError={this.state.showError}>

      <div className="programs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 pl-0 pr-0">
              <div className={prpsliderclass}>
                <div className="img-pro-content">
                  <div className="pull-left pt-3 pl-2">
                    <h4 className="txt-blue fs14">IMAGE PROPERTIES</h4>
                  </div>

                  <div
                    className="pull-right mt-3 pr-2"
                    onClick={this.redirectToCms.bind(this)}
                  >
                    <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-pencil txt-grey edit_icon"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="clearfix"></div>
                  <ul className="pl-2 pr-2 mt-2 editor_properties">
                    <SingleImageSideProperties
                      imgprop={this.state.imgProperties}
                      showPropNo={4}
                    />
                  </ul>
                  {this.state.role === "2" &&
                  this.props.location.pathname !==
                    "/qcuser/SingleImageView/Actioned" ? (
                    <div className="col-md-12 w-100 pr-2 mt-3 pl-2">
                      <div className="form-group dropdown-select">
                        <label
                          htmlFor="pro-name"
                          className="control-label fs14 txt-blue fw-bold"
                        >
                          ERROR TYPE
                        </label>
                        <Picky
                          value={this.state.selectederrorarr}
                          options={listErrorss}
                          onChange={this.selectMultipleOption.bind(this)}
                          valueKey="id"
                          labelKey="name"
                          multiple={true}
                          includeFilter={true}
                          dropdownHeight={150}
                          open={false}
                        />
                      </div>
                    </div>
                  ) : null}
                  {this.state.actionStatusID === "2" &&
                  this.state.lstImgComments.length > 0 &&
                  this.props.location.pathname ===
                    "/qcuser/SingleImageView/Actioned" ? (
                    <div className="col-md-12 comment-panel pl-2 pr-2 mb-4">
                      <h5 className="fs14 txt-blue mb-0 lh25">COMMENTS</h5>
                      <div className="comment-content pt-2">
                        <div className="comment-wrapper">{cmntbox}</div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.actionStatusID !== "2" &&
                  this.props.location.pathname !==
                    "/qcuser/SingleImageView/Actioned" ? (
                    <div className="col-md-12 comment-panel pl-2 pr-2 mb-4">
                      <h5 className="fs14 txt-blue mb-0 lh25">COMMENTS</h5>
                      <div className="comment-content pt-2">
                        <div className="comment-wrapper">
                          {cmntbox}
                          {this.state.newlstImgComments.map((element, key) => (
                            <CommentBox cmntContent={element} />
                          ))}
                        </div>
                        <div>
                          <KeyboardEventHandler
                            handleKeys={["Enter"]}
                            handleFocusableElements={true}
                            onKeyEvent={(key, e) => this.cmntBtnClicked()}
                          >
                            <input
                              type="text"
                              value={this.state.cmtTxt}
                              className="form-control search-input border comments"
                              placeholder="Your Message..."
                              onChange={this.onTextInput.bind(this)}
                              onFocus={() => {
                                this.setState({ cmntboxfocus: true });
                              }}
                              onBlur={() =>
                                this.setState({ cmntboxfocus: false })
                              }
                            />
                          </KeyboardEventHandler>
                        </div>
                        {this.state.cmntBtnStatus ? (
                          <div
                            className="msg-icon"
                            onClick={this.cmntBtnClicked.bind(this)}
                          >
                            <i
                              className="fa fa-paper-plane txt-blue"
                              aria-hidden="true"
                            ></i>
                          </div>
                        ) : (
                          <div className="msg-icon icon-deactive">
                            <i
                              className="fa fa-paper-plane txt-blue"
                              aria-hidden="true"
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-12 pl-2 pr-2 mb-4">
                    {this.state.actionStatusID !== "2" &&
                    this.state.actionStatusID !== "8" &&
                    this.props.location.pathname !==
                      "/qcuser/SingleImageView/Actioned" ? (
                      <Buttons
                        id={"Revert"}
                        class={
                          "btn fw-normal btn-revert btn-img-pro pl-2  pr-2 revert"
                        }
                        btnValue={"Revert to QC"}
                        onClick={this.onRevertToQcClick.bind(this)}
                        btnStatus={false}
                      />
                    ) : null}

                    {this.state.actionStatusID === "3" ? (
                      <Buttons
                        id={"Acknowledge"}
                        class={
                          "btn fw-normal btn-acknowledge btn-img-pro pl-2 pr-2 acknowledge"
                        }
                        btnValue={"Acknowledge"}
                        onClick={this.onAcknowledgeClick.bind(this)}
                        btnStatus={false}
                      />
                    ) : null}

                    {/* For Editor Reverted */}
                    {this.state.actionStatusID !== "8" ? null : this.state
                        .selectederrorarr.length > 0 ? (
                      <Buttons
                        id={"revertToEditor"}
                        class={
                          "btn fw-normal btn-revert btn-img-pro pl-2  pr-2"
                        }
                        btnValue={"Revert to Editor"}
                        btnStatus={true}
                        onClick={this.onRevertToEditorClick.bind(this)}
                      />
                    ) : (
                      <Buttons
                        id={"revertToEditor"}
                        class={
                          "btn fw-normal btn-revert btn-img-pro pl-2 pr-2 revert-editor"
                        }
                        btnValue={"Revert to Editor"}
                        onClick={this.onRevertToEditorClick.bind(this)}
                        btnStatus={false}
                      />
                    )}
                    {this.state.actionStatusID !== "8" ? null : this.state
                        .selectederrorarr.length <= 0 &&
                      this.state.isEditIconClicked ? (
                      <Buttons
                        id={"ApproveWithEdits"}
                        class={
                          "btn fw-normal btn-edit btn-img-pro pl-2 pr-2 edit"
                        }
                        btnValue={"Approve With Edits"}
                        onClick={this.onApproveWthEditsClick.bind(this)}
                        btnStatus={false}
                      />
                    ) : (
                      <Buttons
                        id={"ApproveWithEdits"}
                        class={
                          "btn fw-normal btn-edit btn-img-pro pl-2 pr-2"
                        }
                        btnValue={"Approve With Edits"}
                        btnStatus={true}
                      />
                    )}
                    {this.state.actionStatusID !== "8" ? null : this.state
                        .selectederrorarr.length <= 0 ? (
                      <Buttons
                        id={"Reject"}
                        class={
                          "btn fw-normal btn-reject btn-img-pro pl-2 pr-2"
                        }
                        btnValue={"Reject"}
                        onClick={this.onRejectClick.bind(this)}
                        btnStatus={true}
                      />
                    ) : (
                      <Buttons
                        id={"Reject"}
                        class={
                          "btn fw-normal btn-reject btn-img-pro pl-2 pr-2"
                        }
                        btnValue={"Reject"}
                        onClick={this.onRejectClick.bind(this)}
                        btnStatus={false}
                      />
                    )}
                    {this.state.actionStatusID !== "8" ? null : this.state
                        .selectederrorarr.length > 0 ? (
                      <Buttons
                        id={"Approve"}
                        class="btn fw-normal btn-approve btn-img-pro pl-2  pr-2"
                        btnValue={"Approve"}
                        btnStatus={true}
                        onClick={this.onApproveClick.bind(this)}
                      />
                    ) : (
                      <Buttons
                        id={"Approve"}
                        class={
                          "btn fw-normal btn-approve btn-img-pro pl-2  pr-2"
                        }
                        btnValue={"Approve"}
                        onClick={this.onApproveClick.bind(this)}
                        btnStatus={false}
                      />
                    )}
                    {this.state.actionStatusID !== "8" ? null : this.state
                        .selectederrorarr.length > 0 ? (
                      <Buttons
                        id={"Improvisation"}
                        class={
                          "btn fw-normal btn-improvisation btn-img-pro pl-2 pr-2"
                        }
                        btnValue={"Improvisation"}
                        btnStatus={true}
                      />
                    ) : (
                      <Buttons
                        id={"Improvization"}
                        class={
                          "btn fw-normal btn-improvisation btn-img-pro pl-2 pr-2"
                        }
                        btnValue={"Improvisation"}
                        onClick={this.onImprovisationClick.bind(this)}
                        btnStatus={false}
                      />
                    )}
                  </div>
                </div>

                <div
                  className="collapse-arrow arrow-right"
                  id="sidebarCollapse"
                  onClick={this.onSliderClick.bind(this)}
                >
                  <img
                    src={ToggleImage}
                    alt="toggle-icon"
                    width="8"
                    height="40"
                  />
                </div>
              </div>
              <div className={fullpageviewtoggle}>
                <div className={topSliderClass}>
                  <div className="col-md-4 pl-4">
                    <ul className="border-white">
                      <SingleImageSideProperties
                        imgprop={this.state.imgProperties}
                        showPropNo={1}
                      />
                    </ul>
                  </div>
                  <div className="col-md-4 pl-4">
                    <ul className="border-white">
                      <SingleImageSideProperties
                        imgprop={this.state.imgProperties}
                        showPropNo={2}
                      />
                    </ul>
                  </div>
                  <div className="col-md-4 pl-4">
                    <ul>
                      <SingleImageSideProperties
                        imgprop={this.state.imgProperties}
                        showPropNo={3}
                      />
                    </ul>
                  </div>
                </div>
                <div className="top-hide-panel">
                  <img
                    className="top-toggle-image"
                    onClick={this.onTopToggleClick}
                    src={TopToggle}
                    alt="toggle-icon-top"
                  />
                </div>
                <div className="serial_number_header fs14 txt-orange" >
                  <div className="serial_number"></div>
                  <div
                    className="view-close-icon"
                    onClick={this.closeEditorClickHandler} 
                  >
                    <Link
                      to={{
                        pathname: pathName,
                      }}
                    >
                    <i
                      className="fa fa-times txt-blue fs18 close_icon"
                      aria-hidden="true"
                    ></i>
                  </Link>

                </div>
              </div>
                <div className="row pl-5 pr-3">
                  <div
                    id="img-gallery"
                    className="carousel slide col-md-12"
                    data-ride="carousel"
                    data-interval="false"
                  >
                    <div
                      className={closeToggleClass}
                      onClick={this.closeEditorClickHandler}
                    >
                      <Link
                        to={{
                          pathname: pathName,
                        }}
                      >
                        <i
                          className="fa fa-times txt-blue fs18 close_icon"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                    <div className="carousel-inner img-gallery-carousel-inner text-center">
                      <div className="carousel-item active">
                        <span
                          style={{ cursor: "pointer" }}
                          data-toggle="modal"
                          data-target="#model-img"
                        >
                          <img
                            src={this.state.imgProperties.strImageLink}
                            className={singleImgClass}
                            onClick={this.showZoomImage.bind(this)}
                            alt="zoom"
                          />
                        </span>
                      </div>
                      {this.state.stpLeftArrow ? null : (
                        <a
                          className="carousel-control-prev img-gallery-arrow-left"
                          href="#img-gallery"
                          onClick={() => this.onImgSliderClick("prev")}
                          data-slide="prev"
                        >
                          {" "}
                          <i
                            className="fa fa-chevron-left fs30"
                            aria-hidden="true"
                          ></i>{" "}
                        </a>
                      )}
                      {this.state.isLastImageOfSet ? null : (
                        <a
                          className="carousel-control-next img-gallery-arrow-right"
                          href="#img-gallery"
                          data-slide="next"
                          onClick={() => this.onImgSliderClick("next")}
                        >
                          <i
                            className="fa fa-chevron-right fs30 arrow"
                            aria-hidden="true"
                          ></i>{" "}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    // </DivWithErrorHandling>

    let zoomviewvar = "";

    this.state.imgProperties.strAspectRatio === "Unknown"
      ? (zoomviewvar = null)
      : (zoomviewvar = (
          <Modal
            isOpen={this.state.zoomOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            contentLabel="Example Modal"
            className={this.state.cssVar}
          >
            <ZoomView
              loader={this.state.loaderStatus}
              imgLink={this.state.imgProperties.strImageLink}
              closeModel={this.onEscClick.bind(this)}
              classVar={this.state.cssVar}
            />
          </Modal>
        ));

    return (
      <div className="start complete">
        <KeyboardEventHandler
          handleKeys={["left", "right"]}
          handleFocusableElements={false}
          onKeyEvent={(key, e) => this.onImgSliderClick(key)}
        />
        <KeyboardEventHandler
          handleKeys={["esc"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onEscClick()}
        />
        <KeyboardEventHandler
          handleKeys={["esc"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.closeApprovePopup()}
        />
        <KeyboardEventHandler
          handleKeys={["shift+e"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onApproveWthEditsClick()}
        />
        <KeyboardEventHandler
          handleKeys={["shift+r"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onRejectClick()}
        />
        <KeyboardEventHandler
          handleKeys={["shift+a"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onApproveClick()}
        />
        <KeyboardEventHandler
          handleKeys={["shift+i"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onImprovisationClick()}
        />

        {/* Editor shortcuts */}
        <KeyboardEventHandler
          handleKeys={["shift+k"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onAcknowledgeClick()}
        />
        <KeyboardEventHandler
          handleKeys={["shift+q"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onRevertToQcClick()}
        />
        <KeyboardEventHandler
          handleKeys={["shift+d"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.onRevertToEditorClick()}
        />
        <KeyboardEventHandler
          handleKeys={["F5"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.refreshEditorClick()}
        />

        {this.state.loaderStatus ? (
          <div id="loader-wrapper">
            <div id="loader"></div>
          </div>
        ) : (
          pageView
        )}

        {zoomviewvar}

        <Modal
          isOpen={this.state.approvePopupFlag}
          contentLabel="Message Display"
          style={modalstyle3}
        >
          <div>
            <div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={this.closeApprovePopup}
              >
                &times;
              </button>
            </div>
            <div className="modal-content brnone bbnone blnone ">
              <div className="modal-body pt-3 text-center">
                {this.state.actionPopupStatus}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default EditorSingleImageView;
