import React, { Component } from "react";
class ClientReportedImages extends Component {
  render() {
    return (
      <div className="heading-bottom-line ml-3 pt-4">
        <h1 className="txt-violet fw-normal fs18">
          Client Reported Issues Worklist
        </h1>

        <br />
        <br />
        <br />

        <h2 align="center"> Page under construction.. </h2>
      </div>
    );
  }
}

export default ClientReportedImages;
