import React from "react";
import { mouseWheelZoom } from "mouse-wheel-zoom";
class ZoomView extends React.Component {
  componentDidMount() {
    if (!this.props.loader) {
      mouseWheelZoom({
        element: document.querySelector("[data-wheel-zoom]"),
        zoomStep: 0.25,
      });
    }
  }
  render() {
    return (
      <div className="modal-popup-icon">
        <span className="close-btn">
          <button onClick={() => this.props.closeModel()}>
            <i
              className="fa fa-times fs12 fw-semi-bold pb-2"
              aria-hidden="true"
            ></i>
          </button>
        </span>
        <img
          className={this.props.classVar}
          src={this.props.imgLink}
          data-wheel-zoom
          alt="zoom"
        />
      </div>
    );
  }
}
export default ZoomView;
