import React, { Component } from "react";
import Picky from "react-picky";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import axios from "axios";
import Alert from "react-s-alert";
import config from "../../containers/config";
let $ = require("jquery");

class AllocationDropdownPopup extends Component {
  constructor(props) {
    super();
    this.state = {
      arrayValQc: [],
      selectedOption: "permanent",
      startDate: moment(),
      endDate: moment(),
      date: [],
      childQCList: [],
      minDate: moment(),
      maxDate: moment(),
      responseData: [],
      //loading: true
    };
    // console.log(props.userInfo);
    // console.log(props.parentInfo);
    // console.log(props.categoryType);
    //console.log(props.qcUsersList)
  }

  componentWillMount() {
    let tempAllocDropdownValue = {};
    tempAllocDropdownValue.id = this.props.parentInfo.qcId;
    tempAllocDropdownValue.name = this.props.parentInfo.name;
    // let responseData = [];
    axios
      .get(`${config.apiUrl + config.qcApi}Common/getallocations`)
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.objRespMessage
        ) {
          // console.log(response.data.objRespMessage)
          //responseData = response.data.objRespMessage;
          response.data.objRespMessage.forEach((ed) => {
            if (ed.intEditorID === this.props.userInfo.editorId) {
              const formattedQCUser = ed.lstAllocation.filter((a, i) => {
                return i === 0;
              });

              let tempdate =
                formattedQCUser[0].strFromDate +
                "-" +
                formattedQCUser[0].strToDate;
              var qcdate = this.setDateFormat(tempdate);
              this.setState({
                responseData: response.data.objRespMessage,
                minDate: moment(qcdate.startdate),
                maxDate: moment(qcdate.enddate),
              });
            }
          });
          if (this.props.flag === "Click") {
            response.data.objRespMessage.forEach((ed) => {
              if (ed.intEditorID === this.props.userInfo.editorId) {
                const formattedQCUser = ed.lstAllocation.filter((a, i) => {
                  return i > 0;
                });
                this.setState({ childQCList: formattedQCUser }); //loading: false
              }
            });
          } else if (this.props.flag === "Drag") {
            this.setState({
              childQCList: [],
              arrayValQc: tempAllocDropdownValue,
              startDate: moment(),
              endDate: moment(),
            }); //loading: false
          }
        } else {
          this.setState({ childQCList: [] });
        }
      });

    if (this.props.flag === "Click") {
      // this.state.responseData.forEach(ed => {
      //     if (ed.intEditorID === this.props.userInfo.editorId) {
      //         const formattedQCUser = ed.lstAllocation.filter((a, i) => {
      //             return i > 0
      //         });
      //         this.setState({ childQCList: formattedQCUser }); //loading: false
      //     }
      // });
    } else if (this.props.flag === "Drag") {
      // this.setState({ childQCList: [], arrayValQc: tempAllocDropdownValue, startDate: moment(), endDate: moment() }); //loading: false
    }
  }

  selectMultipleOptionQc(e, name) {
    if (e === "QC Person") {
      this.setState({ arrayValQc: name });
    }
  }

  handleRadioChange = (event) => {
    this.setState({ selectedOption: event.target.value, date: [] });
  };

  onApplyDate = (e) => {
    let date = $(".drp-selected").text();
    var dateobj = this.setDateFormat(date);
    this.setState({
      date: dateobj.qcdate,
      startDate: moment(),
      endDate: moment(),
    }); //startDate: moment(), endDate: moment()
  };

  setDateFormat = (date) => {
    let dateobj = {};
    let splitDate = date.trim().split("-");
    let splitDateFormat1 = splitDate[0].split("/");
    let splitDateFormat2 = splitDate[1].split("/");
    let tempdate1 = [
      splitDateFormat1[2].trim(),
      splitDateFormat1[1].trim(),
      splitDateFormat1[0].trim(),
    ].join("-");
    let tempdate2 = [
      splitDateFormat2[2].trim(),
      splitDateFormat2[1].trim(),
      splitDateFormat2[0].trim(),
    ].join("-");

    let startdate = [
      splitDateFormat1[1].trim(),
      splitDateFormat1[0].trim(),
      splitDateFormat1[2].trim(),
    ].join("/");
    let enddate = [
      splitDateFormat2[1].trim(),
      splitDateFormat2[0].trim(),
      splitDateFormat2[2].trim(),
    ].join("/");

    let tempdate3 = [tempdate1, tempdate2].join("-");
    let qcdate = [startdate, enddate].join("-");

    if (startdate < moment().format("MM/DD/YYYY")) {
      startdate = moment().format("MM/DD/YYYY");
    }
    dateobj = {
      tempdate3: tempdate3,
      fromdate: tempdate1,
      todate: tempdate2,
      qcdate: qcdate,
      startdate: startdate,
      enddate: enddate,
    };
    return dateobj;
  };

  childSaveClick = () => {
    //debugger

    if (
      this.state.arrayValQc.date !== null &&
      this.state.arrayValQc.name !== undefined &&
      this.state.selectedOption !== null &&
      this.state.selectedOption === "permanent" &&
      this.props.categoryType === "P"
    ) {
      var dateobj = this.setDateFormat(this.state.arrayValQc.date);
      let from = dateobj.fromdate;

      let to = dateobj.todate;
      axios
        .post(`${config.apiUrl + config.qcApi}action/Allocate`, {
          intAllocatorID: localStorage.getItem("userRoleID"),
          intEditorID: this.props.userInfo.editorId,
          intQcUserRoleID: this.state.arrayValQc.id,
          intAllocationTypeID:
            this.props.categoryType === "P"
              ? 1
              : this.props.categoryType === "T"
              ? 2
              : 0,
          dteFrom: from,
          dteTo: to,
          tmpmode: 2,
        })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.intStatusCode === 200 &&
            response.data.objRespMessage
          ) {
            this.alertMessage(response.data.objRespMessage);
            this.props.saveClick();
          } else if (
            response.status === 200 &&
            response.data.intStatusCode === 409
          ) {
            this.alertErrorMessage(response.data.objRespMessage);
          } else {
            this.alertErrorMessage("No Action");
          }
        });
    } else if (
      this.state.arrayValQc.name !== undefined &&
      this.state.selectedOption !== null &&
      this.state.selectedOption === "temporary" &&
      this.state.date.length > 0 &&
      this.props.categoryType === "P"
    ) {
      let dateobj = this.setDateFormat(this.state.date);
      let from = dateobj.fromdate;
      let to = dateobj.todate;

      axios
        .post(`${config.apiUrl + config.qcApi}action/Allocate`, {
          intAllocatorID: localStorage.getItem("userRoleID"),
          intEditorID: this.props.userInfo.editorId,
          intQcUserRoleID: this.state.arrayValQc.id,
          intAllocationTypeID: 2,
          dteFrom: from,
          dteTo: to,
          tmpmode: 2,
        })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.intStatusCode === 200 &&
            response.data.objRespMessage
          ) {
            this.alertMessage(response.data.objRespMessage);
            this.props.saveClick();
          } else if (
            response.status === 200 &&
            response.data.intStatusCode === 409
          ) {
            this.alertErrorMessage(response.data.objRespMessage);
          } else {
            this.alertErrorMessage("No Action");
          }
        });
    } else if (
      this.state.arrayValQc.name !== undefined &&
      this.state.date.length > 0 &&
      this.props.categoryType === "T"
    ) {
      let dateobj = this.setDateFormat(this.state.date);
      let from = dateobj.fromdate;
      let to = dateobj.todate;
      axios
        .post(`${config.apiUrl + config.qcApi}action/Allocate`, {
          intAllocatorID: localStorage.getItem("userRoleID"),
          intEditorID: this.props.userInfo.editorId,
          intQcUserRoleID: this.state.arrayValQc.id,
          intAllocationTypeID: 2,
          dteFrom: from,
          dteTo: to,
          tmpmode: this.props.flag === "Drag" ? 1 : 2,
        })
        .then((response) => {
          if (response.status === 200 && response.data.intStatusCode === 200) {
            this.alertMessage(response.data.objRespMessage);
            this.props.saveClick();
          } else if (
            response.status === 200 &&
            response.data.intStatusCode === 409
          ) {
            this.alertErrorMessage(response.data.objRespMessage);
          } else {
            this.alertErrorMessage("No Action");
          }
        });
    } else {
      if (
        this.state.arrayValQc.date === null &&
        this.state.arrayValQc.name !== undefined &&
        this.state.selectedOption === "permanent" &&
        this.props.categoryType === "P"
      ) {
        this.alertErrorMessage("Please select the date of allocation.");
      } else {
        this.alertErrorMessage("Please select the mandatory field(s).");
      }
    }
  };

  alertMessage = (Message) => {
    Alert.success(
      '<div><i class="fa fa-check notification" aria-hidden="true"></i>' +
        Message +
        "</div>",
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 3000,
      }
    );
  };

  alertErrorMessage = (Message) => {
    Alert.error(
      '<div><i class="fa fa-times notification" aria-hidden="true"></i>' +
        Message +
        "</div>",
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 3000,
      }
    );
  };

  childCloseButtonClick = (qcId, editorId, qcdate) => {
    if (qcId !== null && qcdate !== null) {
      let date = this.setDateFormat(qcdate);
      axios
        .post(`${config.apiUrl + config.qcApi}action/Allocate`, {
          intAllocatorID: localStorage.getItem("userRoleID"),
          intEditorID: editorId,
          intQcUserRoleID: qcId,
          intAllocationTypeID: -1,
          dteFrom: date.fromdate, // '2019-08-25',
          dteTo: date.todate, //'2029-10-30',
          tmpmode: 2,
        })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.intStatusCode === 200 &&
            response.data.objRespMessage
          ) {
            const qcList = [...this.state.childQCList];
            const updatedQcList = qcList.filter(
              (qc) => qc.intQCUserRoleID !== qcId
            );
            this.setState({ childQCList: updatedQcList });
            // console.log("updatedQcList", updatedQcList);

            this.alertMessage(response.data.objRespMessage);
            this.props.saveClick();
          } else if (
            response.status === 200 &&
            response.data.intStatusCode === 409
          ) {
            this.alertErrorMessage(response.data.objRespMessage);
          } else {
            this.alertErrorMessage("No Action");
          }
        });
    } else {
      this.alertErrorMessage("No Action");
    }
  };

  render() {
    let QcList = [];
    this.props.qcUsersList.map((qc) =>
      QcList.push({ id: qc.qcId, name: qc.name, date: qc.date })
    );

    let childQcUserList = null;
    let innerForm = null;
    if (!this.props.loading) {
      if (this.state.childQCList) {
        childQcUserList = this.state.childQCList.map((qc) => {
          return (
            <li
              style={{ "--my-color-var": qc.tmpQCColor }}
              className="dark-yellow txt-black"
            >
              <div className="pull-left">{qc.qcName}</div>
              <div className="pull-right">
                <i
                  onClick={this.childCloseButtonClick.bind(
                    this,
                    qc.intQCUserRoleID,
                    this.props.userInfo.editorId,
                    qc.strFromDate + "-" + qc.strToDate
                  )}
                  className="fa fa-times user-name txt-grey"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="clearfix"></div>
              <p className="fs12 txt-grey">{`${qc.strFromDate} - ${qc.strToDate}`}</p>
            </li>
          );
        });
      }

      innerForm = (
        <React.Fragment>
          <li className="grey">
            <div className="form-group">
              <div className="form-group ddl-year">
                <label
                  htmlFor="field-1"
                  className="control-label1 fs12 mb-1 txt-blue"
                >
                  QC Person
                </label>
                <Picky
                  value={this.state.arrayValQc}
                  options={QcList}
                  onChange={this.selectMultipleOptionQc.bind(this, "QC Person")}
                  valueKey="id"
                  labelKey="name"
                  multiple={false}
                  includeFilter={true}
                  dropdownHeight={150}
                  open={false}
                  className={""}
                />
              </div>
            </div>

            {this.props.categoryType === "P" ? (
              <div className="form-group">
                <label
                  htmlFor="field-1"
                  className="control-label1 fs12 mb-1 txt-blue"
                >
                  Allocation Type
                </label>
                <div className="switch-field">
                  <div style={{ marginRight: "10px" }} title="Permanent">
                    <input
                      type="radio"
                      id="radio-one"
                      name="switch-one"
                      value="permanent"
                      checked={this.state.selectedOption === "permanent"}
                      onChange={this.handleRadioChange}
                    />
                    <label htmlFor="radio-one">P</label>
                  </div>
                  <div title="Temporary">
                    <input
                      type="radio"
                      id="radio-two"
                      name="switch-one"
                      value="temporary"
                      checked={this.state.selectedOption === "temporary"}
                      onChange={this.handleRadioChange}
                    />
                    <label htmlFor="radio-two">T</label>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.selectedOption === "temporary" ||
            this.props.categoryType === "T" ? (
              <div className="form-group">
                <p htmlFor="field-1" className="fs12 mb-1 txt-blue">
                  Assign From & To Date
                </p>
                <DateRangePicker
                  id={"uploadDate"}
                  autoApply
                  onApply={this.onApplyDate.bind(this)}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={this.state.minDate}
                  maxDate={this.state.maxDate}
                >
                  <input
                    type="text"
                    className={
                      "form__daterange form-control daterange hasDatepicker"
                    }
                    id="datePickerValue"
                    title={this.state.date}
                    value={this.state.date}
                  />
                  <div className="cal-icon">
                    <i className="fa fa-calendar"></i>
                  </div>
                </DateRangePicker>
              </div>
            ) : null}
          </li>

          <div className="col-12 text-right pr-0 pl-0">
            <button
              type="button"
              className="btn fw-normal btn-save pl-3 pr-3"
              onClick={this.childSaveClick.bind(this)}
            >
              Save
            </button>
            <button
              type="button"
              className="btn fw-normal btn-white txt-blue pl-3 pr-3 ml-2"
              onClick={this.props.childCancelClick}
            >
              Cancel
            </button>
          </div>
        </React.Fragment>
      );
    }

    if (this.state.childQCList.length === 3) {
      innerForm = null;
    }

    return (
      <div className="assign-date slidingDiv" style={{ left: "0" }}>
        <ul className="timeline">
          {innerForm}
          {this.props.categoryType === "T" ? (
            <React.Fragment>{childQcUserList}</React.Fragment>
          ) : null}
        </ul>
      </div>
    );
  }
}

export default AllocationDropdownPopup;
