import React from "react";

class CommentBox extends React.Component {
  render() {
    let shwRsn = false;
    let newText = "";
    if (this.props.cmntContent.strReason !== "") {
      shwRsn = true;
    }
    if (
      this.props.cmntContent.strComment !== "" &&
      this.props.cmntContent.strComment !== null
    ) {
      newText = this.props.cmntContent.strComment
        .split("*|*1n$@n3*|*")
        .map((item, i) => (
          <div style={{ color: "black" }} key={i}>
            {item}
          </div>
        ));
    }
    return (
      <ul>
        <li className="border-bottom pl-1 pr-1 pb-1">
          <div className="pull-left fs11 txt-grey">
            {this.props.cmntContent.strUserName}
          </div>
          <div className="pull-right fs11 txt-grey">
            {this.props.cmntContent.strDateTime}
          </div>
        </li>
        {shwRsn ? (
          <li className="pl-1 pr-1 fs12 pt-2 pb-4">
            {" "}
            {this.props.cmntContent.strReason}
          </li>
        ) : null}
        <li className="pl-1 pr-1 fs12 pt-2 pb-4"> {newText} </li>
      </ul>
    );
  }
}
export default CommentBox;
