import React from "react";

class SingleImageSideProperties extends React.Component {
  render() {
    let propNameObj = null;
    let tempshowPropNo = this.props.showPropNo;
    if (tempshowPropNo === 4) {
      propNameObj = {
        strAssignedLanguage: "Assigned Language",
        strAssignedClient: "Assigned Client",
        strAssignedChannels: "Assigned Channels",
        strContent: "Content",
        strBannerType: "Banner Type",
        strSource: "Source",
        strSourceName: "Source Name",
        strCopyrightHolder: "Copyright Holder",
        strImageTitle: "IsImage Title",
        strEffectiveDate: "Effective Date",
        strReceivedOn: "Received On",
        strResolutionType: "Resolution Type",
        strImageDeliveryPlatform: "Image Delivery Platform",
        strSeriesNo: "Series No",
        strEpisodeNo: "Episode No",
        strEpisodeTitle: "Episode Title",
        strRestrictive: "Is Restrictive Image",
        strRestrictiveReason: "Reason for Is Restrictive",
      };
    } else if (tempshowPropNo === 1) {
      propNameObj = {
        strProgrammeName: "Programme Name",
        strRgnPrgName: "Regional Program Title",
        strGenre: "Genre",
        strSubGenre: "Sub Genre",
        intProdutionYear: "Production Year",
        strStbDescription:"STB Description",

      };
    } else if (tempshowPropNo === 2) {
      propNameObj = {
        lngPromoFileID: "Image ID",
        strProgLanguageName: "Programme Language Name",
        strAiringChannelName: "Airing Channel",
        strAiringLanguageName: "Airing Language",
        strAssignedChannelImage: "Channels",
      };
    } else if (tempshowPropNo === 3) {
      propNameObj = {
        strAspectRatio: "Aspect Ratio",
        strUploadedBy: "Uploaded By",
        strUploadedDate: "Uploaded Date",
        strImageFormat: "Image Format",
        strWidth: "Width X Height",
      };
    }

    let strHeight = "";
    return Object.entries(propNameObj).map(
      ([propNameObjkey, propNameObjvalue]) => {
        let temppropNameObjvalue = null;
        let tempimpPropvalue = null;
        Object.entries(this.props.imgprop).forEach(
          ([impPropkey, impPropvalue]) => {
            if (impPropkey === propNameObjkey) {
              temppropNameObjvalue = propNameObjvalue;
              tempimpPropvalue = impPropvalue;

              if ("strWidth" === impPropkey) {
                strHeight = this.props.imgprop.strHeight;
              }
            }
          }
        );
        if (temppropNameObjvalue === null) {
          return null;
        } else if (temppropNameObjvalue === "Width X Height") {
          return (
            <div className="form-group mb-0">
              <label
                htmlFor="pro-name"
                className="control-label fs12 float-left pro-name w-100 mb-0"
              >
                {temppropNameObjvalue}
              </label>
              <label
                htmlFor="pro-detail"
                className="control-label fs12 fw-bold w-100 mb-1"
              >
                {" "}
                {tempimpPropvalue} X {strHeight}
              </label>
            </div>
          );
        } else if (
          (temppropNameObjvalue === "Channels" || temppropNameObjvalue==="STB Description")&&
          tempimpPropvalue !== ""
        ) {
          return (
            <div className="form-group mb-0">
              <label
                htmlFor="pro-name"
                className="control-label fs12 float-left pro-name w-100 mb-0"
              >
                {temppropNameObjvalue}
              </label>
              <div className="tool">
                <label
                  htmlFor="pro-detail"
                  className="control-label fs12 fw-bold w-100 mb-1 gallery-pro-name"
                >
                  {" "}
                  {tempimpPropvalue}
                </label>
                <span className= {`text ${temppropNameObjvalue === "STB Description"?"tooltip-width-230":""}`}>{tempimpPropvalue}</span>
              </div>
            </div>
          );
        } else if (
          ((temppropNameObjvalue === "Assigned Channels") &
            (tempimpPropvalue !== "")) |
          ((temppropNameObjvalue === "Assigned Client") &
            (tempimpPropvalue !== ""))
        ) {
          return (
            <div className="form-group mb-0">
              <label
                htmlFor="pro-name"
                className="control-label fs12 float-left pro-name w-100 mb-0"
              >
                {temppropNameObjvalue}
              </label>
              <div className="tool-side">
                <label
                  htmlFor="pro-detail"
                  className="control-label fs12 fw-bold w-100 mb-1 gallery-pro-name"
                >
                  {" "}
                  {tempimpPropvalue}
                </label>
                <span className="text-side">{tempimpPropvalue}</span>
              </div>
            </div>
          );
        }
        return (
          <li>
            <div className="form-group mb-0">
              <label
                htmlFor="pro-name"
                className="control-label fs12 float-left pro-name w-100 mb-0"
              >
                {temppropNameObjvalue}
              </label>
              <label
                htmlFor="pro-detail"
                className="control-label fs12 fw-bold w-100 mb-1"
              >
                {" "}
                {tempimpPropvalue}
              </label>
            </div>
          </li>
        );
      }
    );
  }
}

export default SingleImageSideProperties;
