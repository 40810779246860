import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchsorter from "match-sorter";
import Modal from "react-modal";
import { SharedService } from "../SharedService/SharedService";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../../containers/config";
import KeyboardEventHandler from "react-keyboard-event-handler";
let $ = require("jquery");

const modalstyle2 = {
  content: { width: "400px", height: "100px" },
};

class EditorTables extends React.Component {
  constructor(props) {
    super(props);
    let filterData = this.props.filter.map((data) => {
      return {
        ...data,
        isCheckboxSelected: false,
      };
    });
    this.state = {
      selected: {},
      selectedImageID: [],
      selectAll: 0,
      alacartselectedImageID: [],
      selectToggle: "0",
      confirmPopupFlag: false,
      tableData: filterData,
      selectAllFlag: false,
      pageSize: this.props.pageSize,
      currentPage: this.props.currentPage,
    };
    this.toggleRow = this.toggleRow.bind(this);
    this.reactTable = React.createRef();
  }

  componentDidMount() {
    this.subscription =
      SharedService.getEditorFilterDataForTabularView().subscribe((res) => {
        let filterData;
        if (res !== null) {
          filterData = res.map((data) => {
            return {
              ...data,
              isCheckboxSelected: false,
            };
          });
        }
        this.setState({
          tableData: filterData,
          selectAllFlag: false,
        });
      });
    $("div.-pageJump input").attr("readonly", "true");
    $("div.-pageJump input").attr("type", "text");
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  toggleRow(rowData) {
    rowData.isCheckboxSelected = !rowData.isCheckboxSelected;
    let copyTableData = JSON.parse(JSON.stringify(this.state.tableData));

    copyTableData.forEach((data) => {
      if (data.lngImageID === rowData.lngImageID) {
        data.isCheckboxSelected = rowData.isCheckboxSelected;
      }
    });
    this.setState({
      tableData: copyTableData,
    });
  }

  toggleSelectAll() {
    const current = this.reactTable.current;
    let copyTableData = JSON.parse(JSON.stringify(this.state.tableData));
    let selectedAllFlag = this.state.selectAllFlag;
    if (current) {
      const page = current.state.page;
      const pageSize = current.state.pageSize;
      const allData = current.getResolvedState().sortedData;
      const startIdx = page * pageSize;
      const currentData = allData
        .slice(startIdx, startIdx + pageSize)
        .map((item) => item._original);

      if (selectedAllFlag) {
        currentData.forEach((currData) => {
          copyTableData.forEach((data) => {
            if (currData.lngImageID === data.lngImageID) {
              data.isCheckboxSelected = false;
            }
          });
        });
        selectedAllFlag = !selectedAllFlag;
      } else {
        currentData.forEach((currData) => {
          copyTableData.forEach((data) => {
            if (currData.lngImageID === data.lngImageID) {
              data.isCheckboxSelected = true;
            }
          });
        });
        selectedAllFlag = !selectedAllFlag;
      }

      this.setState({
        tableData: copyTableData,
        selectAllFlag: selectedAllFlag,
      });
    }
  }

  onPageSizeChange() {
    const current = this.reactTable.current;
    if (current) {
      const page = current.state.page;
      const pageSize = current.state.pageSize;
      SharedService.setFilterData({
        action: "EditorPaginationCount",
        status: this.props.status,
        pageSize: pageSize,
        currentPage: page,
      });
    }
    sessionStorage.setItem("EditorPageSize", current.state.pageSize);
    sessionStorage.setItem("EditorCurrentPage", current.state.page);
    this.setState({
      pageSize: current.state.pageSize,
      currentPage: current.state.page,
    });
  }

  updateSelect() {
    const current = this.reactTable.current;

    if (current) {
      const page = current.state.page;
      const pageSize = current.state.pageSize;

      SharedService.setFilterData({
        action: "EditorPaginationCount",
        status: this.props.status,
        pageSize: pageSize,
        currentPage: page,
      });
    }
    let copyTableData = JSON.parse(JSON.stringify(this.state.tableData));

    copyTableData.forEach((data) => {
      data.isCheckboxSelected = false;
    });
    sessionStorage.setItem("EditorPageSize", current.state.pageSize);
    sessionStorage.setItem("EditorCurrentPage", current.state.page);
    this.setState({
      tableData: copyTableData,
      selectAllFlag: false,
      pageSize: current.state.pageSize,
      currentPage: current.state.page,
    });
  }

  ackClicked() {
    let imageIdArray = [],
      cmntarr = [],
      ids = [];
    //  let imageIdDataArrayToDelete = [];
    this.state.tableData.forEach((data) => {
      if (data.isCheckboxSelected) {
        imageIdArray.push(data.lngImageID);

        var obj = {};
        obj.imageid = data.lngImageID.toString();
        obj.status = "0";
        ids.push(obj);
        //  imageIdDataArrayToDelete.push(data);
      }
    });

    if (imageIdArray.length > 0) {
      axios
        .post(`${config.apiUrl + config.qcApi}Action/PerformImageQC`, {
          lstImageData: ids, //imageIdArray,
          intWLTypeID: 2,
          lstReasonID: [],
          intActionStatusCode: 7,
          lstComment: cmntarr,
          intUserRoleID: localStorage.getItem("userRoleID"),
        })
        .then((response) => {
          if (response.status === 200) {
            // this.loadNxtImage(tmpIsLastImageofSet);
            let copyTableData = JSON.parse(
              JSON.stringify(this.state.tableData)
            );

            let notSelectedData = [];
            copyTableData.forEach((data) => {
              if (!data.isCheckboxSelected) {
                notSelectedData.push(data);
              }
            });

            this.setState({
              tableData: notSelectedData,
              selectAllFlag: false,
              showError: false,
            });

            SharedService.setFilterData({
              action: "EditorImgAck",
              status: this.props.status,
              data: notSelectedData,
            });
            // this.setState({ approvePopupFlag: true, actionPopupStatus: 'Image has been Acknowledge', showError: false, cmtTxt: "" })
          } else {
            this.setState({ showError: true });
          }
        })
        .catch(function (error) {
          console.log(error);
          this.setState({ showError: true });
        });
    } else {
      this.setState({ confirmPopupFlag: true });
      this.props.stopShortCuts(true);
    }
  }

  closeConfirmPopup = () => {
    this.setState({ confirmPopupFlag: false });
    this.props.stopShortCuts(false);
  };

  render() {
    const products = [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          return (
            <div className="text-center">
              {" "}
              <input
                type="checkbox"
                className="checkbox"
                checked={original.isCheckboxSelected}
                onChange={() => this.toggleRow(original)}
              />
            </div>
          );
        },
        Header: (x) => {
          return (
            <div className="pb-1 text-center">
              {" "}
              <input
                type="checkbox"
                className="checkbox"
                checked={this.state.selectAllFlag}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = this.state.selectAll === 2;
                  }
                }}
                onChange={() => this.toggleSelectAll()}
              />
            </div>
          );
        },
        sortable: false,
        width: 50,
        show: this.props.status === "3" ? true : false,
      },
      {
        Header: "Image",
        Cell: (row) => (
          <div>
            <Link
              to={{
                pathname: "/Editor/EditorSingleImageView",
                imageid: row.original.lngImageID,
                data: this.props.filter,
                actionstatus: this.props.status,
                pageSize: this.state.pageSize,
                currentPage: this.state.currentPage + 1,
              }}
            >
              <img
                alt={""}
                src={row.original.strTabularLink}
                width={50}
                height={50}
              />
            </Link>
          </div>
        ),
      },
      {
        Header: "QC",
        accessor: "strQCUserName",
        width: 150,
      },
      {
        Header: "Error Type",
        accessor: "strError",
        width: 200,
        show: this.props.status === "4" ? true : false,
      },
      {
        Header: "Comment",
        accessor: "strComment",
        show: (this.props.status === "2" || this.props.status === "5") ? false : true,
      },
      {
        Header: "QC Action",
        accessor: "strPrevAction",
        show:
          this.props.status === "6" || this.props.status === "8" ? true : false,
      },
      {
        Header: "Error Type",
        accessor: "strPrevReason",
        show:
          this.props.status === "6" || this.props.status === "8" ? true : false,
      },

      {
        Header: "Programme Name",
        accessor: "strProgrammeName", // String-based value accessors!
        width: 200,
        filterMethod: (filter, rows) =>
          matchsorter(rows, filter.value, { keys: ["title"] }),
        filterAll: true,
      },
      {
        Header: "Genre",
        accessor: "strGenre",
      },
      {
        Header: "Sub Genre",
        accessor: "strSubGenre",
      },
      {
        Header: "Production Year",
        accessor: "intProdutionYear",
      },
      {
        Header: "Language Name",
        accessor: "strLanguageName",
      },
      {
        Header: "Airing Channel",
        accessor: "strAiringChannelName",
      },
      {
        Header: "TimeStamp",
        accessor: "strTimeStamp",
      },
    ];

    return (
      <div>
        <KeyboardEventHandler
          handleKeys={["esc"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.closeConfirmPopup(key)}
        />
        {/* <DivWithErrorHandling showError={this.state.showError}> */}
        <div
          className="tab-pane fade show active"
          id="qc-worklist"
          role="tabpanel"
          aria-labelledby="qc-tab"
        >
          <div className="row ml-0 mr-0">
            <div className="col-md-12">
              <div
                className="border pt-3 pb-3 pr-3 pl-3"
                style={{ overflow: "hidden" }}
              >
                <div className="gallery-header pb-2">
                  <div className="pull-left pt-3">
                    <h3 className="txt-orange fw-normal fs18">
                      {this.state.tableData.length} Images Found{" "}
                    </h3>
                  </div>
                  {this.props.status === "3" ? (
                    <div className="pull-right">
                      <button
                        type="button"
                        className="btn fw-normal btn-white txt-blue pl-3 pr-3 mr-2"
                        onClick={this.ackClicked.bind(this)}
                      >
                        {" "}
                        <i
                          className="fa fa-check fs16"
                          aria-hidden="true"
                        ></i>{" "}
                        Acknowledge{" "}
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-12 pl-0 pr-0 pt-1">
                  {this.state.tableData.length > 0 ? (
                    <ReactTable
                      data={this.state.tableData}
                      columns={products}
                      pageSizeOptions={[25, 50, 100]}
                      defaultPageSize={this.state.pageSize}
                      minRows={0}
                      resizable={false}
                      defaultPage={this.state.currentPage}
                      ref={this.reactTable}
                      sortable={false}
                      onPageSizeChange={this.onPageSizeChange.bind(this)}
                      onPageChange={this.updateSelect.bind(this)}
                    />
                  ) : null}
                </div>

                <div style={{ clear: "both" }}></div>
              </div>
            </div>
          </div>
        </div>
        {/* </DivWithErrorHandling> */}
        {this.state.confirmPopupFlag ? (
          <Modal
            isOpen={true}
            contentLabel="Message Display"
            style={modalstyle2}
          >
            <div>
              <div>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  onClick={this.closeConfirmPopup}
                >
                  &times;
                </button>
              </div>
              <div class="modal-content brnone bbnone blnone ">
                <div class="modal-body pt-3 text-center">
                  Please select atleast one Image
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default EditorTables;
