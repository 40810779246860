import React, { Component } from "react";

class SideNavigationBar extends Component {
  render() {
    return (
      <div>
        <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
        <aside className="app-sidebar">
          <ul className="app-menu">{this.props.children}</ul>
        </aside>
      </div>
    );
  }
}

export default SideNavigationBar;
