import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";

import "./assets/css/bootstrap.min.css";
import "./assets/css/left-side-panel.css";
import "./assets/css/custom-style.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/fSelect.css";
import "./assets/css/collapse-panel.css";
import "./assets/css/jquery-ui.css";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
