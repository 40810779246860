import React from "react";
import Picky from "react-picky";

class ScheduleDropdown extends React.Component {
  render() {
    let str = "";
    if (
      this.props.label === "Image Type" ||
      this.props.label === "Image Size" ||
      this.props.label === "Aspect Ratio" ||
      this.props.label === "Channel" ||
      this.props.label === "Group" ||
      this.props.label === "Airing Language"
    ) {
      str = "*";
    }

    return (
      <div className="col-md-3">
        <div
          style={
            this.props.disabled ? { pointerEvents: "none", opacity: "0.4" } : {}
          }
          className={this.props.customClass}
        >
          <div className="form-group dropdown-select">
            <label
              htmlFor="field-1"
              className="control-label fs11 pl-2 txt-blue"
            >
              {this.props.label} <span className="txt-red">{str}</span>
            </label>
            <Picky
              value={this.props.selectedvalue}
              options={this.props.option}
              onChange={this.props.change}
              valueKey="id"
              labelKey="name"
              multiple={this.props.multiselect}
              includeFilter={this.props.filterFlag}
              dropdownHeight={150}
              open={false}
              onOpen={this.props.onDropdownOpen}
              onClose={this.props.onDropdownClose}
              includeSelectAll={this.props.includeSelectAll}
              className={this.props.validation ? "required" : ""}
              clearFilterOnClose={true}
            />
            {this.props.resetIcon}
          </div>
        </div>
      </div>
    );
  }
}

export default ScheduleDropdown;
