import React from "react";
import axios from "axios";
import "react-dates/initialize";
import "react-picky/dist/picky.css";
import config from "../../containers/config";
import Dropdown from "./ScheduleDropdown";
import Gallery from "./Gallery";
import Tables from "./Tables";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import DateRangePickerNew from "react-bootstrap-daterangepicker";
import { SharedService } from "../SharedService/SharedService";
// import { DivWithErrorHandling } from '../../hoc/DivWithErrorHandling';
import KeyboardEventHandler from "react-keyboard-event-handler";
import Alert from "react-s-alert";
import ToggleSwitch from "./ToggleSwitch";
import exportFromJson from "export-from-json";
class ScheduleQCMainDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredChannelData: 0,
      filterChannelArr: [],
      notFoundArray: [],
      customFilter: false,
      arrayValLoc: props.data.scheduleQcTabContent.bindLocation.selectedValue,
      arrayValEditor:
        props.data.scheduleQcTabContent.bindEditorByLoc.selectedValue,
      arrayValSource: props.data.scheduleQcTabContent.bindSource.selectedValue,
      arrayValChannel:
        props.data.scheduleQcTabContent.bindChannel.selectedValue,
      arrayValChannelGenre:
        props.data.scheduleQcTabContent.bindChannelGenre.selectedValue,
      arrayValImageType:
        props.data.scheduleQcTabContent.bindImageType.selectedValue,
      arrayValContentType:
        props.data.scheduleQcTabContent.bindContentType.selectedValue,
      arrayValGenre: props.data.scheduleQcTabContent.bindGenre.selectedValue,
      arrayValSubGenre:
        props.data.scheduleQcTabContent.bindSubgenre.selectedValue,
      arrayValRatio: props.data.scheduleQcTabContent.bindRatio.selectedValue,
      arrayValImgSize:
        props.data.scheduleQcTabContent.bindImgSize.selectedValue,
      arrayValGroup: props.data.scheduleQcTabContent.bindGroup.selectedValue,
      arrayValAiringLang:
        props.data.scheduleQcTabContent.bindAiringLang.selectedValue,
      arrayValAssignedClient:
        props.data.scheduleQcTabContent.bindAssignedClient.selectedValue,
      arrayValAssignedChannel:
        props.data.scheduleQcTabContent.bindAssignedChannel.selectedValue,
      arrayValAction: props.data.scheduleQcTabContent.bindAction.selectedValue,
      filteredData: props.data.scheduleQcTabContent.filteredData,
      editorIndex: [],
      loading: props.data.scheduleQcTabContent.loader,
      pageSize: props.data.scheduleQcTabContent.pageSize,
      currentPage: props.data.scheduleQcTabContent.currentPage,
      tabName: props.data.tabName,
      startAiringDate: props.data.scheduleQcTabContent.startAiringDate
        ? props.data.scheduleQcTabContent.startAiringDate
        : moment().format("MM/DD/YYYY"),
      endAiringDate: props.data.scheduleQcTabContent.endAiringDate
        ? props.data.scheduleQcTabContent.endAiringDate
        : moment().format("MM/DD/YYYY"),
      displayAiringDate: props.data.scheduleQcTabContent.displayAiringDate,
      isHelpScreenActive: false,
      isConfirmActive: false,
      first: props.data.first,
      temp: false,
      editorRevertedBindValues: [],
      isLocationDropdown: false,
      isEditorDropdown: false,
      isSourceDropdown: false,
      isChannelDropdown: false,
      isContenTypeDropdown: false,
      isImgTypDropdown: false,
      isGenreDropdown: false,
      isSubGenreDropdown: false,
      isRationDropdown: false,
      isImgSizeDropdown: false,
      isGroupDropdown: false,
      isAiringLangDropdown: false,
      isAssignedClientDropdown: false,
      isAssignedChannelDropdown: false,
      isQCActionDropdown: false,
      isSelectAll: true,
      moreFilter: props.data.scheduleQcTabContent.moreFilter,
      AiringDateRequired: false,
      ImageTypeRequired: false,
      ImgRatioRequired: false,
      ImgSizeRequired: false,
      GroupRequired: false,
      ChannelGenreRequired: false,
      AiringLangRequired: false,
      ChannelRequired: false,
      AssignedClientSwitch:
        props.data.scheduleQcTabContent.AssignedClientSwitch,
      MissingImagesSwitch: props.data.scheduleQcTabContent.MissingImagesSwitch,
      DisableAssignedClient:
        props.data.scheduleQcTabContent.DisableAssignedClient,
      contentTypeDisabled: props.data.scheduleQcTabContent.contentTypeDisabled,
      AssignedChannelSwitch:
        props.data.scheduleQcTabContent.AssignedChannelSwitch,
      AllImagesCount: props.data.scheduleQcTabContent.AllImagesCount,
      lstMissingImages: {},
      lstMissingImagesFields: {},
      strMissingImagesJson:
        props.data.scheduleQcTabContent.AllImagesCount.strMissingImagesJson,
    };
    this.handlePageCountChange = this.handlePageCountChange.bind(this);
    this.selectMultipleOption = this.selectMultipleOption.bind(this);
    this.handleAssignedClientSwitch =
      this.handleAssignedClientSwitch.bind(this);
    this.handleMissingImageSwitch = this.handleMissingImageSwitch.bind(this);
    this.handleAssignedChannelSwitch =
      this.handleAssignedChannelSwitch.bind(this);
  }

  componentDidMount() {
    this.script = document.createElement("script");
    this.script.src = "https://apis.google.com/js/api.js";
    this.script.async = true;
    document.body.appendChild(this.script);
    if (
      this.props.data.status === "8" &&
      this.props.data.scheduleQcTabContent.bindEditorByLoc.options.length === 0
    ) {
      this.setState({ isSelectAll: false });
    }
    sessionStorage.setItem("QCActionStatus", this.props.data.status);
    if (this.props.data.isFirstTime) {
      sessionStorage.setItem("firstTab", "1");
      // this.applyFilters();
    }

    this.subscription = SharedService.getIsHelpScreenActive().subscribe(
      (data) => {
        this.setState({ isHelpScreenActive: data.isHelpScreenActive });
      }
    );
  }
  componentWillUnmount()
  {
    this.subscription.unsubscribe();
    window.removeEventListener("scroll",this.scrollfunction);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.data.scheduleQcTabContent.bindEditorByLoc.options !==
      prevProps.data.scheduleQcTabContent.bindEditorByLoc.options
    ) {
      this.setState({ editorRevertedBindValues: [] });
    }
    if (prevProps.data.callApplyFilter !== this.props.data.callApplyFilter) {
      if (this.props.data.callApplyFilter) {
        this.applyFilters();
      }
    }
  }

  selectMultipleOption(value, name) {
    switch (value) {
      case "Editor Location":
        const locIndex = [];
        name.map((i) =>
          locIndex.push({
            id: i.id,
            name: i.name,
          })
        );
        this.props.populateEditor({ locIndex, stateData: this.props.data });
        this.setState({ arrayValLoc: name, locIndex, arrayValEditor: [] });
        break;
      case "Editor":
        this.setState({ arrayValEditor: name });
        break;
      case "Source":
        this.setState({ arrayValSource: name });
        break;
      case "Channel":
        if (name.length <= 40) {
          this.setState({ arrayValChannel: name, ChannelRequired: false });
        } else {
          this.alertMessage("User can select maximum 40 channels");
        }
        break;
      case "Content Type":
        this.setState({ arrayValContentType: name });
        break;

      case "Image Type":
        let CTDisabled = false;
        if (name.length > 1) {
          CTDisabled = true;
          this.resetContentType();
        }
        this.setState({
          arrayValImageType: name,
          ImageTypeRequired: false,
          contentTypeDisabled: CTDisabled,
        });
        break;

      case "Channel Genre":
        const channelGenreIndex = [];
        name.map(i => {
          channelGenreIndex.push({
            id: i.id,
            name: i.name
          })
        })
        let arrayValGroup = this.state.arrayValGroup
        document.querySelector(".input-custom-channel").value = null;
        this.props.populateChannelWRTChannelGenre({
          arrayValGroup,
          channelGenreIndex,
          stateData: this.props.data
        })
        this.setState({ 
          arrayValChannelGenre: name,
          arrayValChannel: [],
          filteredChannelData: 0,
          notFoundArray: [],   
          })
        break;

      case "Genre":
        const genreIndex = [];
        name.map((i) =>
          genreIndex.push({
            id: i.id,
            name: i.name,
          })
        );
        this.props.populateSubGenre({ genreIndex, stateData: this.props.data });
        this.setState({
          arrayValGenre: name,
          genreIndex,
          arrayValSubGenre: [],
          listSubGenre: [],
          bindSubgenre: [],
        });
        break;
      case "Sub Genre":
        this.setState({ arrayValSubGenre: name });
        break;
      case "Aspect Ratio":
        const ratioIndex = [];
        name.map((i) =>
          ratioIndex.push({
            id: i.id,
            name: i.name,
          })
        );
        this.props.populateImgSize({ ratioIndex, stateData: this.props.data });
        this.setState({
          arrayValRatio: name,
          ratioIndex,
          arrayValImgSize: [],
          listImgSize: [],
          bindImgSize: [],
          ImgRatioRequired: false,
        });
        break;

      case "Image Size":
        this.setState({ arrayValImgSize: name, ImgSizeRequired: false });
        break;

      case "Group":
        const groupIndex = [];
        groupIndex.push({
          id: name.id,
          name: name.name,
        });
        document.querySelector(".input-custom-channel").value = null;
        this.props.populateChannelLangChannelGenre({
          groupIndex,
          stateData: this.props.data,
        });

        this.setState({
          arrayValGroup: name,
          groupIndex,
          arrayValChannel: [],
          filteredChannelData: 0,
          arrayValAiringLang: [],
          arrayValChannelGenre: [],
          GroupRequired: false,
          notFoundArray: [],
        });
        break;

      case "Airing Language":
        this.setState({ arrayValAiringLang: name, AiringLangRequired: false });
        break;
      case "Assigned Client":
        if (Object.keys(name).length > 0)
          this.setState({
            arrayValAssignedClient: name,
            DisableAssignedClient: false,
          });
        else this.setState({ arrayValAssignedClient: name });
        break;
      case "Assigned Channel":
        this.setState({ arrayValAssignedChannel: name });
        break;
      case "QC Status":
        this.setState({ arrayValAction: name });
        break;

      default:
        break;
    }
  }

  handleAssignedClientSwitch(AssignedClientSwitch) {
    this.setState({ AssignedClientSwitch });
  }

  handleMissingImageSwitch(MissingImagesSwitch) {
    this.setState({ MissingImagesSwitch });
  }

  handleAssignedChannelSwitch(AssignedChannelSwitch) {
    this.setState({ AssignedChannelSwitch });
  }

  onApplyDateAiring = (e, picker) => {
    let displayDate = `${moment(picker.startDate).format(
      "DD/MM/YYYY"
    )} - ${moment(picker.endDate).format("DD/MM/YYYY")}`;
    this.setState({
      displayAiringDate: displayDate,
      AiringDateRequired: false,
      startAiringDate: moment(picker.startDate).format("MM/DD/YYYY"),
      endAiringDate: moment(picker.endDate).format("MM/DD/YYYY"),
    });
  };

  onCancelAiring = (e) => {
    this.setState({
      displayAiringDate: "",
      startAiringDate: moment(),
      endAiringDate: moment(),
    });
    this.props.data.scheduleQcTabContent.displayAiringDate = "";
    this.props.airingreset(this.state);
  };
  onResetFilter = (FilterType, e) => {
    if (FilterType === "Channel") {
      this.setState({ arrayValChannel: [], filteredChannelData: 0 });
      this.props.resetFilters({ stateData: this.state, type: "Channel" });
    } else if (FilterType === "ChannelSearch") {
      document.querySelector(".input-custom-channel").value = null;
      this.setState({
        filterChannelArr: [],
        notFoundArray: [],
        filteredChannelData: 0,
        arrayValChannel: [],
      });
      this.props.resetFilters({ stateData: this.state, type: "Channel" });
    } else if (FilterType === "Language") {
      this.setState({ arrayValAiringLang: [] });
      this.props.resetFilters({ stateData: this.state, type: "Language" });
    } else if (FilterType === "Assigned Client") {
      this.setState({
        arrayValAssignedClient: null,
        DisableAssignedClient: true,
        AssignedClientSwitch: true,
      });
      this.props.resetFilters({
        stateData: this.state,
        type: "Assigned Client",
      });
    } else if (FilterType === "Content Type") {
      this.resetContentType();
    }
  };
  getMatchingIndex(value, arr) {
    for (
      let i = 0, counter = 0;
      i < arr.length && counter < this.state.arrayValChannel.length;
      i++
    ) {
      // JSON.stringify(arr[i][Object.keys(arr[i])[0]]) === JSON.stringify(value[Object.keys(value)[0]])
      if (arr[i].strChannelID === value.strChannelID) {
        counter++;
        return i;
      }
    }
    return -1;
  }
  resetContentType() {
    this.setState({
      arrayValContentType: null,
    });
    this.props.resetFilters({ stateData: this.state, type: "Content Type" });
  }

  alertMessage = (msg) => {
    Alert.error(
      '<div><i class="fa fa-times notification" aria-hidden="true"></i>' +
      msg +
      "</div>",
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 3000,
      }
    );
  };

  MandatoryFields = () => {
    if (this.state.displayAiringDate.length <= 0) {
      this.setState({ AiringDateRequired: true });
    }
    if (this.state.arrayValImageType.length === 0) {
      this.setState({ ImageTypeRequired: true });
    }
    if (this.state.arrayValGroup === null) {
      this.setState({ GroupRequired: true });
    }
    if (this.state.arrayValAiringLang.length === 0) {
      this.setState({ AiringLangRequired: true });
    }
    if (this.state.arrayValChannel.length === 0) {
      this.setState({ ChannelRequired: true });
    }
    if (this.state.arrayValRatio.length === 0) {
      this.setState({ ImgRatioRequired: true });
    }
    if (this.state.arrayValImgSize.length === 0) {
      this.setState({ ImgSizeRequired: true });
    }
  };

  validateFunc = () => {
    if (
      !this.state.AiringDateRequired &&
      !this.state.ImageTypeRequired &&
      !this.state.GroupRequired &&
      !this.state.AiringLangRequired &&
      !this.state.ChannelRequired &&
      !this.state.ImgRatioRequired &&
      !this.state.ImgSizeRequired &&
      this.state.displayAiringDate.length > 0
    ) {
      return true;
    }
  };

  applyFilters = (e) => {
    if (
      !this.state.isHelpScreenActive &&
      !this.state.isConfirmActive &&
      !this.isDropDownActive()
    ) {
      let arrayValLoc,
        arrayValEditor,
        arrayValImageType,
        arrayValSource,
        arrayValChannel,
        arrayValContentType,
        arrayValGenre,
        arrayValChannelGenre,
        arrayValSubGenre,
        arrayValRatio,
        displayAiringDate,
        arrayValImageSize,
        arrayValGroup,
        arrayValAction,
        arrayValAiringLang,
        arrayValAssignedClient,
        arrayValAssignedChannel;
      if (this.props.data.first < 2) {
        arrayValLoc = this.state.arrayValLoc;
        arrayValEditor = this.state.arrayValEditor;
        arrayValImageType = this.state.arrayValImageType;
        arrayValSource = this.state.arrayValSource;
        arrayValChannel = this.state.arrayValChannel;
        arrayValChannelGenre=this.state.arrayValChannelGenre;
        arrayValContentType = this.state.arrayValContentType;
        arrayValGenre = this.state.arrayValGenre;
        arrayValSubGenre = this.state.arrayValSubGenre;
        arrayValRatio = this.state.arrayValRatio;
        displayAiringDate = this.state.displayAiringDate;
        arrayValImageSize = this.state.arrayValImgSize;
        arrayValGroup = this.state.arrayValGroup;
        arrayValAiringLang = this.state.arrayValAiringLang;
        arrayValAssignedClient = this.state.arrayValAssignedClient;
        arrayValAssignedChannel = this.state.arrayValAssignedChannel;
        arrayValAction = this.state.arrayValAction;
      } else {
        arrayValLoc =
          this.props.data.scheduleQcTabContent.bindLocation.selectedValue
            .length > 0
            ? this.props.data.scheduleQcTabContent.bindLocation.selectedValue
            : this.state.arrayValLoc;
        arrayValEditor =
          this.props.data.scheduleQcTabContent.bindEditorByLoc.selectedValue
            .length > 0
            ? this.props.data.scheduleQcTabContent.bindEditorByLoc.selectedValue
            : this.state.arrayValEditor;
        arrayValImageType =
          this.props.data.scheduleQcTabContent.bindImageType.selectedValue
            .length > 0
            ? this.props.data.scheduleQcTabContent.bindImageType.selectedValue
            : this.state.arrayValImageType;
        arrayValSource =
          this.props.data.scheduleQcTabContent.bindSource.selectedValue.length >
            0
            ? this.props.data.scheduleQcTabContent.bindSource.selectedValue
            : this.state.arrayValSource;
        arrayValChannel =
          this.props.data.scheduleQcTabContent.bindChannel.selectedValue
            .length > 0
            ? this.props.data.scheduleQcTabContent.bindChannel.selectedValue
            : this.state.arrayValChannel;

        arrayValContentType =
          this.props.data.scheduleQcTabContent.bindContentType.selectedValue
            .length > 0
            ? this.props.data.scheduleQcTabContent.bindContentType.selectedValue
            : this.state.arrayValContentType;
        arrayValGenre =
          this.props.data.scheduleQcTabContent.bindGenre.selectedValue.length >
            0
            ? this.props.data.scheduleQcTabContent.bindGenre.selectedValue
            : this.state.arrayValGenre;
        arrayValSubGenre =
          this.props.data.scheduleQcTabContent.bindSubgenre.selectedValue
            .length > 0
            ? this.props.data.scheduleQcTabContent.bindSubgenre.selectedValue
            : this.state.arrayValSubGenre;
        arrayValRatio =
          this.props.data.scheduleQcTabContent.bindRatio.selectedValue.length >
            0
            ? this.props.data.scheduleQcTabContent.bindRatio.selectedValue
            : this.state.arrayValRatio;

        displayAiringDate =
          this.props.data.scheduleQcTabContent.displayAiringDate.length > 0
            ? this.props.data.scheduleQcTabContent.displayAiringDate
            : this.state.displayAiringDate;
        arrayValImageSize =
          this.props.data.scheduleQcTabContent.bindImgSize.selectedValue
            .length > 0
            ? this.props.data.scheduleQcTabContent.bindImgSize.selectedValue
            : this.state.arrayValImgSize;
        arrayValGroup =
          this.props.data.scheduleQcTabContent.bindGroup.selectedValue.length >
            0
            ? this.props.data.scheduleQcTabContent.bindGroup.selectedValue
            : this.state.arrayValGroup;
        arrayValAiringLang =
          this.props.data.scheduleQcTabContent.bindAiringLang.selectedValue
            .length > 0
            ? this.props.data.scheduleQcTabContent.bindAiringLang.selectedValue
            : this.state.arrayValAiringLang;
        arrayValAssignedClient =
          this.props.data.scheduleQcTabContent.bindAssignedClient.selectedValue
            .length > 0
            ? this.props.data.scheduleQcTabContent.bindAssignedClient
              .selectedValue
            : this.state.arrayValAssignedClient;
        arrayValAssignedChannel =
          this.props.data.scheduleQcTabContent.bindAssignedChannel.selectedValue
            .length > 0
            ? this.props.data.scheduleQcTabContent.bindAssignedChannel
              .selectedValue
            : this.state.arrayValAssignedChannel;
        arrayValAction =
          this.props.data.scheduleQcTabContent.bindAction.selectedValue.length >
            0
            ? this.props.data.scheduleQcTabContent.bindAction.selectedValue
            : this.state.arrayValAction;
      }

      this.MandatoryFields();

      let ratioExist = true;

      let ratiomsg = [];
      arrayValRatio.forEach((r) => {
        let exists = false;
        arrayValImageSize.forEach((s) => {
          exists =
            this.props.data.scheduleQcTabContent.bindImgSize.options.some(
              (resp) =>
                resp.intImageSizeID === s.id &&
                (exists || resp.intRatioID === r.id)
            );
        });
        if (!exists) {
          ratiomsg.push(r.name);
        }
        ratioExist = ratioExist && exists;
      });
      if (!ratioExist) {
        this.alertMessage(
          "Please select Image size belonging to the Aspect ratio " +
          [ratiomsg.slice(0, -1).join(", "), ratiomsg.slice(-1)[0]].join(
            ratiomsg.length < 2 ? "" : " and "
          )
        );
        return;
      }

      if (
        arrayValImageType.length === 1 &&
        arrayValImageType[0].name.toLowerCase() === "episode" &&
        arrayValContentType &&
        arrayValContentType.id !== undefined &&
        arrayValContentType.name.toLowerCase() === "official poster"
      ) {
        this.alertMessage("Cannot select Official Poster for Episodic image!");
        return;
      }
      let editorIndex = [];
      arrayValEditor.map((i) => editorIndex.push(i.id));
      let sourceIndex = [];
      arrayValSource.map((i) => sourceIndex.push(i.id));
      let channelIndex = [];
      arrayValChannel.map((i) => channelIndex.push(i.id));
      let imagetypeIndex = [];
      arrayValImageType.map((i) => imagetypeIndex.push(i.id));
      let contentIndex = [];
      if (arrayValContentType && arrayValContentType.id !== undefined) {
        contentIndex.push(arrayValContentType.id);
      }
      let genreIndex = [];
      arrayValGenre.map((i) => genreIndex.push(i.id));
      let subgenreIndex = [];
      arrayValSubGenre.map((i) => subgenreIndex.push(i.id));
      let ratioIndex = [];
      arrayValRatio.map((i) => ratioIndex.push(i.id));
      let imgSizeIndex = [];
      arrayValImageSize.map((i) => imgSizeIndex.push(i.id));
      let groupIndex = [];
      if (arrayValGroup && arrayValGroup.id !== undefined) {
        groupIndex.push(arrayValGroup.id);
      }
      let airinglangIndex = [];
      arrayValAiringLang.map((i) => airinglangIndex.push(i.id));
      let assignedclientIndex = [];
      if (arrayValAssignedClient && arrayValAssignedClient.id !== undefined) {
        assignedclientIndex.push(arrayValAssignedClient.id);
      }
      let assignedchannelIndex = [];
      arrayValAssignedChannel.map((i) => assignedchannelIndex.push(i.id));
      let qcactionIndex = [];
      arrayValAction.map((i) => qcactionIndex.push(i.id));
      this.setState({
        arrayValImageType: arrayValImageType,
        arrayValSource: arrayValSource,
        arrayValChannel: arrayValChannel,
        arrayValChannelGenre: arrayValChannelGenre,
        arrayValContentType: arrayValContentType,
        arrayValGenre: arrayValGenre,
        arrayValSubGenre: arrayValSubGenre,
        arrayValRatio: arrayValRatio,
        displayAiringDate: displayAiringDate,
        isHelpScreenActive: false,
        isConfirmActive: false,
        arrayValLoc: arrayValLoc,
        arrayValEditor: arrayValEditor,
        arrayValImgSize: arrayValImageSize,
        arrayValGroup: arrayValGroup,
        arrayValAction: arrayValAction,
        arrayValAiringLang: arrayValAiringLang,
        arrayValAssignedClient: arrayValAssignedClient,
        arrayValAssignedChannel: arrayValAssignedChannel,
      });

      let pageSizeTemp1, pageSizeTemp2;
      let currentPageTemp1, currentPageTemp2;

      if (this.props.data.status === "1") {
        pageSizeTemp1 =
          this.props.data.first < 2
            ? 25
            : this.props.data.scheduleQcTabContent.pageSize;
        currentPageTemp1 =
          this.props.data.first < 2
            ? 1
            : this.props.data.scheduleQcTabContent.currentPage;
        this.setState({
          pageSize: pageSizeTemp1,
          currentPage: currentPageTemp1,
        });
      } else if (this.props.data.status !== "1") {
        pageSizeTemp2 =
          this.props.data.first < 2
            ? 25
            : this.props.data.scheduleQcTabContent.pageSize;
        currentPageTemp2 =
          this.props.data.first < 2
            ? 0
            : this.props.data.scheduleQcTabContent.currentPage;
        this.setState({
          pageSize: pageSizeTemp2,
          currentPage: currentPageTemp2,
        });
      }
      let statusID = [];
      statusID = Array.from(String(this.props.data.status), Number);

      this.setState({ loading: "true", first: 0 });
      if (this.validateFunc()) {
        //(editorIndex.length >= 0) { //(this.validateFunc()) {
        if (this.props.data.status === "1") {
          axios
            .post(
              `${config.apiUrl + config.qcApi}Image/GetScheduleGridImages/`,
              // {
              //     "intUserRoleID": localStorage.getItem("userRoleID"),
              //     "dtmAiringFromDate": "2021-04-14",//airingtempdate1, //"2016-01-01",
              //     "dtmAiringToDate": "2021-04-27",//airingtempdate2,//"2022-01-01",
              //     "lstAiringChannel": [
              //         "10000000000080000"
              //     ],
              //     //channelIndex,//["10000000058680000", "10000000066030000", "10000000000930000"],
              //     "lstAiringLanguage": [
              //         "10000000000030000",
              //         "10000000000040000",
              //         "10000000000080000",
              //         "10000000000090000",
              //         "10000000000140000",
              //         "10000000000160000",
              //         "10000000000370000",
              //         "10000000000490000",
              //         "10000000000610000",
              //         "10000000000640000",
              //         "10000000000680000"
              //       ],
              //     "lstImageType": [

              //         327
              //     ],
              //     //imagetypeIndex,//imagetypeIndex, //[326],
              //     "lstRatio": [3],//ratioIndex, //[1],
              //     "lstImgSize": [30],//imgSizeIndex, //[16],
              //     "lstSourceID": sourceIndex,//[],
              //     "intContentID": contentIndex[0] || -1,
              //     "strAssignedClient": assignedclientIndex[0] || "0",
              //     "lstGenre": genreIndex, //[],
              //     "lstSubGenre": subgenreIndex,//[],
              //     "lstEditorID": editorIndex,//[],
              //     "lstActionStatusID": qcactionIndex, //[],
              //     "blnMissingImage": true,//this.state.MissingImagesSwitch, //true,
              //     "blnOpenToAllClients": this.state.AssignedClientSwitch, //true,
              //     "blnOnlyAssignedChannel": this.state.AssignedChannelSwitch,
              //     "blnExpandedView": true//this.state.moreFilter //true
              // })
              {
                intUserRoleID: localStorage.getItem("userRoleID"),
                dtmAiringFromDate: moment(this.state.startAiringDate).format(
                  "YYYY-MM-DD"
                ), //"2016-01-01",
                dtmAiringToDate: moment(this.state.endAiringDate).format(
                  "YYYY-MM-DD"
                ), //"2022-01-01",
                lstAiringChannel: channelIndex,
                lstAiringLanguage: airinglangIndex, //["10000000000140000", "10000000000040000"],
                lstImageType: imagetypeIndex, //imagetypeIndex, //[326],
                lstRatio: ratioIndex, //[1],
                lstImgSize: imgSizeIndex, //[16],
                lstSourceID: sourceIndex, //[],
                intContentID: contentIndex[0] || -1,
                strAssignedClient: assignedclientIndex[0] || "0",
                lstGenre: genreIndex, //[],
                lstSubGenre: subgenreIndex, //[],
                lstEditorID: editorIndex, //[],
                lstActionStatusID: qcactionIndex, //[],
                blnMissingImage: this.state.MissingImagesSwitch, //true,
                blnOpenToAllClients: this.state.AssignedClientSwitch, //true,
                blnOnlyAssignedChannel: this.state.AssignedChannelSwitch,
                blnExpandedView: this.state.moreFilter, //true
              }
            )
            .then((response) => {
              if (
                response.status === 200 &&
                response.data &&
                response.data.objRespMessage
              ) {
                this.setState({
                  strMissingImagesJson:
                    response.data.objRespMessage.strMissingImagesJson,
                  filteredData: response.data.objRespMessage.lstImages,
                  loading: "false",
                  pageSize: pageSizeTemp1,
                  currentPage: currentPageTemp1,
                  showError: false,
                  AllImagesCount: response.data.objRespMessage,
                });
                this.props.applyFilter({
                  propsData: this.props.data,
                  stateData: this.state,
                });
                this.clickMoreFilterSQ();
              } else if (response.status === 204) {
                this.setState({
                  filteredData: [],
                  loading: "false",
                  showError: false,
                  AllImagesCount: [],
                });
                this.props.applyFilter({
                  propsData: this.props.data,
                  stateData: this.state,
                });
                this.clickMoreFilterSQ();
              } else {
                this.setState({
                  filteredData: [],
                  loading: "false",
                  showError: true,
                  AllImagesCount: [],
                });
              }
            })
            .catch((error) => {
              if (!error.response) {
                error = "Not able to communicate with the backend service !";
                console.log(error);
              } else {
                switch (error.response.status) {
                  case 404:
                    error =
                      "API Error - 404 received from the API\n\n API URL -" +
                      error.request.responseURL;
                    console.log(error);
                    break;

                  case 504:
                    error =
                      "API Error - 504 received from the API\n\n API URL -" +
                      error.request.responseURL;
                    console.log(error);
                    break;

                  default:
                    error =
                      "Unknown Error received from the API - Status " +
                      error.response.status;
                    console.log(error);
                    break;
                }
              }
              this.setState({
                filteredData: [],
                loading: "false",
                showError: true,
                AllImagesCount: [],
              });
            });
        } else {
          axios
            .post(
              `${config.apiUrl + config.qcApi}Image/GetScheduleTabularImages/`,
              // {
              //     "intUserRoleID": localStorage.getItem("userRoleID"),
              //     "dtmAiringFromDate": "2016-12-04",//airingtempdate1, //"2016-01-01",
              //     "dtmAiringToDate": "2020-12-04",//airingtempdate2,//"2022-01-01",
              //     "lstAiringChannel": [
              //         "10000000063070000",
              //         "10000000003660000",
              //         "10000000065200000",
              //         "10000000019520000",
              //         "10000000000080000"
              //     ],
              //     //channelIndex,//["10000000058680000", "10000000066030000", "10000000000930000"],
              //     "lstAiringLanguage": ["10000000000140000", "10000000000270000", "10000000000040000"],//airinglangIndex,//["10000000000140000", "10000000000040000"],
              //     "lstImageType": [
              //         326,
              //         327,
              //         328
              //     ],
              //     //imagetypeIndex,//imagetypeIndex, //[326],
              //     "lstRatio": [3, 2, 1],//ratioIndex, //[1],
              //     "lstImgSize": [30, 14, 15, 16, 17, 18, 19, 1],//imgSizeIndex, //[16],
              //     "lstSourceID": sourceIndex,//[],
              //     "intContentID": contentIndex[0] || -1,
              //     "strAssignedClient": assignedclientIndex[0] || "0",
              //     "lstGenre": genreIndex, //[],
              //     "lstSubGenre": subgenreIndex,//[],
              //     "lstEditorID": editorIndex,//[],
              //     "lstActionStatusID": statusID, //[],
              //     "blnMissingImage": true,//this.state.MissingImagesSwitch, //true,
              //     "blnOpenToAllClients": this.state.AssignedClientSwitch, //true,
              //     "blnOnlyAssignedChannel": this.state.AssignedChannelSwitch,
              //     "blnExpandedView": false//this.state.moreFilter //true
              // }
              {
                intUserRoleID: localStorage.getItem("userRoleID"),
                dtmAiringFromDate: moment(this.state.startAiringDate).format(
                  "YYYY-MM-DD"
                ), //"2016-01-01",
                dtmAiringToDate: moment(this.state.endAiringDate).format(
                  "YYYY-MM-DD"
                ), //"2022-01-01",
                lstAiringChannel: channelIndex,
                lstAiringLanguage: airinglangIndex, //airinglangIndex,//["10000000000140000", "10000000000040000"],
                lstImageType: imagetypeIndex,
                lstRatio: ratioIndex, //ratioIndex, //[1],
                lstImgSize: imgSizeIndex, //imgSizeIndex, //[16],
                lstSourceID: sourceIndex, //[],
                intContentID: contentIndex[0] || -1,
                strAssignedClient: assignedclientIndex[0] || "0",
                lstGenre: genreIndex, //[],
                lstSubGenre: subgenreIndex, //[],
                lstEditorID: editorIndex, //[],
                lstActionStatusID: statusID, //[],
                blnMissingImage: this.state.MissingImagesSwitch, //true,
                blnOpenToAllClients: this.state.AssignedClientSwitch, //true,
                blnOnlyAssignedChannel: this.state.AssignedChannelSwitch,
                blnExpandedView: this.state.moreFilter, //true
              }
            )
            .then((response) => {
              if (
                response.status === 200 &&
                response.data &&
                response.data.objRespMessage
              ) {
                this.setState({
                  filteredData: response.data.objRespMessage,
                  loading: "false",
                  showError: false,
                });
                this.props.applyFilter({
                  propsData: this.props.data,
                  stateData: this.state,
                });
                SharedService.setFilterDataForTabularView(
                  response.data.objRespMessage
                );
                this.clickMoreFilterSQ();
              } else if (response.status === 204) {
                this.setState({
                  filteredData: [],
                  loading: "false",
                  showError: false,
                });
                this.props.applyFilter({
                  propsData: this.props.data,
                  stateData: this.state,
                });
                SharedService.setFilterDataForTabularView(
                  response.data.objRespMessage
                );
                this.clickMoreFilterSQ();
              } else {
                this.setState({
                  filteredData: [],
                  loading: "false",
                  showError: true,
                });
              }
            })
            .catch((error) => {
              if (!error.response) {
                error = "Not able to communicate with the backend service !";
                console.log(error);
              } else {
                switch (error.response.status) {
                  case 404:
                    error =
                      "API Error - 404 received from the API\n\n API URL -" +
                      error.request.responseURL;
                    console.log(error);
                    break;

                  case 504:
                    error =
                      "API Error - 504 received from the API\n\n API URL -" +
                      error.request.responseURL;
                    console.log(error);
                    break;

                  default:
                    error =
                      "Unknown Error received from the API - Status " +
                      error.response.status;
                    console.log(error);
                    break;
                }
              }
              this.setState({
                filteredData: [],
                loading: "false",
                showError: true,
                AllImagesCount: [],
              });
            });
        }
      } else {
        this.setState({
          filteredData: [],
          loading: "false",
          showError: false,
          AllImagesCount: [],
        });
      }
    }
  };
  scrollfunction = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      this.setState({ isShowTopBtn: true })
    }
    else {
      this.setState({ isShowTopBtn: false })
    }
  }
  topFunction=()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  }
  handleClick() {
    this.applyFilters();
  }

  handlePageSizeChange = (e, currentPage) => {
    let pagesize = Number(e.target.value);
    sessionStorage.setItem("QCPageSize", pagesize);
    sessionStorage.setItem("QCCurrentPage", currentPage);
    this.setState({ pageSize: pagesize, currentPage: 1 });
    SharedService.setFilterData({
      action: "paginationCount",
      status: "1",
      pageSize: pagesize,
      currentPage: 1,
      worklisttype: "1",
    });
  };

  handlePageCountChange = (pagesize, currentPage) => {
    sessionStorage.setItem("QCPageSize", pagesize);
    sessionStorage.setItem("QCCurrentPage", currentPage);
    let currentPageState = currentPage;
    this.setState({ currentPage: currentPageState });
    SharedService.setFilterData({
      action: "paginationCount",
      status: "1",
      pageSize: pagesize,
      currentPage: currentPageState,
      worklisttype: "1",
    });
  };

  makeApiCall = () => {
    var spreadsheetBody = {};
    let data = JSON.parse(this.state.strMissingImagesJson);
    let jsonParsedData = JSON.parse(data[0]["JSON"]);
    let fieldnames = Object.keys(jsonParsedData[0]);
    let arrData = [fieldnames];
    jsonParsedData.forEach((objectToArrayData) => {
      arrData.push(Object.values(objectToArrayData));
    });
    var request = window.gapi.client.sheets.spreadsheets.create(
      {},
      spreadsheetBody
    );
    request.then(
      (response) => {
        this.updateApiCall(response.result, arrData);
      },
      (reason) => {
        console.error("error: " + reason.result.error.message);
      }
    );
  };
  updateApiCall = (spreadsheetData, data) => {
    var params = {
      spreadsheetId: spreadsheetData.spreadsheetId, // TODO: Update placeholder value.
      range: "A1", // TODO: Update placeholder value.
      valueInputOption: "RAW",
    };
    var valueRangeBody = {
      values: data,
    };

    var request = window.gapi.client.sheets.spreadsheets.values.update(
      params,
      valueRangeBody
    );
    request.then(
      (response) => {
        window.gapi.client.sheets.spreadsheets
          .batchUpdate({
            spreadsheetId: spreadsheetData.spreadsheetId,
            resource: {
              requests: [
                {
                  repeatCell: {
                    fields: "userEnteredFormat(textFormat)",
                    range: {
                      startRowIndex: 0,
                      endRowIndex: 1,
                    },
                    cell: {
                      userEnteredFormat: {
                        textFormat: {
                          bold: true,
                        },
                      },
                    },
                  },
                },
                {
                  updateSpreadsheetProperties: {
                    properties: {
                      title: `Missing_Image_Group_${this.state.arrayValGroup.name
                        }_${this.state.displayAiringDate
                          .replaceAll("/", "")
                          .replaceAll(" - ", "_")}`,
                    },
                    fields: "title",
                  },
                },
              ],
            },
          })
          .then(function (response) {
            window.open(spreadsheetData.spreadsheetUrl, "_black");
          });
      },
      (reason) => {
        console.error("error: " + reason.result.error.message);
      }
    );
  };

  initClient = () => {
    window.gapi.client
      .init({
        clientId: "237066764951-39tl0otv4asdbhdeglji8854dtemru72.apps.googleusercontent.com",
        scope: "https://www.googleapis.com/auth/drive",
        discoveryDocs: [
          "https://sheets.googleapis.com/$discovery/rest?version=v4",
        ],
      })
      .then(() => {
        window.gapi.auth2
          .getAuthInstance()
          .isSignedIn.listen(this.updateSignInStatus);
        this.updateSignInStatus(
          window.gapi.auth2.getAuthInstance().isSignedIn.get()
        );
      });
  };

  updateSignInStatus = (isSignedIn) => {
    if (isSignedIn) {
      this.makeApiCall();
    } else {
      this.handleSignInClick();
    }
  };

  handleSignInClick = (event) => {
    window.gapi.auth2.getAuthInstance().signIn();
  };

  handleSignOutClick = (event) => {
    window.gapi.auth2.getAuthInstance().signOut();
  };

  downloadCSV = () => {
    let data = JSON.parse(this.state.strMissingImagesJson);
    let jsonDataMissingImages = JSON.parse(data[0]["JSON"]);
    let fileName = `Missing_Image_Group_${this.state.arrayValGroup.name
      }_${this.state.displayAiringDate
        .replaceAll("/", "")
        .replaceAll(" - ", "_")}`;
    const exportType = "xls";
    exportFromJson({ data: jsonDataMissingImages, fileName, exportType });
    //window.gapi.load("client:auth2", this.initClient);
  };

  applyReset() {
    if (
      !this.state.isHelpScreenActive &&
      !this.state.isConfirmActive &&
      !this.isDropDownActive()
    ) {
      this.setState({
        arrayValLoc: [],
        arrayValEditor: [],
        arrayValSource: [],
        arrayValChannel: [],
        arrayValImageType: [],
        arrayValContentType: null,
        arrayValGenre: [],
        arrayValChannelGenre: [],
        arrayValSubGenre: [],
        arrayValRatio: [],
        filteredData: [],
        startAiringDate: moment(),
        endAiringDate: moment(),
        displayAiringDate: "",
        arrayValImgSize: [],
        arrayValGroup: null,
        arrayValAiringLang: [],
        arrayValAssignedClient: null,
        arrayValAssignedChannel: [],
        arrayValAction: [],
        ImageTypeRequired: false,
        GroupRequired: false,
        AiringLangRequired: false,
        ChannelRequired: false,
        ImgRatioRequired: false,
        ImgSizeRequired: false,
        AiringDateRequired: false,
        AssignedClientSwitch: true,
        DisableAssignedClient: true,
        MissingImagesSwitch: false,
        contentTypeDisabled: false,
        AssignedChannelSwitch: false,
        AllImagesCount: [],
        filteredChannelData: 0,
        notFoundArray: [],
      });
      document.querySelector(".input-custom-channel").value = "";
      this.props.applyReset({ stateData: this.state });
    }
  }

  stopShortCut = (tmpIsConfirmActive) => {
    this.setState({ isConfirmActive: tmpIsConfirmActive });
  };

  isDropOpen(val, drpDwnName) {
    if (drpDwnName === "Editor Location") {
      this.setState({ isLocationDropdown: val });
    } else if (drpDwnName === "Editor") {
      this.setState({ isEditorDropdown: val });
    } else if (drpDwnName === "Source") {
      this.setState({ isSourceDropdown: val });
    } else if (drpDwnName === "Channel") {
      if (!val) {
        let tempArrBeforeSort = [];
        let tempProps =
          this.props.data.scheduleQcTabContent.bindChannel.options;
        this.state.arrayValChannel.forEach((valChannel) => {
          let tempVar = {
            strChannelID: valChannel.id,
            strChannelName: `${valChannel.name}`,
          };
          tempArrBeforeSort.push(tempVar);
          let intVal = this.getMatchingIndex(tempVar, tempProps);
          tempProps.splice(intVal, 1);
        });
        tempArrBeforeSort.forEach((tempArr) => {
          tempProps.unshift(tempArr);
        });
      }
      this.setState({ isChannelDropdown: val });
    } else if (drpDwnName === "Content Type") {
      this.setState({ isContenTypeDropdown: val });
    } else if (drpDwnName === "Image Type") {
      this.setState({ isImgTypDropdown: val });
    } else if (drpDwnName === "Genre") {
      this.setState({ isGenreDropdown: val });
    } else if (drpDwnName === "Sub Genre") {
      this.setState({ isSubGenreDropdown: val });
    } else if (drpDwnName === "Aspect Ratio") {
      this.setState({ isRationDropdown: val });
    } else if (drpDwnName === "Image Size") {
      this.setState({ isImgSizeDropdown: val });
    } else if (drpDwnName === "Group") {
      this.setState({ isGroupDropdown: val });
    } else if (drpDwnName === "Airing Language") {
      this.setState({ isAiringLangDropdown: val });
    } else if (drpDwnName === "Assigned Client") {
      this.setState({ isAssignedClientDropdown: val });
    } else if (drpDwnName === "Assigned Channel") {
      this.setState({ isAssignedChannelDropdown: val });
    } else if (drpDwnName === "QC Status") {
      this.setState({ isQCActionDropdown: val });
    }
  }
  getFilterValue = () => {
    let customClass = document.querySelector(".input-custom-channel").value;
    this.setState({
      arrayValChannel: [],
      filteredChannelData: customClass.length,
    });
    let channel = this.props.data.scheduleQcTabContent.bindChannel.options;
    let arr = customClass.toLowerCase().split(",");

    let filteredArray = [];
    let notFoundArray = [];
    let chnlIndex = [];
    arr.forEach((search) => {
      let isRepeat = 0;
      let isMatch = 0;
      channel.forEach((channellist) => {
        if (channellist["strChannelName"].toLowerCase() === search.trim()) {
          if (!filteredArray.includes(channellist)) {
            filteredArray.push(channellist);
            isMatch = 1;
          } else {
            isRepeat = 1;
          }
        }
      });
      if (!isMatch && !isRepeat) {
        if (!notFoundArray.includes(search)) notFoundArray.push(search);
      }
    });
    if (filteredArray.length > 40) {
      this.alertMessage("Maximum 40 channels allowed");
      filteredArray = [];
    }
    filteredArray = filteredArray
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort();
    filteredArray.map((i) =>
      chnlIndex.push({
        id: i.strChannelID,
        name: i.strChannelName,
      })
    );
    this.setState({
      filterChannelArr: filteredArray,
      notFoundArray: notFoundArray,
      arrayValChannel: chnlIndex,
    });
  };

  isDropDownActive = () => {
    let retVal = false;
    if (
      this.state.isEditorDropdown ||
      this.state.isLocationDropdown ||
      this.state.isSourceDropdown ||
      this.state.isChannelDropdown ||
      this.state.isContenTypeDropdown ||
      this.state.isImgTypDropdown ||
      this.state.isGenreDropdown ||
      this.state.isSubGenreDropdown ||
      this.state.isRationDropdown ||
      this.state.isImgSizeDropdown ||
      this.state.isGroupDropdown ||
      this.state.isAiringLangDropdown ||
      this.state.isAssignedClientDropdown ||
      this.state.isAssignedChannelDropdown ||
      this.state.isQCActionDropdown ||
      this.state.customFilter
    ) {
      retVal = true;
    }
    return retVal;
  };

  daterangeclicked = (e) => {
    document.getElementById("datePicker").readOnly = false;
  };

  clickMoreFilterSQ = () => {
    if (!this.state.moreFilter) {
      this.setState({
        arrayValLoc: [],
        arrayValEditor: [],
        arrayValSource: [],
        arrayValContentType: null,
        arrayValGenre: [],
        arrayValSubGenre: [],
        arrayValAssignedClient: null,
        arrayValAssignedChannel: [],
        arrayValAction: [],
        AssignedClientSwitch: true,
        DisableAssignedClient: true,
        MissingImagesSwitch: false,
        AssignedChannelSwitch: false,
        filterChannelArr: [],
        notFoundArray: [],
        contentTypeDisabled:
          this.state.arrayValImageType.length > 1 ? true : false,
      });
      this.props.applyResetOnMoreFilterButton({ stateData: this.state });
    }
  };

  clickMoreFilter = () => {
    this.setState({ moreFilter: this.state.moreFilter ? false : true });
  };

  render() {
    if (
      sessionStorage.getItem("isEditorReverted") === "0" &&
      sessionStorage.getItem("firstTab") === "1"
    ) {
      sessionStorage.setItem("isActiveTab", this.props.data.status);
    } else if (this.props.data.status !== "8") {
      sessionStorage.setItem("isEditorReverted", "0");
    } else if (sessionStorage.getItem("isEditorReverted") === "1") {
      sessionStorage.setItem("isActiveTab", "8");
    }

    const {
      bindLocation,
      bindEditorByLoc,
      bindContentType,
      bindGenre,
      bindSubgenre,
      bindRatio,
      bindImageType,
      bindChannelGenre,
      bindSource,
      bindChannel,
      bindImgSize,
      bindGroup,
      bindAiringLang,
      bindAssignedClient,
      bindAssignedChannel,
      bindAction,
    } = this.props.data.scheduleQcTabContent;
    let listLoc = [];
    bindLocation.options.map((location) =>
      listLoc.push({
        id: location.intOfficeLocID,
        name: location.strOfficeLocation,
      })
    );

    // let abc;
    // if (this.props.data.scheduleQcTabContent.bindEditorByLoc.selectedValue.length > 0) {
    //     abc = "true";
    // }

    let listEditor = [];
    bindEditorByLoc.options.map((editor) =>
      listEditor.push({ id: editor.intEditorId, name: editor.strEditorName })
    );

    //let bindEditorByLoc=this.props.data.scheduleQcTabContent.bindEditorByLoc.options;//
    //let bindEditorByLoc = this.state.editorRevertedBindValues.length > 0 ? this.state.editorRevertedBindValues : this.props.data.scheduleQcTabContent.bindEditorByLoc.options;
    //bindEditorByLoc.map((editor) => listEditor.push({ id: editor.intEditorId, name: editor.strEditorName }))

    let listSource = [];
    bindSource.options.map((source) =>
      listSource.push({ id: source.intSourceID, name: source.strSource })
    );

    let listChannel = [];

    if (this.state.filteredChannelData > 0) {
      this.state.filterChannelArr.map((Channel) =>
        listChannel.push({
          id: Channel.strChannelID,
          name: Channel.strChannelName,
        })
      );
    } else {
      bindChannel.options.map((channel) =>
        listChannel.push({
          id: channel.strChannelID,
          name: channel.strChannelName,
        })
      );
    }

    let listContentType = [];
    bindContentType.options.map((contenttype) =>
      listContentType.push({
        id: contenttype.lngContentTypeID,
        name: contenttype.strContentTypeName,
      })
    );

    let listImageType = [];
    bindImageType.options.map((imagetype) =>
      listImageType.push({
        id: imagetype.intImageTypeID,
        name: imagetype.strImageTypeName,
      })
    );

    let listChannelGenre = []
    bindChannelGenre.options.map((channelgenre) => {
      listChannelGenre.push({
        id: channelgenre.strChannelGenreID,
        name: channelgenre.strChannelGenreName
      })
    })

    let listGenre = [];
    bindGenre.options.map((genre) =>
      listGenre.push({ id: genre.strGenreID, name: genre.strGenreName })
    );

    let listSubGenre = [];
    bindSubgenre.options.map((subgenre) =>
      listSubGenre.push({
        id: subgenre.strSubGenreID,
        name: subgenre.strSubGenreName,
      })
    );

    let listRatio = [];
    bindRatio.options.map((ratio) =>
      listRatio.push({ id: ratio.intRatioID, name: ratio.strRatioName })
    );

    let listImgSize = [];
    bindImgSize.options.map((size) =>
      listImgSize.push({ id: size.intImageSizeID, name: size.strImgSizeName })
    );

    let listGroup = [];
    bindGroup.options.map((grp) =>
      listGroup.push({ id: grp.intGroupID, name: grp.strGroupName })
    );

    let listAiringLang = [];
    bindAiringLang.options.map((lang) =>
      listAiringLang.push({
        id: lang.strLanguageID,
        name: lang.strLanguageName,
      })
    );

    let listAssignedClient = [];
    bindAssignedClient.options.map((client) =>
      listAssignedClient.push({
        id: client.lngAssignedClientID,
        name: client.strAssignedClientName,
      })
    );

    let listAssignedChannel = [];
    bindAssignedChannel.options.map((channel) =>
      listAssignedChannel.push({
        id: channel.strChannelID,
        name: channel.strChannelName,
      })
    );

    let listQCAction = [];
    bindAction.options.map((action) =>
      listQCAction.push({ id: action.lngActionID, name: action.strActionName })
    );

    let gridSection;

    if (
      this.props.data.scheduleQcTabContent.loader === "false" &&
      this.state.loading === "false"
    ) {
      if (
        this.props.data.scheduleQcTabContent.filteredData.length > 0 &&
        this.props.data.status === "1"
      ) {
        gridSection = (
          <Gallery
            downloadCSV={this.downloadCSV}
            MissingImagesSwitch={this.state.MissingImagesSwitch}
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            filterParams={this.props.data.scheduleQcTabContent.filteredData}
            ImagesCount={this.props.data.scheduleQcTabContent.AllImagesCount}
            handlePageSizeChange={this.handlePageSizeChange}
            rejectionErrorList={
              this.props.data.scheduleQcTabContent.bindErrors.options
            }
            loader={this.state.loading}
            respData={this.props.applyFilter}
            handlePageCountChange={this.handlePageCountChange}
            status={this.props.data.status}
            stopShortCuts={this.stopShortCut}
            WorkListID="1"
            actionfilter={this.state.arrayValAction}
          />
        );
      } else if (
        this.props.data.scheduleQcTabContent.filteredData.length > 0 &&
        this.props.data.status !== "1"
      ) {
        gridSection = (
          <Tables
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            filter={this.props.data.scheduleQcTabContent.filteredData}
            status={this.props.data.status}
            stopShortCuts={this.stopShortCut}
            WorkListID="1"
          ></Tables>
        );
      } else if (
        this.props.data.scheduleQcTabContent.filteredData.length === 0
      ) {
        gridSection = (
          <div>
            <div
              className="tab-pane fade show active"
              id="qc-worklist"
              role="tabpanel"
              aria-labelledby="qc-tab"
            >
              <div className="row ml-0 mr-0">
                <div className="col-md-12">
                  <div
                    className="border pt-3 pb-3 pr-3 pl-3"
                    style={{ overflow: "hidden" }}
                  >
                    <div className="pull-left pt-3">
                      <h3 className="txt-orange fw-normal fs18">
                        0 Images Found{" "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else if (this.state.loading === "true") {
      gridSection = (
        <div id="loader-wrapper">
          <div id="loader"></div>
        </div>
      );
    }

    let filterIcon = "";
    let filterText = "";
    let filterBlockClass = "";
    if (this.state.moreFilter) {
      filterIcon = "fa fa-minus-circle";
      filterText = "Less Filters";
      filterBlockClass = "moreFilterBlock opened";
    } else {
      filterIcon = "fa fa-plus-circle";
      filterText = "More Filters";
      filterBlockClass = "moreFilterBlock";
    }
    window.addEventListener("scroll",this.scrollfunction)
    return (
      <div>
        <KeyboardEventHandler
          handleKeys={["Enter"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.applyFilters()}
        />
        <KeyboardEventHandler
          handleKeys={["r"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.applyReset()}
        />

        <div className="tab-content mt-3" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="qc-worklist"
            role="tabpanel"
            aria-labelledby="qc-tab"
          >
            <div className="row ml-0 mr-0">
              <div className="col">
                <div
                  style={
                    this.props.disabled
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                  className={this.props.customClass}
                >
                  <div className="form-group dropdown-select">
                    <label
                      htmlFor="field-1"
                      className="control-label fs11 pl-2 txt-blue"
                    >
                      {"Channel Search"}
                      <span className="txt-red"></span>
                    </label>
                    <input
                      className={"input-custom-channel"}
                      onChange={() => {
                        this.getFilterValue();
                      }}
                      autoComplete="off"
                      type="text"
                      onFocus={() => {
                        this.setState({ customFilter: true });
                      }}
                      onBlur={() => {
                        this.setState({ customFilter: false });
                      }}
                      name="filter"
                    ></input>
                    <i
                      className="fa fa-undo user-name icon-deactive airing-reset"
                      aria-hidden="true"
                      onClick={this.onResetFilter.bind(this, "ChannelSearch")}
                    ></i>
                    {this.state.notFoundArray.length > 0 &&
                      this.state.notFoundArray[0] !== "" ? (
                      <p className="control-label fs11 pl-2 txt-red">
                        Channel not found:{this.state.notFoundArray.join(",")}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row ml-0 mr-0">
              <div className="col-md-3">
                <div className="form-group dropdown-select">
                  <label
                    htmlFor="field-1"
                    className="control-label fs11 pl-2 txt-blue"
                  >
                    Airing From & To Date <span className="txt-red">*</span>
                  </label>
                  <DateRangePickerNew
                    id={"airingDate"}
                    autoApply
                    onApply={this.onApplyDateAiring}
                    onCancel={this.onCancelAiring}
                    minDate={moment().subtract(14, "day")}
                    maxDate={moment().add(35, "day")}
                    startDate={this.state.startAiringDate}
                    endDate={this.state.endAiringDate}
                  >
                    <input
                      readOnly
                      type="text"
                      className={
                        this.state.AiringDateRequired
                          ? "form__daterange form-control daterange hasDatepicker required"
                          : "form__daterange form-control daterange hasDatepicker"
                      }
                      id="datePicker1"
                      value={this.state.displayAiringDate}
                    />
                    <div className="cal-icon">
                      <i className="fa fa-calendar mr5"></i>
                    </div>
                  </DateRangePickerNew>
                  <i
                    className="fa fa-undo user-name icon-deactive airing-reset"
                    aria-hidden="true"
                    onClick={this.onCancelAiring}
                  ></i>
                </div>
              </div>
              <Dropdown
                label={"Group"}
                multiselect={false}
                validation={this.state.GroupRequired}
                selectedvalue={this.state.arrayValGroup}
                option={listGroup}
                change={this.selectMultipleOption.bind(this, "Group")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Group")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Group")}
              ></Dropdown>
              <Dropdown
                label={"ChannelGenre"}
                multiselect={true}
                validation={this.state.ChannelGenreRequired}
                selectedvalue={this.state.arrayValChannelGenre}
                option={listChannelGenre}
                change={this.selectMultipleOption.bind(this, "Channel Genre")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Channel Genre")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Channel Genre")}
              ></Dropdown>

              <Dropdown
                label={"Airing Language"}
                customClass={"contentTypeClass"}
                resetIcon={
                  <i
                    className="fa fa-undo user-name icon-deactive contentTypeReset"
                    aria-hidden="true"
                    onClick={this.onResetFilter.bind(this, "Language")}
                  ></i>
                }
                multiselect={true}
                validation={this.state.AiringLangRequired}
                selectedvalue={this.state.arrayValAiringLang}
                option={listAiringLang}
                change={this.selectMultipleOption.bind(this, "Airing Language")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(
                  this,
                  true,
                  "Airing Language"
                )}
                onDropdownClose={this.isDropOpen.bind(
                  this,
                  false,
                  "Airing Language"
                )}
                includeSelectAll={true}
              ></Dropdown>
            </div>
            <div className="row ml-0 mr-0">
              <Dropdown
                label={"Channel"}
                customClass={"contentTypeClass"}
                resetIcon={
                  <i
                    className="fa fa-undo user-name icon-deactive contentTypeReset"
                    aria-hidden="true"
                    onClick={this.onResetFilter.bind(this, "Channel")}
                  ></i>
                }
                multiselect={true}
                validation={this.state.ChannelRequired}
                includeSelectAll={listChannel.length < 40}
                selectedvalue={this.state.arrayValChannel}
                option={listChannel}
                change={this.selectMultipleOption.bind(this, "Channel")}
                filterFlag={!this.state.filteredChannelData > 0}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Channel")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Channel")}
              ></Dropdown>
              {/* Editorvalidation={this.state.EditorRequired} */}
              <Dropdown
                label={"Image Type"}
                multiselect={true}
                validation={this.state.ImageTypeRequired}
                selectedvalue={this.state.arrayValImageType}
                option={listImageType}
                change={this.selectMultipleOption.bind(this, "Image Type")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Image Type")}
                onDropdownClose={this.isDropOpen.bind(
                  this,
                  false,
                  "Image Type"
                )}
              ></Dropdown>
              <Dropdown
                label={"Aspect Ratio"}
                multiselect={true}
                validation={this.state.ImgRatioRequired}
                selectedvalue={this.state.arrayValRatio}
                option={listRatio}
                change={this.selectMultipleOption.bind(this, "Aspect Ratio")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(
                  this,
                  true,
                  "Aspect Ratio"
                )}
                onDropdownClose={this.isDropOpen.bind(
                  this,
                  false,
                  "Aspect Ratio"
                )}
                includeSelectAll={true}
              ></Dropdown>
              <Dropdown
                label={"Image Size"}
                multiselect={true}
                validation={this.state.ImgSizeRequired}
                selectedvalue={this.state.arrayValImgSize}
                option={listImgSize}
                change={this.selectMultipleOption.bind(this, "Image Size")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Image Size")}
                onDropdownClose={this.isDropOpen.bind(
                  this,
                  false,
                  "Image Size"
                )}
              ></Dropdown>
            </div>

            <div className={filterBlockClass}>
              <div className="row ml-0 mr-0 position-r">
                <Dropdown
                  label={"Content Type"}
                  multiselect={false}
                  resetIcon={
                    <i
                      className="fa fa-undo user-name icon-deactive contentTypeReset"
                      aria-hidden="true"
                      onClick={this.onResetFilter.bind(this, "Content Type")}
                    ></i>
                  }
                  customClass={"contentTypeClass"}
                  selectedvalue={this.state.arrayValContentType}
                  option={listContentType}
                  change={this.selectMultipleOption.bind(this, "Content Type")}
                  filterFlag={true}
                  onDropdownOpen={this.isDropOpen.bind(
                    this,
                    true,
                    "Content Type"
                  )}
                  onDropdownClose={this.isDropOpen.bind(
                    this,
                    false,
                    "Content Type"
                  )}
                  disabled={this.state.contentTypeDisabled}
                ></Dropdown>
                <Dropdown
                  label={"Genre"}
                  multiselect={true}
                  selectedvalue={this.state.arrayValGenre}
                  option={listGenre}
                  change={this.selectMultipleOption.bind(this, "Genre")}
                  filterFlag={true}
                  onDropdownOpen={this.isDropOpen.bind(this, true, "Genre")}
                  onDropdownClose={this.isDropOpen.bind(this, false, "Genre")}
                ></Dropdown>
                <Dropdown
                  label={"Sub Genre"}
                  multiselect={true}
                  selectedvalue={this.state.arrayValSubGenre}
                  option={listSubGenre}
                  change={this.selectMultipleOption.bind(this, "Sub Genre")}
                  filterFlag={true}
                  onDropdownOpen={this.isDropOpen.bind(this, true, "Sub Genre")}
                  onDropdownClose={this.isDropOpen.bind(
                    this,
                    false,
                    "Sub Genre"
                  )}
                ></Dropdown>
                <Dropdown
                  label={"Source"}
                  multiselect={true}
                  selectedvalue={this.state.arrayValSource}
                  option={listSource}
                  change={this.selectMultipleOption.bind(this, "Source")}
                  filterFlag={true}
                  onDropdownOpen={this.isDropOpen.bind(this, true, "Source")}
                  onDropdownClose={this.isDropOpen.bind(this, false, "Source")}
                ></Dropdown>

              </div>
              <div className="row ml-0 mr-0">
               <Dropdown
                  label={"Editor Location"}
                  multiselect={true}
                  selectedvalue={this.state.arrayValLoc}
                  option={listLoc}
                  change={this.selectMultipleOption.bind(
                    this,
                    "Editor Location"
                  )}
                  filterFlag={true}
                  onDropdownOpen={this.isDropOpen.bind(
                    this,
                    true,
                    "Editor Location"
                  )}
                  onDropdownClose={this.isDropOpen.bind(
                    this,
                    false,
                    "Editor Location"
                  )}
                ></Dropdown>
                <Dropdown
                  label={"Editor"}
                  multiselect={true}
                  selectedvalue={this.state.arrayValEditor}
                  option={listEditor}
                  includeSelectAll={this.state.isSelectAll}
                  change={this.selectMultipleOption.bind(this, "Editor")}
                  filterFlag={true}
                  onDropdownOpen={this.isDropOpen.bind(this, true, "Editor")}
                  onDropdownClose={this.isDropOpen.bind(this, false, "Editor")}
                ></Dropdown>
                <Dropdown
                  label={"Assigned Client"}
                  multiselect={false}
                  resetIcon={
                    <i
                      className="fa fa-undo user-name icon-deactive contentTypeReset"
                      aria-hidden="true"
                      onClick={this.onResetFilter.bind(this, "Assigned Client")}
                    ></i>
                  }
                  customClass={"contentTypeClass"}
                  selectedvalue={this.state.arrayValAssignedClient}
                  option={listAssignedClient}
                  change={this.selectMultipleOption.bind(
                    this,
                    "Assigned Client"
                  )}
                  filterFlag={true}
                  onDropdownOpen={this.isDropOpen.bind(
                    this,
                    true,
                    "Assigned Client"
                  )}
                  onDropdownClose={this.isDropOpen.bind(
                    this,
                    false,
                    "Assigned Client"
                  )}
                ></Dropdown>
                {/* <Dropdown label={"Assigned Channel"} multiselect={true} selectedvalue={this.state.arrayValAssignedChannel} option={listAssignedChannel} change={this.selectMultipleOption.bind(this, 'Assigned Channel')} filterFlag={true} onDropdownOpen={this.isDropOpen.bind(this, true, 'Assigned Channel')} onDropdownClose={this.isDropOpen.bind(this, false, 'Assigned Channel')}></Dropdown> */}
                {this.props.data.status === "1" ? (
                  <Dropdown
                    label={"QC Status"}
                    multiselect={true}
                    selectedvalue={this.state.arrayValAction}
                    option={listQCAction}
                    change={this.selectMultipleOption.bind(this, "QC Status")}
                    filterFlag={true}
                    onDropdownOpen={this.isDropOpen.bind(
                      this,
                      true,
                      "QC Status"
                    )}
                    onDropdownClose={this.isDropOpen.bind(
                      this,
                      false,
                      "QC Status"
                    )}
                  ></Dropdown>
                ) : (
                  <>
                    <ToggleSwitch
                      label={"Allow Open To All Clients"}
                      disabledswitch={this.state.DisableAssignedClient}
                      onChange={this.handleAssignedClientSwitch}
                      checked={this.state.AssignedClientSwitch}
                    ></ToggleSwitch>
                    <ToggleSwitch
                      label={"Show only assigned channels"}
                      onChange={this.handleAssignedChannelSwitch}
                      checked={this.state.AssignedChannelSwitch}
                    ></ToggleSwitch>
                  </>
                )}
              </div>
            </div>
            {this.state.moreFilter ? (
              <div className="row ml-0 mr-0 pb20">
                {this.props.data.status === "1" ? (
                  <React.Fragment>
                    <ToggleSwitch
                      label={"Allow Open To All Clients"}
                      disabledswitch={this.state.DisableAssignedClient}
                      onChange={this.handleAssignedClientSwitch}
                      checked={this.state.AssignedClientSwitch}
                    ></ToggleSwitch>
                    <ToggleSwitch
                      label={"Show Missing Images"}
                      onChange={this.handleMissingImageSwitch}
                      checked={this.state.MissingImagesSwitch}
                    ></ToggleSwitch>
                    <ToggleSwitch
                      label={"Show only assigned channels"}
                      onChange={this.handleAssignedChannelSwitch}
                      checked={this.state.AssignedChannelSwitch}
                    ></ToggleSwitch>
                  </React.Fragment>
                ) : null}
              </div>
            ) : null}
            <div className="row ml-0 mr-0 pb20">
            { this.state.isShowTopBtn && <button type="button" id="myBtn" onClick={this.topFunction}>
        <i className="material-icons">keyboard_arrow_up</i>
    </button>}
              <div className="col-md-12 mt5 text-right">
                <button
                  type="button"
                  className="filter btn fw-normal btn-white txt-blue pl-3 pr-3 mr-20"
                  onClick={this.handleClick.bind(this)}
                >
                  Apply Filters
                </button>

                {this.state.arrayValLoc.length > 0 ||
                  this.state.arrayValEditor.length > 0 ||
                  this.state.arrayValSource.length > 0 ||
                  this.state.arrayValChannel.length > 0 ||
                  this.state.arrayValImageType.length > 0 ||
                  this.state.arrayValContentType !== null ||
                  this.state.arrayValGenre.length > 0 ||
                  this.state.arrayValSubGenre.length > 0 ||
                  this.state.arrayValRatio.length > 0 ||
                  this.props.data.scheduleQcTabContent.filteredData.length > 0 ||
                  this.state.arrayValImgSize.length > 0 ||
                  this.state.arrayValGroup !== null ||
                  this.state.arrayValAiringLang.length > 0 ||
                  this.state.arrayValAssignedClient !== null ||
                  this.state.arrayValAssignedChannel.length > 0 ||
                  this.state.arrayValAction.length > 0 ||
                  this.state.filteredChannelData > 0 ? (
                  <button
                    type="button"
                    className="reset btn btn-reset pr20 pl-0"
                    onClick={this.applyReset.bind(this)}
                  >
                    <i className="fa fa-undo reset-icon" aria-hidden="true"></i>{" "}
                    Reset
                  </button>
                ) : null}
                <div className="filter-block" onClick={this.clickMoreFilter}>
                  <span>
                    <i className={filterIcon} aria-hidden="true"></i>
                  </span>
                  <span>{filterText}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <DivWithErrorHandling showError={this.state.showError}> */}
          {gridSection}
          {/* </DivWithErrorHandling> */}
        </div>
      </div>
    );
  }
}
export default ScheduleQCMainDropdown;
