import React from "react";
import Picky from "react-picky";
import Modal from "react-modal";
import Alert from "react-s-alert";
const modalstyle2 = {
  content: {
    width: "400px",
    height: "100px",
  },
};

class BulkRejectPopup extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedErrorValue: [],
      confirmPopupFlag: false,
    };
  }

  selectErrorOption(name) {
    const errrorIndex = [];
    name.map((i) => errrorIndex.push(i.id));
    this.setState({ selectedErrorValue: name, errrorIndex });
  }

  rejectMessage = () => {
    Alert.error(
      '<div><i class="fa fa-times" aria-hidden="true"></i> Please select atleast one error</div>',
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 2000,
      }
    );
  };

  errorConfirmButton() {
    if (this.state.selectedErrorValue.length > 0) {
      this.props.confirmReject(this.state.errrorIndex);
    } else {
      // this.setState({ confirmPopupFlag: true })
      this.rejectMessage();
    }
  }

  closeConfirmPopup = () => {
    this.setState({ confirmPopupFlag: false });
  };

  render() {
    let listErrors = [];
    this.props.rejectList.map((error) =>
      listErrors.push({ id: error.intErrorCode, name: error.strErrorName })
    );

    return (
      <div>
        <div>
          <button
            type="button"
            className="close mb-2"
            data-dismiss="modal"
            onClick={this.props.closeModel}
          >
            &times;
          </button>
        </div>
        <div className="modal-content p-2">
          <div className="modal-header">
            <h4 className="modal-title fw-normal fs16 txt-orange pb-2 pt-2 pl-2">
              Image Error Comments
            </h4>
          </div>
          <div className="modal-body p-2 pt-3">
            <div className="form-group dropdown-select">
              <label className="control-label fs11 pl-2 txt-blue">
                Error Type
              </label>
              <Picky
                value={this.state.selectedErrorValue}
                options={listErrors}
                onChange={this.selectErrorOption.bind(this)}
                valueKey="id"
                labelKey="name"
                multiple={true}
                includeFilter={true}
                dropdownHeight={200}
                open={false}
              />
            </div>
          </div>
          <div className="modal-footer text-center">
            <button
              type="button"
              className="btn fw-normal btn-blue txt-white pl-3 pr-3 mr-2"
              onClick={() => this.errorConfirmButton()}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn fw-normal btn-white txt-blue pl-3 pr-3"
              onClick={this.props.closeModel}
            >
              Cancel
            </button>
          </div>
        </div>
        {this.state.confirmPopupFlag ? (
          <Modal
            isOpen={true}
            contentLabel="Message Display"
            style={modalstyle2}
          >
            <div>
              <div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.closeConfirmPopup}
                >
                  &times;
                </button>
              </div>
              <div className="modal-content brnone bbnone blnone ">
                <div className="modal-body pt-3 text-center">
                  Please select atleast one error
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default BulkRejectPopup;
