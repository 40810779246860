import React from "react";
import Switch from "react-switch";

class ToggleSwitch extends React.Component {
  render() {
    return (
      <div className="col-md-3 mt10">
        <div>
          <span className="txt-blue allowClientLabel">{this.props.label}</span>
          <Switch
            onColor="#ccebf7"
            onHandleColor="#009dd9"
            offHandleColor="#707276"
            offColor="#ccc"
            disabled={this.props.disabledswitch}
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={this.props.onChange}
            checked={this.props.checked}
            height={12}
            width={28}
            handleDiameter={15}
            className="react-switch"
          />
        </div>
      </div>
    );
  }
}

export default ToggleSwitch;
