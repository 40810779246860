import React, { Fragment } from "react";
import axios from "axios";
import Modal from "react-modal";
import BulkRejectPopup from "./BulkRejectPopup";
import config from "../../containers/config";
import { Link } from "react-router-dom";
import KeyboardEventHandler from "react-keyboard-event-handler";
// import { DivWithErrorHandling } from '../../hoc/DivWithErrorHandling';
import { SharedService } from "../SharedService/SharedService";
import Buttons from "./Buttons";
import MissingImage from "../../assets/images/missing-image.png";
import DisplayMessage from "./DisplayMessage";

// let $ = require('jquery');

// let start1;
// let end1;
let globalpageNum;
let img_index=null,mode=null;

const customStyles = {
  content: {
    height: "285px",
    width: "450px",
    overflow: "visible",
  },
};

const modalstyle = {
  content: { width: "320px", height: "135px" },
};

const modalstyle2 = {
  content: {
    width: "400px",
    height: "135px",
  },
};

const modalstyle3 = {
  content: { width: "320px", height: "150px" },
};

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.currentPage,
      data: [],
      data1: [],
      dropdowncount: this.props.pageSize,
      pagecount: 1,
      previndexcount: 0,
      totalpagecount: 0,
      modalIsOpen: false,
      selectedErrorValue: [],
      LastImgSetReached: false,
      confirmPopupFlag: false,
      modalDispMsgIsOpen: false,
      failureCount: 0,
      items: 0,
      isApprove: 1,
      zeroImagesToAction: false,
      ActionableImages: [],
    };
    sessionStorage.setItem("QCPageSize", this.props.pageSize);
    sessionStorage.setItem("QCCurrentPage", this.props.currentPage);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      currentPage: this.props.currentPage,
    });
  }
  removeImageFocus=()=>
  {
        let unaction_element = document.getElementById(`u${img_index}`);
    let action_element = document.getElementById(`a${img_index}`);
    if(img_index || img_index===0)
    {
     
      if(unaction_element)
      {
        unaction_element.scrollIntoView=()=>{};
        unaction_element.style.boxShadow='none';
      }
      if(action_element)
      {
        action_element.scrollIntoView=()=>{};
        action_element.style.boxShadow='none';
      }

    }
  }
  imageFocus = () => {
    let unaction_element = document.getElementById(`u${img_index}`);
    let action_element = document.getElementById(`a${img_index}`);
    this.subscription = SharedService.getImageIndex().subscribe((data) => {
      img_index = data.id
      mode = data.mode
    })
    if ((img_index || img_index === 0) && this.props.status === '1') {
      if (unaction_element) {
        unaction_element.scrollIntoView({ block: "center", inline: "nearest" })
        unaction_element.style.boxShadow = '0px 0px 6px red'
        unaction_element.style.display = 'inline-block'
      }
      if (action_element) {
        action_element.scrollIntoView({ block: "center", inline: "nearest" })
        action_element.style.boxShadow = '0px 0px 6px red'
        action_element.style.display = 'inline-block'

      }
    }
    if(img_index==null)
    {
      window.scrollTo(0,0);
    }

  }
  componentDidMount()
  {
    this.imageFocus()
  }
  componentWillUnmount()
  {
    SharedService.setImageIndex({id:null, mode:null})
  }


  getPage = () => {
    localStorage.setItem("pagesize", this.props.pageSize);
    let tempLastImgSetReached = this.state.LastImgSetReached;
    // start1 = "";
    // end1 = "";
    var start = this.props.pageSize * (this.props.currentPage - 1);
    // start1 = start;
    localStorage.setItem("startIndexOfPage", start);
    var end = start + this.props.pageSize;
    globalpageNum = this.props.pageSize;
    // end1 = end
    localStorage.setItem("endIndexOfPage", end);

    if (tempLastImgSetReached) {
      start = 0;
      end = this.props.pageSize;
    }

    return {
      currentPage: this.props.currentPage,
      filterParams: this.props.filterParams.slice(start, end),
      numPages: this.getNumPages(),
      handleClick: function (pageNum) {
        return function () {
          this.handlePageChange(pageNum);
          this.removeImageFocus();
        }.bind(this);
      }.bind(this),
    };
  };

  getNumPages = () => {
    var numPages = Math.floor(
      this.props.filterParams.length / this.props.pageSize
    );
    if (this.props.filterParams.length % this.props.pageSize > 0) {
      numPages++;
    }
    return numPages;
  };

  handlePageChange = (pageNum) => {
    this.props.handlePageCountChange(this.props.pageSize, pageNum);
    this.setState({ currentPage: pageNum, LastImgSetReached: false });
    globalpageNum = pageNum;
  };

  closeModal(e, values) {
    this.setState({ modalIsOpen: false });
    this.props.stopShortCuts(false);
  }

  pager = (page) => {
    if (this.state.LastImgSetReached) {
      page.currentPage = 1;
    }
    var pageLinks = [];
    if (page.currentPage > 1) {
      pageLinks.push(
        <input
          type="button"
          value="Previous"
          style={{
            background: "#fff",
            border: "1px solid #cccccc",
            width: "110px",
            padding: "5px 0px",
            color: "#000",
            fontSize: "13px",
          }}
          onClick={page.handleClick(page.currentPage - 1)}
        />
      );
      pageLinks.push(" ");
    }
    pageLinks.push(
      <span
        style={{ fontSize: "13px", color: "#000", padding: "0px 5px 0 10px" }}
      >
        Page
      </span>
    );
    pageLinks.push(
      <span
        style={{ fontSize: "13px", color: "#000", padding: "0px 10px 0 0" }}
      >
        {page.currentPage} of {page.numPages}
      </span>
    );
    pageLinks.push(
      <select
        className="imgCount"
        style={{
          width: "120px",
          padding: "4px 10px",
          border: "1px solid #cccccc",
          color: "#000",
          marginRight: "5px",
        }}
        id="pageSize"
        value={this.props.pageSize}
        onChange={(e) => this.props.handlePageSizeChange(e, page.currentPage)}
      >
        <option value="25" id="25">
          25 Images
        </option>
        <option value="50" id="50">
          50 Images
        </option>
        <option value="100" id="100">
          100 Images
        </option>
        <option value="400" id="400">
          400 Images
        </option>
      </select>
    );
    if (page.currentPage < page.numPages) {
      // start1 = 0;
      pageLinks.push(" ");
      pageLinks.push(
        <input
          className="next"
          type="button"
          value="Next"
          style={{
            background: "#fff",
            border: "1px solid #cccccc",
            width: "110px",
            padding: "5px 0px",
            color: "#000",
            marginRight: "5px",
            fontSize: "13px",
          }}
          onClick={page.handleClick(page.currentPage + 1)}
        />
      );
    }
    return <div className="pager">{pageLinks}</div>;
  };

  bulkApprove() {
      this.removeImageFocus();
    let images = this.Calculate();
    if (images.length === 0) {
      this.setState({
        modalDispMsgIsOpen: true,
        items: images.length,
        zeroImagesToAction: true,
      });
      this.props.stopShortCuts(false);
    } else {
      this.setState({
        confirmPopupFlag: true,
        items: images.length,
        ActionableImages: images,
      });
      this.props.stopShortCuts(true);
      SharedService.setImageIndex({ id: null, mode: null })
    }
  }

  bulkReject() {
    this.removeImageFocus();
    let images = this.Calculate();
    if (images.length === 0) {
      this.setState({
        modalDispMsgIsOpen: true,
        items: images.length,
        zeroImagesToAction: true,
      });
      this.props.stopShortCuts(false);
    } else {
      this.setState({
        modalIsOpen: true,
        items: images.length,
        ActionableImages: images,
      });
      this.props.stopShortCuts(true);
    }
  }

  approveConfirmButton = () => {
    const arrrejectReason = [];

    this.performBulkAction(2, arrrejectReason);
    this.props.stopShortCuts(false);
  };

  closeConfirmPopup = () => {
    this.setState({
      confirmPopupFlag: false,
      ActionableImages: [],
      modalIsOpen: false,
    });
    this.props.stopShortCuts(false);
  };

  confirmReject(arrRejectReason) {
    this.setState({ modalIsOpen: false });
    this.performBulkAction(4, arrRejectReason);
    this.props.stopShortCuts(false);
  }

  Calculate() {
    let lstImages = [];
    let tempArrLength = this.props.filterParams.length;
    let currentPage = this.props.currentPage;
    let minValue = (currentPage - 1) * globalpageNum;
    let maxValue = Math.min(currentPage * globalpageNum, tempArrLength);

    let tempFilteredData = JSON.parse(JSON.stringify(this.props.filterParams));

    for (let i = minValue; i < maxValue; i++) {
      if (
        tempFilteredData[i].lngImageID &&
        (tempFilteredData[i].intActionStatusCode.toString() === "0" ||
          tempFilteredData[i].intActionStatusCode.toString() === "1")
      ) {
        var obj = {};
        obj.imageid = tempFilteredData[i].lngImageID;
        obj.status = "0";
        lstImages.push(obj);
      }
    }
    return lstImages;
  }

  closeModal2(e, values) {
    this.setState({ modalDispMsgIsOpen: false, isApprove: 1 });
  }

  performBulkAction(intActionCode, arrrejectReason) {
    let lstImages = this.state.ActionableImages;
    let ImgStartIndex = localStorage.getItem("startIndexOfPage");
    let tempArrLength = this.props.filterParams.length;
    let tempLastImgSetReached = false;
    let currentPage = this.props.currentPage;
    let minValue = (currentPage - 1) * globalpageNum;
    let maxValue = Math.min(currentPage * globalpageNum, tempArrLength);

    axios
      .post(`${config.apiUrl + config.qcApi}Action/PerformImageQC/`, {
        intActionStatusCode: intActionCode,
        lstImageData: lstImages,
        lstReasonID: arrrejectReason,
        intWLTypeID: parseInt(this.props.WorkListID),
        intUserRoleID: localStorage.getItem("userRoleID"),
        lstComment: [],
      })
      .then((response) => {
        if (response.status === 200) {
          let qcactionIndex = [];
          if (this.props.actionfilter)
            this.props.actionfilter.map((i) => qcactionIndex.push(i.id));
          let tmpCounter = 0;

          for (
            tmpCounter = maxValue - 1;
            tmpCounter >= minValue;
            tmpCounter--
          ) {
            if (this.props.filterParams[tmpCounter].lngImageID) {
              let tmpImg = response.data.objRespMessage[0].filter(
                (img) =>
                  img.imageID === this.props.filterParams[tmpCounter].lngImageID
              );

              if (tmpImg && tmpImg[0]) {
                this.props.filterParams[tmpCounter].intActionStatusCode =
                  tmpImg[0].actionStatusID;
                if (this.props.WorkListID === "1") {
                  if (
                    qcactionIndex.length > 0 &&
                    !qcactionIndex.includes(tmpImg[0].actionStatusID)
                  ) {
                    this.props.filterParams.splice(tmpCounter, 1);
                  }
                } else {
                  this.props.filterParams.splice(tmpCounter, 1);
                }
              }
            }
          }

          let tmpfailureCount =
            this.state.items -
            response.data.objRespMessage[1][0].currentActionCount;

          if (tempArrLength - globalpageNum <= ImgStartIndex) {
            this.props.handlePageCountChange(this.props.pageSize, 1);
            sessionStorage.setItem("QCCurrentPage", 1);
            tempLastImgSetReached = true;
          }
          let page = tempLastImgSetReached ? 1 : this.props.currentPage;

          let tempcount = {};
          tempcount.intUnActionedCount = 0;
          tempcount.intMissingCount = 0;
          tempcount.intActionedCount = 0;
          this.props.filterParams.forEach((arrVal) => {
            if (
              arrVal.lngImageID &&
              (arrVal.intActionStatusCode === 1 ||
                arrVal.intActionStatusCode === 0)
            )
              tempcount.intUnActionedCount += 1;
            else if (arrVal.lngImageID) tempcount.intActionedCount += 1;
            else tempcount.intMissingCount += 1;
          });

          SharedService.setFilterData({
            page,
            action: "BulkAction",
            WorkListID: this.props.WorkListID,
            filterParams: this.props.filterParams,
            AllImagesCount: tempcount,
          });
          this.setState({
            LastImgSetReached: tempLastImgSetReached,
            currentPage: tempLastImgSetReached ? 1 : this.props.currentPage,
            showError: false,
            failureCount: tmpfailureCount,
            modalDispMsgIsOpen: true,
            items: response.data.objRespMessage[1][0].currentActionCount,
            zeroImagesToAction: false,
            isApprove: intActionCode === 4 ? 6 : 5,
            modalIsOpen: false,
            confirmPopupFlag: false,
            ActionableImages: [],
          });
          //
          this.getPage();
        } else {
          this.setState({
            showError: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        this.setState({
          showError: true,
        });
      });
  }

  selectErrorOption(name) {
    this.setState({ selectedErrorValue: name });
  }

  componentWillMount() {
    Modal.setAppElement("body");
  }

  render() {
    let opacityClass = "gallery-img-width float-left p-3";
    if (this.props.WorkListID === "1") {
      opacityClass = "gallery-img-width float-left p-3 opacity-50";
    }

    var page = this.getPage();
    let listErrors = [];
    this.props.rejectionErrorList.map((error, index) =>
      listErrors.push({ id: error.intErrorCode, name: error.strErrorName })
    );

    return (
      <div>
        <KeyboardEventHandler
          handleKeys={["esc"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => {
            this.closeModal(key);
            this.closeConfirmPopup(key);
          }}
        />
        {/* <DivWithErrorHandling showError={this.state.showError}> */}
        <div
          className="tab-pane fade show active"
          id="qc-worklist"
          role="tabpanel"
          aria-labelledby="qc-tab"
        >
          <div className="row ml-0 mr-0">
            <div className="col-md-12">
              <div
                className="border pt20 pb-3 pr-3 pl-3"
                style={{ overflow: "hidden" }}
              >
                <div className="gallery-header pb-2">
                  <div className="pull-left pt-3 imgCategory">
                    <h3 className="txt-orange fw-normal fs18">
                      {this.props.WorkListID === "2" ? (
                        <Fragment>
                          {" "}
                          {this.props.filterParams.length} Images Found{" "}
                        </Fragment>
                      ) : (
                        <Fragment>
                          {this.props.ImagesCount.intUnActionedCount +
                            " Images for QC, "}
                          {this.props.ImagesCount.intMissingCount > 0 &&
                          this.props.MissingImagesSwitch ? (
                            <button
                              onClick={this.props.downloadCSV}
                              className="downloadCSV"
                            >
                              <span className="missing_count cursor-pointer">
                                {this.props.ImagesCount.intMissingCount +
                                  " Missing Images, "}
                              </span>
                            </button>
                          ) : this.props.MissingImagesSwitch &&
                            this.props.ImagesCount.intMissingCount === 0 ? (
                            <span className="missing_count cursor-pointer">
                              {this.props.ImagesCount.intMissingCount +
                                " Missing Images, "}
                            </span>
                          ) : null}
                          {this.props.ImagesCount.intActionedCount +
                            " QCed Images"}
                        </Fragment>
                      )}
                    </h3>
                  </div>

                  {this.props.filterParams.length > 0 ? (
                    <div className="pull-right">
                      <Buttons
                        id={"Approve"}
                        class={
                          "bulkApprove btn fw-normal btn-approve btn-bulk-action pl-2 pr-2 mr5"
                        }
                        btnValue={"Bulk Approve"}
                        onClick={this.bulkApprove.bind(this)}
                      />
                      <Buttons
                        id={"Reject"}
                        class={
                          "bulkReject btn fw-normal btn-reject btn-bulk-action pl-2 pr-2"
                        }
                        btnValue={"Bulk Reject"}
                        onClick={this.bulkReject.bind(this)}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="col-md-12 pl-0 pr-0 pt-1">
                  {page.filterParams.map((element, index) => {
                    return (
                      <Fragment key={`MissingImg${index}`}>
                        {this.props.WorkListID === "1" && element.isMissing ? (
                          <div className={opacityClass}>
                            {" "}
                            <div>
                              <img
                                alt="GalleryImg"
                                className="gallery-img"
                                id={index}
                                src={MissingImage}
                              />
                            </div>
                            <div className="position-r">
                              <p className="gallery-pro-name mb-1 mt-2 fs12 fw-semi-bold pr30">
                                {element.strProgrammeName}
                              </p>
                              <p
                                className="fs11 mt-0 mb-0 txt-black"
                                style={{ fontWeight: "bold" }}
                              >
                                {" "}
                                S# {element.strSeriesNo} | Ep#{" "}
                                <b>{element.strEpisodeNo}</b>
                              </p>
                            </div>
                          </div>
                        ) : null}
                        {(this.props.WorkListID === "1" &&
                          (element.intActionStatusCode === 1 ||
                            element.intActionStatusCode === 0) &&
                          element.isMissing === false) ||
                        (this.props.WorkListID === "2" &&
                          element.intActionStatusCode === 0) ? (
                          <div className={"gallery-img-width float-left p-3"}>
                            {" "}
                            <div id={`u${index}`}>
                              <Link
                                to={{
                                  pathname: "/qcuser/SingleImageView",
                                  imageid: element.lngImageID,
                                  data: this.props.filterParams,
                                  pageSize: this.props.pageSize,
                                  currentPage: this.props.currentPage,
                                  actionstatus: this.props.status,
                                  imgindex: index,
                                  WorkListType: this.props.WorkListID,
                                  actionfilter: this.props.actionfilter,
                                }}
                              >
                                <img
                                  alt={"GalleryImg"}
                                  className="gallery-img"
                                  id={index}
                                  src={element.strGridLink}
                                />
                              </Link>
                            </div>
                            <div className="position-r">
                              <p className="gallery-pro-name mb-1 mt-2 fs12 fw-semi-bold pr30">
                                {element.strProgrammeName}
                              </p>
                              <p className="fs11 mt-0 mb-0 txt-black">
                                {" "}
                                W {element.intWidth} X H {element.intHeight}
                              </p>
                            </div>
                          </div>
                        ) : null}
                        {this.props.WorkListID === "1" &&
                        element.intActionStatusCode !== 1 &&
                        element.isMissing === false ? (
                          <div className={opacityClass}>
                            {" "}
                            <div  id={`a${index}`}>
                              <Link
                                to={{
                                  pathname: "/qcuser/SingleImageView",
                                  imageid: element.lngImageID,
                                  data: this.props.filterParams,
                                  pageSize: this.props.pageSize,
                                  actionstatus: this.props.status,
                                  currentPage: this.props.currentPage,
                                  imgindex: index,
                                  WorkListType: this.props.WorkListID,
                                  actionfilter: this.props.actionfilter,
                                }}
                              >
                                <img
                                  alt={"GalleryImg"}
                                  className="gallery-img"
                                  id={index}
                                  src={element.strGridLink}
                                />
                              </Link>
                            </div>
                            <div className="position-r">
                              <p className="gallery-pro-name mb-1 mt-2 fs12 fw-semi-bold pr30">
                                {element.strProgrammeName}
                              </p>
                              {element.intActionStatusCode === 2 ? (
                                <span className="fw-normal gallery-approve-icon f-r gallery-icons"></span>
                              ) : null}
                              {element.intActionStatusCode === 3 ? (
                                <span className="fw-normal gallery-edit-icon f-r gallery-icons"></span>
                              ) : null}
                              {element.intActionStatusCode === 4 ? (
                                <span className="fw-normal gallery-reject-icon f-r gallery-icons"></span>
                              ) : null}
                              {element.intActionStatusCode === 5 ? (
                                <span className="fw-normal gallery-improvisation-icon f-r gallery-icons"></span>
                              ) : null}
                              <p className="fs11 mt-0 mb-0 txt-black">
                                {" "}
                                W {element.intWidth} X H {element.intHeight}
                              </p>
                            </div>
                          </div>
                        ) : null}

                        {/* <div>
                                                <Link to={{
                                                    pathname: '/qcuser/SingleImageView',
                                                    imageid: element.lngImageID,
                                                    data: this.props.filterParams,
                                                    pageSize: this.props.pageSize,
                                                    actionstatus: this.props.status,
                                                    currentPage: this.props.currentPage,
                                                    actionstatus: this.props.status,
                                                    imgindex: index,
                                                    WorkListType: this.props.WorkListID
                                                }}>
                                                    <img alt={"QCimg"} className="gallery-img" id={index} src={element.strGridLink} />
                                                    {/* <img alt={"QCimg"} className="gallery-img" id={index} src={MissingImage} /> 
                                                </Link>
                                            </div>
                                            <div className="position-r"><p className="gallery-pro-name mb-1 mt-2 fs12 fw-semi-bold pr30">{element.strProgrammeName}</p>
                                                {/* <span className="fw-normal gallery-approve-icon f-r gallery-icons"></span> 
                                                <p className="fs11 mt-0 mb-0 txt-black"> W {element.intWidth} X H {element.intHeight}</p>
                                            </div> */}
                      </Fragment>
                    );
                  })}
                </div>
                <div style={{ clear: "both" }}></div>
                {this.props.filterParams.length > 0 ? (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {this.pager(page)}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* </DivWithErrorHandling> */}
        <Modal
          style={customStyles}
          isOpen={this.state.modalIsOpen}
          contentLabel="Example Modal"
        >
          <BulkRejectPopup
            rejectList={this.props.rejectionErrorList}
            closeModel={this.closeModal.bind(this)}
            confirmReject={this.confirmReject.bind(this)}
          />
        </Modal>
        {this.state.confirmPopupFlag ? (
          <Modal
            isOpen={true}
            contentLabel="Message Display"
            style={modalstyle2}
          >
            <div>
              <div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.closeConfirmPopup}
                >
                  &times;
                </button>
              </div>
              <div className="modal-content brnone bbnone blnone ">
                <div className="modal-body pt-3 text-center">
                  {/* Do you want to proceed ? */}
                  Do you want to approve {this.state.items} images ?
                </div>
                <div className="modal-footer approve-footer text-center">
                  <button
                    type="button"
                    className="btn fw-normal btn-blue txt-white pl-3 pr-3 pop-btn2"
                    onClick={() => this.approveConfirmButton()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        <Modal
          isOpen={this.state.modalDispMsgIsOpen}
          onRequestClose={this.closeModal2.bind(this)}
          contentLabel="Message Display"
          style={this.state.failureCount > 0 ? modalstyle3 : modalstyle}
        >
          <DisplayMessage
            closeModel={this.closeModal2.bind(this)}
            isApprove={this.state.isApprove}
            count={this.state.items}
            zeroImagesToAction={this.state.zeroImagesToAction}
            failureCount={this.state.failureCount}
            last={false}
          />
        </Modal>
      </div>
    );
  }
}
export default Gallery;
