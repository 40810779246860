import React, { Component } from "react";
import moment from "moment";
import DateRangePickerNew from "react-bootstrap-daterangepicker";
import axios from "axios";
import Alert from "react-s-alert";
import config from "../../containers/config";
import Modal from "react-modal";
let $ = require("jquery");

const modalstyle2 = {
  content: {
    width: "400px",
    height: "150px",
  },
};

class SaveQCDate extends Component {
  constructor(props) {
    super();
    this.state = {
      QCStartDate: moment(),
      QCEndDate: moment(),
      saveQCDate: [],
      notification: "Please select the date of allocation.",
      click: props.clicks,
      saveQCDateFlag: false,
    };
  }

  componentWillMount() {
    if (this.props.parentInfo.date !== null) {
      //|| this.props.resetClick === false
      this.setState({ saveQCDate: this.props.parentInfo.date });
    } else {
      this.setState({ saveQCDate: [] });
    }
  }

  onApplyDate = (e) => {
    let date = $(".drp-selected").text();
    var dateobj = this.setDateFormat(date);
    this.setState({
      saveQCDate: dateobj.tempdate3,
      QCStartDate: dateobj.startdate,
      QCEndDate: dateobj.enddate,
    });
  };

  saveQCDatePopUp = () => {
    if (this.state.saveQCDate.length <= 0) {
      this.alertErrorMessage("Please select the date of allocation.");
      this.setState({ saveQCDateFlag: false });
    } else {
      this.setState({ saveQCDateFlag: true });
    }
  };

  saveQcDate = (e) => {
    if (this.state.saveQCDate.length > 0) {
      let fromDate = moment(this.state.QCStartDate).format("YYYY-MM-DD");
      let toDate = moment(this.state.QCEndDate).format("YYYY-MM-DD");
      axios
        .post(`${config.apiUrl + config.qcApi}action/SaveQCDate`, {
          intQCUserRoleID: this.props.parentInfo.qcId,
          intAllocatorID: localStorage.getItem("userRoleID"),
          dtmFrom: fromDate,
          dtmTo: toDate,
        })
        .then((response) => {
          if (response.status === 200) {
            this.props.saveClick();

            if (response.data.intStatusCode === 409) {
              this.alertErrorMessage(response.data.objRespMessage);
              this.setState({ saveQCDateFlag: false });
            } else {
              this.alertMessage(response.data.objRespMessage);
              this.setState({ saveQCDateFlag: false });
            }
          } else {
            this.alertErrorMessage("Please select the date of allocation.");
            this.setState({ saveQCDateFlag: false });
          }
        });
    } else {
      this.alertErrorMessage("Please select the date of allocation.");
      this.setState({ saveQCDateFlag: false });
    }
  };

  alertMessage = (Message) => {
    Alert.success(
      '<div><i class="fa fa-check notification" aria-hidden="true"></i>' +
        Message +
        "</div>",
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 3000,
      }
    );
  };

  alertErrorMessage = (Message) => {
    Alert.error(
      '<div><i class="fa fa-times notification" aria-hidden="true"></i>' +
        Message +
        "</div>",
      {
        position: "bottom-right",
        effect: "bouncyflip",
        html: true,
        timeout: 3000,
      }
    );
  };

  setDateFormat = (date) => {
    let dateobj = {};
    let splitDate = date.split(" - ");
    let splitDateFormat1 = splitDate[0].split("/");
    let splitDateFormat2 = splitDate[1].split("/");
    let tempdate1 = [
      splitDateFormat1[1].trim(),
      splitDateFormat1[0].trim(),
      splitDateFormat1[2].trim(),
    ].join("/");
    let tempdate2 = [
      splitDateFormat2[1].trim(),
      splitDateFormat2[0].trim(),
      splitDateFormat2[2].trim(),
    ].join("/");
    let tempdate3 = [tempdate1, tempdate2].join(" - ");

    dateobj = {
      tempdate3: tempdate3,
      startdate: splitDate[0],
      enddate: splitDate[1],
    };
    return dateobj;
  };

  cancelClick(e) {
    this.setState({ saveQCDateFlag: e });
  }

  render() {
    return (
      <ul className="timeline">
        <li className="grey">
          <div className="form-group">
            <p className="control-label1">
              <label
                htmlFor="field-1"
                className="control-label1 fs12 mb-1 txt-blue"
              >
                Assign From & To Date
              </label>
            </p>
            <DateRangePickerNew
              id={"saveQCDate"}
              autoApply
              onApply={this.onApplyDate.bind(this)}
              minDate={moment()}
              startDate={this.state.QCStartDate}
              endDate={this.state.QCEndDate}
            >
              <input
                type="text"
                className={
                  "form__daterange form-control daterange hasDatepicker"
                }
                id="datePickerValue123"
                title={this.state.saveQCDate}
                value={this.state.saveQCDate}
              />
              <div className="cal-icon">
                <i className="fa fa-calendar"></i>
              </div>
            </DateRangePickerNew>
          </div>
        </li>
        <div className="col-12 text-right pr-0 pl-0">
          <button
            type="button"
            className="btn fw-normal btn-save pl-3 pr-3"
            onClick={this.saveQCDatePopUp.bind()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn fw-normal btn-white txt-blue pl-3 pr-3 ml-2"
            onClick={this.props.cancelQcDate}
          >
            Cancel
          </button>
        </div>
        {this.state.saveQCDateFlag ? (
          <Modal
            isOpen={true}
            contentLabel="Message Display"
            style={modalstyle2}
          >
            <div>
              <div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => this.cancelClick(false)}
                >
                  &times;
                </button>
              </div>
              <div className="modal-content brnone bbnone blnone ">
                <div className="modal-body pt-3 text-center">
                  Do you want to proceed ?
                </div>
                <div className="text-center mt-20">
                  <button
                    type="button"
                    className="btn fw-normal btn-blue txt-white pl-3 pr-3 pop-btn2 mr-20"
                    onClick={() => this.saveQcDate()}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn fw-normal btn-blue txt-white pl-3 pr-3 pop-btn2"
                    onClick={() => this.cancelClick(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </ul>
    );
  }
}

export default SaveQCDate;
