import React from "react";
import Picky from "react-picky";

class Dropdown extends React.Component {
  render() {
    let str = "";
    if (this.props.label === "Editor") {
      str = "*";
    }
    return (
      <div className="col-md-3">
        <div
          style={
            this.props.disabled ? { pointerEvents: "none", opacity: "0.4" } : {}
          }
          className={  this.props.status==='8'? this.props.customClass:null}
        >
          <div className="form-group dropdown-select">
            <label
              htmlFor="field-1"
              className="control-label fs11 pl-2 txt-blue"
            >
              {this.props.label} <span className="txt-red">{str}</span>
            </label>
            <Picky
              value={this.props.selectedvalue}
              options={this.props.option}
              onChange={this.props.change}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeFilter={this.props.filterFlag}
              dropdownHeight={150}
              open={false}
              onOpen={this.props.onDropdownOpen}
              onClose={this.props.onDropdownClose}
              includeSelectAll={this.props.includeSelectAll}
              className={this.props.Editorvalidation ? "required" : ""}
              clearFilterOnClose={this.props.clearfilter}
            />
            {this.props.status === '8' ?
              (this.props.refreshIcon) : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Dropdown;
