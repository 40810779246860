import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import QcIndividual from "../components/QCUser/QcIndividual";
import ClientReportedImages from "../components/QCUser/ClientReportedImages";
import MissingImagesWorklist from "../components/QCUser/MissingImageWorklist";
import DailyQcDashboard from "../components/QCUser/DailyQcDashboard";
import ScheduledQcDashboard from "../components/QCUser/ScheduledQcDashboard";
import SchedledQcWorklist from "../components/QCUser/ScheduleQcWorklist";
import ScheduledQcTeam from "../components/QCUser/ScheduledQcTeam";
import DailyQcWorklist from "../components/QCUser/DailyQcWorklist";
import EditorSingleImageView from "../components/Editor/EditorSingleImageView";
import ErrorPage from "../components/ErrorPage";
import DefaultLayout from "../hoc/DefaultLayout";
import PrivateRoute from "../hoc/PrivateRoute";
import Login from "../components/login";
import SingleImageView from "../components/QCUser/SingleImageView";
import { SharedService } from "../components/SharedService/SharedService";
import axios from "axios";
import config from "./config";
import NotFound from "../components/QCUser/NotFound";
// import { RedirectedIfError } from '../hoc/RedirectedIfError';
import EditorDailyQcWorklist from "../components/Editor/EditorDailyQcWorklist";
import WorkAssignment from "../components/Allocator/WorkAssignment";
import moment from "moment";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/bouncyflip.css";
import Alert from "react-s-alert";

let initDate = moment(),
  finalDate = moment();
initDate = initDate.subtract(2, "day");
initDate = initDate.format("DD/MM/YYYY");
finalDate = finalDate.format("DD/MM/YYYY");
finalDate = initDate + "-" + finalDate;

let edRevinitDate = moment(),
  edRevfinalDate = moment();
edRevinitDate = edRevinitDate.subtract(3, "month");
edRevinitDate = edRevinitDate.format("DD/MM/YYYY");
edRevfinalDate = edRevfinalDate.format("DD/MM/YYYY");
edRevfinalDate = edRevinitDate + "-" + edRevfinalDate;

let editorPrefillDate = moment(),
  editorPrefillfinalDate = moment();
editorPrefillDate = editorPrefillDate.subtract(1, "month");
editorPrefillDate = editorPrefillDate.format("DD/MM/YYYY");
editorPrefillfinalDate = editorPrefillfinalDate.format("DD/MM/YYYY");
editorPrefillfinalDate = editorPrefillDate + "-" + editorPrefillfinalDate;

const tabContent = {
  bindLocation: { options: [], selectedValue: [] },
  bindEditorByLoc: { options: [], selectedValue: [] }, //requiredField: false },
  bindSource: { options: [], selectedValue: [] },
  bindChannel: { options: [], selectedValue: [] },
  bindDeliveryPlatform: { options: [], selectedValue: [] },
  bindContentType: { options: [], selectedValue: [] },
  bindImageType: { options: [], selectedValue: [] },
  bindGenre: { options: [], selectedValue: [] },
  bindSubgenre: { options: [], selectedValue: [] },
  bindRatio: { options: [], selectedValue: [] },
  bindErrors: { options: [], selectedValue: [] },
  bindImageEditType: { options: [], selectedValue: [] },
  bindImgSize: { options: [], selectedValue: [] },
  bindGroup: { options: [], selectedValue: [] },
  bindAssignedLanguage: { options: [], selectedValue: [] },
  filteredData: [],
  displayDate: finalDate,
  loader: "",
  pageSize: 25,
  currentPage: 1,
  startDate: null,
  endDate: null,
  startAiringDate: null,
  endAiringDate: null,
  displayAiringDate: "",
  activeTab: false,
  editorRevDate: edRevfinalDate,
  otherTabDates: editorPrefillfinalDate,
};

const editorTabContent = {
  bindSource: { options: [], selectedValue: [] },
  bindChannel: { options: [], selectedValue: [] },
  bindWorklist: { options: [], selectedValue: [] },
  displayDate: editorPrefillfinalDate, // finalDate,
  filteredData: [],
  loader: "",
  pageSize: 25,
  currentPage: 0,
  startDate: null,
  endDate: null,
  startAiringDate: null,
  endAiringDate: null,
  displayAiringDate: "",
  activeTab: false,
  approvedDate: finalDate,
};

const scheduleQcTabContent = {
  bindLocation: { options: [], selectedValue: [] },
  bindEditorByLoc: { options: [], selectedValue: [] }, //requiredField: false },
  bindSource: { options: [], selectedValue: [] },
  bindChannel: { options: [], selectedValue: [] },
  bindChannelGenre: { options: [], selectedValue: [] },
  bindContentType: { options: [], selectedValue: null },
  bindImageType: { options: [], selectedValue: [] },
  bindGenre: { options: [], selectedValue: [] },
  bindSubgenre: { options: [], selectedValue: [] },
  bindRatio: { options: [], selectedValue: [] },
  bindErrors: { options: [], selectedValue: [] },
  bindImgSize: { options: [], selectedValue: [] },
  bindGroup: { options: [], selectedValue: null },
  bindAiringLang: { options: [], selectedValue: [] },
  bindAssignedClient: { options: [], selectedValue: null },
  bindAssignedChannel: { options: [], selectedValue: [] },
  bindAction: { options: [], selectedValue: [] },
  filteredData: [],
  loader: "",
  pageSize: 25,
  currentPage: 1,
  startAiringDate: null,
  endAiringDate: null,
  displayAiringDate: "",
  activeTab: false,
  editorRevDate: edRevfinalDate,
  moreFilter: false,
  AssignedClientSwitch: true,
  DisableAssignedClient: true,
  MissingImagesSwitch: false,
  contentTypeDisabled: false,
  AssignedChannelSwitch: false,
  AllImagesCount: [],
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          tabName: "Daily Worklist",
          status: "1",
          tabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Approved",
          status: "2",
          tabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Approved With Edits Images",
          status: "3",
          tabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Rejected Images",
          status: "4",
          tabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Editor Reverted",
          status: "8",
          tabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Improvisation Worklist",
          status: "5",
          tabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
      ],
      editorTabs: [
        {
          tabName: "Approved",
          status: "2",
          editorTabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Approved With Edits Images",
          status: "3",
          editorTabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Rejected Images",
          status: "4",
          editorTabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "QC Reverted Images",
          status: "6",
          editorTabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Improvisation Worklist",
          status: "5",
          editorTabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
      ],

      scheduleQcTabs: [
        {
          tabName: "Scheduled Worklist",
          status: "1",
          scheduleQcTabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Approved",
          status: "2",
          scheduleQcTabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Approved With Edits Images",
          status: "3",
          scheduleQcTabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Rejected Images",
          status: "4",
          scheduleQcTabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Editor Reverted",
          status: "8",
          scheduleQcTabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
        {
          tabName: "Improvisation Worklist",
          status: "5",
          scheduleQcTabContent,
          isFirstTime: true,
          callApplyFilter: false,
          first: 0,
        },
      ],

      userroleid: 0,
      isSingleImageView: false,
      isHelpScreenActive: false,
      isEditorActive: false,
    };
    let storageData = JSON.parse(sessionStorage.getItem("WholeQCTab"));
    let storageDataEditor = JSON.parse(
      sessionStorage.getItem("WholeQCTabEditor")
    );
    if (storageData) {
      sessionStorage.setItem("firstTab", "0");
    }
    if (storageDataEditor) {
      sessionStorage.setItem("firstTabEditor", "0");
    }
    let name = [];
    let reparr = [];
    let date = finalDate;
    // let editordate = edRevfinalDate;
    let splitDate = date.split("-");
    let splitDateFormat1 = splitDate[0].split("/");
    let splitDateFormat2 = splitDate[1].split("/");

    // let editorplitDate = editordate.split('-');
    // let editorsplitDateFormat1 = editorplitDate[0].split('/');
    // let editorsplitDateFormat2 = editorplitDate[1].split('/');

    // let tabCopyEditorRevrted = [];

    let tempdate1 = [
      splitDateFormat1[2],
      splitDateFormat1[1],
      splitDateFormat1[0],
    ].join("-");
    let tempdate2 = [
      splitDateFormat2[2],
      splitDateFormat2[1],
      splitDateFormat2[0],
    ].join("-");

    // let editortempdate1 = [editorsplitDateFormat1[2], editorsplitDateFormat1[1], editorsplitDateFormat1[0]].join('-');
    // let editortempdate2 = [editorsplitDateFormat2[2], editorsplitDateFormat2[1], editorsplitDateFormat2[0]].join('-');

    this.subscription = SharedService.getUserDetails().subscribe((data) => {
      localStorage.setItem("userRoleID", data.userRoleID);
      this.setState({ userroleid: data.userRoleID });
      axios
        .get(
          `${config.apiUrl + config.qcApi}Common/GetEditorByLocation/${data.userRoleID
          }`
        )
        .then((response) => {
          let tabCopy = JSON.parse(JSON.stringify(this.state.tabs)); // create a copy of state
          let scheduleQCTabCopy = JSON.parse(
            JSON.stringify(this.state.scheduleQcTabs)
          );
          if (
            response.status === 200 &&
            response.data &&
            response.data.objRespMessage
          ) {
            reparr = response.data.objRespMessage;
            if (reparr !== null) {
              reparr.forEach((ele) => {
                let objele = {
                  id: ele.intEditorId,
                  name: ele.strEditorName,
                };
                name.push(objele);
              });
            }

            tabCopy.map((tab) =>
            tab.status !== "8"
              ? (
                tab.tabContent.bindEditorByLoc.options = response.data.objRespMessage,
                tab.tabContent.bindEditorByLoc.selectedValue = name      
                    )
              : null
          );
            sessionStorage.setItem(
              "ListOfEditor",
              JSON.stringify(response.data.objRespMessage)
            );

           
            axios
              .get(
                `${config.apiUrl + config.qcApi}Common/GetEditorByLocation/${data.userRoleID
                }?status=8`
              )
              .then((response) => {
                let tabCopyEditorRevrted = JSON.parse(
                  JSON.stringify(this.state.tabs)
                ); // create a copy of state
                if (
                  response.status === 200 &&
                  response.data &&
                  response.data.objRespMessage
                ) {
                  reparr = [];
                  reparr = response.data.objRespMessage;
                  if (reparr !== null) {
                    name = [];
                    reparr.forEach((ele) => {
                      let objele = {
                        id: ele.intEditorId,
                        name: ele.strEditorName,
                      };
                      name.push(objele);
                    });
                  }

                  tabCopyEditorRevrted.map((tab) =>
                  tab.status === "8"
                    ? (
                      tab.tabContent.bindEditorByLoc.options = response.data.objRespMessage,
                      tab.tabContent.bindEditorByLoc.selectedValue = name
                      )
														 
                    : null
                 );
                  sessionStorage.setItem(
                    "EditorRev",
                    JSON.stringify(response.data.objRespMessage)
                  );

                }
                this.setState({ tabs: tabCopyEditorRevrted, showError: false });
                SharedService.setDataForDQ(tabCopyEditorRevrted);
              })
              .catch((error) => {
                if (!error.response) {
                  error = "Not able to communicate with the backend service !";
                  console.log(error);
                  this.setState({ showError: true });
                }
              });

            if (storageData) {
              storageData.forEach((stData) => {
                stData.callApplyFilter = true;
                stData.isFirstTime = true;
                stData.tabContent.loader = "";
                stData.first = 2;
                if (stData.status !== "8") {
                  stData.tabContent.bindEditorByLoc.options =
                    response.data.objRespMessage;
                  stData.tabContent.bindEditorByLoc.selectedValue = name;
                }
                if (stData.status === "1") {
                  stData.tabContent.currentPage = parseInt(
                    sessionStorage.getItem("QCCurrentPage")
                  );
                }
              });

              // let finalTabCopy = JSON.parse(JSON.stringify(tabCopy));
              // let storageDataCopy = {
              //   ...finalTabCopy,
              //   ...storageData
              // }
              this.setState({ tabs: storageData });
              SharedService.setDataForDQ(storageData);
            } else {
              //////////////////////FIRST TIME/////////////////////////////////////////////////////////////////////////////////

              sessionStorage.setItem("firstTab", "1");
              sessionStorage.setItem("isActiveTab", "1");
              axios
                .post(
                  `${config.apiUrl + config.qcApi}Image/GetDailyQCImages/`,
                  {
                    lstEditorID: name.map((e) => e.id),
                    dtmFromDate: tempdate1,
                    dtmToDate: tempdate2,
                    intUserRoleID: data.userRoleID,
                  }
                )
                .then((response) => {
                  if (
                    response.status === 200 &&
                    response.data &&
                    response.data.objRespMessage
                  ) {
                    let tabCopyTemp = JSON.parse(
                      JSON.stringify(this.state.tabs)
                    );
                    tabCopyTemp.forEach((state) => {
                      if (state.status === "1") {
                        state.tabContent.filteredData =
                          response.data.objRespMessage;
                        state.tabContent.loader = "false";
                        state.isFirstTime = false;
                      }
                    });
                    this.setState({ tabs: tabCopyTemp, showError: false });
                    SharedService.setDataForDQ(tabCopyTemp);
                  } else if (response.status === 204) {
                    let tabCopyTemp = JSON.parse(
                      JSON.stringify(this.state.tabs)
                    );
                    tabCopyTemp.forEach((state) => {
                      if (state.status === "1") {
                        state.tabContent.filteredData = [];
                        state.tabContent.loader = "false";
                        state.isFirstTime = false;
                      }
                    });
                    this.setState({ tabs: tabCopyTemp, showError: false });
                    SharedService.setDataForDQ(tabCopyTemp);
                  } else {
                    this.setState({ showError: true });
                  }
                });

              ///////////////////////////////////////////////////////////////////////////////////////////////////////
              this.setState({
                tabs: tabCopy,
                scheduleQcTabs: scheduleQCTabCopy,
                showError: false,
              });
              SharedService.setDataForDQ(this.state.tabs);
              SharedService.setDataForScheduleQC(this.state.scheduleQcTabs);
            }
          } else {
            this.setState({ showError: true });
          }
        })
        .catch((error) => {
          if (!error.response) {
            error = "Not able to communicate with the backend service !";
            console.log(error);
            this.setState({ showError: true });
          }
        });

      ////////////////////////////////////////////// EDITOR FlOW //////////////////////////////////////////////////////////////////////////////////////////////////

      if (storageDataEditor) {
        storageDataEditor.forEach((stData) => {
          stData.callApplyFilter = true;
          stData.isFirstTime = true;
          stData.first = 2;
        });

        // let storageDataCopy = {
        //   ...storageDataEditor
        // }

        this.setState({ editorTabs: storageDataEditor });
        SharedService.setEditorDataForDQ(storageDataEditor);
      } else {
        sessionStorage.setItem("firstTabEditor", "1");
        sessionStorage.setItem("isActiveTabEditor", "2");
        axios
          .post(
            `${config.apiUrl + config.qcApi}Image/GetEditorTabularImages/`,
            {
              objDailyQCFilter: {
                dtmFromDate: tempdate1,
                dtmToDate: tempdate2,
              },
              intActionStatusCode: 2,
              intUserRoleID: data.userRoleID, //localStorage.getItem("userRoleID")
            }
          )
          .then((response) => {
            if (
              response.status === 200 &&
              response.data &&
              response.data.objRespMessage
            ) {
              let tabCopyTemp = JSON.parse(
                JSON.stringify(this.state.editorTabs)
              );
              tabCopyTemp.forEach((state) => {
                if (state.status === "2") {
                  state.editorTabContent.filteredData =
                    response.data.objRespMessage;
                  state.editorTabContent.loader = "false";
                  state.isFirstTime = false;
                }
              });
              this.setState({ editorTabs: tabCopyTemp, showError: false });
              SharedService.setEditorDataForDQ(tabCopyTemp);
            } else if (response.status === 204) {
              let tabCopyTemp = JSON.parse(
                JSON.stringify(this.state.editorTabs)
              );
              tabCopyTemp.forEach((state) => {
                if (state.status === "2") {
                  state.editorTabContent.filteredData = [];
                  state.editorTabContent.loader = "false";
                  state.isFirstTime = false;
                }
              });
              this.setState({ editorTabs: tabCopyTemp, showError: false });
              SharedService.setEditorDataForDQ(tabCopyTemp);
            } else {
              this.setState({ showError: true });
            }
          });
      }
    });
  }

  async componentDidMount() {
    try {
      try {
        const [
          bindLocation,
          bindSource,
          bindChannel,
          bindChannelGenre,
          bindDeliveryPlatform,
          bindContentType,
          bindImageType,
          bindGenre,
          bindRatio,
          bindErrors,
          bindImageEditType,
          bindGroup,
          bindEditor,
          bindAssignedLanguage,
          bindAssignedClient,
          bindAction,
        ] = await Promise.all([
          axios.get(`${config.apiUrl + config.qcApi}Common/GetCountryLocation`),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetSource`),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetChannels`),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetChannelGenre`),
          axios.get(
            `${config.apiUrl + config.qcApi}Common/GetDeliveryPlatforms`
          ),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetContentTypes`),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetImageTypes`),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetGenres`),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetRatios`),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetErrors`),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetImageEditType`),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetChannelGroup`),
          axios.get(
            `${config.apiUrl + config.qcApi
            }Common/GetEditorByLocation/0?isAllocator=2`
          ),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetLanguages`),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetAssignedClient`),
          axios.get(`${config.apiUrl + config.qcApi}Common/GetAction`),
        ]);
        // Hardcoded the data for Worklist options
        let showError = false;
        let tabCopy = JSON.parse(JSON.stringify(this.state.tabs)); // create a copy of state
        let editorTabCopy = JSON.parse(JSON.stringify(this.state.editorTabs)); // create a copy of state
        let scheduleQcTabCopy = JSON.parse(
          JSON.stringify(this.state.scheduleQcTabs)
        ); // create a copy of state

        if (
          bindLocation.status === 200 &&
          bindLocation.data &&
          bindLocation.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
            (tab.tabContent.bindLocation.options =
              bindLocation.data.objRespMessage)
          );
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindLocation.options =
              bindLocation.data.objRespMessage)
          );
        } else {
          showError = true;
        }

        if (
          bindEditor.status === 200 &&
          bindEditor.data &&
          bindEditor.data.objRespMessage
        ) {
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindEditorByLoc.options =
              bindEditor.data.objRespMessage)
          );
        } else {
          showError = true;
        }

        const bindWorklist = {
          objRespMessage: [
            { intWorklistID: 2, strWorklist: "Daily QC" },
            { intWorklistID: 1, strWorklist: "Scheduled QC" },
          ],
          intStatusCode: 200,
        };
        editorTabCopy.map(
          (tab) =>
          (tab.editorTabContent.bindWorklist.options =
            bindWorklist.objRespMessage)
        );

        if (
          bindSource.status === 200 &&
          bindSource.data &&
          bindSource.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
            (tab.tabContent.bindSource.options =
              bindSource.data.objRespMessage)
          );
          editorTabCopy.map(
            (tab) =>
            (tab.editorTabContent.bindSource.options =
              bindSource.data.objRespMessage)
          );
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindSource.options =
              bindSource.data.objRespMessage)
          );
        } else {
          showError = true;
        }

        if (
          bindChannel.status === 200 &&
          bindChannel.data &&
          bindChannel.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
            (tab.tabContent.bindChannel.options =
              bindChannel.data.objRespMessage)
          );
          editorTabCopy.map(
            (tab) =>
            (tab.editorTabContent.bindChannel.options =
              bindChannel.data.objRespMessage)
          );
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindChannel.options =
              bindChannel.data.objRespMessage)
          );
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindAssignedChannel.options =
              bindChannel.data.objRespMessage)
          );
        } else {
          showError = true;
        }

        if (
          bindChannelGenre.status = 200 &&
          bindChannel.data &&
          bindChannelGenre.data.objRespMessage
        ) {
          console.log(bindChannelGenre.data.objRespMessage)
          scheduleQcTabCopy.map(
            (tab) =>
              (tab.scheduleQcTabContent.bindChannelGenre.options = bindChannelGenre.data.objRespMessage)
          )
        }
        else {
          showError = true;
        }


        if (
          bindDeliveryPlatform.status === 200 &&
          bindDeliveryPlatform.data &&
          bindDeliveryPlatform.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
            (tab.tabContent.bindDeliveryPlatform.options =
              bindDeliveryPlatform.data.objRespMessage)
          );
        } else {
          showError = true;
        }

        if (
          bindContentType.status === 200 &&
          bindContentType.data &&
          bindContentType.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
            (tab.tabContent.bindContentType.options =
              bindContentType.data.objRespMessage)
          );
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindContentType.options =
              bindContentType.data.objRespMessage)
          );
        } else {
          showError = true;
        }

        if (
          bindImageType.status === 200 &&
          bindImageType.data &&
          bindImageType.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
            (tab.tabContent.bindImageType.options =
              bindImageType.data.objRespMessage)
          );
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindImageType.options =
              bindImageType.data.objRespMessage)
          );
        } else {
          showError = true;
        }

        if (
          bindGenre.status === 200 &&
          bindGenre.data &&
          bindGenre.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
              (tab.tabContent.bindGenre.options = bindGenre.data.objRespMessage)
          );
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindGenre.options =
              bindGenre.data.objRespMessage)
          );
        } else {
          showError = true;
        }

        if (
          bindRatio.status === 200 &&
          bindRatio.data &&
          bindRatio.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
              (tab.tabContent.bindRatio.options = bindRatio.data.objRespMessage)
          );
          var filteredRatios = bindRatio.data.objRespMessage.filter(
            (el) => !(el.intRatioID < 0)
          );
          scheduleQcTabCopy.map(
            (tab) =>
              (tab.scheduleQcTabContent.bindRatio.options = filteredRatios)
          );
        } else {
          showError = true;
        }

        if (
          bindErrors.status === 200 &&
          bindErrors.data &&
          bindErrors.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
            (tab.tabContent.bindErrors.options =
              bindErrors.data.objRespMessage)
          );
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindErrors.options =
              bindErrors.data.objRespMessage)
          );
        } else {
          showError = true;
        }

        if (
          bindImageEditType.status === 200 &&
          bindImageEditType.data &&
          bindImageEditType.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
            (tab.tabContent.bindImageEditType.options =
              bindImageEditType.data.objRespMessage)
          );
        } else showError = true;

        if (
          bindGroup.status === 200 &&
          bindGroup.data &&
          bindGroup.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
              (tab.tabContent.bindGroup.options = bindGroup.data.objRespMessage)
          );
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindGroup.options =
              bindGroup.data.objRespMessage)
          );
        } else showError = true;

        if (
          bindAssignedLanguage.status === 200 &&
          bindAssignedLanguage.data &&
          bindAssignedLanguage.data.objRespMessage
        ) {
          tabCopy.map(
            (tab) =>
            (tab.tabContent.bindAssignedLanguage.options =
              bindAssignedLanguage.data.objRespMessage)
          );
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindAiringLang.options =
              bindAssignedLanguage.data.objRespMessage)
          );
        } else showError = true;

        if (
          bindAssignedClient.status === 200 &&
          bindAssignedClient.data &&
          bindAssignedClient.data.objRespMessage
        ) {
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindAssignedClient.options =
              bindAssignedClient.data.objRespMessage)
          );
        } else showError = true;

        if (
          bindAction.status === 200 &&
          bindAction.data &&
          bindAction.data.objRespMessage
        ) {
          scheduleQcTabCopy.map(
            (tab) =>
            (tab.scheduleQcTabContent.bindAction.options =
              bindAction.data.objRespMessage)
          );
        } else showError = true;

        this.setState({
          tabs: tabCopy,
          editorTabs: editorTabCopy,
          scheduleQcTabs: scheduleQcTabCopy,
          showError: showError,
        });
        SharedService.setDataForDQ(tabCopy);
        SharedService.setEditorDataForDQ(editorTabCopy);
        SharedService.setDataForScheduleQC(scheduleQcTabCopy);
      } catch (err) {
        console.log(err);
        this.setState({ showError: true });
      }

      this.subscription = SharedService.getFilterData().subscribe((data) => {
        if (data.action === "applyfilter") {
          let tabCopy = JSON.parse(JSON.stringify(this.state.tabs));
          tabCopy.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              state.tabContent.bindLocation.selectedValue = [];
              state.tabContent.bindEditorByLoc.selectedValue = [];
              state.tabContent.bindSource.selectedValue = [];
              state.tabContent.bindChannel.selectedValue = [];
              state.tabContent.bindDeliveryPlatform.selectedValue = [];
              state.tabContent.bindImageType.selectedValue = [];
              state.tabContent.bindContentType.selectedValue = [];
              state.tabContent.bindGenre.selectedValue = [];
              state.tabContent.bindSubgenre.selectedValue = [];
              state.tabContent.bindRatio.selectedValue = [];
              state.tabContent.bindImageEditType.selectedValue = [];
              state.tabContent.bindImgSize.selectedValue = [];
              state.tabContent.bindGroup.selectedValue = [];
              state.tabContent.bindAssignedLanguage.selectedValue = [];
              state.tabContent.LinearVOD = data.stateData.LinearVOD;
              data.stateData.arrayValLoc.forEach((arrVal) => {
                state.tabContent.bindLocation.selectedValue.push(arrVal);
              });
              data.propsData.tabContent.bindEditorByLoc.selectedValue.forEach(
                (arrVal) => {
                  state.tabContent.bindEditorByLoc.selectedValue.push(arrVal);
                }
              );
              data.stateData.arrayValSource.forEach((arrVal) => {
                state.tabContent.bindSource.selectedValue.push(arrVal);
              });
              data.stateData.arrayValChannel.forEach((arrVal) => {
                state.tabContent.bindChannel.selectedValue.push(arrVal);
              });

              data.stateData.arrayValDelPtfm.forEach((arrVal) => {
                state.tabContent.bindDeliveryPlatform.selectedValue.push(
                  arrVal
                );
              });
              data.stateData.arrayValImageType.forEach((arrVal) => {
                state.tabContent.bindImageType.selectedValue.push(arrVal);
              });

              data.stateData.arrayValImageEditType.forEach((arrVal) => {
                state.tabContent.bindImageEditType.selectedValue.push(arrVal);
              });

              data.stateData.arrayValContentType.forEach((arrVal) => {
                state.tabContent.bindContentType.selectedValue.push(arrVal);
              });
              data.stateData.arrayValGenre.forEach((arrVal) => {
                state.tabContent.bindGenre.selectedValue.push(arrVal);
              });
              data.stateData.arrayValSubGenre.forEach((arrVal) => {
                state.tabContent.bindSubgenre.selectedValue.push(arrVal);
              });
              data.stateData.arrayValRatio.forEach((arrVal) => {
                state.tabContent.bindRatio.selectedValue.push(arrVal);
              });
              data.stateData.arrayValImgSize.forEach((arrVal) => {
                state.tabContent.bindImgSize.selectedValue.push(arrVal);
              });

              data.stateData.arrayValGroup.forEach((arrVal) => {
                state.tabContent.bindGroup.selectedValue.push(arrVal);
              });

              data.stateData.arrayValAssignedLanguage.forEach((arrVal) => {
                state.tabContent.bindAssignedLanguage.selectedValue.push(
                  arrVal
                );
              });

              state.tabContent.filteredData = data.stateData.filteredData;
              state.tabContent.displayDate = data.stateData.displayDate;
              state.tabContent.loader = data.stateData.loading;
              state.tabContent.pageSize = data.stateData.pageSize;
              state.tabContent.currentPage = data.stateData.currentPage;
              state.tabContent.startDate = data.stateData.startDate;
              state.tabContent.endDate = data.stateData.endDate;
              state.tabContent.startAiringDate = data.stateData.startAiringDate;
              state.tabContent.endAiringDate = data.stateData.endAiringDate;
              state.tabContent.displayAiringDate =
                data.stateData.displayAiringDate;
              state.isFirstTime = false;
              state.tabContent.activeTab = true;
              state.first = 0;
              // state.tabContent.LinearVOD = data.stateData.LinearVOD
            } else {
              state.tabContent.activeTab = false;
            }
          });
          let test = JSON.parse(JSON.stringify(tabCopy));
          test.forEach((state) => {
            state.tabContent.filteredData = [];
          });

          sessionStorage.setItem("WholeQCTab", JSON.stringify(test));
          this.setState({ tabs: tabCopy });
          SharedService.setDataForDQ(tabCopy);
        }

        if (data.action === "applyReset") {
          this.state.tabs.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              state.tabContent.bindLocation.selectedValue = [];
              state.tabContent.bindEditorByLoc.selectedValue = [];
              state.tabContent.bindEditorByLoc.options =
                state.status === "8"
                  ? JSON.parse(sessionStorage.getItem("EditorRev"))
                  : JSON.parse(sessionStorage.getItem("ListOfEditor"));
              state.tabContent.bindSource.selectedValue = [];
              state.tabContent.bindChannel.selectedValue = [];
              state.tabContent.bindDeliveryPlatform.selectedValue = [];
              state.tabContent.bindImageType.selectedValue = [];
              state.tabContent.bindContentType.selectedValue = [];
              state.tabContent.bindGenre.selectedValue = [];
              state.tabContent.bindSubgenre.selectedValue = [];
              state.tabContent.bindSubgenre.options = [];
              state.tabContent.bindRatio.selectedValue = [];
              state.tabContent.bindImageEditType.selectedValue = [];
              state.tabContent.bindImgSize.selectedValue = [];
              state.tabContent.bindImgSize.options = [];
              state.tabContent.bindGroup.selectedValue = [];
              state.tabContent.bindAssignedLanguage.selectedValue = [];
              state.tabContent.filteredData = [];
              state.tabContent.otherTabDates = editorPrefillfinalDate;
              state.tabContent.displayDate = "";
              state.tabContent.loader = "";
              state.tabContent.startDate = null;
              state.tabContent.endDate = null;
              state.tabContent.pageSize = 25;
              state.tabContent.displayAiringDate = "";
              state.tabContent.startAiringDate = null;
              state.tabContent.endAiringDate = null;
              state.status === "1"
                ? (state.tabContent.currentPage = 1)
                : (state.tabContent.currentPage = 0);
              state.isFirstTime = false;

              state.first = 0;
            }
          });
          let test = JSON.parse(JSON.stringify(this.state.tabs));
          sessionStorage.setItem("WholeQCTab", JSON.stringify(test));

          this.setState({ ...this.state });
          SharedService.setDataForDQ(this.state.tabs);
        }

        if (data.action === "BulkAction") {
          let tabCopy =
            data.WorkListID === "2"
              ? JSON.parse(JSON.stringify(this.state.tabs))
              : JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          tabCopy.forEach((state) => {
            if (state.status === "1") {
              if (data.WorkListID === "2") {
                state.tabContent.currentPage = data.page;
                state.tabContent.filteredData = data.filterParams;
                SharedService.setDataForDQ(tabCopy);
              } else {
                state.scheduleQcTabContent.currentPage = data.page;
                state.scheduleQcTabContent.filteredData = data.filterParams;
                //state.scheduleQcTabContent.AllImagesCount = data.AllImagesCount;
                state.scheduleQcTabContent.AllImagesCount = {
                  ...state.scheduleQcTabContent.AllImagesCount,
                  intActionedCount: data.AllImagesCount.intActionedCount,
                  intMissingCount: data.AllImagesCount.intMissingCount,
                  intUnActionedCount: data.AllImagesCount.intUnActionedCount,
                };
                SharedService.setDataForScheduleQC(tabCopy);
              }
            }
          });
          sessionStorage.setItem("WholeQCTab", JSON.stringify(tabCopy));
          if (data.WorkListID === "2") {
            this.setState({ tabs: tabCopy });
            SharedService.setDataForDQ(this.state.tabs);
          } else {
            this.setState({ scheduleQcTabs: tabCopy });
            SharedService.setDataForScheduleQC(this.state.scheduleQcTabs);
          }
        }

        if (data.action === "populateEditor") {
          let tabCopy =
            data.worklisttype === "2"
              ? JSON.parse(JSON.stringify(this.state.tabs))
              : JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          tabCopy.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              // state.tabContent.bindEditorByLoc.options = [];
              if (data.worklisttype === "2") {
                state.tabContent.bindEditorByLoc.selectedValue = [];
              } else {
                state.scheduleQcTabContent.bindEditorByLoc.selectedValue = [];
              }

              let actionStatus = data.stateData.status;
              let locatioIds = data.locIndex.map((loc) => {
                return loc.id;
              });
              let link = "";
              if (data.worklisttype === "2") {
                link = axios.get(
                  `${config.apiUrl + config.qcApi}Common/GetEditorByLocation/${this.state.userroleid
                  }?strLocation=${String(locatioIds)}&status=${actionStatus}`
                );
              } else {
                link = axios.get(
                  `${config.apiUrl + config.qcApi
                  }Common/GetEditorByLocation/0?strLocation=${String(
                    locatioIds
                  )}&isAllocator=2&status=${actionStatus}`
                );
              }

              link
                .then((response) => {
                  if (
                    response.status === 200 &&
                    response.data &&
                    response.data.objRespMessage
                  ) {
                    if (data.worklisttype === "2") {
                      //state.tabContent.bindLocation.selectedValue = data.locIndex;
                      state.tabContent.bindEditorByLoc.options = response.data.objRespMessage;

                      // let test = JSON.parse(JSON.stringify(tabCopy));
                      // sessionStorage.setItem("WholeQCTab", JSON.stringify(test));

                      this.setState({ tabs: tabCopy, showError: false });
                      SharedService.setDataForDQ(tabCopy);
                    } else {
                      state.scheduleQcTabContent.bindEditorByLoc.options = response.data.objRespMessage;
                      this.setState({
                        scheduleQcTabs: tabCopy,
                        showError: false,
                      });
                      SharedService.setDataForScheduleQC(tabCopy);
                    }
                  } else if (response.status === 204) {
                    if (data.worklisttype === "2") {
                      state.tabContent.bindEditorByLoc.options = [];
                      state.tabContent.bindEditorByLoc.selectedValue = [];
                      this.setState({ tabs: tabCopy, showError: false });
                      SharedService.setDataForDQ(tabCopy);
                    } else {
                      state.scheduleQcTabContent.bindEditorByLoc.options = [];
                      state.scheduleQcTabContent.bindEditorByLoc.selectedValue =
                        [];
                      this.setState({
                        scheduleQcTabs: tabCopy,
                        showError: false,
                      });
                      SharedService.setDataForScheduleQC(tabCopy);
                    }
                  } else {
                    this.setState({ showError: true });
                  }
                })
                .catch((error) => {
                  if (!error.response) {
                    error =
                      "Not able to communicate with the backend service !";
                    console.log(error);
                    this.setState({ showError: true });
                  }
                });
            }
          });
        }

        if (data.action === "populateSubGenre") {
          let tabCopy =
            data.worklisttype === "2"
              ? JSON.parse(JSON.stringify(this.state.tabs))
              : JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          tabCopy.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              if (data.genreIndex.length > 0) {
                //  state.tabContent.bindSubgenre.options = [];
                let genreIds = data.genreIndex.map((genre) => {
                  return genre.id;
                });
                axios
                  .get(
                    `${config.apiUrl + config.qcApi
                    }Common/GetSubGenresByGenre/${String(genreIds)}`
                  )
                  .then((response) => {
                    if (
                      response.status === 200 &&
                      response.data &&
                      response.data.objRespMessage
                    ) {
                      //state.tabContent.bindGenre.selectedValue = data.genreIndex;
                      if (data.worklisttype === "2") {
                        state.tabContent.bindSubgenre.options = response.data.objRespMessage;
                        this.setState({ tabs: tabCopy, showError: false });
                        SharedService.setDataForDQ(this.state.tabs);
                      } else {
                        state.scheduleQcTabContent.bindSubgenre.options = response.data.objRespMessage;
                        this.setState({
                          scheduleQcTabs: tabCopy,
                          showError: false,
                        });
                        SharedService.setDataForScheduleQC(
                          this.state.scheduleQcTabs
                        );
                      }
                      // let test = JSON.parse(JSON.stringify(tabCopy));
                      // sessionStorage.setItem("WholeQCTab", JSON.stringify(test));
                    } else {
                      this.setState({ showError: true });
                    }
                  })
                  .catch((error) => {
                    if (!error.response) {
                      error =
                        "Not able to communicate with the backend service !";
                      console.log(error);
                      this.setState({ showError: true });
                    }
                  });
              } else {
                if (data.worklisttype === "2") {
                  state.tabContent.bindGenre.selectedValue = [];
                  state.tabContent.bindSubgenre.options = [];
                  state.tabContent.bindSubgenre.selectedValue = [];
                  this.setState({ tabs: tabCopy });
                  SharedService.setDataForDQ(tabCopy);
                } else {
                  state.scheduleQcTabContent.bindGenre.selectedValue = [];
                  state.scheduleQcTabContent.bindSubgenre.options = [];
                  state.scheduleQcTabContent.bindSubgenre.selectedValue = [];
                  this.setState({ scheduleQcTabs: tabCopy });
                  SharedService.setDataForScheduleQC(tabCopy);
                }
              }
            }
          });
        }

        if (data.action === "populateImgSize") {
          let tabCopy =
            data.worklisttype === "2"
              ? JSON.parse(JSON.stringify(this.state.tabs))
              : JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          tabCopy.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              if (data.ratioIndex.length > 0) {
                let ratioIds = data.ratioIndex.map((ratio) => {
                  return ratio.id;
                });
                axios
                  .get(
                    `${config.apiUrl + config.qcApi
                    }Common/GetImagesize/${String(ratioIds)}`
                  )
                  .then((response) => {
                    if (
                      response.status === 200 &&
                      response.data &&
                      response.data.objRespMessage
                    ) {
                      if (data.worklisttype === "2") {
                        state.tabContent.bindImgSize.options = response.data.objRespMessage;
                        this.setState({ tabs: tabCopy, showError: false });
                        SharedService.setDataForDQ(this.state.tabs);
                      } else {
                        state.scheduleQcTabContent.bindImgSize.options = response.data.objRespMessage;
                        this.setState({
                          scheduleQcTabs: tabCopy,
                          showError: false,
                        });
                        SharedService.setDataForScheduleQC(
                          this.state.scheduleQcTabs
                        );
                      }
                    } else if (response.status === 204) {
                      if (data.worklisttype === "2") {
                        state.tabContent.bindImgSize.options = [];
                        state.tabContent.bindImgSize.selectedValue = [];
                        this.setState({ tabs: tabCopy, showError: false });
                        SharedService.setDataForDQ(tabCopy);
                      } else {
                        state.scheduleQcTabContent.bindImgSize.options = [];
                        state.scheduleQcTabContent.bindImgSize.selectedValue =
                          [];
                        this.setState({
                          scheduleQcTabs: tabCopy,
                          showError: false,
                        });
                        SharedService.setDataForScheduleQC(tabCopy);
                      }
                    } else {
                      this.setState({ showError: true });
                    }
                  })
                  .catch((error) => {
                    if (!error.response) {
                      error =
                        "Not able to communicate with the backend service !";
                      console.log(error);
                      this.setState({ showError: true });
                    }
                  });
              } else {
                if (data.worklisttype === "2") {
                  state.tabContent.bindRatio.selectedValue = [];
                  state.tabContent.bindImgSize.options = [];
                  state.tabContent.bindImgSize.selectedValue = [];
                  this.setState({ tabs: tabCopy });
                  SharedService.setDataForDQ(tabCopy);
                } else {
                  state.scheduleQcTabContent.bindRatio.selectedValue = [];
                  state.scheduleQcTabContent.bindImgSize.options = [];
                  state.scheduleQcTabContent.bindImgSize.selectedValue = [];
                  this.setState({ scheduleQcTabs: tabCopy });
                  SharedService.setDataForScheduleQC(tabCopy);
                }
              }
            }
          });
        }


        if (data.action === "populateChannel&Language&ChannelGenre") {

          let tabCopy = JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          tabCopy.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              state.scheduleQcTabContent.bindChannel.selectedValue = [];

              state.scheduleQcTabContent.bindAiringLang.selectedValue = [];

              let grupIds = data.groupIndex.map((grp) => {
                return grp.id;
              });

              let channel = `${config.apiUrl + config.qcApi
                }Common/GetChannels/?strChannelGroupID=${String(grupIds)}`;
              let lang = `${config.apiUrl + config.qcApi
                }Common/GetLanguages/?strChannelGroupID=${String(grupIds)}`;
              let channelgenre = `${config.apiUrl + config.qcApi
                }Common/GetChannelGenre/?strChannelGroupID=${String(grupIds)}`;

              const requestChannel = axios.get(channel);
              const requestLang = axios.get(lang);
              const requestChannelGenre = axios.get(channelgenre);
              axios
                .all([requestChannel, requestLang, requestChannelGenre])
                .then(
                  axios.spread((...responses) => {
                    const responseChannel = responses[0];
                    const responseLang = responses[1];
                    const responseChannelGenre = responses[2];

                    if (
                      responseChannel.status === 200 &&
                      responseChannel.data &&
                      responseChannel.data.objRespMessage &&
                      responseLang.status === 200 &&
                      responseLang.data &&
                      responseLang.data.objRespMessage &&
                      responseChannelGenre.status === 200 &&
                      responseChannelGenre.data &&
                      responseChannelGenre.data.objRespMessage
                    ) {
                      state.scheduleQcTabContent.bindChannel.options =
                        responseChannel.data.objRespMessage === null
                          ? []
                          : responseChannel.data.objRespMessage;
                      state.scheduleQcTabContent.bindAiringLang.options =
                        responseLang.data.objRespMessage === null
                          ? []
                          : responseLang.data.objRespMessage;
                      state.scheduleQcTabContent.bindChannelGenre.options =
                        responseChannelGenre.data.objRespMessage === null
                          ? []
                          : responseChannelGenre.data.objRespMessage
                      this.setState({
                        scheduleQcTabs: tabCopy,
                        showError: false,
                      });
                      SharedService.setDataForScheduleQC(tabCopy);
                    } else if (
                      responseChannel.status === 204 ||
                      responseLang.status === 204 ||
                      responseChannelGenre.status === 204
                    ) {
                      state.scheduleQcTabContent.bindChannel.options = [];
                      state.scheduleQcTabContent.bindChannel.selectedValue = [];

                      state.scheduleQcTabContent.bindAiringLang.options = [];
                      state.scheduleQcTabContent.bindAiringLang.selectedValue =
                        [];

                      state.scheduleQcTabContent.bindChannelGenre.options = [];
                      state.scheduleQcTabContent.bindChannelGenre.selectedValue =
                        [];

                      this.setState({
                        scheduleQcTabs: tabCopy,
                        showError: false,
                      });
                      SharedService.setDataForScheduleQC(tabCopy);
                    } else {
                      this.setState({ showError: true });
                    }
                    // use/access the results
                  })
                )
                .catch((error) => {
                  if (!error.response) {
                    error =
                      "Not able to communicate with the backend service !";
                    console.log(error);
                    this.setState({ showError: true });
                  }
                });
            }
          });
        }

        if (data.action === "populateChannelWRTGroup&ChannelGenre") {

          let tabCopy = JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          let groupId = data.arrayValGroup ? data.arrayValGroup.id : null;
          tabCopy.forEach((state) => {
            if (state.tabName === data.stateData.tabName && groupId) {
              state.scheduleQcTabContent.bindChannel.selectedValue = [];
              let channelGenreIds = data.channelGenreIndex.map((channelGenre) => {
                return channelGenre.id;
              });
              let requestchannel = ""
              if (channelGenreIds.length > 0) {
                requestchannel = `${config.apiUrl + config.qcApi
                  }Common/GetChannels/?strChannelGroupID=${String(groupId)}&strChannelGenreID=${String(channelGenreIds)}`;
              }
              else {
                requestchannel = `${config.apiUrl + config.qcApi
                  }Common/GetChannels/?strChannelGroupID=${String(groupId)}`;
              }

              axios.get(requestchannel)
                .then(responseChannel => {
                  if (
                    responseChannel.status === 200 &&
                    responseChannel.data &&
                    responseChannel.data.objRespMessage
           
                  ) {
                    state.scheduleQcTabContent.bindChannel.options =
                      responseChannel.data.objRespMessage === null
                        ? []
                        : responseChannel.data.objRespMessage;

                    this.setState({
                      scheduleQcTabs: tabCopy,
                      showError: false,
                    });
                    SharedService.setDataForScheduleQC(tabCopy);
                  } else if (
                    responseChannel.status === 204
                  ) {
                    state.scheduleQcTabContent.bindChannel.options = [];
                    state.scheduleQcTabContent.bindChannel.selectedValue = [];

                    this.setState({
                      scheduleQcTabs: tabCopy,
                      showError: false,
                    });
                    SharedService.setDataForScheduleQC(tabCopy);
                  } else {
                    this.setState({ showError: true });
                  }
                  // use/access the results
                })

                .catch((error) => {
                  if (!error.response) {
                    error =
                      "Not able to communicate with the backend service !";
                    console.log(error);
                    this.setState({ showError: true });
                  }
                });
            }
          });
        }

        if (data.action === "imageTopView") {
          let tabCopy =
            data.worklisttype === "2"
              ? JSON.parse(JSON.stringify(this.state.tabs))
              : JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          tabCopy.forEach((state) => {
            if (state.status === data.status) {
              if (data.worklisttype === "2") {
                state.tabContent.pageSize = data.pageSize;
                state.tabContent.currentPage =
                  data.status === "8" || data.status === "4"
                    ? data.currentPage - 1
                    : data.currentPage;
                state.tabContent.activeTab = true;
              } else {
                state.scheduleQcTabContent.pageSize = data.pageSize;
                state.scheduleQcTabContent.currentPage =
                  data.status === "8" || data.status === "4"
                    ? data.currentPage - 1
                    : data.currentPage;
                state.scheduleQcTabContent.activeTab = true;
                if (data.status !== "8" && data.status !== "4") {
                  state.scheduleQcTabContent.filteredData = data.filteredData;
                  state.scheduleQcTabContent.AllImagesCount = {
                    ...state.scheduleQcTabContent.AllImagesCount,
                    intActionedCount: data.AllImagesCount.intActionedCount,
                    intMissingCount: data.AllImagesCount.intMissingCount,
                    intUnActionedCount: data.AllImagesCount.intUnActionedCount,
                  };
                }
              }
            } else {
              data.worklisttype === "2"
                ? (state.tabContent.activeTab = false)
                : (state.scheduleQcTabContent.activeTab = false);
            }
          });

          let test = JSON.parse(JSON.stringify(tabCopy));
          sessionStorage.setItem("WholeQCTab", JSON.stringify(test));

          if (data.worklisttype === "2") {
            this.setState({ tabs: tabCopy });
            SharedService.setDataForDQ(this.state.tabs);
          } else {
            this.setState({ scheduleQcTabs: tabCopy });
            SharedService.setDataForScheduleQC(this.state.scheduleQcTabs);
          }
        }

        if (data.action === "LastImage") {
          let tabCopy =
            data.worklisttype === "2"
              ? JSON.parse(JSON.stringify(this.state.tabs))
              : JSON.parse(JSON.stringify(this.state.scheduleQcTabs));

          tabCopy.forEach((state) => {
            if (state.tabName === "Daily Worklist") {
              state.tabContent.pageSize = data.pageSize;
              state.tabContent.currentPage = data.currentPage;
            }
            if (state.tabName === "Scheduled Worklist") {
              state.scheduleQcTabContent.pageSize = data.pageSize;
              state.scheduleQcTabContent.currentPage = data.currentPage;
              state.scheduleQcTabContent.filteredData = data.filteredData;
              //state.scheduleQcTabContent.AllImagesCount = data.AllImagesCount;
              state.scheduleQcTabContent.AllImagesCount = {
                ...state.scheduleQcTabContent.AllImagesCount,
                intActionedCount: data.AllImagesCount.intActionedCount,
                intMissingCount: data.AllImagesCount.intMissingCount,
                intUnActionedCount: data.AllImagesCount.intUnActionedCount,
              };
            }
          });

          let test = JSON.parse(JSON.stringify(tabCopy));
          sessionStorage.setItem("WholeQCTab", JSON.stringify(test));

          if (data.worklisttype === "2") {
            this.setState({ tabs: tabCopy });
            SharedService.setDataForDQ(this.state.tabs);
          } else {
            this.setState({ scheduleQcTabs: tabCopy });
            SharedService.setDataForScheduleQC(this.state.scheduleQcTabs);
          }
        }

        if (data.action === "moveToDailyQcFromApproved") {
          // let tabCopy = JSON.parse(JSON.stringify(this.state.tabs));
          let tabCopy =
            data.WorkListType === "2"
              ? JSON.parse(JSON.stringify(this.state.tabs))
              : JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          tabCopy.forEach((state) => {
            if (state.status === data.status) {
              if (data.WorkListType === "2") {
                state.tabContent.filteredData = data.data;
              } else {
                state.scheduleQcTabContent.filteredData = data.data;
              }
            }
          });

          let test = JSON.parse(JSON.stringify(tabCopy));

          sessionStorage.setItem("WholeQCTab", JSON.stringify(test));
          if (data.WorkListType === "2") {
            this.setState({ tabs: tabCopy });
            SharedService.setDataForDQ(this.state.tabs);
          } else {
            this.setState({ scheduleQcTabs: tabCopy });
            SharedService.setDataForScheduleQC(this.state.scheduleQcTabs);
          }
        }

        if (data.action === "paginationCount") {
          // let tabCopy = JSON.parse(JSON.stringify(this.state.tabs));
          let tabCopy =
            data.worklisttype === "2"
              ? JSON.parse(JSON.stringify(this.state.tabs))
              : JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          tabCopy.forEach((state) => {
            if (state.status === data.status) {
              if (data.worklisttype === "2") {
                state.tabContent.pageSize = data.pageSize;
                state.tabContent.currentPage = data.currentPage;
              } else {
                state.scheduleQcTabContent.pageSize = data.pageSize;
                state.scheduleQcTabContent.currentPage = data.currentPage;
              }
            }
          });
          if (data.worklisttype === "2") {
            let test = JSON.parse(JSON.stringify(tabCopy));
            sessionStorage.setItem("WholeQCTab", JSON.stringify(test));

            this.setState({ tabs: tabCopy });
            SharedService.setDataForDQ(tabCopy);
          } else {
            this.setState({ scheduleQcTabs: tabCopy });
            SharedService.setDataForScheduleQC(tabCopy);
          }
        }

        if (data.action === "Editor") {
          let tabCopy =
            data.worklisttype === "2"
              ? JSON.parse(JSON.stringify(this.state.tabs))
              : JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          tabCopy.forEach((state) => {
            if (state.status === data.status) {
              if (data.worklisttype === "2") {
                state.tabContent.bindEditorByLoc.selectedValue = [];
                state.tabContent.bindEditorByLoc.selectedValue = data;
                //state.tabContent.bindEditorByLoc.requiredField = true;
                if (state.tabContent.bindEditorByLoc.selectedValue.length > 0) {
                  //state.tabContent.bindEditorByLoc.requiredField = false;
                }
              } else {
                state.scheduleQcTabContent.bindEditorByLoc.selectedValue = [];
                state.scheduleQcTabContent.bindEditorByLoc.selectedValue = data;
              }
            }
          });
          //sessionStorage.setItem("WholeQCTab", JSON.stringify(tabCopy));
          if (data.worklisttype === "2") {
            this.setState({ tabs: tabCopy });
            SharedService.setDataForDQ(tabCopy);
          } else {
            this.setState({ scheduleQcTabs: tabCopy });
            SharedService.setDataForScheduleQC(tabCopy);
          }
        }

//  It is called once we click on refresh button in EditorReverted Tab(DailyQC)
        if (data.action === "EditorReverted") {
          let url = "";
          let name = [];
          let reparr = [];
          if (data.locatioIds.length > 0) {
            url = `${config.apiUrl + config.qcApi}Common/GetEditorByLocation/${data.userRoleID
              }?strLocation=${data.locatioIds}&status=8`;
          } else {
            url = `${config.apiUrl + config.qcApi}Common/GetEditorByLocation/${data.userRoleID
              }?status=8`;
          }

          axios.get(url).then((response) => {
            let tabCopyEditorRevrted = JSON.parse(
              JSON.stringify(this.state.tabs)
            ); // create a copy of state
            if (
              response.status === 200 &&
              response.data &&
              response.data.objRespMessage
            ) {
              reparr = response.data.objRespMessage;
              if (reparr !== null) {
                name = [];
                reparr.forEach((ele) => {
                  let objele = {
                    id: ele.intEditorId,
                    name: ele.strEditorName,
                  };
                  name.push(objele);
                });
              }

              tabCopyEditorRevrted.map((tab) =>
                tab.status === "8"
                  ? (tab.tabContent.bindEditorByLoc.options = response.data.objRespMessage,
                    tab.tabContent.bindEditorByLoc.selectedValue = name
                    )
                  : null
              );

            } else if (response.status === 204) {

              tabCopyEditorRevrted.map((tab) =>
              tab.status === "8"
                ? (tab.tabContent.bindEditorByLoc.options = [],
                  tab.tabContent.bindEditorByLoc.selectedValue = []
                  )
                : null
             );

            }

            response.data.objRespMessage === undefined  //called when response.status === 204
              ? sessionStorage.setItem("EditorRev", JSON.stringify([]))
              : sessionStorage.setItem(
                "EditorRev",
                JSON.stringify(response.data.objRespMessage)
              );

            this.setState({ tabs: tabCopyEditorRevrted, showError: false });
            SharedService.setDataForDQ(tabCopyEditorRevrted);
          });
        }

        if (data.action === "AiringDateReset") {
          let tabCopy = JSON.parse(JSON.stringify(this.state.tabs));
          tabCopy.forEach((state) => {
            if (state.tabName === data.tabName) {
              state.tabContent.displayAiringDate = "";
              state.tabContent.startAiringDate = null;
              state.tabContent.endAiringDate = null;
            }
          });
          let test = JSON.parse(JSON.stringify(tabCopy));
          sessionStorage.setItem("WholeQCTab", JSON.stringify(test));
          this.setState({ tabs: tabCopy });
          SharedService.setDataForDQ(tabCopy);
        }
        if (data.action === "ScheduleQCAiringDateReset") {
          let tabCopy = JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          tabCopy.forEach((state) => {
            if (state.tabName === data.tabName) {
              state.scheduleQcTabContent.displayAiringDate = "";
              state.scheduleQcTabContent.startAiringDate = null;
              state.scheduleQcTabContent.endAiringDate = null;
            }
          });
          let test = JSON.parse(JSON.stringify(tabCopy));
          sessionStorage.setItem("WholeQCTab", JSON.stringify(test));
          this.setState({ scheduleQcTabs: tabCopy });
          SharedService.setDataForScheduleQC(tabCopy);
        }

        // Editor Role Actions
        if (data.action === "EditorApplyFilter") {
          let tabCopyTemp = JSON.parse(JSON.stringify(this.state.editorTabs));
          tabCopyTemp.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              state.editorTabContent.bindSource.selectedValue = [];
              state.editorTabContent.bindChannel.selectedValue = [];
              state.editorTabContent.bindWorklist.selectedValue = [];

              data.stateData.arrayValSource.forEach((arrVal) => {
                state.editorTabContent.bindSource.selectedValue.push(arrVal);
              });
              data.stateData.arrayValChannel.forEach((arrVal) => {
                state.editorTabContent.bindChannel.selectedValue.push(arrVal);
              });

              data.stateData.arrayValWorklist.forEach((arrVal) => {
                state.editorTabContent.bindWorklist.selectedValue.push(arrVal);
              });
              state.editorTabContent.filteredData = data.stateData.filteredData;
              state.editorTabContent.displayDate = data.stateData.displayDate;
              state.editorTabContent.loader = data.stateData.loading;
              state.editorTabContent.pageSize = data.stateData.pageSize;
              state.status === "1"
                ? (state.editorTabContent.currentPage =
                  state.startDate.currentPage)
                : (state.editorTabContent.currentPage =
                  data.stateData.currentPage);
              state.editorTabContent.startDate = data.stateData.startDate;
              state.editorTabContent.endDate = data.stateData.endDate;
              state.editorTabContent.startAiringDate =
                data.stateData.startAiringDate;
              state.editorTabContent.endAiringDate =
                data.stateData.endAiringDate;
              state.editorTabContent.displayAiringDate =
                data.stateData.displayAiringDate;
              state.isFirstTime = false;
              state.first = 0;
            }
          });
          let test = JSON.parse(JSON.stringify(tabCopyTemp));
          test.forEach((state) => {
            state.editorTabContent.filteredData = [];
          });
          sessionStorage.setItem("WholeQCTabEditor", JSON.stringify(test));
          this.setState({ editorTabs: tabCopyTemp });
          SharedService.setEditorDataForDQ(tabCopyTemp);
        }

        if (data.action === "EditorApplyReset") {
          let tabCopy = JSON.parse(JSON.stringify(this.state.editorTabs));
          tabCopy.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              state.editorTabContent.bindSource.selectedValue = [];
              state.editorTabContent.bindChannel.selectedValue = [];
              state.editorTabContent.bindWorklist.selectedValue = [];
              state.editorTabContent.filteredData = [];
              state.editorTabContent.displayDate = "";
              state.editorTabContent.loader = "";
              state.editorTabContent.startDate = null;
              state.editorTabContent.endDate = null;
              state.editorTabContent.startAiringDate = null;
              state.editorTabContent.endAiringDate = null;
              state.editorTabContent.displayAiringDate = "";
              state.editorTabContent.pageSize = 25;
              state.status === "1"
                ? (state.editorTabContent.currentPage = 1)
                : (state.editorTabContent.currentPage = 0);
              state.isFirstTime = false;
              state.first = 0;
              state.editorTabContent.approvedDate = "";
            }
          });
          let test = JSON.parse(JSON.stringify(tabCopy));
          sessionStorage.setItem("WholeQCTabEditor", JSON.stringify(test));
          this.setState({ editorTabs: tabCopy });
          SharedService.setEditorDataForDQ(tabCopy);
        }

        if (data.action === "EditorAiringDateReset") {
          let tabCopy = JSON.parse(JSON.stringify(this.state.editorTabs));
          tabCopy.forEach((state) => {
            if (state.tabName === data.tabName) {
              state.editorTabContent.displayAiringDate = "";
              state.editorTabContent.startAiringDate = moment();
              state.editorTabContent.endAiringDate = moment();
            }
          });
          let test = JSON.parse(JSON.stringify(tabCopy));
          sessionStorage.setItem("WholeQCTabEditor", JSON.stringify(test));
          this.setState({ editorTabs: tabCopy });
          SharedService.setEditorDataForDQ(tabCopy);
        }

        if (data.action === "EditorPaginationCount") {
          let tabCopy = JSON.parse(JSON.stringify(this.state.editorTabs));
          tabCopy.forEach((state) => {
            if (state.status === data.status) {
              state.editorTabContent.pageSize = data.pageSize;
              state.editorTabContent.currentPage = data.currentPage;
            }
          });
          let test = JSON.parse(JSON.stringify(tabCopy));
          sessionStorage.setItem("WholeQCTabEditor", JSON.stringify(test));
          this.setState({ editorTabs: tabCopy });
          SharedService.setEditorDataForDQ(tabCopy);
        }

        if (data.action === "EditorImgAck") {
          let tabCopy = JSON.parse(JSON.stringify(this.state.editorTabs));
          tabCopy.forEach((state) => {
            if (state.status === data.status) {
              state.editorTabContent.filteredData = data.data;
            }
          });
          let test = JSON.parse(JSON.stringify(tabCopy));
          sessionStorage.setItem("WholeQCTabEditor", JSON.stringify(test));
          this.setState({ editorTabs: tabCopy });
          SharedService.setEditorDataForDQ(this.state.editorTabs);
        }

        if (data.action === "ImageTopViewEditor") {
          let tabCopy = JSON.parse(JSON.stringify(this.state.editorTabs));
          tabCopy.forEach((state) => {
            if (state.status === data.status) {
              state.editorTabContent.pageSize = data.pageSize;
              state.editorTabContent.currentPage = data.currentPage - 1;
              state.editorTabContent.activeTab = true;
            } else {
              state.editorTabContent.activeTab = false;
            }
          });

          let test = JSON.parse(JSON.stringify(tabCopy));
          sessionStorage.setItem("WholeQCTabEditor", JSON.stringify(test));

          this.setState({ editorTabs: tabCopy });
          SharedService.setEditorDataForDQ(this.state.editorTabs);
        }

        if (data.action === "EditorLastImage") {
          let tabCopy = JSON.parse(JSON.stringify(this.state.editorTabs));
          tabCopy.forEach((state) => {
            if (state.tabName === "Daily Worklist") {
              state.editorTabContent.pageSize = data.pageSize;
              state.editorTabContent.currentPage = data.currentPage;
              state.editorTabContent.activeTab = true;
            } else {
              state.editorTabContent.activeTab = false;
            }
          });

          let test = JSON.parse(JSON.stringify(tabCopy));
          sessionStorage.setItem("WholeQCTabEditor", JSON.stringify(test));

          this.setState({ editorTabs: tabCopy });
          SharedService.setEditorDataForDQ(this.state.editorTabs);
        }

        // Schedule QC
        if (data.action === "ScheduleQCApplyFilter") {
          let scheduleqctabCopy = JSON.parse(
            JSON.stringify(this.state.scheduleQcTabs)
          );
          scheduleqctabCopy.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              state.scheduleQcTabContent.bindLocation.selectedValue = [];
              state.scheduleQcTabContent.bindEditorByLoc.selectedValue = [];
              state.scheduleQcTabContent.bindSource.selectedValue = [];
              state.scheduleQcTabContent.bindChannel.selectedValue = [];
              state.scheduleQcTabContent.bindChannelGenre.selectedValue=[];
              state.scheduleQcTabContent.bindImageType.selectedValue = [];
              state.scheduleQcTabContent.bindContentType.selectedValue = null;
              state.scheduleQcTabContent.bindGenre.selectedValue = [];
              state.scheduleQcTabContent.bindSubgenre.selectedValue = [];
              state.scheduleQcTabContent.bindRatio.selectedValue = [];
              state.scheduleQcTabContent.bindImgSize.selectedValue = [];
              state.scheduleQcTabContent.bindGroup.selectedValue = null;
              state.scheduleQcTabContent.bindAiringLang.selectedValue = [];
              state.scheduleQcTabContent.bindAssignedClient.selectedValue =
                null;
              state.scheduleQcTabContent.bindAssignedChannel.selectedValue = [];
              state.scheduleQcTabContent.bindAction.selectedValue = [];

              data.stateData.arrayValLoc.forEach((arrVal) => {
                state.scheduleQcTabContent.bindLocation.selectedValue.push(
                  arrVal
                );
              });
              data.stateData.arrayValEditor.forEach((arrVal) => {
                state.scheduleQcTabContent.bindEditorByLoc.selectedValue.push(
                  arrVal
                );
              });
              data.stateData.arrayValSource.forEach((arrVal) => {
                state.scheduleQcTabContent.bindSource.selectedValue.push(
                  arrVal
                );
              });
              data.stateData.arrayValChannel.forEach((arrVal) => {
                state.scheduleQcTabContent.bindChannel.selectedValue.push(
                  arrVal
                );
              });

              data.stateData.arrayValChannelGenre.forEach((arrayVal)=>
              {
                state.scheduleQcTabContent.bindChannelGenre.selectedValue.push(
                  arrayVal
                )
              })

              data.stateData.arrayValImageType.forEach((arrVal) => {
                state.scheduleQcTabContent.bindImageType.selectedValue.push(
                  arrVal
                );
              });

              if (data.stateData.arrayValContentType !== null) {
                state.scheduleQcTabContent.bindContentType.selectedValue =
                  data.stateData.arrayValContentType;
              }

              data.stateData.arrayValGenre.forEach((arrVal) => {
                state.scheduleQcTabContent.bindGenre.selectedValue.push(arrVal);
              });
              data.stateData.arrayValSubGenre.forEach((arrVal) => {
                state.scheduleQcTabContent.bindSubgenre.selectedValue.push(
                  arrVal
                );
              });
              data.stateData.arrayValRatio.forEach((arrVal) => {
                state.scheduleQcTabContent.bindRatio.selectedValue.push(arrVal);
              });
              data.stateData.arrayValImgSize.forEach((arrVal) => {
                state.scheduleQcTabContent.bindImgSize.selectedValue.push(
                  arrVal
                );
              });

              if (data.stateData.arrayValGroup !== null) {
                state.scheduleQcTabContent.bindGroup.selectedValue =
                  data.stateData.arrayValGroup;
              }

              data.stateData.arrayValAiringLang.forEach((arrVal) => {
                state.scheduleQcTabContent.bindAiringLang.selectedValue.push(
                  arrVal
                );
              });

              if (data.stateData.arrayValAssignedClient !== null) {
                state.scheduleQcTabContent.bindAssignedClient.selectedValue =
                  data.stateData.arrayValAssignedClient;
              }

              data.stateData.arrayValAssignedChannel.forEach((arrVal) => {
                state.scheduleQcTabContent.bindAssignedChannel.selectedValue.push(
                  arrVal
                );
              });
              data.stateData.arrayValAction.forEach((arrVal) => {
                state.scheduleQcTabContent.bindAction.selectedValue.push(
                  arrVal
                );
              });

              state.scheduleQcTabContent.filteredData =
                data.stateData.filteredData;
              if (data.propsData.status === "1") {
                state.scheduleQcTabContent.AllImagesCount =
                  data.stateData.AllImagesCount;
              }
              state.scheduleQcTabContent.loader = data.stateData.loading;
              state.scheduleQcTabContent.pageSize = data.stateData.pageSize;
              state.scheduleQcTabContent.currentPage =
                data.stateData.currentPage;
              state.scheduleQcTabContent.startAiringDate =
                data.stateData.startAiringDate;
              state.scheduleQcTabContent.endAiringDate =
                data.stateData.endAiringDate;
              state.scheduleQcTabContent.displayAiringDate =
                data.stateData.displayAiringDate;
              state.isFirstTime = false;
              state.scheduleQcTabContent.activeTab = true;
              state.first = 0;

              state.scheduleQcTabContent.moreFilter = data.stateData.moreFilter;
              state.scheduleQcTabContent.AssignedClientSwitch =
                data.stateData.AssignedClientSwitch;
              state.scheduleQcTabContent.DisableAssignedClient =
                data.stateData.DisableAssignedClient;
              state.scheduleQcTabContent.MissingImagesSwitch =
                data.stateData.MissingImagesSwitch;
              state.scheduleQcTabContent.contentTypeDisabled =
                data.stateData.contentTypeDisabled;
              state.scheduleQcTabContent.AssignedChannelSwitch =
                data.stateData.AssignedChannelSwitch;
            } else {
              state.scheduleQcTabContent.activeTab = false;
            }
          });
          // let test = JSON.parse(JSON.stringify(tabCopy));
          // test.forEach(state => {
          //   state.scheduleQcTabContent.filteredData = [];
          // });

          //sessionStorage.setItem("WholeQCTab", JSON.stringify(test));
          this.setState({ scheduleQcTabs: scheduleqctabCopy });
          SharedService.setDataForScheduleQC(scheduleqctabCopy);
        }

        if (data.action === "ScheduleQCApplyReset") {
          let scheduleqctabCopy = JSON.parse(
            JSON.stringify(this.state.scheduleQcTabs)
          );
          scheduleqctabCopy.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              state.scheduleQcTabContent.bindLocation.selectedValue = [];
              state.scheduleQcTabContent.bindEditorByLoc.selectedValue = [];
              // state.scheduleQcTabContent.bindEditorByLoc.options = state.status === "8" ? JSON.parse(sessionStorage.getItem("EditorRev")) : JSON.parse(sessionStorage.getItem("ListOfEditor"));
              state.scheduleQcTabContent.bindSource.selectedValue = [];
              state.scheduleQcTabContent.bindChannel.selectedValue = [];
              state.scheduleQcTabContent.bindChannelGenre.selectedValue= [];
              state.scheduleQcTabContent.bindImageType.selectedValue = [];
              state.scheduleQcTabContent.bindContentType.selectedValue = null;
              state.scheduleQcTabContent.bindGenre.selectedValue = [];
              state.scheduleQcTabContent.bindSubgenre.selectedValue = [];
              state.scheduleQcTabContent.bindSubgenre.options = [];
              state.scheduleQcTabContent.bindRatio.selectedValue = [];
              state.scheduleQcTabContent.bindImgSize.selectedValue = [];
              state.scheduleQcTabContent.bindImgSize.options = [];

              state.scheduleQcTabContent.bindGroup.selectedValue = null;

              state.scheduleQcTabContent.bindAiringLang.selectedValue = [];
              state.scheduleQcTabContent.bindAssignedClient.selectedValue =
                null;
              state.scheduleQcTabContent.bindAssignedChannel.selectedValue = [];
              state.scheduleQcTabContent.bindAction.selectedValue = [];

              state.scheduleQcTabContent.filteredData = [];
              state.scheduleQcTabContent.AllImagesCount = [];
              state.scheduleQcTabContent.loader = "";
              state.scheduleQcTabContent.pageSize = 25;

              state.status === "1"
                ? (state.scheduleQcTabContent.currentPage = 1)
                : (state.scheduleQcTabContent.currentPage = 0);
              state.isFirstTime = false;

              state.scheduleQcTabContent.startAiringDate = moment();
              state.scheduleQcTabContent.endAiringDate = moment();

              state.scheduleQcTabContent.displayAiringDate = "";
              state.first = 0;
              state.scheduleQcTabContent.AssignedClientSwitch = true;
              state.scheduleQcTabContent.DisableAssignedClient = true;
              state.scheduleQcTabContent.MissingImagesSwitch = false;
              state.scheduleQcTabContent.contentTypeDisabled = false;
              state.scheduleQcTabContent.AssignedChannelSwitch = false;
            }
          });
          // let test = JSON.parse(JSON.stringify(this.state.scheduleQcTabs));
          // sessionStorage.setItem("WholeQCTab", JSON.stringify(test));

          this.setState({ scheduleQcTabs: scheduleqctabCopy });
          SharedService.setDataForScheduleQC(scheduleqctabCopy);
        }

        if (data.action === "FilterReset") {
          let scheduleqctabCopy = JSON.parse(
            JSON.stringify(this.state.scheduleQcTabs)
          );
          scheduleqctabCopy.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              if (data.type === "Channel") {
                state.scheduleQcTabContent.bindChannel.selectedValue = [];
              } else if (data.type === "Language") {
                state.scheduleQcTabContent.bindAiringLang.selectedValue = [];
              } else if (data.type === "Assigned Client") {
                state.scheduleQcTabContent.bindAssignedClient.selectedValue =
                  [];
                state.scheduleQcTabContent.AssignedClientSwitch = true;
                state.scheduleQcTabContent.DisableAssignedClient = true;
              } else if (data.type === "Content Type") {
                state.scheduleQcTabContent.bindContentType.selectedValue = [];
              }
            }
          });
          this.setState({ scheduleQcTabs: scheduleqctabCopy });
          SharedService.setDataForScheduleQC(scheduleqctabCopy);
        }

        if (data.action === "MoreFilterResetSQ") {
          let scheduleqctabCopy = JSON.parse(
            JSON.stringify(this.state.scheduleQcTabs)
          );
          scheduleqctabCopy.forEach((state) => {
            if (state.tabName === data.stateData.tabName) {
              state.scheduleQcTabContent.bindLocation.selectedValue = [];
              state.scheduleQcTabContent.bindEditorByLoc.selectedValue = [];
              state.scheduleQcTabContent.bindSource.selectedValue = [];
              state.scheduleQcTabContent.bindContentType.selectedValue = null;
              state.scheduleQcTabContent.bindGenre.selectedValue = [];
              state.scheduleQcTabContent.bindSubgenre.selectedValue = [];
              state.scheduleQcTabContent.bindSubgenre.options = [];
              state.scheduleQcTabContent.bindAssignedClient.selectedValue =
                null;
              state.scheduleQcTabContent.bindAction.selectedValue = [];
              state.scheduleQcTabContent.AssignedClientSwitch = true;
              state.scheduleQcTabContent.DisableAssignedClient = true;
              state.scheduleQcTabContent.MissingImagesSwitch = false;
              state.scheduleQcTabContent.contentTypeDisabled =
                data.stateData.arrayValImageType.length > 1 ? true : false;
              state.scheduleQcTabContent.AssignedChannelSwitch = false;
            }
          });
          this.setState({ scheduleQcTabs: scheduleqctabCopy });
          SharedService.setDataForScheduleQC(scheduleqctabCopy);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    return (
      <div>
        <Router>
          {/* <RedirectedIfError showError={this.state.showError}> */}
          <Switch>
            <PrivateRoute
              exact
              path="/Authorize/:encryptedtoken"
              component={ScheduledQcTeam}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/qcuser/dailyqcworklist"
              tabs={this.state.tabs}
              component={DailyQcWorklist}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/qcuser/SingleImageView/Actioned"
              component={EditorSingleImageView}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/qcuser/SingleImageView"
              component={SingleImageView}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/qcuser/SingleImageView/EditorReverted"
              component={EditorSingleImageView}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/qcuser/scheduleqcworklist"
              scheduleqctabs={this.state.scheduleQcTabs}
              component={SchedledQcWorklist}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/qcuser/qcindividual"
              component={QcIndividual}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/qcuser/clientreportedimages"
              component={ClientReportedImages}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/qcuser/notfound"
              component={NotFound}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/qcuser/dailyqcdashboard"
              component={DailyQcDashboard}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/qcuser/scheduleqcdashboard"
              component={ScheduledQcDashboard}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/editor/dailyqcworklist"
              editortabs={this.state.editorTabs}
              component={EditorDailyQcWorklist}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/editor/EditorSingleImageView"
              component={EditorSingleImageView}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/editor/scheduleqcworklist"
              component={SchedledQcWorklist}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/editor/missingimagesworklist"
              component={MissingImagesWorklist}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/editor/clientreportedworklist"
              component={ClientReportedImages}
              layout={DefaultLayout}
            />
            <PrivateRoute
              exact
              path="/allocator/dailyqcworklist"
              component={WorkAssignment}
              layout={DefaultLayout}
            />
            <Route path="/login" component={Login} />
            <Route component={ErrorPage} />
          </Switch>
          {/* </RedirectedIfError> */}
        </Router>
        <Alert stack={{ limit: 3 }} />
      </div>
    );
  }
}

export default App;
