import React from "react";

export default class ScheduledQcTeam extends React.Component {
  render() {
    return (
      <div>
        <h3> Scheduled Qc Team </h3>
      </div>
    );
  }
}
