import axios from "axios";
import config from "../containers/config";
import { SharedService } from "../components/SharedService/SharedService";
import { Observable } from "rxjs";

export const userService = {
  login,
  logout,
};

function login(tokenId) {
  const obs = Observable.create((observable) => {
    const loginlink = "login/validateuser/" + tokenId; //const loginlink = 'login/validateuser/cWNfdG9vbF9xY191c2VyfDVEL0dEQVlWSTcwPQ%3d%3d';

    axios
      .post(`${config.apiUrl + config.qcApi + loginlink}`)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem(
            "loginresp",
            response.data.objRespMessage.strToken
          );
          localStorage.setItem(
            "userID",
            response.data.objRespMessage.lngUserID
          );
          localStorage.setItem(
            "userName",
            response.data.objRespMessage.strUserName
          );

          const tempUserID = localStorage.getItem("userID");
          const userrolesapi = `Menu/GetUserRoles/${tempUserID}`;
          return axios.get(`${config.apiUrl + config.qcApi + userrolesapi}`);
        } else {
          const error = response.objRespMessage;
          return Promise.reject(error);
        }
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem(
            "roleID",
            response.data.objRespMessage[0].intRoleID
          );
          SharedService.setUserDetails({
            userRoleID: response.data.objRespMessage[0].intUserRoleID,
            role: response.data.objRespMessage[0].strRoleName,
            roleId: response.data.objRespMessage[0].intRoleID,
            userdata: response.data.objRespMessage,
          });

          observable.next({
            userRoleID: response.data.objRespMessage[0].intUserRoleID,
            role: response.data.objRespMessage[0].strRoleName,
            roleId: response.data.objRespMessage[0].intRoleID,
          });
        } else {
          const error = response.objRespMessage;
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!error.response) {
          error = "Not able to communicate with the backend service !";
        } else {
          switch (error.response.status) {
            case 404:
              error =
                "API Error - 404 received from the API\n\n API URL -" +
                error.request.responseURL;
              break;

            case 504:
              error =
                "API Error - 504 received from the API\n\n API URL -" +
                error.request.responseURL;
              break;

            default:
              error =
                "Unknown Error received from the API - Status " +
                error.response.status;
              break;
          }
        }
        observable.error(error);
      });
  });
  return obs;
}

function logout() {
  // remove user from local storage to log user out
  if (
    localStorage.getItem("userRoleID") !== null &&
    localStorage.getItem("userRoleID") !== ""
  ) {
    const tempUserRoleID = localStorage.getItem("userRoleID");
    const logoutlink = `login/logoff/${tempUserRoleID}`;
    axios
      .get(`${config.apiUrl + config.qcApi + logoutlink}`)
      .then((response) => {
        if (response.status === 200) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  localStorage.removeItem("loginresp");
  localStorage.removeItem("userID");
  localStorage.removeItem("userRoleID");
  localStorage.removeItem("userName");
  localStorage.removeItem("CmsToken");
  sessionStorage.removeItem("WholeQCTab");
  sessionStorage.removeItem("WholeQCTabEditor");
  sessionStorage.removeItem("firstTab");
  sessionStorage.removeItem("firstTabEditor");
  sessionStorage.removeItem("isActiveTab");
  sessionStorage.removeItem("isActiveTabEditor");
}
