import React from "react";
import axios from "axios";
import "react-dates/initialize";
import "react-picky/dist/picky.css";
import config from "../../containers/config";
import Dropdown from "./Dropdown";
import Gallery from "./Gallery";
import Tables from "./Tables";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import DateRangePickerNew from "react-bootstrap-daterangepicker";
import { SharedService } from "../SharedService/SharedService";
import KeyboardEventHandler from "react-keyboard-event-handler";
import ToggleSwitch from "./ToggleSwitch";

class MainDropdown extends React.Component {
  constructor(props) {
    super(props);

    let subtractTwoDaysDate = moment().subtract(2, "days").format("MM/DD/YYYY");
    let subtractOneMonthDate = moment()
      .subtract(1, "months")
      .format("MM/DD/YYYY");
    let subtractThreeMonthDate = moment()
      .subtract(3, "months")
      .format("MM/DD/YYYY");
    this.state = {
      LinearVOD: props.data.tabContent.LinearVOD,
      arrayValLoc: props.data.tabContent.bindLocation.selectedValue,
      arrayValEditor: props.data.tabContent.bindEditorByLoc.selectedValue,
      arrayValSource: props.data.tabContent.bindSource.selectedValue,
      arrayValChannel: props.data.tabContent.bindChannel.selectedValue,
      arrayValImageType: props.data.tabContent.bindImageType.selectedValue,
      arrayValDelPtfm: props.data.tabContent.bindDeliveryPlatform.selectedValue,
      arrayValContentType: props.data.tabContent.bindContentType.selectedValue,
      arrayValGenre: props.data.tabContent.bindGenre.selectedValue,
      arrayValSubGenre: props.data.tabContent.bindSubgenre.selectedValue,
      arrayValRatio: props.data.tabContent.bindRatio.selectedValue,
      arrayValImageEditType:
        props.data.tabContent.bindImageEditType.selectedValue,
      arrayValImgSize: props.data.tabContent.bindImgSize.selectedValue,
      arrayValGroup: props.data.tabContent.bindGroup.selectedValue,
      arrayValAssignedLanguage:
        props.data.tabContent.bindAssignedLanguage.selectedValue,
      filteredData: props.data.tabContent.filteredData,
      displayDate:
        props.data.status === "8"
          ? props.data.tabContent.editorRevDate
          : props.data.status !== "1"
          ? props.data.tabContent.otherTabDates
          : props.data.tabContent.displayDate,
      editorIndex: [],
      loading: props.data.tabContent.loader,
      pageSize: props.data.tabContent.pageSize,
      currentPage: props.data.tabContent.currentPage,
      //requiredField : props.data.tabContent.bindEditorByLoc.requiredField,
      EditorRequired: false, //props.data.tabContent.bindEditorByLoc.requiredField,
      dateRequired: false,
      tabName: props.data.tabName,
      startDate: props.data.tabContent.startDate
        ? moment(props.data.tabContent.startDate).format("MM/DD/YYYY")
        : props.data.status === "1"
        ? subtractTwoDaysDate
        : props.data.status === "8"
        ? subtractThreeMonthDate
        : subtractOneMonthDate,
      endDate: props.data.tabContent.endDate
        ? moment(props.data.tabContent.endDate).format("MM/DD/YYYY")
        : moment().format("MM/DD/YYYY"),
      startAiringDate: props.data.tabContent.startAiringDate
        ? moment(props.data.tabContent.startAiringDate).format("MM/DD/YYYY")
        : moment(),
      endAiringDate: props.data.tabContent.endAiringDate
        ? moment(props.data.tabContent.endAiringDate).format("MM/DD/YYYY")
        : moment(),
      displayAiringDate: props.data.tabContent.displayAiringDate,
      isHelpScreenActive: false,
      isConfirmActive: false,
      first: props.data.first,
      temp: false,
      editorRevertedBindValues: [],
      arrayValImageType2: [],
      // isDropDownActive: false,
      isLocationDropdown: false,
      isEditorDropdown: false,
      isSourceDropdown: false,
      isChannelDropdown: false,
      isImageEditTypeDropdown: false,
      isContenTypeDropdown: false,
      isImgDlvPltDropdown: false,
      isImgTypDropdown: false,
      isGenreDropdown: false,
      isSubGenreDropdown: false,
      isRationDropdown: false,
      isImgSizeDropdown: false,
      isGroupDropdown: false,
      isAssnLangDropdown: false,
      isSelectAll: true,
    };
    this.handlePageCountChange = this.handlePageCountChange.bind(this);
    this.selectMultipleOption = this.selectMultipleOption.bind(this);
  }

  componentDidMount() {

    if (
      this.props.data.status === "8" &&
      this.props.data.tabContent.bindEditorByLoc.options.length === 0
    ) {
      this.setState({ isSelectAll: false });
    }
    sessionStorage.setItem("QCActionStatus", this.props.data.status);
    if (this.props.data.isFirstTime) {
      sessionStorage.setItem("firstTab", "1");
      this.applyFilters();
    }

    this.subscription = SharedService.getIsHelpScreenActive().subscribe(
      (data) => {
        this.setState({ isHelpScreenActive: data.isHelpScreenActive });
      }
    );
  }

  componentWillUnmount()
  {
    this.subscription.unsubscribe();
    window.removeEventListener("scroll",this.scrollfunction);  
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.data.tabContent.bindEditorByLoc.options !==
      prevProps.data.tabContent.bindEditorByLoc.options
    ) {
      this.setState({ editorRevertedBindValues: [] });
    }
    if (prevProps.data.callApplyFilter !== this.props.data.callApplyFilter) {
      if (this.props.data.callApplyFilter) {
        this.applyFilters();
      }
    }
  }

  onRefreshEditorRevert=()=>
  {
    if (sessionStorage.getItem("isActiveTab") === "8"  ) {
      let userRoleID = localStorage.getItem("userRoleID");
      let locatioIds = this.state.arrayValLoc.map((loc) => {
        return loc.id;
      });
      this.props.EditorRevertedFunc({ locatioIds, userRoleID });
    }
  
  }

  selectMultipleOption(value, name) {
    switch (value) {
      case "Editor Location":
        const locIndex = [];
        name.map((i) =>
          locIndex.push({
            id: i.id,
            name: i.name,
          })
        );
        this.props.populateEditor({ locIndex, stateData: this.props.data });
        this.setState({ arrayValLoc: name, locIndex, arrayValEditor: [] });
        break;
      case "Editor":
        this.setState({ arrayValEditor: name, EditorRequired: false });
        break;
      case "Source":
        this.setState({ arrayValSource: name });
        break;
      case "Channel":
        this.setState({ arrayValChannel: name });
        break;

      case "Image Edit Type":
        this.setState({ arrayValImageEditType: name });
        break;

      case "Content Type":
        this.setState({ arrayValContentType: name });
        break;
      case "Image Delivery Platform":
        this.setState({ arrayValDelPtfm: name });
        break;
      case "Image Type":
        this.setState({ arrayValImageType: name });
        break;
      case "Genre":
        const genreIndex = [];
        name.map((i) =>
        
          genreIndex.push({
            id: i.id,
            name: i.name,
          })
        );
        this.props.populateSubGenre({ genreIndex, stateData: this.props.data });
        this.setState({
          arrayValGenre: name,
          genreIndex,
          arrayValSubGenre: [],
          listSubGenre: [],
          bindSubgenre: [],
        });
        break;
      case "Sub Genre":
        this.setState({ arrayValSubGenre: name });
        break;
      case "Aspect Ratio":
        // this.setState({ arrayValRatio: name });
        const ratioIndex = [];
        name.map((i) =>
          ratioIndex.push({
            id: i.id,
            name: i.name,
          })
        );
        this.props.populateImgSize({ ratioIndex, stateData: this.props.data });
        this.setState({
          arrayValRatio: name,
          ratioIndex,
          arrayValImgSize: [],
          listImgSize: [],
          bindImgSize: [],
        });
        break;

      case "Image Size":
        this.setState({ arrayValImgSize: name });
        break;

      case "Group":
        this.setState({ arrayValGroup: name });
        break;

      case "Assigned Language":
        this.setState({ arrayValAssignedLanguage: name });
        break;
      default:
        break;
    }
  }

  onApplyDate = (e, picker) => {
    let displayDate = `${moment(picker.startDate).format(
      "DD/MM/YYYY"
    )} - ${moment(picker.endDate).format("DD/MM/YYYY")}`;
    if (this.props.data.status === "8") {
      this.props.data.tabContent.editorRevDate = displayDate;
    } else if (this.props.data.status !== "1") {
      this.props.data.tabContent.otherTabDates = displayDate;
    } else {
      this.props.data.tabContent.displayDate = displayDate;
    }
    this.setState({
      displayDate: displayDate,
      dateRequired: false,
      startDate: moment(picker.startDate).format("MM/DD/YYYY"),
      endDate: moment(picker.endDate).format("MM/DD/YYYY"),
    });
    this.props.data.tabContent.displayDate = displayDate;
    this.props.data.tabContent.dateRequired = false;
    this.props.data.tabContent.startDate = this.state.startDate;
    this.props.data.tabContent.endDate = this.state.endDate;
  };

  onApplyDateAiring = (e, picker) => {
    let displayAiringDate = `${moment(picker.startDate).format(
      "DD/MM/YYYY"
    )} - ${moment(picker.endDate).format("DD/MM/YYYY")}`;
    this.setState({
      displayAiringDate: displayAiringDate,
      startAiringDate: moment(picker.startDate).format("MM/DD/YYYY"),
      endAiringDate: moment(picker.endDate).format("MM/DD/YYYY"),
    });
    this.props.data.tabContent.displayAiringDate = displayAiringDate;
    this.props.data.tabContent.startAiringDate = this.state.startAiringDate;
    this.props.data.tabContent.endAiringDate = this.state.endAiringDate;
  };

  onCancelAiring = (e) => {
    this.setState({
      displayAiringDate: "",
      endAiringDate: moment(),
      startAiringDate: moment(),
    });
    this.props.airingreset(this.state);
  };

  applyFilters = (e) => {
    if (
      !this.state.isHelpScreenActive &&
      !this.state.isConfirmActive &&
      !this.isDropDownActive()
    ) {
      let arrayValLoc,
        arrayValEditor,
        arrayValImageType,
        arrayValSource,
        arrayValChannel,
        arrayValDelPtfm,
        arrayValContentType,
        arrayValGenre,
        arrayValSubGenre,
        arrayValRatio,
        arrayValImageEditType,
        arrayValImageSize,
        arrayValGroup,
        arrayValAssignedLanguage,
        LinearVOD;
      arrayValEditor = this.props.data.tabContent.bindEditorByLoc.selectedValue;
      if (this.props.data.first < 2) {
        arrayValLoc = this.state.arrayValLoc;
        arrayValImageType = this.state.arrayValImageType;
        arrayValSource = this.state.arrayValSource;
        arrayValChannel = this.state.arrayValChannel;
        arrayValDelPtfm = this.state.arrayValDelPtfm;
        arrayValContentType = this.state.arrayValContentType;
        arrayValGenre = this.state.arrayValGenre;
        arrayValSubGenre = this.state.arrayValSubGenre;
        arrayValRatio = this.state.arrayValRatio;
        arrayValImageEditType = this.state.arrayValImageEditType;
        arrayValImageSize = this.state.arrayValImgSize;
        arrayValGroup = this.state.arrayValGroup;
        arrayValAssignedLanguage = this.state.arrayValAssignedLanguage;
        LinearVOD = this.state.LinearVOD;
      } else {
        arrayValLoc =
          this.props.data.tabContent.bindLocation.selectedValue.length > 0
            ? this.props.data.tabContent.bindLocation.selectedValue
            : this.state.arrayValLoc;
        arrayValImageType =
          this.props.data.tabContent.bindImageType.selectedValue.length > 0
            ? this.props.data.tabContent.bindImageType.selectedValue
            : this.state.arrayValImageType;
        arrayValSource =
          this.props.data.tabContent.bindSource.selectedValue.length > 0
            ? this.props.data.tabContent.bindSource.selectedValue
            : this.state.arrayValSource;
        arrayValChannel =
          this.props.data.tabContent.bindChannel.selectedValue.length > 0
            ? this.props.data.tabContent.bindChannel.selectedValue
            : this.state.arrayValChannel;
        arrayValDelPtfm =
          this.props.data.tabContent.bindDeliveryPlatform.selectedValue.length >
          0
            ? this.props.data.tabContent.bindDeliveryPlatform.selectedValue
            : this.state.arrayValDelPtfm;
        arrayValContentType =
          this.props.data.tabContent.bindContentType.selectedValue.length > 0
            ? this.props.data.tabContent.bindContentType.selectedValue
            : this.state.arrayValContentType;
        arrayValGenre =
          this.props.data.tabContent.bindGenre.selectedValue.length > 0
            ? this.props.data.tabContent.bindGenre.selectedValue
            : this.state.arrayValGenre;
        arrayValSubGenre =
          this.props.data.tabContent.bindSubgenre.selectedValue.length > 0
            ? this.props.data.tabContent.bindSubgenre.selectedValue
            : this.state.arrayValSubGenre;
        arrayValRatio =
          this.props.data.tabContent.bindRatio.selectedValue.length > 0
            ? this.props.data.tabContent.bindRatio.selectedValue
            : this.state.arrayValRatio;
        arrayValImageEditType =
          this.props.data.tabContent.bindImageEditType.selectedValue.length > 0
            ? this.props.data.tabContent.bindImageEditType.selectedValue
            : this.state.arrayValImageEditType;
        LinearVOD =
          this.props.data.tabContent.LinearVOD === true
            ? this.props.data.tabContent.LinearVOD
            : this.state.LinearVOD;
        arrayValImageSize =
          this.props.data.tabContent.bindImgSize.selectedValue.length > 0
            ? this.props.data.tabContent.bindImgSize.selectedValue
            : this.state.arrayValImgSize;
        arrayValGroup =
          this.props.data.tabContent.bindGroup.selectedValue.length > 0
            ? this.props.data.tabContent.bindGroup.selectedValue
            : this.state.arrayValGroup;
        arrayValAssignedLanguage =
          this.props.data.tabContent.bindAssignedLanguage.selectedValue.length >
          0
            ? this.props.data.tabContent.bindAssignedLanguage.selectedValue
            : this.state.arrayValAssignedLanguage;
      }

      this.setState({ arrayValEditor: arrayValEditor });
      let editorIndex = [];
      arrayValEditor.map((i) => editorIndex.push(i.id));
      let sourceIndex = [];
      arrayValSource.map((i) => sourceIndex.push(i.id));
      let channelIndex = [];
      arrayValChannel.map((i) => channelIndex.push(i.id));
      let imagetypeIndex = [];
      arrayValImageType.map((i) => imagetypeIndex.push(i.id));
      let deliveryIndex = [];
      arrayValDelPtfm.map((i) => deliveryIndex.push(i.id));
      let contentIndex = [];
      arrayValContentType.map((i) => contentIndex.push(i.id));
      let genreIndex = [];
      arrayValGenre.map((i) => genreIndex.push(i.id));
      let subgenreIndex = [];
      arrayValSubGenre.map((i) => subgenreIndex.push(i.id));
      let ratioIndex = [];
      arrayValRatio.map((i) => ratioIndex.push(i.id));
      let imgEditTypeIndex = [];
      arrayValImageEditType.map((i) => imgEditTypeIndex.push(i.id));
      let imgSizeIndex = [];
      arrayValImageSize.map((i) => imgSizeIndex.push(i.id));
      let groupIndex = [];
      arrayValGroup.map((i) => groupIndex.push(i.id));
      let assignedlangIndex = [];
      arrayValAssignedLanguage.map((i) => assignedlangIndex.push(i.id));
      if (
        (!this.props.data.isFirstTime && this.props.data.first < 2) ||
        this.props.data.first === 2
      ) {
        if (arrayValEditor.length === 0) {
          if (this.state.displayDate.length <= 0) {
            this.setState({
              dateRequired: true,
              EditorRequired: true,
              temp: true,
            });
          } else if (this.state.displayDate.length > 0) {
            this.setState({
              dateRequired: false,
              EditorRequired: true,
              temp: true,
            });
          }
          return;
        } else if (arrayValEditor.length > 0) {
          if (this.state.displayDate.length <= 0) {
            this.setState({
              dateRequired: true,
              EditorRequired: false,
              temp: false,
            });
            return;
          } else if (this.state.displayDate.length > 0) {
            this.setState({
              dateRequired: false,
              EditorRequired: false,
              temp: false,
            });
          }
        }
      }

      this.setState({
        arrayValImageType: arrayValImageType,
        arrayValSource: arrayValSource,
        arrayValChannel: arrayValChannel,
        arrayValDelPtfm: arrayValDelPtfm,
        arrayValContentType: arrayValContentType,
        arrayValGenre: arrayValGenre,
        arrayValSubGenre: arrayValSubGenre,
        arrayValRatio: arrayValRatio,
        isHelpScreenActive: false,
        isConfirmActive: false,
        arrayValLoc: arrayValLoc,
        arrayValEditor: arrayValEditor,
        arrayValImageEditType: arrayValImageEditType,
        arrayValImgSize: arrayValImageSize,
        arrayValGroup: arrayValGroup,
        arrayValAssignedLanguage: arrayValAssignedLanguage,
        LinearVOD: LinearVOD,
      });

      let pageSizeTemp1, pageSizeTemp2;
      let currentPageTemp1, currentPageTemp2;

      if (this.props.data.status === "1") {
        pageSizeTemp1 =
          this.props.data.first < 2 ? 25 : this.props.data.tabContent.pageSize;
        currentPageTemp1 =
          this.props.data.first < 2
            ? 1
            : this.props.data.tabContent.currentPage;
        this.setState({
          pageSize: pageSizeTemp1,
          currentPage: currentPageTemp1,
        });
      } else if (this.props.data.status !== "1") {
        pageSizeTemp2 =
          this.props.data.first < 2 ? 25 : this.props.data.tabContent.pageSize;
        currentPageTemp2 =
          this.props.data.first < 2
            ? 0
            : this.props.data.tabContent.currentPage;
        this.setState({
          pageSize: pageSizeTemp2,
          currentPage: currentPageTemp2,
        });
      }

      this.setState({ loading: "true", first: 0 });
      if (editorIndex.length > 0) {
        if (this.props.data.status === "1") {
          axios
            .post(`${config.apiUrl + config.qcApi}Image/GetDailyQCImages/`, {
              lstEditorID: editorIndex,
              dtmFromDate: moment(this.state.startDate).format("YYYY-MM-DD"),
              dtmToDate: moment(this.state.endDate).format("YYYY-MM-DD"),
              dtmairingFromDate:
                this.state.displayAiringDate !== ""
                  ? moment(this.state.startAiringDate).format("YYYY-MM-DD")
                  : null,
              dtmairingToDate:
                this.state.displayAiringDate !== ""
                  ? moment(this.state.endAiringDate).format("YYYY-MM-DD")
                  : null,
              lstSourceID: sourceIndex,
              lstChannelID: channelIndex,
              lstDeliveryPlatform: deliveryIndex || [],
              lstContentType: contentIndex || [],
              lstImageType: imagetypeIndex || [],
              lstGenre: genreIndex || [],
              lstSubGenre: subgenreIndex || [],
              lstRatio: ratioIndex || [],
              intUserRoleID: localStorage.getItem("userRoleID"),
              lstImageEditType: imgEditTypeIndex,
              lstImageSize: imgSizeIndex,
              lstChannelGroup: groupIndex,
              lstAssnLang: assignedlangIndex,
              sovdimage: this.state.LinearVOD,
            })
            .then((response) => {
              //   setTimeout(function(){
              if (
                response.status === 200 &&
                response.data &&
                response.data.objRespMessage
              ) {
                this.setState({
                  filteredData: response.data.objRespMessage,
                  loading: "false",
                  pageSize: pageSizeTemp1,
                  currentPage: currentPageTemp1,
                  showError: false,
                });
                this.props.applyFilter({
                  propsData: this.props.data,
                  stateData: this.state,
                });
              } else if (response.status === 204) {
                this.setState({
                  filteredData: [],
                  loading: "false",
                  showError: false,
                });
                this.props.applyFilter({
                  propsData: this.props.data,
                  stateData: this.state,
                });
              } else {
                this.setState({
                  filteredData: [],
                  loading: "false",
                  showError: true,
                });
              } //}, 3000);
            })
            .catch((error) => {
              if (!error.response) {
                error = "Not able to communicate with the backend service !";
                console.log(error);
              } else {
                switch (error.response.status) {
                  case 404:
                    error =
                      "API Error - 404 received from the API\n\n API URL -" +
                      error.request.responseURL;
                    console.log(error);
                    break;

                  case 504:
                    error =
                      "API Error - 504 received from the API\n\n API URL -" +
                      error.request.responseURL;
                    console.log(error);
                    break;

                  default:
                    error =
                      "Unknown Error received from the API - Status " +
                      error.response.status;
                    console.log(error);
                    break;
                }
              }
              this.setState({
                filteredData: [],
                loading: "false",
                showError: true,
              });
            });
        } else {
          axios
            .post(`${config.apiUrl + config.qcApi}Image/GetTabularImages/`, {
              objDailyQCFilter: {
                lstEditorID: editorIndex,
                dtmFromDate: moment(this.state.startDate).format("YYYY-MM-DD"),
                dtmToDate: moment(this.state.endDate).format("YYYY-MM-DD"),
                dtmairingFromDate:
                  this.state.displayAiringDate !== ""
                    ? moment(this.state.startAiringDate).format("YYYY-MM-DD")
                    : null,
                dtmairingToDate:
                  this.state.displayAiringDate !== ""
                    ? moment(this.state.endAiringDate).format("YYYY-MM-DD")
                    : null,
                lstSourceID: sourceIndex,
                lstChannelID: channelIndex,
                lstDeliveryPlatform: deliveryIndex || [],
                lstContentType: contentIndex || [],
                lstImageType: imagetypeIndex || [],
                lstGenre: genreIndex || [],
                lstSubGenre: subgenreIndex || [],
                lstRatio: ratioIndex || [],
                lstImageEditType: imgEditTypeIndex,
                lstImageSize: imgSizeIndex,
                lstChannelGroup: groupIndex,
                sovdimage: this.state.LinearVOD,
              },
              intActionStatusCode: this.props.data.status,
              intUserRoleID: localStorage.getItem("userRoleID"),
            })
            .then((response) => {
              if (
                response.status === 200 &&
                response.data &&
                response.data.objRespMessage
              ) {
                this.setState({
                  filteredData: response.data.objRespMessage,
                  loading: "false",
                  showError: false,
                });
                this.props.applyFilter({
                  propsData: this.props.data,
                  stateData: this.state,
                });
                SharedService.setFilterDataForTabularView(
                  response.data.objRespMessage
                );
              } else if (response.status === 204) {
                this.setState({
                  filteredData: [],
                  loading: "false",
                  showError: false,
                });
                this.props.applyFilter({
                  propsData: this.props.data,
                  stateData: this.state,
                });
                SharedService.setFilterDataForTabularView(
                  response.data.objRespMessage
                );
              } else {
                this.setState({
                  filteredData: [],
                  loading: "false",
                  showError: true,
                });
              }
            })
            .catch((error) => {
              if (!error.response) {
                error = "Not able to communicate with the backend service !";
                console.log(error);
              } else {
                switch (error.response.status) {
                  case 404:
                    error =
                      "API Error - 404 received from the API\n\n API URL -" +
                      error.request.responseURL;
                    console.log(error);
                    break;

                  case 504:
                    error =
                      "API Error - 504 received from the API\n\n API URL -" +
                      error.request.responseURL;
                    console.log(error);
                    break;

                  default:
                    error =
                      "Unknown Error received from the API - Status " +
                      error.response.status;
                    console.log(error);
                    break;
                }
              }
              this.setState({
                filteredData: [],
                loading: "false",
                showError: true,
              });
            });
        }
      } else {
        this.setState({ filteredData: [], loading: "false", showError: false });
      }
    }
  };

  scrollfunction = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      this.setState({ isShowTopBtn: true })
    }
    else {
      this.setState({ isShowTopBtn: false })
    }
  }

  topFunction=()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  handleClick() {
    this.applyFilters();
  }
  LinearVOD(data) {
    if (data !== null && data !== undefined) {
      this.setState({ LinearVOD: !data, arrayValDelPtfm: [] });
    } else {
      this.setState({ LinearVOD: !this.state.LinearVOD, arrayValDelPtfm: [] });
    }
  }
  handlePageSizeChange = (e, currentPage) => {
    let pagesize = Number(e.target.value);
    sessionStorage.setItem("QCPageSize", pagesize);
    sessionStorage.setItem("QCCurrentPage", currentPage);
    this.setState({ pageSize: pagesize, currentPage: 1 });
    SharedService.setFilterData({
      action: "paginationCount",
      status: "1",
      pageSize: pagesize,
      currentPage: 1,
      worklisttype: "2",
    });
  };

  handlePageCountChange = (pagesize, currentPage) => {
    sessionStorage.setItem("QCPageSize", pagesize);
    sessionStorage.setItem("QCCurrentPage", currentPage);
    let currentPageState = currentPage;
    this.setState({ currentPage: currentPageState });
    SharedService.setFilterData({
      action: "paginationCount",
      status: "1",
      pageSize: pagesize,
      currentPage: currentPageState,
      worklisttype: "2",
    });
  };

  applyReset() {
    if (
      !this.state.isHelpScreenActive &&
      !this.state.isConfirmActive &&
      !this.isDropDownActive()
    ) {
      this.setState({
        arrayValLoc: [],
        arrayValEditor: [],
        arrayValSource: [],
        arrayValChannel: [],
        arrayValImageType: [],
        arrayValDelPtfm: [],
        arrayValContentType: [],
        arrayValGenre: [],
        arrayValSubGenre: [],
        arrayValRatio: [],
        displayDate: "",
        filteredData: [],
        EditorRequired: false,
        Required: false,
        endDate: moment(),
        startDate: moment().subtract(2, "days"),
        startAiringDate: moment(),
        endAiringDate: moment(),
        displayAiringDate: "",
        arrayValImageEditType: [],
        arrayValImgSize: [],
        arrayValGroup: [],
        arrayValAssignedLanguage: [],
        LinearVOD: false,
      });
      this.props.applyReset({ stateData: this.state });
    }
  }

  stopShortCut = (tmpIsConfirmActive) => {
    this.setState({ isConfirmActive: tmpIsConfirmActive });
  };

  isDropOpen(val, drpDwnName) {
    if (drpDwnName === "Editor Location") {
      this.setState({ isLocationDropdown: val });
    } else if (drpDwnName === "Editor") {
      this.setState({ isEditorDropdown: val });
    } else if (drpDwnName === "Source") {
      this.setState({ isSourceDropdown: val });
    } else if (drpDwnName === "Channel") {
      this.setState({ isChannelDropdown: val });
    } else if (drpDwnName === "Image Edit Type") {
      this.setState({ isImageEditTypeDropdown: val });
    } else if (drpDwnName === "Content Type") {
      this.setState({ isContenTypeDropdown: val });
    } else if (drpDwnName === "Image Delivery") {
      this.setState({ isImgDlvPltDropdown: val });
    } else if (drpDwnName === "Image Type") {
      this.setState({ isImgTypDropdown: val });
    } else if (drpDwnName === "Genre") {
      this.setState({ isGenreDropdown: val });
    } else if (drpDwnName === "Sub Genre") {
      this.setState({ isSubGenreDropdown: val });
    } else if (drpDwnName === "Aspect Ratio") {
      this.setState({ isRationDropdown: val });
    } else if (drpDwnName === "Image Size") {
      this.setState({ isImgSizeDropdown: val });
    } else if (drpDwnName === "Group") {
      this.setState({ isGroupDropdown: val });
    } else if (drpDwnName === "Assigned Language") {
      this.setState({ isAssnLangDropdown: val });
    }
  }

  isDropDownActive = () => {
    let retVal = false;
    if (
      this.state.isEditorDropdown ||
      this.state.isLocationDropdown ||
      this.state.isSourceDropdown ||
      this.state.isChannelDropdown ||
      this.state.isImageEditTypeDropdown ||
      this.state.isContenTypeDropdown ||
      this.state.isImgDlvPltDropdown ||
      this.state.isImgTypDropdown ||
      this.state.isGenreDropdown ||
      this.state.isSubGenreDropdown ||
      this.state.isRationDropdown ||
      this.state.isImgSizeDropdown ||
      this.state.isGroupDropdown ||
      this.state.isAssnLangDropdown ||
      this.state.customFilter
    ) {
      retVal = true;
    }
    return retVal;
  };

  daterangeclicked = (e) => {
    document.getElementById("datePicker").readOnly = false;
  };

  render() {
    if (
      sessionStorage.getItem("isEditorReverted") === "0" &&
      sessionStorage.getItem("firstTab") === "1"
    ) {
      sessionStorage.setItem("isActiveTab", this.props.data.status);
    } else if (this.props.data.status !== "8") {
      sessionStorage.setItem("isEditorReverted", "0");
    } else if (sessionStorage.getItem("isEditorReverted") === "1") {
      sessionStorage.setItem("isActiveTab", "8");
    }

    const {
      bindLocation,
      bindEditorByLoc,
      bindContentType,
      bindGenre,
      bindSubgenre,
      bindDeliveryPlatform,
      bindRatio,
      bindImageType,
      bindSource,
      bindChannel,
      bindImageEditType,
      bindImgSize,
      bindGroup,
      bindAssignedLanguage,
    } = this.props.data.tabContent;
    let listLoc = [];
    bindLocation.options.map((location) =>
      listLoc.push({
        id: location.intOfficeLocID,
        name: location.strOfficeLocation,
      })
    );

    let listEditor = [];
    bindEditorByLoc.options.map((editor) =>
      listEditor.push({ id: editor.intEditorId, name: editor.strEditorName })
    );

    let listSource = [];
    bindSource.options.map((source) =>
      listSource.push({ id: source.intSourceID, name: source.strSource })
    );

    let listChannel = [];
    bindChannel.options.map((channel) =>
      listChannel.push({
        id: channel.strChannelID,
        name: channel.strChannelName,
      })
    );

    let listDeliveryPtfm = [];
    bindDeliveryPlatform.options.map((deliveryptfm) =>
      listDeliveryPtfm.push({
        id: deliveryptfm.lngDeliveryPlatformID,
        name: deliveryptfm.strDeliveryPlatformName,
      })
    );

    let listImageEditType = [];
    bindImageEditType.options.map((imgEditTyp) =>
      listImageEditType.push({
        id: imgEditTyp.intImgTypeID,
        name: imgEditTyp.strImgType2,
      })
    );

    let listContentType = [];
    bindContentType.options.map((contenttype) =>
      listContentType.push({
        id: contenttype.lngContentTypeID,
        name: contenttype.strContentTypeName,
      })
    );

    let listImageType = [];
    bindImageType.options.map((imagetype) =>
      listImageType.push({
        id: imagetype.intImageTypeID,
        name: imagetype.strImageTypeName,
      })
    );

    let listGenre = [];
    bindGenre.options.map((genre) =>
      listGenre.push({ id: genre.strGenreID, name: genre.strGenreName })
    );

    let listSubGenre = [];
    bindSubgenre.options.map((subgenre) =>
      listSubGenre.push({
        id: subgenre.strSubGenreID,
        name: subgenre.strSubGenreName,
      })
    );

    let listRatio = [];
    bindRatio.options.map((ratio) =>
      listRatio.push({ id: ratio.intRatioID, name: ratio.strRatioName })
    );

    let listImgSize = [];
    bindImgSize.options.map((size) =>
      listImgSize.push({ id: size.intImageSizeID, name: size.strImgSizeName })
    );

    let listGroup = [];
    bindGroup.options.map((grp) =>
      listGroup.push({ id: grp.intGroupID, name: grp.strGroupName })
    );

    let listAssignedLanguage = [];

    bindAssignedLanguage.options.map((al) =>
      listAssignedLanguage.push({
        id: al.strLanguageID,
        name: al.strLanguageName,
      })
    );

    let gridSection;
    if (
      this.props.data.tabContent.loader === "false" &&
      this.state.loading === "false"
    ) {
      if (
        this.props.data.tabContent.filteredData.length > 0 &&
        this.props.data.status === "1"
      ) {
        gridSection = (
          <Gallery
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            filterParams={this.props.data.tabContent.filteredData}
            handlePageSizeChange={this.handlePageSizeChange}
            rejectionErrorList={this.props.data.tabContent.bindErrors.options}
            loader={this.state.loading}
            respData={this.props.applyFilter}
            handlePageCountChange={this.handlePageCountChange}
            status={this.props.data.status}
            stopShortCuts={this.stopShortCut}
            WorkListID="2"
          />
        );
      } else if (
        this.props.data.tabContent.filteredData.length > 0 &&
        this.props.data.status !== "1"
      ) {
        gridSection = (
          <Tables
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            filter={this.props.data.tabContent.filteredData}
            status={this.props.data.status}
            stopShortCuts={this.stopShortCut}
            WorkListID="2"
          ></Tables>
        );
      } else if (this.props.data.tabContent.filteredData.length === 0) {
        gridSection = (
          <div>
            <div
              className="tab-pane fade show active"
              id="qc-worklist"
              role="tabpanel"
              aria-labelledby="qc-tab"
            >
              <div className="row ml-0 mr-0">
                <div className="col-md-12">
                  <div
                    className="border pt-3 pb-3 pr-3 pl-3"
                    style={{ overflow: "hidden" }}
                  >
                    <div className="pull-left pt-3">
                      <h3 className="txt-orange fw-normal fs18">
                        0 Images Found{" "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else if (this.state.loading === "true") {
      gridSection = (
        <div id="loader-wrapper">
          <div id="loader"></div>
        </div>
      );
    }
    window.addEventListener("scroll",this.scrollfunction)

    return (
      <div>
        <KeyboardEventHandler
          handleKeys={["Enter"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.applyFilters()}
        />
        <KeyboardEventHandler
          handleKeys={["r"]}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.applyReset()}
        />

        <div className="tab-content mt-3" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="qc-worklist"
            role="tabpanel"
            aria-labelledby="qc-tab"
          >
            <div className="row ml-0 mr-0">
              <div className="col-md-3">
                <div className="form-group dropdown-select">
                  <label
                    htmlFor="field-1"
                    className="control-label fs11 pl-2 txt-blue"
                  >
                    Upload From & To Date <span className="txt-red">*</span>
                  </label>
                  <DateRangePickerNew
                    id={"uploadDate"}
                    autoApply
                    onApply={this.onApplyDate}
                    minDate={moment().add(-6, "months")}
                    maxDate={moment()}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  >
                    <input
                      autoComplete="off"
                      readOnly
                      onClick={this.daterangeclicked.bind(this)}
                      type="text"
                      className={
                        this.state.dateRequired
                          ? "form__daterange form-control daterange hasDatepicker required"
                          : "form__daterange form-control daterange hasDatepicker"
                      }
                      id="datePicker"
                      value={this.state.displayDate === "" ? "" : this.state.displayDate}
                    />
                    <div className="cal-icon">
                      <i className="fa fa-calendar"></i>
                    </div>
                  </DateRangePickerNew>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group dropdown-select">
                  <label
                    htmlFor="field-1"
                    className="control-label fs11 pl-2 txt-blue"
                  >
                    Airing From & To Date{" "}
                  </label>
                  <DateRangePickerNew
                    id={"airingDate"}
                    autoApply
                    onApply={this.onApplyDateAiring}
                    onCancel={this.onCancelAiring.bind(this)}
                    minDate={moment().subtract(14, "day")}
                    maxDate={moment().add(28, "day")}
                    startDate={this.state.startAiringDate}
                    endDate={this.state.endAiringDate}
                  >
                    <input
                      readOnly
                      type="text"
                      className={
                        "form__daterange form-control daterange hasDatepicker airingdate"
                      }
                      id="datePicker1"
                      value={this.state.displayAiringDate}
                      autoComplete="off"
                    />
                    <div className="cal-icon">
                      <i className="fa fa-calendar mr5"></i>
                    </div>
                  </DateRangePickerNew>
                  <i
                    className="fa fa-undo user-name icon-deactive airing-reset"
                    aria-hidden="true"
                    onClick={() => {
                      this.onCancelAiring();
                    }}
                  ></i>
                </div>
              </div>
              <Dropdown
                label={"Editor Location"}
                selectedvalue={this.state.arrayValLoc}
                option={listLoc}
                change={this.selectMultipleOption.bind(this, "Editor Location")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(
                  this,
                  true,
                  "Editor Location"
                )}
                onDropdownClose={this.isDropOpen.bind(
                  this,
                  false,
                  "Editor Location"
                )}
              ></Dropdown>
              <Dropdown
                label={"Editor"}
                customClass={"contentTypeClass"}
                refreshIcon={
                  <i
                    className="fa fa-refresh user-name icon-deactive contentTypeRefresh "
                    aria-hidden="true"
                     onClick={this.onRefreshEditorRevert}
                  ></i>
                }
                Editorvalidation={this.state.EditorRequired}
                selectedvalue={
                  this.props.data.tabContent.bindEditorByLoc.selectedValue
                }
                option={listEditor}
                includeSelectAll={this.state.isSelectAll}
                change={this.props.selectMultipleOptionTest.bind(
                  this,
                  this.props.data
                )}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Editor")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Editor")}
                status={this.props.data.status}
              ></Dropdown>
            </div>
            <div className="row ml-0 mr-0">
              <Dropdown
                label={"Source"}
                selectedvalue={this.state.arrayValSource}
                option={listSource}
                change={this.selectMultipleOption.bind(this, "Source")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Source")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Source")}
              ></Dropdown>
              <Dropdown
                label={"Channel"}
                selectedvalue={this.state.arrayValChannel}
                option={listChannel}
                change={this.selectMultipleOption.bind(this, "Channel")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Channel")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Channel")}
              ></Dropdown>
              <Dropdown
                label={"Image Edit Type"}
                selectedvalue={this.state.arrayValImageEditType}
                option={listImageEditType}
                change={this.selectMultipleOption.bind(this, "Image Edit Type")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(
                  this,
                  true,
                  "Image Edit Type"
                )}
                onDropdownClose={this.isDropOpen.bind(
                  this,
                  false,
                  "Image Edit Type"
                )}
              ></Dropdown>
              <Dropdown
                label={"Content Type"}
                selectedvalue={this.state.arrayValContentType}
                option={listContentType}
                change={this.selectMultipleOption.bind(this, "Content Type")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(
                  this,
                  true,
                  "Content Type"
                )}
                onDropdownClose={this.isDropOpen.bind(
                  this,
                  false,
                  "Content Type"
                )}
              ></Dropdown>
              {/* <Dropdown label={"Image Delivery Platform"} selectedvalue={this.state.arrayValDelPtfm} option={listDeliveryPtfm} change={this.selectMultipleOption.bind(this, 'Image Delivery Platform')} filterFlag={true} onDropdownOpen={this.isDropOpen.bind(this, true, 'Image Delivery')} onDropdownClose={this.isDropOpen.bind(this, false, 'Image Delivery')}></Dropdown> */}
            </div>
            <div className="row ml-0 mr-0">
              <Dropdown
                label={"Image Delivery Platform"}
                disabled={this.state.LinearVOD}
                selectedvalue={this.state.arrayValDelPtfm}
                option={listDeliveryPtfm}
                change={this.selectMultipleOption.bind(
                  this,
                  "Image Delivery Platform"
                )}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(
                  this,
                  true,
                  "Image Delivery"
                )}
                onDropdownClose={this.isDropOpen.bind(
                  this,
                  false,
                  "Image Delivery"
                )}
              ></Dropdown>
              <Dropdown
                label={"Image Type"}
                selectedvalue={this.state.arrayValImageType}
                option={listImageType}
                change={this.selectMultipleOption.bind(this, "Image Type")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Image Type")}
                onDropdownClose={this.isDropOpen.bind(
                  this,
                  false,
                  "Image Type"
                )}
              ></Dropdown>
              <Dropdown
                label={"Genre"}
                selectedvalue={this.state.arrayValGenre}
                option={listGenre}
                change={this.selectMultipleOption.bind(this, "Genre")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Genre")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Genre")}
              ></Dropdown>
              <Dropdown
                label={"Sub Genre"}
                selectedvalue={this.state.arrayValSubGenre}
                option={listSubGenre}
                change={this.selectMultipleOption.bind(this, "Sub Genre")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Sub Genre")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Sub Genre")}
              ></Dropdown>
              {/* <Dropdown label={"Aspect Ratio"} selectedvalue={this.state.arrayValRatio} option={listRatio} change={this.selectMultipleOption.bind(this, 'Aspect Ratio')} filterFlag={true} onDropdownOpen={this.isDropOpen.bind(this, true, 'Aspect Ratio')} onDropdownClose={this.isDropOpen.bind(this, false, 'Aspect Ratio')}></Dropdown> */}
            </div>
            <div className="row ml-0 mr-0">
              <Dropdown
                label={"Aspect Ratio"}
                selectedvalue={this.state.arrayValRatio}
                option={listRatio}
                change={this.selectMultipleOption.bind(this, "Aspect Ratio")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(
                  this,
                  true,
                  "Aspect Ratio"
                )}
                onDropdownClose={this.isDropOpen.bind(
                  this,
                  false,
                  "Aspect Ratio"
                )}
              ></Dropdown>
              <Dropdown
                label={"Image Size"}
                selectedvalue={this.state.arrayValImgSize}
                option={listImgSize}
                change={this.selectMultipleOption.bind(this, "Image Size")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Image Size")}
                onDropdownClose={this.isDropOpen.bind(
                  this,
                  false,
                  "Image Size"
                )}
                clearfilter={true}
              ></Dropdown>
              <Dropdown
                label={"Group"}
                selectedvalue={this.state.arrayValGroup}
                option={listGroup}
                change={this.selectMultipleOption.bind(this, "Group")}
                filterFlag={true}
                onDropdownOpen={this.isDropOpen.bind(this, true, "Group")}
                onDropdownClose={this.isDropOpen.bind(this, false, "Group")}
              ></Dropdown>
              {this.props.data.status === "1" ? (
                <Dropdown
                  label={"Assigned Language"}
                  selectedvalue={this.state.arrayValAssignedLanguage}
                  option={listAssignedLanguage}
                  change={this.selectMultipleOption.bind(
                    this,
                    "Assigned Language"
                  )}
                  filterFlag={true}
                  onDropdownOpen={this.isDropOpen.bind(
                    this,
                    true,
                    "Assigned Language"
                  )}
                  onDropdownClose={this.isDropOpen.bind(
                    this,
                    false,
                    "Assigned Language"
                  )}
                ></Dropdown>
              ) : null}
            </div>
            <div className="row ml-0 mr-0">
              <ToggleSwitch
                label={"Linear and VOD"}
                onChange={() => {
                  this.LinearVOD();
                }}
                checked={this.state.LinearVOD}
              ></ToggleSwitch>
            </div>
            <div className="col-md-12 mt-1 apply-filter-block">
              {this.state.isShowTopBtn && <button type="button" id="myBtn" onClick={this.topFunction}>
                <i className="material-icons">keyboard_arrow_up</i>
              </button>}
              <button
                type="button"
                className="filter btn fw-normal btn-white txt-blue pl-3 pr-3"
                onClick={this.handleClick.bind(this)}
              >
                Apply Filters
              </button>
              {this.state.arrayValLoc.length > 0 ||
              this.props.data.tabContent.bindEditorByLoc.selectedValue.length >
                0 ||
              this.state.arrayValSource.length > 0 ||
              this.state.arrayValChannel.length > 0 ||
              this.state.arrayValImageType.length > 0 ||
              this.state.arrayValDelPtfm.length > 0 ||
              this.state.arrayValContentType.length > 0 ||
              this.state.arrayValGenre.length > 0 ||
              this.state.arrayValSubGenre.length > 0 ||
              this.state.arrayValRatio.length > 0 ||
              this.state.displayDate.length > 0 ||
              this.props.data.tabContent.filteredData.length > 0 ||
              this.state.arrayValImgSize.length > 0 ||
              this.state.arrayValGroup.length > 0 ||
              this.state.arrayValAssignedLanguage.length > 0 ||
              this.state.LinearVOD ? (
                <button
                  type="button"
                  className="reset btn btn-reset"
                  onClick={this.applyReset.bind(this)}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset
                </button>
              ) : null}
            </div>
          </div>
          {/* <DivWithErrorHandling showError={this.state.showError}> */}
          {gridSection}
          {/* </DivWithErrorHandling> */}
        </div>
      </div>
    );
  }
}
export default MainDropdown;
