import React from "react";

export default class ErrorPage extends React.Component {
  render() {
    return (
      // <div>
      //     <h3 className=""><span className=""></span> HTTP Error 404 - Page not found!</h3>
      // </div>
      <div>
        <div className="logout-block">
          <div>
            <h5 className="logout-sucess">HTTP Error 404 - Page not found!</h5>
          </div>
        </div>
        <p className="main-footer text-right logout-footer">
          � 2000-present. Gracenote, A Nielsen Company. All rights reserved.
        </p>
      </div>
    );
  }
}
